import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    userProfile: {
        data: null,
        isUserInfoFound: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    brandProfile: {
        data: null,
        isBrandInfoFound: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    collectionInfo: {
        data: null,
        isCollectionInfoFound: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    productInfo: {
        data: null,
        isProductInfoFound: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    promotionInfo: {
        data: null,
        isPromotionInfoFound: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    rewardsPoliciesInfo: {
        data: null,
        isRewardsPoliciesInfoFound: false,
        isLoading: false,
        isError: false,
        error: '',
    },
};

const publicPageSlice = createSlice({
    name: 'publicPage',
    initialState: initialState,
    reducers: {
        getUserProfile: (state, action) => {
            state.userProfile.isLoading = true;
        },
        getUserProfileSuccess: (state, action) => {
            state.userProfile.data = action?.payload;
            state.userProfile.isUserInfoFound = true;
            state.userProfile.isLoading = false;
            state.userProfile.isError = false;
            state.userProfile.error = '';
        },
        getUserProfileFailed: (state, action) => {
            state.userProfile.data = null;
            state.userProfile.isUserInfoFound = false;
            state.userProfile.isLoading = false;
            state.userProfile.isError = true;
            state.userProfile.error = action.payload;
        },
        getUserProfileStateClear: (state, action) => {
            state.userProfile.data = null;
            state.userProfile.isUserInfoFound = false;
            state.userProfile.isLoading = false;
            state.userProfile.isError = false;
            state.userProfile.error = '';
        },

        getBrandProfile: (state, action) => {
            state.brandProfile.isLoading = true;
        },
        getBrandProfileSuccess: (state, action) => {
            state.brandProfile.data = action?.payload;
            state.brandProfile.isBrandInfoFound = true;
            state.brandProfile.isLoading = false;
            state.brandProfile.isError = false;
            state.brandProfile.error = '';
        },
        getBrandProfileFailed: (state, action) => {
            state.brandProfile.data = null;
            state.brandProfile.isBrandInfoFound = false;
            state.brandProfile.isLoading = false;
            state.brandProfile.isError = true;
            state.brandProfile.error = action.payload;
        },
        getBrandProfileStateClear: (state, action) => {
            state.brandProfile.data = null;
            state.brandProfile.isBrandInfoFound = false;
            state.brandProfile.isLoading = false;
            state.brandProfile.isError = false;
            state.brandProfile.error = '';
        },

        getCollectionInfo: (state, action) => {
            state.collectionInfo.isLoading = true;
        },
        getCollectionInfoSuccess: (state, action) => {
            state.collectionInfo.data = action?.payload;
            state.collectionInfo.isCollectionInfoFound = true;
            state.collectionInfo.isLoading = false;
            state.collectionInfo.isError = false;
            state.collectionInfo.error = '';
        },
        getCollectionInfoFailed: (state, action) => {
            state.collectionInfo.data = null;
            state.collectionInfo.isCollectionInfoFound = false;
            state.collectionInfo.isLoading = false;
            state.collectionInfo.isError = true;
            state.collectionInfo.error = action.payload;
        },
        getCollectionInfoStateClear: (state, action) => {
            state.collectionInfo.data = null;
            state.collectionInfo.isCollectionInfoFound = false;
            state.collectionInfo.isLoading = false;
            state.collectionInfo.isError = false;
            state.collectionInfo.error = '';
        },

        getProductInfo: (state, action) => {
            state.productInfo.isLoading = true;
        },
        getProductInfoSuccess: (state, action) => {
            state.productInfo.data = action?.payload;
            state.productInfo.isProductInfoFound = true;
            state.productInfo.isLoading = false;
            state.productInfo.isError = false;
            state.productInfo.error = '';
        },
        getProductInfoFailed: (state, action) => {
            state.productInfo.data = null;
            state.productInfo.isProductInfoFound = false;
            state.productInfo.isLoading = false;
            state.productInfo.isError = true;
            state.productInfo.error = action.payload;
        },
        getProductInfoStateClear: (state, action) => {
            state.productInfo.data = null;
            state.productInfo.isProductInfoFound = false;
            state.productInfo.isLoading = false;
            state.productInfo.isError = false;
            state.productInfo.error = '';
        },

        getPromotionInfo: (state, action) => {
            state.promotionInfo.isLoading = true;
        },
        getPromotionInfoSuccess: (state, action) => {
            state.promotionInfo.data = action?.payload;
            state.promotionInfo.isPromotionInfoFound = true;
            state.promotionInfo.isLoading = false;
            state.promotionInfo.isError = false;
            state.promotionInfo.error = '';
        },
        getPromotionInfoFailed: (state, action) => {
            state.promotionInfo.data = null;
            state.promotionInfo.isPromotionInfoFound = false;
            state.promotionInfo.isLoading = false;
            state.promotionInfo.isError = true;
            state.promotionInfo.error = action.payload;
        },
        getPromotionInfoStateClear: (state, action) => {
            state.promotionInfo.data = null;
            state.promotionInfo.isPromotionInfoFound = false;
            state.promotionInfo.isLoading = false;
            state.promotionInfo.isError = false;
            state.promotionInfo.error = '';
        },

        getRewardsPoliciesInfo: (state, action) => {
            state.rewardsPoliciesInfo.isLoading = true;
        },
        getRewardsPoliciesInfoSuccess: (state, action) => {
            state.rewardsPoliciesInfo.data = action?.payload;
            state.rewardsPoliciesInfo.isRewardsPoliciesInfoFound = true;
            state.rewardsPoliciesInfo.isLoading = false;
            state.rewardsPoliciesInfo.isError = false;
            state.rewardsPoliciesInfo.error = '';
        },
        getRewardsPoliciesInfoFailed: (state, action) => {
            state.rewardsPoliciesInfo.data = null;
            state.rewardsPoliciesInfo.isRewardsPoliciesInfoFound = false;
            state.rewardsPoliciesInfo.isLoading = false;
            state.rewardsPoliciesInfo.isError = true;
            state.rewardsPoliciesInfo.error = action.payload;
        },
        getRewardsPoliciesInfoStateClear: (state, action) => {
            state.rewardsPoliciesInfo.data = null;
            state.rewardsPoliciesInfo.isRewardsPoliciesInfoFound = false;
            state.rewardsPoliciesInfo.isLoading = false;
            state.rewardsPoliciesInfo.isError = false;
            state.rewardsPoliciesInfo.error = '';
        },

    }
});

export const {
    getUserProfile,
    getUserProfileSuccess,
    getUserProfileFailed,
    getUserProfileStateClear,

    getBrandProfile,
    getBrandProfileSuccess,
    getBrandProfileFailed,
    getBrandProfileStateClear,

    getCollectionInfo,
    getCollectionInfoSuccess,
    getCollectionInfoFailed,
    getCollectionInfoStateClear,

    getProductInfo,
    getProductInfoSuccess,
    getProductInfoFailed,
    getProductInfoStateClear,

    getPromotionInfo,
    getPromotionInfoSuccess,
    getPromotionInfoFailed,
    getPromotionInfoStateClear,

    getRewardsPoliciesInfo,
    getRewardsPoliciesInfoSuccess,
    getRewardsPoliciesInfoFailed,
    getRewardsPoliciesInfoStateClear,

} = publicPageSlice.actions;

export default publicPageSlice.reducer;
