import React, {useCallback, useEffect, useState} from "react";
import './Style.css';
import getCroppedImg from "./CropImageFunctionality";
import Cropper from "react-easy-crop";
import {Button, Slider, Stack, Typography} from "@mui/material";
import AWS from 'aws-sdk';


const ImageCropper = ({ onCropImage, inputImg, imgName, closeModal, ratio, type}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            let typeName = type;
            const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels, typeName);
            onCropImage(
                new File([croppedImage], imgName, {
                    type: "image/png",
                    lastModified: new Date().getTime(),
                })
            );
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels]);
    return (
        <div className="cropper" style={{textAlign: 'center'}}>
            <Cropper
                minZoom={1}
                image={inputImg}
                crop={crop}
                zoom={zoom}
                aspect={type == '1' ? 3/2 : 9/16}
                restrictPosition={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                style={{
                    containerStyle: {
                        width: 500,
                        height: 300,
                        position: "relative",
                        backgroundColor: '#d3d3d3'
                    },
                }}
            />
                <Stack direction={'row'} spacing={3} px={4} py={2}>
                    <Typography>Zoom: </Typography>
                    <Slider
                        type="range"
                        defaultValue={zoom}
                        value={zoom}
                        aria-labelledby="Zoom"
                        max={3.2}
                        min={ratio ? 0.4 : 0.6}
                        step={0.1}
                        onChange={e => setZoom(e.target.value)}
                    />
                </Stack>

                <Stack
                    p={'16px'}
                    sx={{borderTop: 1, borderColor: 'other.divider'}}
                    direction={'row'}
                    justifyContent={'flex-end'}
                    spacing={1}
                >
                    <Button
                        onClick={closeModal}
                        sx={{color: 'text.primary'}}
                        variant="text"
                    >
                        Reset Image
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            showCroppedImage();
                            // closeModal();
                        }}
                    >
                        Save Image
                    </Button>

                </Stack>
        </div>
    );
}

export default ImageCropper;