import React, {useState} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import GraphReport from "./GraphReport";
import DateRangeController from "./DateRangePicker/DateRangeController";

const DashboardGraph = ({datePickerCallback, formatedGraphData}) => {
    const [date, setDate] = useState('');

    const handleChange = event => {
        setDate(event.target.value);
    };

    return (
        <>
            <Stack pb={4}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={5}>
                    <Box>
                        <Typography variant="h5">Total Followers</Typography>
                        <Typography variant="h5">Total Product Saved</Typography>
                    </Box>
                    <DateRangeController datePickerCallback={datePickerCallback}/>
                </Stack>

                <Box>
                    <GraphReport formatedGraphData={formatedGraphData}/>
                </Box>
            </Stack>
        </>
    );
};

export default DashboardGraph;
