export const RESPONSE_LOADING_TIME = 1000;
export const ONBOARDING_DELAY_TIME = 2000;
export const RESPONSE_LOADING_TIME_FOR_REGISTRATION = 2000;


//---------STATUS CODE-------------------------------
export const STATUS__ALL = 'ALL';
export const STATUS__APPROVED = 'APPROVED';
export const STATUS__REJECTED = 'REJECTED';
export const STATUS__BLOCKED = 'BLOCKED';
export const STATUS__PENDING = 'PENDING';
export const STATUS__DRAFT = 'DRAFT';
export const STATUS__PUBLISHED = 'PUBLISHED';
export const STATUS__ACTIVE = 'ACTIVE';
export const STATUS__TRASHED = 'TRASH';

//---------STATUS CODE ends-------------------------------

//----------Trash---------------------------------------
export const MOVE__TO__TRASH = true;
export const MOVE__TO__RESTORE = false;
//----------EndTrash-------------------------------------



//----------Banner Fratured---------------------------------------
export const IS__FEATURED__ALL = 'ALL';
export const IS__FEATURED__APPLIED = 'APPLIED';
export const IS__FEATURED__APPLY = "APPLY";
export const IS__FEATURED__DRAFT = "DRAFT";
export const IS__FEATURED__FEATURED = "FEATURED";
//----------EndTrash-------------------------------------

//----------Route keys---------------------------------------
export const DASHBOARD__HIDDEN__KEY = 101;
export const FORCE__LOGIN = 99;
export const ONBOARDING__HIDDEN__KEY = 100;
export const MY_PROFILE__HIDDEN__KEY = 102;
export const MY_PRODUCT__HIDDEN__KEY = 301;
export const ADD_PRODUCT__HIDDEN__KEY = 302;
export const EDIT_PRODUCT__HIDDEN__KEY = 303;
export const MY_GALLERY__HIDDEN__KEY = 401;
export const TEAM_MEMBER__HIDDEN__KEY = 501;
export const ADD_TEAM_MEMBER__HIDDEN__KEY = 502;
export const EDIT_TEAM_MEMBER__HIDDEN__KEY = 503;
export const BANNER__HIDDEN__KEY = 601;
export const ADD_BANNER__HIDDEN__KEY = 602;
export const EDIT_BANNER__HIDDEN__KEY = 603;
export const PROMOTIONS__HIDDEN__KEY = 800;
export const ADD_PROMOTION__HIDDEN__KEY = 801;
export const EDIT_PROMOTION__HIDDEN__KEY = 802;
export const PROMOTION_DETAILS__HIDDEN__KEY = 803;
export const UNAUTHORIZED__HIDDEN__KEY = 700;
//----------End DashboardMain-------------------------------------

//=============================================================
export const FTPO__ADMIN = 'Admin';
export const FTPO__USER = 'User';
export const FTPO__EDITOR = 'Editor';
//=============================================================

export  const PROMOTION_STATUS_INACTIVE = 0
export  const PROMOTION_STATUS_ACTIVE = 1
export  const PROMOTION_STATUS_REQUESTED = 2
export  const PROMOTION_STATUS_COMPLETED = 3
export  const PROMOTION_STATUS_PAUSED = 4