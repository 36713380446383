export const GenderPreferenesLists = [
    {
        id: 1,
        name: 'For Male',
    },
    {
        id: 2,
        name: 'For Female',
    },
    {
        id: 3,
        name: 'For Other Genders',
    },
]

export const GenderPreference = [
    {
        id: 1,
        value: 'ALL',
        name: 'For All Gender',
    },
    {
        id: 2,
        value: 'MALE',
        name: 'For Male',
    },
    {
        id: 3,
        value: 'FEMALE',
        name: 'For Female',
    },
    {
        id: 4,
        value: 'OTHERS',
        name: 'For Other Genders',
    },
]

export const FashionCategories = [
    {
        uid: '99ca995e-fdd4-406d-ab5d-88e62b58fbf7',
        categoryName: 'Causul',
    },
    {
        uid: '99ca995e-fdd4-406d-ab5d-88e62b58fbf4',
        categoryName: 'Formal',
    },
    {
        uid: '99ca995e-fdd4-406d-ab5d-88e62b58fbf3',
        categoryName: 'Funky',
    },
    {
        uid: '99ca995e-fdd4-406d-ab5d-88e62b58fbf1',
        categoryName: 'Futuristic',
    },
]