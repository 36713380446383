import routeLink from "../constant/routeLink";
import HomeIcon from "../../assets/images/Icons/homeIcon.svg";
import HomeIconActive from "../../assets/images/Icons/sidebarDashboardActive.svg";
import MyProductsIcon from "../../assets/images/Icons/MyProductsIcon.svg";
import MyProductsIconActive from "../../assets/images/Icons/sidebarProductActive.svg";
import GalleryIcon from "../../assets/images/Icons/GalleryIcon.svg";
import GalleryIconActive from "../../assets/images/Icons/sidebarGalleryAcive.svg";
import TeamIcon from "../../assets/images/Icons/TeamIcon.svg";
import TeamIconActive from "../../assets/images/Icons/sidebarTeamMemberActive.svg";
import BannersIcon from "../../assets/images/Icons/BannersIcon.svg";
import BannersIconActive from "../../assets/images/Icons/sidebarBannerActive.svg";
import PromotionInactiveIcon from "../../assets/images/Icons/promotionInactive.svg";
import PromotionActiveIcon from "../../assets/images/Icons/promotionActive.svg";

export const appSideBar = [
    {
        key: routeLink.dashboard.hidden_key,
        name: routeLink.dashboard.name,
        route: routeLink.dashboard.to,
        icon: <img src={HomeIcon} alt="" />,
        activeIcon: <img src={HomeIconActive} alt="" />
    },
    {
        key: routeLink.myProduct.hidden_key,
        name: routeLink.myProduct.name,
        route: routeLink.myProduct.to,
        icon: <img src={MyProductsIcon} alt="" />,
        activeIcon: <img src={MyProductsIconActive} alt="" />
    },
    {
        key: routeLink.myGallery.hidden_key,
        name: routeLink.myGallery.name,
        route: routeLink.myGallery.to,
        icon: <img src={GalleryIcon} alt="" />,
        activeIcon: <img src={GalleryIconActive} alt="" />
    },
    {
        key: routeLink.teamMembers.hidden_key,
        name: routeLink.teamMembers.name,
        route: routeLink.teamMembers.to,
        icon: <img src={TeamIcon} alt="" />,
        activeIcon: <img src={TeamIconActive} alt="" />
    },
    {
        key: routeLink.banners.hidden_key,
        name: routeLink.banners.name,
        route: routeLink.banners.to,
        icon: <img src={BannersIcon} alt="" />,
        activeIcon: <img src={BannersIconActive} alt="" />
    },
    {
        key: routeLink.promotions.hidden_key,
        name: routeLink.promotions.name,
        route: routeLink.promotions.to,
        icon: <img src={PromotionInactiveIcon} alt="" />,
        activeIcon: <img src={PromotionActiveIcon} alt="" />
    },
]