import React, { useEffect, useState } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import HeaderWithLogo from '../common/HeaderWithLogo';
import { PublicProfileCard } from '../PublicProfileStyle';
import PublicProfileInfoCard from '../common/PublicProfileInfoCard';
import DownloadSection from '../common/DownloadSection';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPublicPage } from '../../../state/features/publicPage/publicPageSelector';
import { getPromotionInfo } from '../../../state/features/publicPage/publicPageSlice';
import PublicPageSkeletonLoader from '../common/PublicPageSkeletonLoader';
import PromotionImageGallery from './BrandImageBanner';
import UserImageGallery from '../UserProfile/UserImageGallery';
import BrandImageBanner from './BrandImageBanner';

const PublicPromotion = () => {
    const dispatch = useDispatch();
    const { promotionId } = useParams();
    const { promotionInfo } = useSelector(selectPublicPage);

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const getDescription = () => {
        if (promotionInfo?.data?.promotionDescription) {
            if (showFullDescription || (promotionInfo?.data?.promotionDescription?.length <= 150)) {
                return promotionInfo?.data?.promotionDescription;
            } else {
                return `${promotionInfo?.data?.promotionDescription?.substring(0, 150)}...`;
            }
        }
        else
            return 'No Description give'
    };

    useEffect(() => {
        dispatch(getPromotionInfo({ uid: promotionId }))

    }, [promotionId])

    return (
        <Stack height={'100vh'} alignItems={'center'} spacing={{ xs: 1, md: 5 }}>
            <HeaderWithLogo />
            {
                promotionInfo.isLoading ? (
                    <PublicPageSkeletonLoader />
                ) : (
                    <PublicProfileCard sx={{ pt: 1.5, pb: 4 }} variant="outlined" elevation={0}>
                        <Stack p={2} spacing={2}>
                            <Typography variant="h3" fontSize={32}>
                                {promotionInfo?.data?.promotionName ? promotionInfo?.data?.promotionName : 'No Title Given'}
                            </Typography>

                            <Stack spacing={1}>
                                <Typography variant="body2" color={'other.gray7'}>
                                    {getDescription()}
                                </Typography>

                                <Link href="#" underline="none" sx={{ fontSize: 14, fontWeight: 600 }} onClick={toggleDescription}>
                                    {promotionInfo?.data?.promotionDescription?.length > 150 ? showFullDescription ? 'Read Less' : 'Read More' : ''}
                                </Link>
                            </Stack>
                        </Stack>
                        {
                            promotionInfo?.data?.product &&
                                (
                                    <UserImageGallery
                                        images={promotionInfo?.data?.product?.productImages}
                                        cols={promotionInfo?.data?.product?.productImages?.length >= 2 ? 2 : 1}
                                        wrapperStyle={{ height: 250, mt: 0, borderRadius: 5 }}
                                    />
                                )
                        }
                        {
                            promotionInfo?.data?.promotionBanner &&
                            (
                                <BrandImageBanner
                                    image={"https://s3.amazonaws.com/ftpo-media/upload/brand/promotion/image/xscKzN17012630791701263079.jpg"}
                                    cols={1}
                                    wrapperStyle={{ height: 250, mt: 0, borderRadius: 5 }}
                                />
                            )
                        }
                        <Stack
                            spacing={{ xs: 1, sm: 2 }}
                            mt={{ xs: -18, sm: -6 }}
                            zIndex={99}
                            px={{ sm: 2 }}
                            position={'relative'}
                        >
                            <PublicProfileInfoCard
                                imageData={promotionInfo?.data?.brand?.brandLogo && promotionInfo?.data?.brand?.brandLogo}
                                title={
                                    promotionInfo?.data?.product ?
                                        promotionInfo?.data?.product?.productName &&
                                        promotionInfo?.data?.product?.productName
                                        : promotionInfo?.data?.brand?.brandName
                                }
                                subtitle={
                                    promotionInfo?.data?.product ?
                                        promotionInfo?.data?.brand?.brandName &&
                                        `by ${promotionInfo?.data?.brand?.brandName}`
                                        : ''
                                }
                                label={
                                    promotionInfo?.data?.product ?
                                        promotionInfo?.data?.product?.totalSaves > 1 ?
                                            `${promotionInfo?.data?.product?.totalSaves} saves`
                                            : `${promotionInfo?.data?.product?.totalSaves} save`
                                        : promotionInfo?.data?.brand?.brandFollowers > 1 ?
                                            `${promotionInfo?.data?.brand?.brandFollowers} followers`
                                            : `${promotionInfo?.data?.brand?.brandFollowers} follower`
                                }
                            />

                            <DownloadSection
                                wrapperStyle={{ mt: 0 }}
                                description={'To view the item details, download the app'}
                            />
                        </Stack>
                    </PublicProfileCard>
                )
            }
        </Stack>
    );
};

export default PublicPromotion;
