import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import ProductService from "../../../../services/Product.service";
import {
    multipleProductDeleteFailed,
    multipleProductDeleteSuccess,
    singleProductDeleteFailed,
    singleProductDeleteSuccess
} from "./ProductDeleteSlice";
import {showErrorMessage, showSuccessMessage} from "../../../../helpers/utility/Toastify";
import {productList} from "../ProductList/ProductListSlice";

function* productdeleteWatcher() {
    yield takeEvery('ProductDelete/multipleProductDelete', multipleProductDeleteSaga);
    yield takeEvery('ProductDelete/singleProductDelete', singleProductDeleteSaga);
}

function* multipleProductDeleteSaga(action) {
    try {
        const response = yield call(ProductService.multipleProductDelete, action.payload);

        if (response?.success) {
            yield put(multipleProductDeleteSuccess(response?.data));
            yield put(productList());
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleProductDeleteFailed(response?.message));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleProductDeleteFailed(err.message));
    }
}

function* singleProductDeleteSaga(action) {
    try {
        console.log("===singleProductDeleteSaga=====", action.payload);
        const response = yield call(ProductService.singleProductDelete, action.payload);

        if (response?.success) {
            yield put(singleProductDeleteSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(singleProductDeleteFailed(response?.message));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(singleProductDeleteFailed(err.message));
    }
}

export default function* productDeleteSaga() {
    yield all([productdeleteWatcher()]);
}
