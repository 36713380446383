import { requests } from "./http.service";

class ProfileService {
    myProfile(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/profile/user", body);
    }
    brandProfile(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/profile/brand", body);
    }
    updateBrandProfile(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/profile/brand/update", body);
    }
    updateMyProfile(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/profile/user/update", body);
    }
    getFashionCategory(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/category/get/all/fashion-categories", body);
    }
    getClothingCategory(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/category/get/all/clothing-categories", body);
    }
    changePassword(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/profile/password/update", body);
    }
}

export default new ProfileService();