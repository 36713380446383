import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
    getBrandProfileFailed,
    getBrandProfileSuccess,
    getCollectionInfoFailed,
    getCollectionInfoSuccess,
    getProductInfoFailed,
    getProductInfoSuccess,
    getPromotionInfoFailed,
    getPromotionInfoSuccess,
    getRewardsPoliciesInfoFailed,
    getRewardsPoliciesInfoSuccess,
    getUserProfileFailed,
    getUserProfileSuccess,
} from "./publicPageSlice";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import PublicPageService from "../../../services/PublicPage.service";

function* publicPageWatcher() {
    yield takeEvery('publicPage/getUserProfile', getUserProfileSaga);
    yield takeEvery('publicPage/getBrandProfile', getBrandProfileSaga);
    yield takeEvery('publicPage/getCollectionInfo', getCollectionInfoSaga);
    yield takeEvery('publicPage/getProductInfo', getProductInfoSaga);
    yield takeEvery('publicPage/getPromotionInfo', getPromotionInfoSaga);
    yield takeEvery('publicPage/getRewardsPoliciesInfo', getRewardsPoliciesInfoSaga);
}

function* getUserProfileSaga(action) {
    try {
        const response = yield call(PublicPageService.user, action.payload);

        if (response?.success) {
            yield put(getUserProfileSuccess(response?.data));
        } else {
            yield put(getUserProfileFailed(response?.message));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getUserProfileFailed(err.message));
    }
}

function* getBrandProfileSaga(action) {
    try {

        const response = yield call(PublicPageService.brand, action.payload);
        if (response?.success) {
            yield put(getBrandProfileSuccess(response?.data));
        } else {
            yield put(getBrandProfileFailed(response?.message));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getBrandProfileFailed(err.message));
    }
}

function* getCollectionInfoSaga(action) {
    try {
        const response = yield call(PublicPageService.collection, action.payload);

        if (response?.success) {
            yield put(getCollectionInfoSuccess(response?.data));
        } else {
            yield put(getCollectionInfoFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getCollectionInfoFailed(err.message));
    }
}

function* getProductInfoSaga(action) {
    try {
        const response = yield call(PublicPageService.product, action.payload);

        if (response?.success) {
            yield put(getProductInfoSuccess(response?.data));
        } else {
            yield put(getProductInfoFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getProductInfoFailed(err.message));
    }
}

function* getPromotionInfoSaga(action) {
    try {
        const response = yield call(PublicPageService.promotion, action.payload);

        if (response?.success) {
            yield put(getPromotionInfoSuccess(response?.data));
        } else {
            yield put(getPromotionInfoFailed(response?.message));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getPromotionInfoFailed(err.message));
    }
}

function* getRewardsPoliciesInfoSaga(action) {
    try {
        const response = yield call(PublicPageService.rewardsPolicies, action.payload);

        if (response?.success) {
            yield put(getRewardsPoliciesInfoSuccess(response?.data));
        } else {
            yield put(getRewardsPoliciesInfoFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getRewardsPoliciesInfoFailed(err.message));
    }
}


export default function* publicPageSaga() {
    yield all([publicPageWatcher()]);
}
