import { requests } from "./http.service";

class OnboardingService {
    getOnboardData(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/onboard/get/current/step", body);
    }
    onboarding(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/onboard/stage/update", body);
    }

    getFashionCategories(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/category/get/all/fashion-categories", body);
    }

    updateFashionCategories(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/onboard/set/fashion/categories", body);
    }

    updateGenderPreference(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/onboard/set/gender/preferences", body);
    }

    updateProfileLogo(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/onboard/set/profile/logo", body);
    }

    updateBrandDescription(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/onboard/set/description", body);
    }

    updateBrandStoreLink(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/onboard/set/store/link", body);
    }
}

export default new OnboardingService();