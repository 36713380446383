import React from 'react';
import {Box, Button, Link, Typography} from "@mui/material";
import errorIcon from "../../assets/images/Error401.svg";
import productNotFound from "../../assets/images/productNotFound.svg";
import imageNotFound from "../../assets/images/imageNotFound.svg";
import teamMemberNotFound from "../../assets/images/teamMemberNotFound.svg";
import {Link as RouterLink} from "react-router-dom";

const DataNotFound = ({title = ''}) => {
    let heading, image;
    if (title === 'product') {
        heading = (<Typography variant="h3">Product not found!</Typography>);
        image = (<img src={productNotFound} alt="Product not found" width={350}/>);
    } else if (title === 'gallery') {
        heading = (<Typography variant="h3">Gallery image not found!</Typography>);
        image = (<img src={imageNotFound} alt="" width={350}/>);
    } else if (title === 'teamMember') {
        heading = (<Typography variant="h3">Team member not found!</Typography>);
        image = (<img src={teamMemberNotFound} alt="Team member not found"/>);
    } else if (title === 'banner') {
        heading = (<Typography variant="h3">Banner not found!</Typography>);
        image = (<img src={imageNotFound} alt="Banner not found" width={350}/>);
    } else if (title === 'promotion') {
        heading = (<Typography variant="h3">Promotion data not found!</Typography>);
        image = (<img src={imageNotFound} alt="Promotion data not found" width={350}/>);
    } else {
        heading = (<Typography variant="h3">Data not found!</Typography>);
        image = (<img src={errorIcon} alt="Data not found" width={350}/>);
    }
    return (
        <Box sx={{textAlign: 'center'}} height={'calc(100vh - 250px)'}>
            {image}
            <Box>
                {heading}
                <Typography variant="body1" color="text.secondary" mb={3}>
                    Please try refining your search terms or using different keywords to find the information you're
                    looking for.
                </Typography>

                <Link component={RouterLink} to="/dashboard" underline="none">
                    <Button variant="contained" disableElevation>
                        Go back to home
                    </Button>
                </Link>
            </Box>
        </Box>
    );
}
export default DataNotFound;