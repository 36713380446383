import React from 'react';
import { Box, ImageList, ImageListItem } from '@mui/material';

const BrandImageBanner = ({ image, wrapperStyle, cols = 1, gap = 2 }) => {

    return (
        <Box
            sx={{
                borderRadius: 6,
                overflowY: 'hidden',
                position: 'relative',
                mt: 5,
                ...wrapperStyle
            }}
        >
            {
                image ? (
                    <ImageList variant="masonry" cols={cols} gap={gap}>
                        <ImageListItem>
                            <img
                                srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${image}?w=248&fit=crop&auto=format`}
                                alt={'promotion banner'}
                                loading="lazy"
                            />
                        </ImageListItem>
                    </ImageList>
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            height: '320px',
                            backgroundColor: '#D3D3D3',
                            borderRadius: '6px'
                        }}
                    >
                    </Box>
                )
            }


            <Box
                sx={{
                    top: 0,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(transparent, white)'
                }}
            />
        </Box>
    );
};

export default BrandImageBanner;
