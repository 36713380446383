import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { PublicProfileChip } from '../PublicProfileStyle';

const PublicProfileInfo = ({ imageUrl, title, label }) => {
    return (
        <Stack alignItems={'center'} spacing={1}>
            <Avatar alt={title} src={imageUrl} sx={{ width: 80, height: 80 }} />

            <Typography variant="h5">{title}</Typography>

            <PublicProfileChip label={label} />
        </Stack>
    );
};

export default PublicProfileInfo;
