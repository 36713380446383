import React, {useEffect, useState} from 'react';
import {Box, Grid, Stack, Typography} from '@mui/material';
import BaseWrapper from '../../assets/global/wrapper/BaseWrapper';
import DashboardStatus from './DashboardStatus';
import TopSavedProducts from './TopSavedProducts';
import DashboardGraph from './DashboardGraph';
import {useDispatch, useSelector} from "react-redux";
import {getDashboardCounts, getGraphData, getTopSavedProduct} from "../../state/features/Dashboard/dashboardSlice";
import {selectDashboard} from "../../state/features/Dashboard/dashboardSelector";
import {selectProfile} from "../../state/features/Profile/profileSelector";
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const DashboardMain = () => {
    const dispatch = useDispatch();
    const {graphData, topSavedProduct} = useSelector(selectDashboard);
    const {myProfile} = useSelector(selectProfile);
    const [productDateRange, setProductDateRange] = useState(null);

    useEffect(() => {
        dispatch(getTopSavedProduct({fromDate: productDateRange?._fromDate, toDate: productDateRange?._toDate}));
    }, [productDateRange]);

    useEffect(() => {
        dispatch(getDashboardCounts());
    }, []);

    const datePickerCallback = (data) => {
        const reqObj = {
            from_date: data._fromDate,
            to_date: data._toDate,
            duration_type: data.durationType,
            difference: data.difference + 1,
        };
        dispatch(getGraphData({fromDate: data._fromDate, toDate: data._toDate}));
    };

    return (
        <Box height={'calc(100vh - 109px)'} sx={{bgcolor: 'other.bgPaperElevation', overflowY: 'auto'}}>

            <Stack direction={'row'} spacing={1}>
                {/*<DashboardCustomizeRoundedIcon  />*/}
                <Typography variant="h3">
                    Welcome
                    to {myProfile?.data?.brandName ? myProfile?.data?.brandName : ''} dashboard!
                </Typography>
            </Stack>
            <Stack direction={'row'} spacing={1} mb={3}>
                <SentimentSatisfiedAltIcon  color="primary"/>
                <Typography variant="button" display="block" gutterBottom  color="primary">
                    Hello, {myProfile?.data?.name ? myProfile?.data?.name : ''}!
                </Typography>
            </Stack>

            <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}
                  sx={{bgcolor: 'other.bgPaperElevation'}}>
                <Grid item xs={12} lg={8}>
                    <Box sx={{flex: 1}}>
                        <Stack mb={2}>
                            <DashboardStatus/>
                        </Stack>

                        <BaseWrapper>
                            <DashboardGraph datePickerCallback={datePickerCallback}
                                            formatedGraphData={graphData?.data}/>
                        </BaseWrapper>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <BaseWrapper>
                        <TopSavedProducts setProductDateRange={setProductDateRange}/>
                    </BaseWrapper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashboardMain;
