import {Button, Chip, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import SingleDeleteModal from "../../../assets/global/Modal/SingleDeleteModal";
import SingleRestoreModal from "../../../assets/global/Modal/SingleRestoreModal";
import SingleTrashModal from "../../../assets/global/Modal/SingleTrashModal";
import StatusChangeModal from "../../../assets/global/Modal/StatusChangeModal";
import EnhancedTableHead from "../../../assets/global/TableHeader/EnhancedTableHead";
import deleteIcon from "../../../assets/images/Icons/deleteIcon.svg";
import editIcon from "../../../assets/images/Icons/editIcon.svg";
import restoreIcon from "../../../assets/images/Icons/restoreIcon.svg";
import viewIcon from "../../../assets/images/Icons/viewIcon.svg";
import {
    MOVE__TO__RESTORE,
    MOVE__TO__TRASH, PROMOTION_STATUS_COMPLETED,
    STATUS__PENDING,
    STATUS__TRASHED
} from "../../../helpers/constant/coreConstant";
import {
    createPromotionTableData,
    getComparator,
    PromotionTableHeadCell,
    stableSort
} from "../../../helpers/utility/TableContents";
import {selectPromotion} from "../../../state/features/Promotions/promotionSelector";
import {
    changePromotionReward,
    changePromotionRewardStateClear,
    changeStatusInfo,
    changeStatusInfoClear,
    editPromotionData,
    getPromotionList,
    singleDeletePromotionData,
    singleDeletePromotionStateClear,
    singleRestorePromotionData,
    singleRestorePromotionStateClear,
    singleTrashPromotionData,
    singleTrashPromotionStateClear
} from "../../../state/features/Promotions/promotionSlice";
import ViewPromotion from "../ViewPromotion/ViewPromotion";
import DeclareWinnerModal from "./modal/DeclareWinnerModal";
import {PromotionTableStatus, PublishedFeatured, PromotionWinners} from "./PromotionTableContent";

const PromotionTable = ({
    selected,
    setSelected,
    setTrashedSelected,
    trashedSelected,
    category,
    searchText
}) => {
    const dispatch = useDispatch();
    const {
        promotionList,
        singleTrashPromotion,
        singleDeletePromotion,
        singleRestorePromotion,
        statusInfo,
        declareRewards
    } = useSelector(selectPromotion);
    const { page, perPage, total } = promotionList || {};
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('uid');
    const [trashModal, setTrashModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [singlePromotionId, setSinglePromotionId] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [statusModal, setStatusModal] = useState('');
    const [promotionDeclareModal, setPromotionDeclareModal] = useState(false);

    const rows =
        promotionList?.data?.map((items) => (
            createPromotionTableData(items?.promotionTitle, items?.promotionType, items?.startDate, items?.endDate, Number(items?.shares),
                Number(items?.completed), items?.rewarded, items?.winners, items?.status, items?.publish, items?.uid, '')
        ));
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n?.uid);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleSelectOnlyTrash = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n?.uid);
            setTrashedSelected(newSelected);
            return;
        }
        setTrashedSelected([]);
    };
    const handleChangePage = (event, newPage) => {
        dispatch(getPromotionList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
            page: (newPage + 1),
            per_page: perPage
        }));
    };
    const handleChangeRowsPerPage = event => {
        let row = parseInt(event.target.value, 10);
        dispatch(getPromotionList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
            page: page,
            per_page: row
        }));
    };
    const isSelected = uid => selected.indexOf(uid) !== -1;
    const isTrashedSelected = id => trashedSelected.indexOf(id) !== -1;

    // Avoid a Layout jump when reaching the last page with empty rows.
    const emptyRows = (page - 1) > 0 ? Math.max(0, (1 + (page - 1)) * perPage - rows?.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, (page - 1), perPage]
    );
    const handleTrashSelected = (event, id) => {
        if (selected?.length > 0) {
            setSelected([]);
        }
        const selectedIndex = trashedSelected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(trashedSelected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(trashedSelected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(trashedSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                trashedSelected.slice(0, selectedIndex),
                trashedSelected.slice(selectedIndex + 1)
            );
        }
        setTrashedSelected(newSelected);
    };

    const handleCategorySelected = (event, id) => {
        if (trashedSelected?.length > 0) {
            setTrashedSelected([]);
        }
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const onSingleTrashModalOpen = (id) => {
        if (id) {
            setTrashModal(true);
            setSinglePromotionId(id);
        }
    }
    const onSingleProductTrashed = () => {
        if (singlePromotionId) {
            dispatch(singleTrashPromotionData({
                uid: singlePromotionId,
                inTrash: MOVE__TO__TRASH,
                category: category,
            }));
        }
    }

    useEffect(() => {
        if (singleTrashPromotion?.isTrashPromotion) {
            setTrashModal(false);
            dispatch(singleTrashPromotionStateClear())
        }
    }, [singleTrashPromotion?.isTrashPromotion]);

    const onSingleDeleteModalOpen = (id) => {
        if (id) {
            setDeleteModal(true);
            setSinglePromotionId(id);
        }
    }
    const onSingleProductDelete = () => {
        if (singlePromotionId) {
            dispatch(singleDeletePromotionData({
                promotionUid: singlePromotionId,
                category: category,
                searchInputText: searchText,
            }));
            setSinglePromotionId(null);
        }
    }

    useEffect(() => {
        if (singleDeletePromotion?.isSingleDeletePromotion) {
            setDeleteModal(false);
            dispatch(singleDeletePromotionStateClear())
        }
    }, [singleDeletePromotion?.isSingleDeletePromotion]);

    const onSingleRestoreModalOpen = (id) => {
        if (id) {
            setRestoreModal(true);
            setSinglePromotionId(id);
        }
    }
    const onSinglePromotionRestore = () => {
        if (singlePromotionId) {
            dispatch(singleRestorePromotionData({
                uid: singlePromotionId,
                inTrash: MOVE__TO__RESTORE,
                category: category,
            }));
            setSinglePromotionId(null);
        }
    }
    useEffect(() => {
        if (singleRestorePromotion?.isSingleRestorePromotion) {
            setRestoreModal(false);
            dispatch(singleRestorePromotionStateClear())
        }
    }, [singleRestorePromotion?.isSingleRestorePromotion]);

    const handleChangeReward = (e, id) => {
        const value = e.target?.checked;
        if (id) {
            dispatch(changePromotionReward({
                promotionUid: id,
                rewards: value,
            }));
        }
    }
    const handleViewPromotion = (id) => {
        if (id) {
            setViewModal(true);
            dispatch(editPromotionData({ promotionUid: id }));
        }
    }
    const dateRange = (startDateStr, endDateStr) => {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedStartDate = startDate.toLocaleDateString('en-US', options);
        const formattedEndDate = endDate.toLocaleDateString('en-US', options);
        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    const handleStatusChange = (id, status) => {
        if (id) {
            setSinglePromotionId(id);
            setChangeStatus(true);
            setStatusModal(status);
        }
    }
    const onChangeStatus = (id, status) => {
        dispatch(changeStatusInfo({
            promotionUid: id,
            status: status,
            searchInputText: searchText,
            category: category
        }))
    }
    useEffect(() => {
        setChangeStatus(false);
        dispatch(changeStatusInfoClear())
    }, [statusInfo?.isStatus])

    const handleDeclareWinner = (id) => {
        if (id) {
            setSinglePromotionId(id);
            setPromotionDeclareModal(true);
        }
    }
    const handlePromotionDeclare = () => {
        if (singlePromotionId) {
            dispatch(changePromotionReward({
                promotionUid: singlePromotionId,
                rewards: true,
                searchInputText: searchText,
                category: category
            }));
        }
    }
    useEffect(() => {
        if (declareRewards?.isDeclareRewards) {
            setPromotionDeclareModal(false);
            dispatch(changePromotionRewardStateClear());
        }
    }, [declareRewards?.isDeclareRewards]);

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        tableHeader={PromotionTableHeadCell}
                        numSelected={selected.length || trashedSelected?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={category === STATUS__TRASHED ? handleSelectOnlyTrash : handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={visibleRows?.length}
                    />
                    <TableBody>
                        {visibleRows?.map((row, index) => {
                            const isItemSelected = isSelected(row?.uid);
                            const isTrashSelected = isTrashedSelected(row?.uid);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected || isTrashSelected}
                                    tabIndex={-1}
                                    key={row?.uid}
                                    selected={isItemSelected || isTrashSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        {
                                            category === STATUS__TRASHED ? (
                                                <Checkbox
                                                    color="primary"
                                                    onClick={event => handleTrashSelected(event, row.uid)}
                                                    checked={isTrashSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            ) : (
                                                <Checkbox
                                                    color="primary"
                                                    onClick={event => handleCategorySelected(event, row.uid)}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell >
                                        <Link to={`/promotion-details/${row?.uid}`} style={{ textDecoration: 'none', color: '#000' }}>
                                            <Tooltip title={'Click here to see details'}>
                                                <Stack>
                                                    <Typography variant="body2medium">
                                                        {row?.promotionTitle}
                                                    </Typography>
                                                    <Typography variant="alertDescription" color={'text.secondary'}>
                                                        {
                                                            dateRange(row?.startDate, row?.endDate)
                                                        }
                                                    </Typography>
                                                </Stack>
                                            </Tooltip>
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            row?.promotionType === 1 ? (
                                                <Chip label={"Brand"} variant={'outlined'}/>
                                            ) : (
                                                <Chip label={"Product"} variant={'outlined'} color={'info'} />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip variant={'outlined'} label={row?.winners} color="primary" sx={{padding: '0px 8px'}}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip variant={'outlined'} label={row?.shares} color="secondary" sx={{padding: '0px 8px'}}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <PromotionWinners uid={row?.uid} status={row?.status} rewarded={row?.rewarded} handleDeclareWinner={handleDeclareWinner}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <PromotionTableStatus promotionStatus={row?.status} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <PublishedFeatured status={row?.status} id={row?.uid} loading={false} handleApply={handleStatusChange}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            display={'inline-flex'}
                                            direction={'row'}
                                            spacing={1}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            <IconButton
                                                size="small"
                                                aria-label="delete"
                                                onClick={() => handleViewPromotion(row?.uid)}
                                            >
                                                <img src={viewIcon} alt="" />
                                            </IconButton>
                                            {
                                                category === STATUS__TRASHED ? (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="restore"
                                                        onClick={() => onSingleRestoreModalOpen(row?.uid)}
                                                    >
                                                        <img src={restoreIcon} alt="" />
                                                    </IconButton>
                                                ) : (
                                                    <Link to={`/edit-promotion/${row?.uid}`} style={{ textDecoration: 'none' }}>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="edit"
                                                        >
                                                            <img src={editIcon} alt="" />
                                                        </IconButton>
                                                    </Link>
                                                )
                                            }
                                            {
                                                category === STATUS__TRASHED ? (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={() => onSingleDeleteModalOpen(row?.uid)}
                                                    >
                                                        <img src={deleteIcon} alt="" />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={() => onSingleTrashModalOpen(row?.uid)}
                                                    >
                                                        <img src={deleteIcon} alt="" />
                                                    </IconButton>
                                                )
                                            }
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={total}
                rowsPerPage={perPage}
                page={(page - 1)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <BasicWithHeaderModal width={460} open={trashModal} header={false}>
                <SingleTrashModal
                    isLoading={singleTrashPromotion?.isLoading}
                    onSingleTrash={onSingleProductTrashed}
                    handleModalClose={() => setTrashModal(false)}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={deleteModal} header={false}>
                <SingleDeleteModal
                    isLoading={singleDeletePromotion?.isLoading}
                    onSingleDelete={onSingleProductDelete}
                    handleModalClose={() => setDeleteModal(false)}
                />
            </BasicWithHeaderModal>

            <BasicWithHeaderModal width={460} open={restoreModal} header={false}>
                <SingleRestoreModal
                    isLoading={singleRestorePromotion?.isLoading}
                    onSingleRestore={onSinglePromotionRestore}
                    handleModalClose={() => setRestoreModal(false)}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal
                modalTitle="Promotion Details"
                open={viewModal}
                onClose={() => setViewModal(false)}
                width={1200}
            >
                <ViewPromotion />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={changeStatus} header={false}>
                <StatusChangeModal
                    handleModalClose={() => setChangeStatus(false)}
                    isLoading={statusInfo?.isLoading}
                    onChangeStatus={onChangeStatus}
                    status={statusModal}
                    uid={singlePromotionId}
                />
            </BasicWithHeaderModal>

            <BasicWithHeaderModal width={460} open={promotionDeclareModal} header={false}>
                <DeclareWinnerModal
                    handleModalClose={() => setPromotionDeclareModal(false)}
                    isLoading={declareRewards?.isLoading}
                    handlePromotionDeclare={handlePromotionDeclare}
                />
            </BasicWithHeaderModal>
        </Box>
    )
}
export default PromotionTable;