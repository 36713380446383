import { requests } from "./http.service";

class GalleryService {
    galleryImageList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/gallery/list", body);
    }
    imageUploadToS3(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/gallery/single/image/upload", body);
    }
    imageSavedToDatabase(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/gallery/upload/new/image", body);
    }
    multipleImageDelete(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/gallery/bulk/delete", body);
    }
    singleImageDelete(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/gallery/single/delete", body);
    }
}

export default new GalleryService();