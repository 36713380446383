import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import Notification from "../../../services/Notification.service";
import {getNotificationListFailed, getNotificationListSuccess} from "./notificationSlice";

function* notificationWatcher() {
    yield takeLatest('notification/getNotificationList', getNotificationListSaga);
}

function* getNotificationListSaga(action) {
    try {
        const response = yield call(Notification.getNotificationList, action.payload);
        if (response?.success) {
            yield put(getNotificationListSuccess(response?.data));
        } else {
            yield put(getNotificationListFailed(response?.message));
        }
    } catch (err) {

        // yield put(getNotificationListFailed(response?.message));
    }
}

export default function* notificationSaga() {
    yield all([notificationWatcher()]);
}
