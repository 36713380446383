import { requests } from "./http.service";

class BannerService {
    addBanner(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/create", body);
    }
    bannerList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/list", body);
    }
    editBanner(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/get/specific/data", body);
    }
    updateBanner(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/update", body);
    }
    singleTrash(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/in-trash/update/status/single", body);
    }
    multipleTrash(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/in-trash/update/status/bulk", body);
    }
    singleDelete(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/single/delete", body);
    }
    multipleDelete(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/bulk/delete", body);
    }
    applyFeatures(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/is-featured/update/status/single", body);
    }
    visibility(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/banner/visibility/update", body);
    }
}

export default new BannerService();