import React, {useEffect} from 'react';
import {Box, FormControl, FormHelperText, InputAdornment, TextField, Typography} from '@mui/material';
import {ProductValidationPattern} from "../../../helpers/constant/validationPattern";
import {Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {
    setProductName,
    setProductPrice,
    setStoreLink
} from "../../../state/features/Products/AddProduct/AddProductSlice";

const AddProductOtherInfo = ({control, setValue}) => {
    const {productPrice, storeLink} = useSelector(selectAddProduct);
    const dispatch = useDispatch();


    useEffect(() => {
        if (productPrice) {
            setValue("productPrice", productPrice || "");
        }
        if (storeLink) {
            setValue("productStoreLink", storeLink || "");
        }
    }, [productPrice, storeLink]);

    return (
        <Box mb={5}>
            <Typography variant="h5" mb={3}>
                Other Info
            </Typography>
            <Controller
                control={control}
                name="productPrice"
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl size="small" sx={{mb:2}} fullWidth error={!!(invalid && error.message)}>
                        <TextField
                            label="Product Price"
                            type={'number'}
                            id="outlined-start-adornment"
                            fullWidth
                            focused
                            InputProps={{
                                endAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            value={value}
                            onChange={(e) => {
                                onChange(e);
                                dispatch(setProductPrice(e?.target?.value))
                            }}
                            error={!!(invalid && error.message)}
                        />
                        <FormHelperText>{invalid && error?.message}</FormHelperText>
                    </FormControl>
                )}
                rules={ProductValidationPattern.productPrice}
            />
            <Controller
                control={control}
                name="productStoreLink"
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl size="small" sx={{mb:2}} fullWidth error={!!(invalid && error.message)}>
                        <TextField
                            label="Store Link"
                            id="outlined-start-adornment"
                            fullWidth
                            focused
                            value={value}
                            onChange={(e) => {
                                onChange(e);
                                dispatch(setStoreLink(e?.target?.value))
                            }}
                            error={!!(invalid && error.message)}
                        />
                        <FormHelperText>{invalid && error?.message}</FormHelperText>
                    </FormControl>
                )}
                rules={ProductValidationPattern.storeLink}
            />
        </Box>
    );
};

export default AddProductOtherInfo;
