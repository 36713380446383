import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { lightModePalette, myBreakpoints, typographyProperties } from './helpers/constant/themeConstant';
import Routes from './router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: lightModePalette,
                typography: typographyProperties,
                breakpoints: myBreakpoints
            }),
        []
    );
    return (
        <ThemeProvider theme={theme}>
            <Routes />
            <ToastContainer />
        </ThemeProvider>
    );
}

export default App;
