import DeleteIcon from '@mui/icons-material/Delete';
import {Button, CircularProgress, ImageListItemBar, LinearProgress, Stack, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    singleImageDelete,
    singleImageDeleteClearState
} from "../../state/features/Gallery/GalleryImageDelete/gallerySlice";
import trashIcon from "../../assets/images/Icons/trashIcon.svg";
import BasicWithHeaderModal from "../../assets/global/Modal/BasicWithHeaderModal";
import {showErrorMessage} from "../../helpers/utility/Toastify";
import {galleryImageFormat} from "../../helpers/utility/Product";
import {selectGallery} from "../../state/features/Gallery/GalleryImageDelete/gallerySelector";
import LoadingButton from "../../assets/global/Button/LoadingButton";

const uploadBoxStyle = {
    // width: 400,
    height: 'auto',
    p: 5,
    border: '1px solid',
    borderColor: 'other.divider',
    borderRadius: 0.8,
    mb: 1,
    textAlign: 'center'
};
const ImageGallery = ({
                          imageIds,
                          setImageIds,
                          AbortHandler,
                          uploading,
                          progressValue,
                          selectedItems,
                          setSelectedItems,
                          galleryType,
                      }) => {
    const dispatch = useDispatch();
    const {galleryImage, singleImage} = useSelector(selectGallery);
    const {data = [], isFetching} = galleryImage || {};
    const [ctaHover, setCtaHover] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [imageId, setImageId] = useState(null);

    const handleHover = index => setCtaHover(index);
    const handleChange = items => {
        const format = galleryImageFormat(items);
        if (!imageIds.includes(format?.id) && !selectedItems?.some(ids => ids.id === format?.id)) {
            if (selectedItems?.length < 5) {
                setSelectedItems(prevItems => [...prevItems, format]);
                setImageIds(prevItems => [...prevItems, format?.id]);
            } else {
                showErrorMessage("You can\'t select more than 5 images!");
            }
        } else {
            setSelectedItems(prevItems => prevItems.filter(item => item?.id !== format?.id));
            setImageIds(prevItems => prevItems.filter(item => item !== format?.id));
        }

    };

    const handleModalOpen = (id) => {
        if (id) {
            setModalOpen(true);
            setImageId(id);
        } else {
            showErrorMessage("Image not found!");
        }
    }
    const onSingleImageDelete = () => {
        if (imageId) {
            dispatch(singleImageDelete({uid: imageId}))
            setImageId(null);
        } else {
            showErrorMessage("Image not found!");
        }
    }
    useEffect(() => {
        if (singleImage?.isSingleImageDelete) {
            setModalOpen(false);
            dispatch(singleImageDeleteClearState())
        }
    }, [singleImage?.isSingleImageDelete]);

    return (
        <>
            <Box sx={{mt: 4.2}}>
                <ImageList variant="masonry" cols={4} gap={10}>
                    {uploading && (
                        <ImageListItem>
                            <Box sx={uploadBoxStyle}>
                                <Box textAlign={'center'} sx={{height: '100%'}} mt={2}>
                                    <LinearProgress
                                        sx={{borderRadius: 4, bgcolor: '#00000014'}}
                                        variant="determinate"
                                        value={progressValue}
                                    />
                                    <Typography variant="body2" color={'text.secondary'} mt={1} mb={4}>
                                        Uploading image...
                                    </Typography>

                                    <span>
                                         <CircularProgress size={20} color={"inherit"}/>
                                        {/*<Button*/}
                                        {/*    variant="contained"*/}
                                        {/*    disableElevation*/}
                                        {/*    sx={{*/}
                                        {/*        '&.MuiButton-contained': {*/}
                                        {/*            bgcolor: 'other.lightGray',*/}
                                        {/*            color: 'text.primary'*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*    onClick={AbortHandler}*/}
                                        {/*>*/}
                                        {/*  Cancel*/}
                                        {/*</Button>*/}
                                        </span>
                                </Box>
                            </Box>
                        </ImageListItem>

                    )}
                    {
                        data?.map((item, index) => (
                            <Box
                                sx={{
                                    position: 'relative',
                                    background: 'text.secondary'
                                }}
                                onMouseEnter={() => handleHover(index)}
                                onMouseLeave={() => handleHover(null)}
                                key={item?.imageName}
                            >
                                <ImageListItem
                                    key={item.imageName}
                                    direction={'row'}
                                    sx={{
                                        '&:hover': {
                                            background: 'text.secondary',
                                            transition: 'opacity 0.3s ease'
                                            // opacity: '0.5'
                                        }
                                    }}
                                >
                                    <img
                                        src={`${item.imageUrl}?w=200&fit=crop&auto=format`}
                                        srcSet={`${item.imageUrl}?w=200&fit=crop&auto=format&dpr=2 2x`}
                                        alt={item.imageName}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={item.imageName}
                                    />
                                </ImageListItem>

                                {(ctaHover === index || imageIds.includes(item.uid) || selectedItems?.some(items => items.id === item?.uid)) && (
                                    <Box
                                        sx={{
                                            display: 'block',
                                            position: 'absolute',
                                            top: '0px',
                                            right: '0px',
                                            width: '100%'
                                        }}
                                    >
                                        <Stack direction="row" justifyContent="space-between" alignItems="center"
                                               pr={1}>
                                            <Box>
                                                <Checkbox
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {fontSize: 28, color: 'common.white'},
                                                        '&.Mui-checked': {
                                                            color: 'common.white'
                                                        },
                                                        zIndex: 9
                                                    }}
                                                    checked={imageIds.includes(item?.uid) || selectedItems?.some(items => items?.id === item?.uid)}
                                                    onChange={() => handleChange(item)}
                                                />
                                            </Box>

                                            {(galleryType === null) && (
                                                <Box sx={{cursor: 'pointer', zIndex: 9}}>
                                                    <DeleteIcon
                                                        sx={{fontSize: 28, color: 'error.light'}}
                                                        onClick={() => handleModalOpen(item?.uid)}
                                                    />
                                                </Box>
                                            )}
                                        </Stack>
                                    </Box>
                                )}

                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        height: '100%',
                                        width: '100%',
                                        opacity: imageIds.includes(item?.uid) || selectedItems?.some(items => items.id === item?.uid) || ctaHover === index ? 1 : 0,
                                        transition: '.5s ease',
                                        backgroundColor:
                                            ctaHover === index ? 'text.secondary' : 'other.primaryStatesOutlinedBorder'
                                    }}
                                    className="overlay"
                                ></Box>
                            </Box>
                        ))
                    }
                </ImageList>
                <Box sx={{textAlign: 'center'}} m={5}>
                    {
                        isFetching && <CircularProgress size={80} color={"inherit"}/>
                    }
                </Box>

                <BasicWithHeaderModal width={460} open={modalOpen} header={false}>
                    <Box p={'64px 40px 48px 40px'} textAlign={'center'}>
                        <img src={trashIcon} alt=""/>

                        <Typography variant="h5" mt={3} mb={1}>
                            Are you sure you want to <br/> remove{' '}
                            this image?
                        </Typography>
                        <Typography variant="body2" color={'text.secondary'}>
                            Once deleted, they can’t be restored.
                        </Typography>
                    </Box>

                    <Stack
                        p={'16px'}
                        sx={{borderTop: 1, borderColor: 'other.divider'}}
                        direction={'row'}
                        justifyContent={'flex-end'}
                        spacing={1}
                    >
                        <Button sx={{color: 'text.primary'}} variant="text" onClick={() => setModalOpen(false)}>
                            Cancel
                        </Button>
                        <LoadingButton
                            isLoading={singleImage?.isLoading}
                            loadingText={'Deleting...'}
                            color="error"
                            variant="contained"
                            color="error"
                            onClick={() => onSingleImageDelete()}
                        >
                            Delete
                        </LoadingButton>
                    </Stack>
                </BasicWithHeaderModal>
            </Box>
        </>
    );
};
export default ImageGallery;
