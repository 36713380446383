import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import ProductService from "../../../../services/Product.service";
import {
    setClothingCategory,
    setFashionCategory, setGenderPreference,
    setPostDescription,
    setProductDescription, setProductImages,
    setProductName,
    setProductPrice, setProductSKUId, setProductStatus,
    setSelectedImages,
    setStoreLink
} from "../AddProduct/AddProductSlice";
import {editProductFailed, editProductSuccess} from "./EditProductSlice";

function* editProductWatcher() {
    yield takeEvery('EditProduct/editProduct', editProductSaga);
}

function* editProductSaga(action) {
    try {
        const response = yield call(ProductService.editProduct, action.payload);
        if (response?.success) {
            yield put(editProductSuccess(response?.data));
            yield put(setProductName(response?.data?.name));
            yield put(setProductSKUId(response?.data?.productSkuId));
            yield put(setProductDescription(response?.data?.description));
            yield put(setClothingCategory(response?.data?.clothingCategoryUid));
            yield put(setFashionCategory(response?.data?.fashionCategoryUid));
            yield put(setGenderPreference(response?.data?.gender));
            yield put(setProductPrice(response?.data?.productPrice));
            yield put(setStoreLink(response?.data?.productStoreLink));
            yield put(setPostDescription(response?.data?.postDescription));
            yield put(setSelectedImages(JSON.parse(response?.data?.productImages)));
            yield put(setProductStatus(response?.data?.status));
        } else {
            yield put(editProductFailed(response?.message));
        }
    } catch (err) {
        yield put(editProductFailed(err.message));
    }
}

export default function* editProductsSaga() {
    yield all([editProductWatcher()]);
}
