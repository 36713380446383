import React, {PureComponent, useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {Area, AreaChart, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';


const CustomTick = ({x, y, payload}) => {

    // Show tick every three values
    // if (payload.index % 2 !== 0) {
    //     return null;
    // }

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)" style={{fontSize: '10px'}}>
                {payload.value}
            </text>
        </g>
    );
};

const data = [
    {
        date: '2023-07-04',
        storeVisits: 20,
    },
    {
        date: '2023-07-05',
        storeVisits: 40,
    },
    {
        date: '2023-07-06',
        storeVisits: 38,
    },
    {
        date: '2023-07-07',
        storeVisits: 10,
    },
    {
        date: '2023-07-08',
        storeVisits: 36,
    },
    {
        date: '2023-07-09',
        storeVisits: 33,
    },
    {
        date: '2023-07-10',
        storeVisits: 28,
    },
];

class CustomizedLabel extends PureComponent {
    render() {
        const {x, y, stroke, value} = this.props;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
        );
    }
}

class CustomizedAxisTick extends PureComponent {
    render() {
        const {x, y, stroke, payload} = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {payload.value}
                </text>
            </g>
        );
    }
}

const GraphReport = ({formatedGraphData}) => {
    const [graphData, setGraphData] = useState('');

    useEffect(() => {
        if (formatedGraphData) {
            const formatData = [];
            formatedGraphData.forEach((data) => {
                const obj = {
                    "Date": data.dayOrMonth,
                    "Total Followers": data.totalFollowers,
                    "Total Product Save": data.totalProductSave,
                };
                formatData.push(obj);
            });
            setGraphData(formatData);
        }
    }, [formatedGraphData]);


    return (
        <>
            <Box>
                <AreaChart
                    width={700}
                    height={400}
                    data={graphData}
                >
                    {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                    <XAxis dataKey="Date" height={60} tick={<CustomizedAxisTick/>}/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Area type="monotone" dataKey="Total Followers" stackId="1" stroke="#8884d8" fill="#8884d8" label={<CustomizedLabel/>}/>
                    <Area type="monotone" dataKey="Total Product Save" stackId="1" stroke="#82ca9d" fill="#82ca9d" label={<CustomizedLabel/>}/>
                </AreaChart>
            </Box>
        </>
    );
};

export default GraphReport;