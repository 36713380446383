import {Box, Button, List, ListItem, Stack, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, {useEffect, useState} from "react";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import {useSelector} from "react-redux";
import {selectPromotion} from "../../../state/features/Promotions/promotionSelector";

const StyledButton = {
    '&.MuiButton-outlined': {
        color: 'common.white',
        borderColor: 'common.white',
        mb: 1.2
    },

    '&.MuiButton-contained': {
        color: 'common.black',
        bgcolor: 'common.white'
    }
};
const PromotionBannerUpload = ({handleImage}) => {
    const {editPromotion, updatePromotion} = useSelector(selectPromotion);
    const [ctaHover, setCtaHover] = useState(false);
    const [bannerImage, setBannerImage] = useState(null);
    const handleHover = index => setCtaHover(index);
    let inputElement = null;
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleImage(file)
            setBannerImage({
                file: file,
                src: URL.createObjectURL(file)
            });
        } else {
            showErrorMessage("Image not found!");
        }
    }
    const handleRemoveImage = () => {
        setBannerImage(null);
    }

    useEffect(() => {
        if(editPromotion?.data?.bannerUrl) {
            setBannerImage({
                file: "",
                src: editPromotion?.data?.bannerUrl
            })
        }
    }, [editPromotion?.data?.bannerUrl]);

    return (
        <>
            <Stack
                py={4}
                px={5}
                spacing={3}
                alignItems={'flex-start'}
                // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
            >

                <Typography variant="h6">Add Promotion Banner</Typography>

                <List sx={{listStyleType: 'disc', mt: `0 !important`, pl: 3}}>
                    <ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>
                        <Typography variant="body2">
                            Use 390 * 320 images images for the best view experience.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>
                        <Typography variant="body2">
                            Size limit: 10 megabytes
                        </Typography>
                    </ListItem>
                </List>

                {/*//--------------- banner images -----------------*/}
                {
                    bannerImage?.src ? (
                        <Box position={'relative'}>
                            <img
                                src={bannerImage?.src}
                                style={{
                                    // width: '686px',
                                    // height: '431px',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '6px',
                                    gap: '10px',
                                    border: '1px',
                                }}
                                alt=""
                            />

                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    height: '99%',
                                    width: '100%',
                                    opacity: ctaHover ? 1 : 0,
                                    transition: '.5s ease',
                                    backgroundColor: 'text.secondary'
                                }}
                                onMouseEnter={() => handleHover(true)}
                                onMouseLeave={() => handleHover(false)}
                            >
                                <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
                                    <Box textAlign={'center'} pb={2}>
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            spacing={1.2}
                                        >
                                            <Button
                                                sx={StyledButton}
                                                size="small"
                                                variant="contained"
                                                onClick={() => inputElement?.click()}
                                            >
                                                Change
                                            </Button>
                                            <input
                                                ref={input => (inputElement = input)}
                                                accept="image/*"
                                                type="file"
                                                style={{display: "none"}}
                                                onChange={handleImageUpload}
                                            />
                                            <Button
                                                color="error"
                                                size="small"
                                                variant="contained"
                                                onClick={handleRemoveImage}
                                            >
                                                Remove
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon/>}
                                /* onClick={onImageUpload} */
                                sx={{cursor: 'pointer'}}
                                onClick={() => inputElement?.click()}
                            >
                                Click to add image
                            </Button>
                            <input
                                ref={input => (inputElement = input)}
                                accept="image/*"
                                type="file"
                                style={{display: "none"}}
                                onChange={handleImageUpload}
                            />
                        </>
                    )
                }


            </Stack>
        </>
    );

}
export default PromotionBannerUpload;