import React from 'react';
import Box from "@mui/material/Box";
import {Grid, Stack, Typography} from "@mui/material";
import bannerPreview from '../../../assets/images/bannerPreview.jpg';

const BannerProfile = ({bannerDetails}) => {
    return (
        <Grid
            container
            sx={{
                overflowY: 'auto'
            }}
        >
            <Grid item sm={12} lg={7}>
                <Box
                    py={4}
                    px={5}
                    borderRight={{lg: '1px solid'}}
                    borderColor={{lg: 'other.divider'}}
                    // sx={{height: {sm: 'auto', lg: 'calc(100vh - 260px)'}, overflowY: 'auto'}}
                >
                    <Typography variant="h6" mb={3}>
                        Banner Image
                    </Typography>

                    <img src={bannerDetails?.banner} width={'100%'} height={'400px'} alt=""/>
                </Box>
            </Grid>
            <Grid item sm={12} lg={5}>
                <Stack
                    py={4}
                    px={5}
                    spacing={4}
                    // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                >
                    <Typography variant="h6">Banner Info</Typography>

                    <Stack>
                        <Typography variant="alertDescription" color={'action.active'}>
                            Title
                        </Typography>
                        <Typography variant="body1medium" color={'text.primary'}>
                            {bannerDetails?.title.substring(0, 45) + '...'}
                        </Typography>
                    </Stack>

                    {/*<Stack>*/}
                    {/*    <Typography variant="alertDescription" color={'action.active'}>*/}
                    {/*        ID*/}
                    {/*    </Typography>*/}
                    {/*    <Typography variant="body1medium" color={'text.primary'}>*/}
                    {/*        1214612*/}
                    {/*    </Typography>*/}
                    {/*</Stack>*/}
                </Stack>
            </Grid>
        </Grid>
    )
}
export default BannerProfile;