import {all, put} from 'redux-saga/effects';
import {call, debounce, delay, takeEvery} from '@redux-saga/core/effects';
import {
    getGalleryImage,
    getGalleryImageClearState,
    getGalleryImageFailed,
    getGalleryImageSuccess,
    getMoreGalleryImageFailed,
    getMoreGalleryImageSuccess,
    multipleImageDeleteFailed,
    multipleImageDeleteSuccess,
    singleImageDeleteFailed,
    singleImageDeleteSuccess
} from "./gallerySlice";
import {showErrorMessage, showSuccessMessage} from "../../../../helpers/utility/Toastify";
import GalleryService from "../../../../services/Gallery.service";

function* galleryImageDeleteWatcher() {
    yield debounce(500, 'GalleryImageDelete/getGalleryImage', getGalleryImageSaga);
    yield takeEvery('GalleryImageDelete/getMoreGalleryImage', getMoreGalleryImageSaga);
    yield takeEvery('GalleryImageDelete/multipleImageDelete', multipleDeleteSaga);
    yield takeEvery('GalleryImageDelete/singleImageDelete', singleDeleteSaga);
}

function* getGalleryImageSaga(action) {
    try {
        const response = yield call(GalleryService.galleryImageList, action.payload);

        if (response?.success) {
            yield put(getGalleryImageSuccess(response?.data));
            // showSuccessMessage(response?.message);
        } else {
            yield put(getGalleryImageFailed(response?.message));
            // showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getGalleryImageFailed(err.message));
    }
}

function* getMoreGalleryImageSaga(action) {
    try {
        const response = yield call(GalleryService.galleryImageList, action.payload);

        yield delay(1000);
        if (response?.success) {
            yield put(getMoreGalleryImageSuccess(response?.data));
            // showSuccessMessage(response?.message);
        } else {
            yield put(getMoreGalleryImageFailed(response?.message));
            // showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getMoreGalleryImageFailed(err.message));
    }
}

function* multipleDeleteSaga(action) {
    try {
        const response = yield call(GalleryService.multipleImageDelete, action.payload);

        if (response?.success) {
            yield put(multipleImageDeleteSuccess(response?.data));
            yield put(getGalleryImage());
            yield put(getGalleryImageClearState());
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleImageDeleteFailed(response?.message));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleImageDeleteFailed(err.message));
    }
}

function* singleDeleteSaga(action) {
    try {
        const response = yield call(GalleryService.singleImageDelete, action.payload);

        if (response?.success) {
            yield put(singleImageDeleteSuccess(response?.data));
            yield put(getGalleryImage());
            yield put(getGalleryImageClearState());
            showSuccessMessage(response?.message);
        } else {
            yield put(singleImageDeleteFailed(response?.message));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(singleImageDeleteFailed(err.message));
    }
}

export default function* gallerySaga() {
    yield all([galleryImageDeleteWatcher()]);
}
