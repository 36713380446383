import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
    changePasswordFailed,
    changePasswordSuccess,
    clothingCategoriesFailed,
    clothingCategoriesSuccess,
    fashionCategoriesFailed,
    fashionCategoriesSuccess,
    getBrandProfileData,
    getBrandProfileDataFailed,
    getBrandProfileDataSuccess,
    getMyProfileData,
    getMyProfileDataFailed,
    getMyProfileDataSuccess,
    updateBrandProfileFailed,
    updateBrandProfileSuccess,
    updateMyProfileFailed,
    updateMyProfileSuccess
} from "./profileSlice";
import ProfileService from "../../../services/Profile.service";
import {showSuccessMessage} from "../../../helpers/utility/Toastify";

function* profileWatcher() {
    yield takeEvery('profile/getMyProfileData', myProfileSaga);
    yield takeEvery('profile/updateMyProfileData', updateMyProfileSaga);
    yield takeEvery('profile/getBrandProfileData', brandProfileSaga);
    yield takeEvery('profile/updateBrandProfileData', updateBrandProfileSaga);
    yield takeEvery('profile/getFashionCategories', getFashionCategoriesSaga);
    yield takeEvery('profile/getClothingCategories', getClothingCategoriesSaga);
    yield takeEvery('profile/changePasswordData', changePasswordSaga);
}

function* myProfileSaga(action) {
    try {
        const response = yield call(ProfileService.myProfile, action.payload);
        if (response?.success) {
            yield put(getMyProfileDataSuccess(response?.data));
        } else {
            yield put(getMyProfileDataFailed(response?.message));
        }
    } catch (err) {
        yield put(getMyProfileDataFailed(err.message));
    }
}

function* updateMyProfileSaga(action) {
    try {
        const response = yield call(ProfileService.updateMyProfile, action.payload);
        if (response?.success) {
            yield put(updateMyProfileSuccess(response?.data));
            yield put(getMyProfileData());
            showSuccessMessage(response?.message);
        } else {
            yield put(updateMyProfileFailed(response?.message));
        }
    } catch (err) {
        yield put(updateMyProfileFailed(err.message));
    }
}

function* brandProfileSaga(action) {
    try {
        const response = yield call(ProfileService.brandProfile, action.payload);
        if (response?.success) {
            yield put(getBrandProfileDataSuccess(response?.data));
        } else {
            yield put(getBrandProfileDataFailed(response?.message));
        }
    } catch (err) {
        yield put(getBrandProfileDataFailed(err.message));
    }
}

function* updateBrandProfileSaga(action) {
    try {
        const response = yield call(ProfileService.updateBrandProfile, action.payload);
        if (response?.success) {
            yield put(updateBrandProfileSuccess(response?.data));
            yield put(getBrandProfileData());
            showSuccessMessage(response?.message);
        } else {
            yield put(updateBrandProfileFailed(response?.message));
        }
    } catch (err) {
        yield put(updateBrandProfileFailed(err.message));
    }
}

function* getFashionCategoriesSaga(action) {
    try {
        const response = yield call(ProfileService.getFashionCategory, action.payload);
        if (response?.success) {
            yield put(fashionCategoriesSuccess(response?.data));
        } else {
            yield put(fashionCategoriesFailed(response?.message));
        }
    } catch (err) {
        yield put(fashionCategoriesFailed(err.message));
    }
}

function* getClothingCategoriesSaga(action) {
    try {
        const response = yield call(ProfileService.getClothingCategory, action.payload);
        if (response?.success) {
            yield put(clothingCategoriesSuccess(response?.data));
        } else {
            yield put(clothingCategoriesFailed(response?.message));
        }
    } catch (err) {
        yield put(clothingCategoriesFailed(err.message));
    }
}
function* changePasswordSaga(action) {
    try {
        const response = yield call(ProfileService.changePassword, action.payload);
        if (response?.success) {
            yield put(changePasswordSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(changePasswordFailed(response?.message));
            showSuccessMessage(response?.message)
        }
    } catch (err) {
        yield put(changePasswordFailed(err.message));
    }
}


export default function* profileSaga() {
    yield all([profileWatcher()]);
}
