import EditIcon from '@mui/icons-material/Edit';
import {
    Avatar,
    Backdrop,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    Link,
    Stack,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectProfile} from "../../state/features/Profile/profileSelector";
import {
    getBrandProfileData,
    getFashionCategories, updateBrandProfileData,
    updateMyProfileData
} from "../../state/features/Profile/profileSlice";
import ImageInput from "../../assets/global/ImageCropper/ImageInput";
import {selectOnboardedSteps} from "../../state/features/OnboardedSteps/OnboardedStepsSelector";
import {updateProfileLogo} from "../../state/features/OnboardedSteps/OnboardedStepsSlice";

const BrandProfile = ({onProfileEdit}) => {
    const {brandProfile, fashionCategories} = useSelector(selectProfile);
    const {profilePicture} = useSelector(selectOnboardedSteps);
    const {myProfile, updateBrandProfile} = useSelector(selectProfile);
    const {isTeamMember} = myProfile?.data || {};
    const dispatch = useDispatch();
    const [image, setImage] = useState('');

    useEffect(() => {
        if (!brandProfile?.data) {
            dispatch(getBrandProfileData());
        }
        if (brandProfile?.data?.profileLogo) {
            setImage(brandProfile?.data?.profileLogo);
        }
    }, [brandProfile?.data]);

    useEffect(() => {
        if (image?.photo?.data) {
            if (!updateBrandProfile?.isLoading) {
                let formData = new FormData();
                formData.append('profileLogo', image?.photo?.data);
                dispatch(updateBrandProfileData(formData));
                setImage(brandProfile?.data?.profileLogo);
            }
        }
    }, [image?.photo?.data]);

    useEffect(() => {
        if (!fashionCategories?.data) {
            dispatch(getFashionCategories())
        }
    }, []);

    const filteredFashionCategories =
        (brandProfile?.data?.categoriesIds !== null) ?
            fashionCategories?.data?.filter(obj =>
                brandProfile?.data?.categoriesIds?.includes(obj.uid)) : [];

    return (
        <Grid
            container
            sx={{
                overflowY: 'auto'
            }}
        >
            <Grid item sm={12} lg={3.55}>
                <Box
                    py={4}
                    px={5}
                    borderRight={{lg: '1px solid'}}
                    borderColor={{lg: 'other.divider'}}
                    sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                >
                    <Stack spacing={3} alignItems={'center'} textAlign={'center'}>
                        <Box>
                            {
                                !isTeamMember ? (
                                    <ImageInput
                                        image={image}
                                        setImage={setImage}
                                        type="1"
                                    />
                                ) : brandProfile?.data?.profileLogo ? (
                                    <Avatar src={brandProfile?.data?.profileLogo} sx={{width: 144, height: 144}}
                                            alt="Brand Logo"/>) : (
                                    <Avatar sx={{width: 144, height: 144}} alt="Brand Logo"/>)

                            }
                            <Backdrop
                                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                open={updateBrandProfile?.isLoading}
                            >
                                <CircularProgress color="inherit"/>
                            </Backdrop>
                        </Box>
                        <Box>
                            <Typography variant="h5" color={'text.primary'} mb={1}>
                                {brandProfile?.data?.name && brandProfile?.data?.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {brandProfile?.data?.description}
                            </Typography>
                        </Box>

                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            sx={{p: 1.6, bgcolor: 'action.hover', borderRadius: 0.6}}
                        >
                            <Stack sx={{mx: 3}}>
                                <Typography variant="body1medium" color={'text.primary'}>
                                    {brandProfile?.data?.followers ? brandProfile?.data?.followers : '0'}
                                </Typography>
                                <Typography variant="alertDescription" color={'text.secondary'}>
                                    Followers
                                </Typography>
                            </Stack>
                            <Divider sx={{mx: 4}} orientation="vertical" flexItem/>
                            <Stack sx={{mx: 3}}>
                                <Typography variant="body1medium" color={'text.primary'}>
                                    {brandProfile?.data?.products ? brandProfile?.data?.products : '0'}
                                </Typography>
                                <Typography variant="alertDescription" color={'text.secondary'}>
                                    Products
                                </Typography>
                            </Stack>
                        </Stack>

                        {
                            !isTeamMember && (
                                <Button
                                    variant="outlined"
                                    startIcon={<EditIcon/>}
                                    onClick={onProfileEdit}
                                >
                                    Edit Profile
                                </Button>
                            )
                        }
                    </Stack>
                </Box>
            </Grid>
            <Grid item sm={12} lg={8.45}>
                <Stack
                    py={4}
                    px={5}
                    borderBottom={{lg: '1px solid'}}
                    borderColor={{lg: 'other.divider'}}
                    spacing={4}
                    // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                >
                    <Stack>
                        <Typography variant="alertDescription" color={'action.active'}>
                            Brand Name
                        </Typography>
                        <Typography variant="body1medium" color={'text.primary'}>
                            {brandProfile?.data?.name && brandProfile?.data?.name}
                        </Typography>
                    </Stack>

                    <Stack>
                        <Typography variant="alertDescription" color={'action.active'} mb={1}>
                            Fashion Category
                        </Typography>

                        <Stack direction={'row'} spacing={1}>
                            {
                                filteredFashionCategories?.length > 0 ? (filteredFashionCategories?.slice(0, 3)?.map((item) => (
                                        <Chip label={item?.categoryName}/>
                                    ))
                                ) : ('No category selected!')
                            }
                            {
                                filteredFashionCategories?.length > 3 && (
                                    <Chip label={`+${filteredFashionCategories.length - 3}`}/>)
                            }
                        </Stack>
                    </Stack>

                    <Stack>
                        <Typography variant="alertDescription" color={'action.active'}>
                            Gender Preferences
                        </Typography>
                        <Typography variant="body1medium" color={'text.primary'}>
                            {brandProfile?.data?.genderPreference && brandProfile?.data?.genderPreference}
                        </Typography>
                    </Stack>

                    <Stack>
                        <Typography variant="alertDescription" color={'action.active'}>
                            Store Link
                        </Typography>

                        <Link color={'text.primary'}>
                            <Typography
                                variant="body1medium">{brandProfile?.data?.storeLink && brandProfile?.data?.storeLink}</Typography>
                        </Link>
                    </Stack>
                </Stack>

                <Stack
                    py={4}
                    px={5}
                    direction={'row'}
                    spacing={20}
                    // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                >
                    <Stack>
                        <Typography variant="body1medium" color={'text.primary'}>
                            Email Address
                        </Typography>
                        <Typography variant="alertDescription" color={'action.active'}>
                            {brandProfile?.data?.email && brandProfile?.data?.email}
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default BrandProfile;
