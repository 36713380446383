import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    addTeamMember: {
        data: null,
        isTeamMemberAdd: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    teamMemberList: {
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
        isLoading: false,
        isError: false,
        error: '',
    },
    editTeamMember: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    updateTeamMember: {
        data: null,
        isTeamMemberUpdate: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleTrashTeamMember: {
        isTeamMemberTrash: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleRestoreTeamMember: {
        isSingleTeamMemberRestore: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleTrashTeamMember: {
        isMultipleTrashTeamMember: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleDeleteTeamMember: {
        isSingleDeleteTeamMember: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleDeleteTeamMember: {
        isMultipleDeleteTeamMember: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleRestoreTeamMember: {
        isMultipleRestoreTeamMember: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    userRole: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    roleChange: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    changeStatus: {
        isStatusChange: false,
        isLoading: false,
        isError: false,
    },
    changePassword: {
        isPasswordChange: false,
        isLoading: false,
        isError: false,
    },
};

const teamMemberSlice = createSlice({
    name: 'teamMember',
    initialState: initialState,
    reducers: {
        addTeamMemberData: (state, action) => {
            state.addTeamMember.isLoading = true;
        },
        addTeamMemberSuccess: (state, action) => {
            state.addTeamMember.data = action?.payload?.data;
            state.addTeamMember.isTeamMemberAdd = true;
            state.addTeamMember.isLoading = false;
            state.addTeamMember.isError = false;
            state.addTeamMember.error = '';
        },
        addTeamMemberFailed: (state, action) => {
            state.addTeamMember.data = null;
            state.addTeamMember.isTeamMemberAdd = false;
            state.addTeamMember.isLoading = false;
            state.addTeamMember.isError = true;
            state.addTeamMember.error = action.payload;
        },
        addTeamMemberStateClear: (state, action) => {
            state.addTeamMember.data = null;
            state.addTeamMember.isTeamMemberAdd = false;
            state.addTeamMember.isLoading = false;
            state.addTeamMember.isError = false;
            state.addTeamMember.error = '';
        },


        getTeamMemberList: (state, action) => {
            state.teamMemberList.isLoading = true;
        },
        getTeamMemberListSuccess: (state, action) => {
            state.teamMemberList.data = action?.payload?.data;
            state.teamMemberList.page = action?.payload?.current_page;
            state.teamMemberList.perPage = action?.payload?.per_page;
            state.teamMemberList.total = action?.payload?.total;
            state.teamMemberList.isLoading = false;
            state.teamMemberList.isError = false;
            state.teamMemberList.error = '';
        },
        getTeamMemberListFailed: (state, action) => {
            state.teamMemberList.data = null;
            state.teamMemberList.isLoading = false;
            state.teamMemberList.total = 0;
            state.teamMemberList.isError = true;
            state.teamMemberList.error = action.payload;
        },
        getTeamMemberListStateClear: (state, action) => {
            state.teamMemberList.data = null;
            state.teamMemberList.isLoading = false;
            state.teamMemberList.isError = false;
            state.teamMemberList.error = '';
            state.teamMemberList.page = 1;
            state.teamMemberList.perPage = 10;
            state.teamMemberList.total = '';
        },


        editTeamMemberData: (state, action) => {
            state.editTeamMember.isLoading = true;
        },
        editTeamMemberSuccess: (state, action) => {
            state.editTeamMember.data = action?.payload;
            state.editTeamMember.isLoading = false;
            state.editTeamMember.isError = false;
            state.editTeamMember.error = '';
        },
        editTeamMemberFailed: (state, action) => {
            state.editTeamMember.data = null;
            state.editTeamMember.isLoading = false;
            state.editTeamMember.isError = true;
            state.editTeamMember.error = action.payload;
        },
        editTeamMemberStateClear: (state, action) => {
            state.editTeamMember.data = null;
            state.editTeamMember.isLoading = false;
            state.editTeamMember.isError = false;
            state.editTeamMember.error = '';
        },

        updateTeamMemberData: (state, action) => {
            state.updateTeamMember.isLoading = true;
        },
        updateTeamMemberSuccess: (state, action) => {
            state.updateTeamMember.data = action?.payload;
            state.updateTeamMember.isTeamMemberUpdate = true;
            state.updateTeamMember.isLoading = false;
            state.updateTeamMember.isError = false;
            state.updateTeamMember.error = '';
        },
        updateTeamMemberFailed: (state, action) => {
            state.updateTeamMember.data = null;
            state.updateTeamMember.isTeamMemberUpdate = false;
            state.updateTeamMember.isLoading = false;
            state.updateTeamMember.isError = true;
            state.updateTeamMember.error = action.payload;
        },
        updateTeamMemberStateClear: (state, action) => {
            state.updateTeamMember.data = null;
            state.updateTeamMember.isTeamMemberUpdate = false;
            state.updateTeamMember.isLoading = false;
            state.updateTeamMember.isError = false;
            state.updateTeamMember.error = '';
        },


        singleTrashTeamMemberData: (state, action) => {
            state.singleTrashTeamMember.isLoading = true;
        },
        singleTrashTeamMemberSuccess: (state, action) => {
            state.singleTrashTeamMember.isTeamMemberTrash = true;
            state.singleTrashTeamMember.isLoading = false;
            state.singleTrashTeamMember.isError = false;
            state.singleTrashTeamMember.error = '';
        },
        singleTrashTeamMemberFailed: (state, action) => {
            state.singleTrashTeamMember.isTeamMemberTrash = false;
            state.singleTrashTeamMember.isLoading = false;
            state.singleTrashTeamMember.isError = true;
            state.singleTrashTeamMember.error = action.payload;
        },
        singleTrashTeamMemberStateClear: (state, action) => {
            state.singleTrashTeamMember.isTeamMemberTrash = false;
            state.singleTrashTeamMember.isLoading = false;
            state.singleTrashTeamMember.isError = false;
            state.singleTrashTeamMember.error = '';
        },

        singleRestoreTeamMemberData: (state, action) => {
            state.singleRestoreTeamMember.isLoading = true;
        },
        singleRestoreTeamMemberSuccess: (state, action) => {
            state.singleRestoreTeamMember.isSingleTeamMemberRestore = true;
            state.singleRestoreTeamMember.isLoading = false;
            state.singleRestoreTeamMember.isError = false;
            state.singleRestoreTeamMember.error = '';
        },
        singleRestoreTeamMemberFailed: (state, action) => {
            state.singleRestoreTeamMember.isSingleTeamMemberRestore = false;
            state.singleRestoreTeamMember.isLoading = false;
            state.singleRestoreTeamMember.isError = true;
            state.singleRestoreTeamMember.error = action.payload;
        },
        singleRestoreTeamMemberStateClear: (state, action) => {
            state.singleRestoreTeamMember.isSingleTeamMemberRestore = false;
            state.singleRestoreTeamMember.isLoading = false;
            state.singleRestoreTeamMember.isError = false;
            state.singleRestoreTeamMember.error = '';
        },

        multipleTrashedTeamMember: (state, action) => {
            state.multipleTrashTeamMember.isLoading = true;
        },
        multipleTrashTeamMemberSuccess: (state, action) => {
            state.multipleTrashTeamMember.isMultipleTrashTeamMember = true;
            state.multipleTrashTeamMember.isLoading = false;
            state.multipleTrashTeamMember.isError = false;
            state.multipleTrashTeamMember.error = '';
        },
        multipleTrashTeamMemberFailed: (state, action) => {
            state.multipleTrashTeamMember.isMultipleTrashTeamMember = false;
            state.multipleTrashTeamMember.isLoading = false;
            state.multipleTrashTeamMember.isError = true;
            state.multipleTrashTeamMember.error = action.payload;
        },
        multipleTrashTeamMemberStateClear: (state, action) => {
            state.multipleTrashTeamMember.isMultipleTrashTeamMember = false;
            state.multipleTrashTeamMember.isLoading = false;
            state.multipleTrashTeamMember.isError = false;
            state.multipleTrashTeamMember.error = '';
        },

        singleDeleteTeamMemberData: (state, action) => {
            state.singleDeleteTeamMember.isLoading = true;
        },
        singleDeleteTeamMemberSuccess: (state, action) => {
            state.singleDeleteTeamMember.isSingleDeleteTeamMember = true;
            state.singleDeleteTeamMember.isLoading = false;
            state.singleDeleteTeamMember.isError = false;
            state.singleDeleteTeamMember.error = '';
        },
        singleDeleteTeamMemberFailed: (state, action) => {
            state.singleDeleteTeamMember.isSingleDeleteTeamMember = false;
            state.singleDeleteTeamMember.isLoading = false;
            state.singleDeleteTeamMember.isError = true;
            state.singleDeleteTeamMember.error = action.payload;
        },
        singleDeleteTeamMemberStateClear: (state, action) => {
            state.singleDeleteTeamMember.isSingleDeleteTeamMember = false;
            state.singleDeleteTeamMember.isLoading = false;
            state.singleDeleteTeamMember.isError = false;
            state.singleDeleteTeamMember.error = '';
        },


        multipleRestoreTeamMemberData: (state, action) => {
            state.multipleRestoreTeamMember.isLoading = true;
        },
        multipleRestoreTeamMemberSuccess: (state, action) => {
            state.multipleRestoreTeamMember.isMultipleRestoreTeamMember = true;
            state.multipleRestoreTeamMember.isLoading = false;
            state.multipleRestoreTeamMember.isError = false;
            state.multipleRestoreTeamMember.error = '';
        },
        multipleRestoreTeamMemberFailed: (state, action) => {
            state.multipleRestoreTeamMember.isMultipleRestoreTeamMember = false;
            state.multipleRestoreTeamMember.isLoading = false;
            state.multipleRestoreTeamMember.isError = true;
            state.multipleRestoreTeamMember.error = action.payload;
        },
        multipleRestoreTeamMemberStateClear: (state, action) => {
            state.multipleRestoreTeamMember.isMultipleRestoreTeamMember = false;
            state.multipleRestoreTeamMember.isLoading = false;
            state.multipleRestoreTeamMember.isError = false;
            state.multipleRestoreTeamMember.error = '';
        },

        multipleDeletedTeamMember: (state, action) => {
            state.multipleDeleteTeamMember.isLoading = true;
        },
        multipleDeleteTeamMemberSuccess: (state, action) => {
            state.multipleDeleteTeamMember.isMultipleDeleteTeamMember = true;
            state.multipleDeleteTeamMember.isLoading = false;
            state.multipleDeleteTeamMember.isError = false;
            state.multipleDeleteTeamMember.error = '';
        },
        multipleDeleteTeamMemberFailed: (state, action) => {
            state.multipleDeleteTeamMember.isMultipleDeleteTeamMember = false;
            state.multipleDeleteTeamMember.isLoading = false;
            state.multipleDeleteTeamMember.isError = true;
            state.multipleDeleteTeamMember.error = action.payload;
        },
        multipleDeleteTeamMemberStateClear: (state, action) => {
            state.multipleDeleteTeamMember.isMultipleDeleteTeamMember = false;
            state.multipleDeleteTeamMember.isLoading = false;
            state.multipleDeleteTeamMember.isError = false;
            state.multipleDeleteTeamMember.error = '';
        },

        getUserRole: (state, action) => {
            state.userRole.isLoading = true;
        },
        getUserRoleSuccess: (state, action) => {
            state.userRole.data = action?.payload;
            state.userRole.isLoading = false;
            state.userRole.isError = false;
            state.userRole.error = '';
        },
        getUserRoleFailed: (state, action) => {
            state.userRole.data = null;
            state.userRole.isLoading = false;
            state.userRole.isError = true;
            state.userRole.error = action.payload;
        },
        getUserRoleStateClear: (state, action) => {
            state.userRole.data = null;
            state.userRole.isLoading = false;
            state.userRole.isError = false;
            state.userRole.error = '';
        },

        changeUserRole: (state, action) => {
            state.roleChange.isLoading = true;
        },
        changeUserRoleSuccess: (state, action) => {
            state.roleChange.data = action?.payload;
            state.roleChange.isLoading = false;
            state.roleChange.isError = false;
            state.roleChange.error = '';
        },
        changeUserRoleFailed: (state, action) => {
            state.roleChange.data = null;
            state.roleChange.isLoading = false;
            state.roleChange.isError = true;
            state.roleChange.error = action.payload;
        },
        changeUserRoleStateClear: (state, action) => {
            state.roleChange.data = null;
            state.roleChange.isLoading = false;
            state.roleChange.isError = false;
            state.roleChange.error = '';
        },

        changeTeamMemberStatus: (state, action) => {
            state.changeStatus.isLoading = true;
        },
        changeTeamMemberStatusSuccess: (state, action) => {
            state.changeStatus.isStatusChange = true;
            state.changeStatus.isLoading = false;
            state.changeStatus.isError = false;
        },
        changeTeamMemberStatusFailed: (state, action) => {
            state.changeStatus.isStatusChange = false;
            state.changeStatus.isLoading = false;
            state.changeStatus.isError = true;
        },
        changeTeamMemberStatusStateClear: (state, action) => {
            state.changeStatus.isStatusChange = false;
            state.changeStatus.isLoading = false;
            state.changeStatus.isError = false;
        },

        changeTeamMemberPassword: (state, action) => {
            state.changePassword.isLoading = true;
        },
        changeTeamMemberPasswordSuccess: (state, action) => {
            state.changePassword.isPasswordChange = true;
            state.changePassword.isLoading = false;
            state.changePassword.isError = false;
        },
        changeTeamMemberPasswordFailed: (state, action) => {
            state.changePassword.isPasswordChange = false;
            state.changePassword.isLoading = false;
            state.changePassword.isError = true;
        },
        changeTeamMemberPasswordStateClear: (state, action) => {
            state.changePassword.isPasswordChange = false;
            state.changePassword.isLoading = false;
            state.changePassword.isError = false;
        },
    }
});

export const {
    addTeamMemberData,
    addTeamMemberSuccess,
    addTeamMemberFailed,
    addTeamMemberStateClear,

    getTeamMemberList,
    getTeamMemberListSuccess,
    getTeamMemberListFailed,
    getTeamMemberListStateClear,

    editTeamMemberData,
    editTeamMemberSuccess,
    editTeamMemberFailed,
    editTeamMemberStateClear,

    updateTeamMemberData,
    updateTeamMemberSuccess,
    updateTeamMemberFailed,
    updateTeamMemberStateClear,

    singleTrashTeamMemberData,
    singleTrashTeamMemberSuccess,
    singleTrashTeamMemberFailed,
    singleTrashTeamMemberStateClear,

    singleRestoreTeamMemberData,
    singleRestoreTeamMemberSuccess,
    singleRestoreTeamMemberFailed,
    singleRestoreTeamMemberStateClear,

    multipleTrashedTeamMember,
    multipleTrashTeamMemberSuccess,
    multipleTrashTeamMemberFailed,
    multipleTrashTeamMemberStateClear,

    singleDeleteTeamMemberData,
    singleDeleteTeamMemberSuccess,
    singleDeleteTeamMemberFailed,
    singleDeleteTeamMemberStateClear,

    multipleDeletedTeamMember,
    multipleDeleteTeamMemberSuccess,
    multipleDeleteTeamMemberFailed,
    multipleDeleteTeamMemberStateClear,

    multipleRestoreTeamMemberData,
    multipleRestoreTeamMemberSuccess,
    multipleRestoreTeamMemberFailed,
    multipleRestoreTeamMemberStateClear,

    getUserRole,
    getUserRoleSuccess,
    getUserRoleFailed,
    getUserRoleStateClear,

    changeUserRole,
    changeUserRoleSuccess,
    changeUserRoleFailed,
    changeUserRoleStateClear,

    changeTeamMemberStatus,
    changeTeamMemberStatusSuccess,
    changeTeamMemberStatusFailed,
    changeTeamMemberStatusStateClear,

    changeTeamMemberPassword,
    changeTeamMemberPasswordSuccess,
    changeTeamMemberPasswordFailed,
    changeTeamMemberPasswordStateClear,


} = teamMemberSlice.actions;

export default teamMemberSlice.reducer;
