import React, {useRef} from 'react';
import Box from '@mui/material/Box';
import Styles from '../styles/ProductAppPreview.module.css';
import {Stack, Typography} from '@mui/material';
import FloatButtonLeft from '../../../assets/images/Icons/floatingButtonLeft.svg';
import FloatButtonRight from '../../../assets/images/Icons/floatingButtonRight.svg';
import iosBar from '../../../assets/images/Icons/iosStatusBar.svg';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {useSelector} from "react-redux";
import {selectProfile} from "../../../state/features/Profile/profileSelector";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";

const ProductAppPreview = ({productImage}) => {
    const swiperRef = useRef(null);
    const {myProfile} = useSelector(selectProfile);
    const {productName, productPrice} = useSelector(selectAddProduct);
    const goToNextSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goToPreviousSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };
    return (
        <Box className={Styles.through_sms_container}>
            <Swiper pagination={true} modules={[Pagination]} className="mySwiper" ref={swiperRef}>
                <Stack
                    width={'100%'}
                    spacing={3}
                    sx={{px: 2, py: 1, position: 'absolute', top: 0, left: 0, zIndex: 9}}
                >
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="body2" fontWeight={600}>
                            9:41
                        </Typography>
                        <img src={iosBar} alt=""/>
                    </Stack>

                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <img src={FloatButtonLeft} alt="" onClick={goToPreviousSlide}/>
                        <img src={FloatButtonRight} alt="" onClick={goToNextSlide}/>
                    </Stack>
                </Stack>

                {
                    productImage?.length > 0 && (
                        productImage?.map((items) => (
                            <SwiperSlide className={Styles.sliderItem}>
                                <img width={'100%'} src={items?.url} alt=""/>
                            </SwiperSlide>
                        ))
                    )
                }
            </Swiper>
            <Stack
                sx={{
                    position: 'absolute',
                    bottom: 20,
                    zIndex: 9,
                    bgcolor: 'common.white',
                    minWidth: '265px',
                }}
                spacing={1}
                px={0.8}
                py={6}
                alignItems={'center'}
                className={Styles.sliderBottom}
            >
                {myProfile?.data?.profileLogo && <img src={myProfile?.data?.profileLogo} width={50} height={50} alt="app logo"/>}
                <Typography
                    variant="h5"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px'
                    }}>
                    {productName && productName}
                </Typography>

                <Typography variant="h6" color={'text.secondary'}>
                    {productPrice && `$ ${productPrice}`}
                </Typography>
            </Stack>
        </Box>
    );
};

export default ProductAppPreview;
