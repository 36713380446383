import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {galleryListFailed, galleryListSuccess} from "./galleryListSlice";
import GalleryService from "../../../../services/Gallery.service";

function* galleryListWatcher() {
    yield takeEvery('GalleryList/galleryList', galleryListSaga);
}

function* galleryListSaga(action) {
    try {
        const response = yield call(GalleryService.galleryImageList, action.payload);

        if (response?.success) {
            yield put(galleryListSuccess(response?.data));
        } else {
            yield put(galleryListFailed(response?.message));
        }
    } catch (err) {
        yield put(galleryListFailed(err.message));
    }
}

export default function* galleryListsSaga() {
    yield all([galleryListWatcher()]);
}
