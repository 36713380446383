export const typographyProperties = {
  htmlFontSize: 16,
  fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '64px',
    letterSpacing: '0'
  },
  h2: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: '42px',
    lineHeight: '56px',
    letterSpacing: '-0.00833em'
  },
  h3: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '48px',
    letterSpacing: '0'
  },
  h4: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '42px',
    letterSpacing: '-1.5px'
  },
  h5: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
    letterSpacing: '0.3px'
  },
  h6: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '0.15px'
  },
  subtitle1: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.15px'
  },
  subtitle2: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px'
  },
  body1: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.15px'
  },
  body1medium: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: ' 0.15px'
  },
  body2: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.15px'
  },
  body1SemiBold: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.15px'
  },
  body2Semibold: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.15px'
  },
  body2medium: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px'
  },
  body2italic: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px'
  },
  buttonSmall: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '22px'
  },
  buttonMedium: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px'
  },
  button: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize',
    borderRadius: '4px'
  },
  caption: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px'
  },
  overline: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  avatarLetter: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.14px'
  },
  inputLevel: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.15px'
  },
  helperText: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.4px'
  },
  chipLight: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.16px'
  },
  inputText: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px'
  },
  tooltip: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '0'
  },
  badge: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.14px'
  },
  chipMedium: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.16px'
  },
  chipLabel: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.16px'
  },
  TableHeader: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.17px'
  },
  alert: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.15px'
  },
  alertTitle: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px'
  },
  alertDescription: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.15px'
  },
  helperText2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.15px'
  },
  info: {
    fontFamily: ['Inter', 'Poppins', 'Arial', 'sans-serif'].join(','),
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px'
  }
};

export const lightModePalette = {
  mode: 'light',
  common: {
    black: '#000000',
    white: '#FFFFFF'
  },
  background: {
    paper: '#FFFFFF',
    default: '#F9FAFB'
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: '#00000099'
  },
  primary: {
    main: '#4897E9', // default
    // light: will be calculated from palette.primary.main,
    // dark: will be calculated from palette.primary.main and palette.action.hover,
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#006DF5',
    contrastText: '#FFFFFF'
  },
  warning: {
    main: '#ED6C02',
    contrastText: '#FFFFFF',
    light: '#FFDCC3',
    dark: '#944200'
  },
  error: {
    main: '#D32F2F',
    light: '#EF5350',
    contrastText: '#FFFFFF'
  },
  info: {
    main: '#0288D1',
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#2E7D32',
    light: '#baf0cb',
    contrastText: '#FFFFFF'
  },
  other: {
    outlinedBorder: 'rgba(148, 157, 178, 0.12)',
    btnOutlinedBorder: 'rgba(0, 109, 245, 0.5)',
    dividerFillColor: 'rgba(148, 157, 178, 0.12)',
    outlinedBorderDarker: 'rgba(148, 157, 178, 0.24)',
    lightGray: '#E0E0E0',
    gray: '#eeeeee',
    skylight: '#7db3f7',
    secondaryHover: '#006df514',
    pageColor: '#f4f5f7',
    backdrop: 'rgb(17 17 17 / 5%)',
    primaryLight: '#D2CCFF',
    primaryDark: '#2972DD',
    bgPaperElevation: '#F2F3F3',
    inputOutlinedEnabledBorder: '#0000003B',
    divider: '#0000001F',
    primaryStatesOutlinedBorder: '#4897E980',
    progressPrimary: '#EBEBEB',
    primaryStatesSelected: 'rgba(72, 151, 233, 0.08)',
    gray5: '#818688',
    gray7: '#43494D',
    gray8: '#2E3539'
  },
  action: {
    active: '#0000008F',
    actionHover: 'rgba(98, 88, 255, 0.06)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.03,
    selected: 'rgba(28, 15, 19, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(3, 6, 11, 0.26)',
    disabledBackground: 'rgba(3, 6, 11, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(28, 15, 19, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12
  }
};

export const myBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    medium: 930,
    large: 1324,
    extraLg: 1570,
    xxl: 1780,
    tab: 1024
  }
};
