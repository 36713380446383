import {AccountCircle} from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import {Button, Chip, Link, ListItemIcon, Menu, MenuItem, Stack} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import BasicWithHeaderModal from '../../../assets/global/Modal/BasicWithHeaderModal';
import logoutIcon from '../../../assets/images/Icons/LogoutIcon.svg';
import {useDispatch, useSelector} from 'react-redux';
import {attemptLogout} from '../../../state/features/user/userSlice';
import {clearStorageWhenLogOut} from '../../../helpers/utility/clearStorageWhenLogOut';
import {selectProfile} from '../../../state/features/Profile/profileSelector';
import {getMyProfileDataClearState} from "../../../state/features/Profile/profileSlice";

const settings = [
    {
        name: 'Account',
        path: '/settings/account',
        icon: <AccountCircle/>
    },
    {
        name: 'Billing',
        path: '/settings/agency-billing',
        icon: <AccountBoxIcon/>
    }
];

const AppNavbarProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logout, setLogout] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const {myProfile} = useSelector(selectProfile);

    const handleOpenUserMenu = event => setAnchorElUser(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorElUser(null);

    const handleLogOut = () => {
        clearStorageWhenLogOut();
        dispatch(attemptLogout());
        dispatch(getMyProfileDataClearState());
        handleCloseUserMenu();
        setLogout(false);
        navigate('/login');
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}
            >
                <IconButton sx={{p: 0}}>
                    {myProfile?.data?.profileLogo ? (
                        <Avatar sx={{width: 35, height: 35}} alt="logo" src={myProfile?.data?.profileLogo}/>
                    ) : (
                        <Avatar alt="logo"/>
                    )}
                </IconButton>

                <Stack direction={'row'} spacing={2}>
                    <Stack alignItems={'flex-start'}>
                        <Typography variant={'subtitle2'} sx={{fontWeight: 'bold'}}>
                            {myProfile?.data?.name}
                        </Typography>
                        {
                            myProfile?.data?.role && (
                                <Chip
                                    size="small"
                                    label={(myProfile?.data?.role === 'Admin' && !myProfile?.data?.isTeamMember) ? 'Brand Admin' : myProfile?.data?.role}
                                    sx={{height: 18, fontSize: 10, fontWeight: 500, color: 'text.secondary'}}
                                />)
                        }

                    </Stack>

                    <Box>
                        <IconButton onClick={handleOpenUserMenu}>
                            <ArrowDropDownIcon/>
                        </IconButton>
                    </Box>
                </Stack>
            </Box>

            <Menu
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                slotProps={{listbox: {id: 'simple-menu'}}}
                sx={{width: 200}}
            >
                <Link
                    component={RouterLink}
                    sx={{textDecoration: 'unset', color: 'text.primary'}}
                    to="/profileSettings"
                    onClick={() => setAnchorElUser(null)}
                >
                    <MenuItem sx={{width: 220}}>
                        <ListItemIcon>
                            <PersonIcon fontSize="small"/>
                        </ListItemIcon>

                        <Typography>View Profile</Typography>
                    </MenuItem>
                </Link>

                <MenuItem onClick={() => {
                    setLogout(true);
                    setAnchorElUser(null)
                }}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>

            <BasicWithHeaderModal
                open={logout}
                header={false}
                width={540}
                onClose={() => setLogout(false)}
            >
                <Box>
                    <Stack alignItems={'center'} spacing={4} py={6} textAlign={'center'}>
                        <img src={logoutIcon} width={120} height={150} alt=""/>

                        <span>
                            <Typography variant="h5" mb={1}>
                              Are you sure you want to log <br/> out of the app?
                            </Typography>
                            <Typography variant="body" color="text.secondary" mb={5}>
                              You’ll have to log in again to continue
                            </Typography>
                        </span>
                    </Stack>

                    <Stack
                        p={'16px'}
                        sx={{borderTop: 1, borderColor: 'other.divider'}}
                        direction={'row'}
                        justifyContent={'flex-end'}
                        spacing={2}
                    >
                        <Button
                            variant="text"
                            disableElevation
                            onClick={() => setLogout(false)}
                            sx={{color: 'text.primary'}}
                        >
                            Cancel
                        </Button>

                        <Button variant="contained" onClick={handleLogOut}>
                            Log Out
                        </Button>
                    </Stack>
                </Box>
            </BasicWithHeaderModal>
        </>
    );
};

export default AppNavbarProfile;
