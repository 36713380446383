import PublicPageLayout from '../components/Layout/PublicPageLayout/PublicPageLayout';
import routeLink from '../helpers/constant/routeLink';
import UserProfile from '../components/PublicPage/UserProfile/UserProfile';
import PublicBrandProfile from '../components/PublicPage/BrandProfile/PublicBrandProfile';
import Collection from '../components/PublicPage/Collection/Collection';
import Product from '../components/PublicPage/Product/Product';
import PublicPromotion from '../components/PublicPage/Promotion/PublicPromotion';
import RewardsPolicies from '../components/PublicPage/RewardsPolicies/RewardsPolicies';
import { Skeleton } from '@mui/material';
import PublicPageSkeletonLoader from '../components/PublicPage/common/PublicPageSkeletonLoader';

const PublicPageRoutes = {
  path: '/',
  element: <PublicPageLayout />,
  children: [
    {
      path: '/user/:userId',
      element: <UserProfile />,
      key: routeLink.editProduct.hidden_key
    },
    {
      path: '/brand/:brandId',
      element: <PublicBrandProfile />,
      key: routeLink.editProduct.hidden_key
    },
    {
      path: '/collection/:collectionId',
      element: <Collection />,
      key: routeLink.editProduct.hidden_key
    },
    {
      path: '/product/:productId',
      element: <Product />,
      key: routeLink.editProduct.hidden_key
    },
    {
      path: '/promotion/:promotionId',
      element: <PublicPromotion />,
      key: routeLink.editProduct.hidden_key
    },
    {
      path: '/rewards-policies/:promotionId',
      element: <RewardsPolicies />,
      key: routeLink.editProduct.hidden_key
    },
    
    {
      path: '/skeleton',
      element: <PublicPageSkeletonLoader />,
      key: routeLink.editProduct.hidden_key
    }
  ]
};

export default PublicPageRoutes;
