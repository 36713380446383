import React from 'react'
import {Box, Breadcrumbs, Link, Typography} from "@mui/material";
import {NavigateNext} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";

const CoreBreadCrumbs = ({pageInfo, keys}) => {
    const {breadcrumbs} = pageInfo || {};
    const handleClick = (e) => {
        console.log("Root path");
    };
    const matchedArray = breadcrumbs.filter(object => keys.includes(object.key));
    return(
        <Box>
            <Breadcrumbs separator={<NavigateNext fontSize='small' />} aria-label='breadcrumb'>
                {matchedArray.map((item, index) =>
                    (index === 1) ? (
                        <Typography fontWeight={"bold"} key='3' color='text.primary' sx={{fontSize: '12px'}}>
                            {item.name}
                        </Typography>
                    ) : (
                        <Link
                            key={item.name}
                            underline={item.path ? "hover" : "none"}
                            color={"inherit"}
                            component={RouterLink}
                            to={item.path}
                            onClick={handleClick}
                            style={{ cursor: item.path ? "pointer" : "default" }}
                            sx={{fontSize: '12px'}}
                        >
                            {item.name}
                        </Link>
                    )
                )}
            </Breadcrumbs>
        </Box>
    )
};
export default CoreBreadCrumbs;