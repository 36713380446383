import {all, put} from 'redux-saga/effects';
import {call, debounce, takeEvery} from '@redux-saga/core/effects';
import TeamMemberService from "../../../services/TeamMember.service";
import {
    addTeamMemberFailed,
    addTeamMemberSuccess,
    changeTeamMemberPasswordFailed,
    changeTeamMemberPasswordSuccess,
    changeTeamMemberStatusFailed,
    changeTeamMemberStatusSuccess,
    changeUserRoleFailed,
    changeUserRoleSuccess,
    editTeamMemberFailed,
    editTeamMemberSuccess,
    getTeamMemberList,
    getTeamMemberListFailed,
    getTeamMemberListSuccess,
    getUserRoleFailed,
    getUserRoleSuccess,
    multipleDeleteTeamMemberFailed,
    multipleDeleteTeamMemberSuccess,
    multipleRestoreTeamMemberFailed,
    multipleRestoreTeamMemberSuccess,
    multipleTrashTeamMemberFailed,
    multipleTrashTeamMemberSuccess,
    singleDeleteTeamMemberFailed,
    singleDeleteTeamMemberSuccess,
    singleRestoreTeamMemberFailed,
    singleRestoreTeamMemberSuccess,
    singleTrashTeamMemberFailed,
    singleTrashTeamMemberSuccess,
    updateTeamMemberFailed,
    updateTeamMemberSuccess
} from "./teamMemberSlice";
import {showErrorMessage, showSuccessMessage} from "../../../helpers/utility/Toastify";
import {STATUS__TRASHED} from "../../../helpers/constant/coreConstant";

function* teamMemberWatcher() {
    yield takeEvery('teamMember/addTeamMemberData', addTeamMemberSaga);
    yield debounce(500, 'teamMember/getTeamMemberList', getTeamMemberListSaga);
    yield takeEvery('teamMember/editTeamMemberData', editTeamMemberSaga);
    yield takeEvery('teamMember/updateTeamMemberData', updateTeamMemberSaga);
    yield takeEvery('teamMember/singleTrashTeamMemberData', singleTrashTeamMemberSaga);
    yield takeEvery('teamMember/singleRestoreTeamMemberData', singleRestoreTeamMemberSaga);
    yield takeEvery('teamMember/multipleTrashedTeamMember', multipleTrashTeamMemberSaga);
    yield takeEvery('teamMember/singleDeleteTeamMemberData', singleDeleteTeamMemberSaga);
    yield takeEvery('teamMember/multipleDeletedTeamMember', multipleDeleteTeamMemberSaga);
    yield takeEvery('teamMember/multipleRestoreTeamMemberData', multipleRestoreTeamMemberSaga);
    yield takeEvery('teamMember/getUserRole', getUserRoleSaga);
    yield takeEvery('teamMember/changeUserRole', changeUserRoleSaga);
    yield takeEvery('teamMember/changeTeamMemberStatus', changeTeamMemberStatusSaga);
    yield takeEvery('teamMember/changeTeamMemberPassword', changeTeamMemberPasswordSaga);
}

function* addTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.addTeamMember, action.payload);

        if (response?.success) {
            yield put(addTeamMemberSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            if (response?.message?.firstName) {
                showErrorMessage(response?.message?.firstName[0]);
            } else if (response?.message?.lastName) {
                showErrorMessage(response?.message?.lastName[0]);
            } else if (response?.message?.email) {
                showErrorMessage(response?.message?.email[0]);
            } else if (response?.message?.roleUid) {
                showErrorMessage(response?.message?.roleUid[0]);
            } else if (response?.message?.profileLogo) {
                showErrorMessage(response?.message?.profileLogo[0]);
            } else if (response?.message?.password) {
                showErrorMessage(response?.message?.password[0]);
            } else {
                showErrorMessage(response?.message);
            }
            yield put(addTeamMemberFailed(response?.data));
        }
    } catch (err) {
        yield put(addTeamMemberFailed(err.message));
    }
}

function* getTeamMemberListSaga(action) {
    try {

        const response = yield call(TeamMemberService.teamMemberList, action.payload);
        if (response?.success) {
            yield put(getTeamMemberListSuccess(response?.data));
        } else {
            yield put(getTeamMemberListFailed([]));
        }
    } catch (err) {
        yield put(getTeamMemberListFailed(err.message));
    }
}

function* editTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.editTeamMember, action.payload);

        if (response?.success) {
            yield put(editTeamMemberSuccess(response?.data));
            // showSuccessMessage(response?.message);
        } else {
            yield put(editTeamMemberFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(editTeamMemberFailed(err.message));
    }
}

function* updateTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.updateTeamMember, action.payload);

        if (response?.success) {
            yield put(updateTeamMemberSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(updateTeamMemberFailed(response?.data));
            if (response?.message?.firstName) {
                showErrorMessage(response?.message?.firstName[0]);
            } else if (response?.message?.lastName) {
                showErrorMessage(response?.message?.lastName[0]);
            } else if (response?.message?.email) {
                showErrorMessage(response?.message?.email[0]);
            } else if (response?.message?.roleUid) {
                showErrorMessage(response?.message?.roleUid[0]);
            } else if (response?.message?.profileLogo) {
                showErrorMessage(response?.message?.profileLogo[0]);
            } else {
                showErrorMessage(response?.message);
            }
        }
    } catch (err) {
        yield put(updateTeamMemberFailed(err.message));
    }
}


function* singleTrashTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.singleTrash, action.payload);

        if (response?.success) {
            yield put(singleTrashTeamMemberSuccess(response?.data));
            yield put(getTeamMemberList({
                status: action.payload?.category,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(singleTrashTeamMemberFailed(response?.data));
        }
    } catch (err) {
        yield put(singleTrashTeamMemberFailed(err.message));
    }
}

function* singleRestoreTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.singleTrash, action.payload);

        if (response?.success) {
            yield put(singleRestoreTeamMemberSuccess(response?.data));
            yield put(getTeamMemberList({
                status: action.payload?.category,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(singleRestoreTeamMemberFailed(response?.message));
        }
    } catch (err) {
        yield put(singleRestoreTeamMemberFailed(err.message));
    }
}

function* multipleTrashTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.multipleTrash, action.payload);

        if (response?.success) {
            yield put(multipleTrashTeamMemberSuccess(response?.data));
            yield put(getTeamMemberList({
                status: action.payload?.category,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleTrashTeamMemberFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleTrashTeamMemberFailed(err.message));
    }
}

function* singleDeleteTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.singleDelete, action.payload);

        if (response?.success) {
            yield put(singleDeleteTeamMemberSuccess(response?.data));
            yield put(getTeamMemberList({
                status: STATUS__TRASHED,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(singleDeleteTeamMemberFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(singleDeleteTeamMemberFailed(err.message));
    }
}

function* multipleDeleteTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.multipleDelete, action.payload);

        if (response?.success) {
            yield put(multipleDeleteTeamMemberSuccess(response?.data));
            yield put(getTeamMemberList({
                status: STATUS__TRASHED,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleDeleteTeamMemberFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleDeleteTeamMemberFailed(err.message));
    }
}

function* multipleRestoreTeamMemberSaga(action) {
    try {
        const response = yield call(TeamMemberService.multipleRestore, action.payload);

        if (response?.success) {
            yield put(multipleRestoreTeamMemberSuccess(response?.data));
            yield put(getTeamMemberList({
                status: action.payload?.status,
                searchInputText: action.payload?.searchInputText,
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleRestoreTeamMemberFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleRestoreTeamMemberFailed(err.message));
    }
}

function* getUserRoleSaga(action) {
    try {
        const response = yield call(TeamMemberService.getUserRoleList, action.payload);

        if (response?.success) {
            yield put(getUserRoleSuccess(response?.data));
        } else {
            yield put(getUserRoleFailed(response?.data));
        }
    } catch (err) {
        yield put(getUserRoleFailed(err.message));
    }
}

function* changeUserRoleSaga(action) {
    try {
        const response = yield call(TeamMemberService.changeRole, action.payload);

        if (response?.success) {
            yield put(changeUserRoleSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(changeUserRoleFailed(response?.data));
            yield put(getTeamMemberList({
                status: action?.payload?.category,
                searchInputText: action.payload?.searchInputText,
                page: action.payload?.page,
                per_page: action.payload?.perPage,
            }));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(changeUserRoleFailed(err.message));
    }
}

function* changeTeamMemberStatusSaga(action) {
    try {
        const response = yield call(TeamMemberService.changeStatus, action.payload);

        if (response?.success) {
            yield put(changeTeamMemberStatusSuccess(response?.data));
            showSuccessMessage(response?.message);
            yield put(getTeamMemberList({
                status: action?.payload?.category,
                searchInputText: action.payload?.searchInputText,
            }));
        } else {
            yield put(changeTeamMemberStatusFailed(response?.message));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(changeTeamMemberStatusFailed(err.message));
    }
}
function* changeTeamMemberPasswordSaga(action) {
    try {
        const response = yield call(TeamMemberService.changePassword, action.payload);

        if (response?.success) {
            yield put(changeTeamMemberPasswordSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            if (response?.message?.password) {
                showErrorMessage(response?.message?.password[0]);
            } else if (response?.message?.confirmPassword) {
                showErrorMessage(response?.message.confirmPassword[0]);
            } else {
                yield put(changeTeamMemberPasswordFailed(response?.message));
                showErrorMessage(response?.message);
            }
        }
    } catch (err) {
        yield put(changeTeamMemberPasswordFailed(err.message));
    }
}

export default function* teamMembersSaga() {
    yield all([teamMemberWatcher()]);
}
