import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import WrapperHeader from "../../assets/global/wrapper/ContentWrapper/WrapperHeader";
import {Box, Button, Grid, Stack} from "@mui/material";
import WrapperFooter from "../../assets/global/wrapper/ContentWrapper/WrapperFooter";
import TeamMemberForm from "./Form/TeamMemberForm";
import UserRoleSelectForm from "./Form/UserRoleSelectForm";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectTeamMember} from "../../state/features/TeamMember/teamMemberSelector";
import {
    editTeamMemberData,
    editTeamMemberStateClear,
    updateTeamMemberData,
    updateTeamMemberStateClear
} from "../../state/features/TeamMember/teamMemberSlice";
import {showErrorMessage} from "../../helpers/utility/Toastify";
import ContentWrapper from "../../assets/global/wrapper/ContentWrapper/ContentWrapper";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import CoreBreadCrumbs from "../../assets/global/BreadCrumbs/CoreBreadCrumbs";
import {teamMemberInfo} from "../../helpers/constant/pageInfo";

const EditTeamMember = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {editTeamMember, updateTeamMember} = useSelector(selectTeamMember);
    const {teamMemberId} = useParams();
    const [image, setImage] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });

    useEffect(() => {
        if (errors) {
            handleValidate(errors)
        }
    }, [errors]);

    const handleValidate = (err) => {
        if (errors?.firstName) {
            showErrorMessage(errors?.firstName?.message);
        } else if (errors?.lastName) {
            showErrorMessage(errors?.lastName?.message)
        } else if (errors?.email) {
            showErrorMessage(errors?.email?.message);
        } else if (errors?.roleUid) {
            showErrorMessage('User role is required!');
        }
    }

    useEffect(() => {
        if (teamMemberId) {
            dispatch(editTeamMemberData({teamMemberUid: teamMemberId}));
        }
    }, [teamMemberId]);

    useEffect(() => {
        if (editTeamMember?.data) {
            let firstName = null;
            let lastName = null;
            const wordList = editTeamMember?.data?.name.split(' ');
            if (wordList.length >= 2) {
                firstName = wordList[0];
                lastName = wordList[1];
            } else if (wordList.length === 1) {
                firstName = wordList[0];
                lastName = null;
            } else {
                firstName = null;
                lastName = null;
            }
            setValue("firstName", firstName || "");
            setValue("lastName", lastName || "");
            setValue("email", editTeamMember?.data?.email || "");
            // setValue("password", editTeamMember?.data?.password || "");
            setValue("password", "");
            setValue("roleUid", editTeamMember?.data?.roleUid || "");
            setSelectedValue(editTeamMember?.data?.roleUid);
        }
    }, [editTeamMember?.data]);

    const onSubmit = (data) => {
        if (data) {
            let formData = new FormData();
            formData.append('firstName', data?.firstName);
            formData.append('lastName', data?.lastName);
            formData.append('email', data?.email);
            formData.append('password', data?.password);
            formData.append('profileLogo', image?.photo?.data && image?.photo?.data);
            formData.append('roleUid', data?.roleUid);
            formData.append('uid', teamMemberId);
            dispatch(updateTeamMemberData(formData));
        } else {
            showErrorMessage("Data not found!");
        }
    }

    useEffect(() => {
        if (updateTeamMember?.isTeamMemberUpdate) {
            reset({
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                roleUid: "",
            });
            dispatch(updateTeamMemberStateClear());
            setImage(null);
            setSelectedValue(null);
            navigate('/teamMembers');
        }
    }, [updateTeamMember?.isTeamMemberUpdate]);

    return (
        <ContentWrapper>
            <WrapperHeader
                title="Edit Team Member"
                backButton={() => {
                    navigate('/teamMembers');
                    dispatch(editTeamMemberStateClear())
                }}
                breadCrumb={<CoreBreadCrumbs pageInfo={teamMemberInfo} keys={[1,3]}/>}
            />

            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    sx={{
                        overflowY: 'auto'
                    }}
                >
                    <Grid item sm={12} lg={4.55}>
                        <Box
                            py={4}
                            px={5}
                            borderRight={{lg: '1px solid'}}
                            borderColor={{lg: 'other.divider'}}
                            sx={{height: {sm: 'auto', lg: 'calc(100vh - 260px)'}, overflowY: 'auto'}}
                        >
                            <TeamMemberForm
                                control={control}
                                setImage={setImage}
                                image={image}
                                type={'edit'}
                                teamMemberId={teamMemberId}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={12} lg={7.45}>
                        <Stack
                            py={4}
                            px={5}
                            spacing={4}
                        >
                            <UserRoleSelectForm
                                control={control}
                                setValue={setValue}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <WrapperFooter>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <Button
                                sx={{color: 'text.primary'}}
                                variant="text"
                                onClick={() => {
                                    navigate('/teamMembers');
                                    dispatch(editTeamMemberStateClear());
                                }}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                isLoading={updateTeamMember?.isLoading}
                                loadingText={'updating...'}
                                variant="contained"
                                type={'submit'}
                                onClick={() => handleValidate(errors)}
                            >
                                Update Info
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </WrapperFooter>
            </Box>
        </ContentWrapper>
    );
}
export default EditTeamMember;
