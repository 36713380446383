import React, {useEffect} from 'react';
import {Box, FormHelperText, Radio, Stack, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {TeamMemberValidationPattern} from "../../../helpers/constant/validationPattern";
import {Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectTeamMember} from "../../../state/features/TeamMember/teamMemberSelector";
import {getUserRole} from "../../../state/features/TeamMember/teamMemberSlice";
import EmptyPage from "../../../assets/global/EmptyComponent/EmptyPage";

const UserRoleSelectForm = ({control, setValue, selectedValue, setSelectedValue}) => {
    const dispatch = useDispatch();
    const {userRole} = useSelector(selectTeamMember);
    const handleRoleChange = role => {
        if (role) {
            setValue('roleUid', role);
        }
    };
    useEffect(() => {
        if (!userRole?.data?.length) {
            dispatch(getUserRole())
        }
    }, []);

    return (
        <>
            <Typography variant="h6">Select User Role</Typography>
            <Controller
                control={control}
                name="roleUid"
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl sx={{my: 2}} fullWidth error={!!(invalid && error.message)}>
                        <Stack spacing={3}>
                            {
                                userRole?.data?.length > 0 ? (
                                    userRole?.data?.map((item, index) => (
                                        <Stack
                                            sx={{
                                                border: '1px solid',
                                                borderColor: selectedValue === item?.uid ? 'primary.main' : 'other.divider',
                                                bgcolor: selectedValue === item?.uid ? 'other.primaryStatesSelected' : '',
                                                borderRadius: 0.8,
                                                cursor: 'pointer'
                                            }}
                                            direction="row"
                                            justifyContent="space-between"
                                            py={2}
                                            pl={3}
                                            pr={1}
                                            onClick={() => {
                                                setSelectedValue(item?.uid);
                                                handleRoleChange(item?.uid)
                                            }}
                                        >
                                            <Stack>
                                                <Typography variant="body1medium" color="text.primary">
                                                    {item?.name}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Only available to invited people.
                                                </Typography>
                                            </Stack>

                                            <Box>
                                                <Radio
                                                    checked={selectedValue === item?.uid}
                                                    // onChange={handleRoleChange}
                                                    // value="99be8e4c-d1aa-49c3-a263-7cda7ce1bf36"
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'admin'}}
                                                />
                                            </Box>
                                        </Stack>
                                    ))
                                ) : (
                                    <EmptyPage title={'Role not found!'}/>

                                )
                            }
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </Stack>
                    </FormControl>
                )}
                rules={TeamMemberValidationPattern.roleUid}
            />
        </>
    )
}

export default UserRoleSelectForm;