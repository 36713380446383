import React from 'react';
import {Box} from '@mui/material';

const ContentWrapper = props => {
  return (
    <Box bgcolor={'common.white'} borderRadius={1} {...props}>
      {props.children}
    </Box>
  );
};

export default ContentWrapper;
