import Onboard from '../components/Onboarding/Onboard';
import OnboardingLayout from '../components/Layout/OnboardingLayout/OnboardingLayout';
import routeLink from "../helpers/constant/routeLink";

const OnboardingRoutes = {
    path: '/',
    element: <OnboardingLayout/>,
    children: [
        {
            path: '/onboarding',
            element: <Onboard/>,
            key: routeLink.onboarding.hidden_key
        },
    ]
};

export default OnboardingRoutes;
