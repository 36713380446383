import React from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {addImageStyle, StyledButton} from "../../../helpers/utility/Product";
import useImageDnD from "../hooks/useImageDnD";
import {Link} from "react-router-dom";


const ImageCard = ({index, image, id, imageName, moveCard, handleRemoveImage}) => {
    const {
        ref,
        handlerId,
        ctaHover,
        imageCrop,
        setImageCrop,
        handleHover,
        handleAdjustImage,

    } = useImageDnD({moveCard, index, id});

    return (
        <Box
            sx={addImageStyle}
            data-handler-id={handlerId}
            ref={ref}
        >
            <img
                src={`${image}?w=191&fit=crop&auto=format`}
                srcSet={`${image}?w=191&fit=crop&auto=format&dpr=2 2x`}
                width={'100%'}
                alt={imageName}
            />

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '100%',
                    width: '100%',
                    opacity: ctaHover === id ? 1 : 0,
                    transition: '.5s ease',
                    backgroundColor: 'text.secondary'
                }}
                onMouseEnter={() => handleHover(id)}
                onMouseLeave={() => handleHover(null)}
            >
                <Stack justifyContent={'space-between'} height={'100%'}>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        color={'common.white'}
                        spacing={1}
                        p={0.5}
                    >
                        <MenuIcon/>
                        <Typography variant="caption">Drag to Re-arrange</Typography>
                    </Stack>

                    <Box textAlign={'center'} pb={2}>
                        <Button
                            size="small"
                            variant="outlined"
                            sx={StyledButton}
                            onClick={() => handleAdjustImage(image, index)}
                        >
                            Adjust Image
                        </Button>

                        <Stack
                            direction={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            spacing={1.2}
                        >
                            <Link to={'/myGallery/?type=productImage'}>
                                <Button sx={StyledButton} size="small" variant="contained">
                                    Change
                                </Button>
                            </Link>
                            <Button
                                color="error"
                                size="small"
                                variant="contained"
                                onClick={() => handleRemoveImage(id)}
                            >
                                Remove
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}
export default ImageCard;