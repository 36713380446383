import TeamMembers from '../components/TeamMemebers/TeamMembers';
import AddProduct from '../components/Products/AddProduct/AddProduct';
import Banners from '../components/Banners/BannerList/Banners';
import DashboardMain from '../components/Dashboard/DashboardMain';
import DashboardLocked from '../components/Dashboard/DashboardLocked';
import EditProduct from '../components/Products/EditProduct/EditProduct';
import MyGallery from '../components/MyGallery/MyGallery';
import MyProductLists from '../components/Products/MyProducts/MyProductLists';
import ProfileSettings from '../components/ProfileSettings/ProfileSettings';
import AppLayout from '../components/Layout/AppLayout/AppLayout';
import AddTeamMember from "../components/TeamMemebers/AddTeamMember";
import AddBanner from "../components/Banners/AddBanner/AddBanner";
import EditBanner from "../components/Banners/EditBanner/EditBanner";
import EditTeamMember from "../components/TeamMemebers/EditTeamMember";
import Error501 from "../components/ErrorPage/Error501";
import routeLink from "../helpers/constant/routeLink";
import Dashboard from "../components/Dashboard/Dashboard";
import FinalPage from "../components/Onboarding/OnboardedSteps/FinalPage";
import PromotionList from "../components/Promotions/PromotionList/PromotionList";
import AddPromotion from "../components/Promotions/AddPromotion/AddPromotion";
import EditPromotion from "../components/Promotions/EditPromotion/EditPromotion";
import PromotionDetails from "../components/Promotions/Details/PromotionDetails";

const MainRoutes = {
    path: '/',
    element: <AppLayout/>,
    children: [
        {
            path: '/dashboard',
            element: <Dashboard/>,
            key: routeLink.dashboard.hidden_key,
        },
        {
            path: '/myProducts',
            element: <MyProductLists/>,
            key: routeLink.myProduct.hidden_key,
        },
        {
            path: '/addNewProduct',
            element: <AddProduct/>,
            key: routeLink.addProduct.hidden_key,
        },
        {
            path: '/editProduct/:productId',
            element: <EditProduct/>,
            key: routeLink.editProduct.hidden_key,
        },
        {
            path: '/myGallery',
            element: <MyGallery/>,
            key: routeLink.myGallery.hidden_key,
        },
        {
            path: '/profileSettings',
            element: <ProfileSettings/>,
            key: routeLink.myProfile.hidden_key,
        },
        {
            path: '/teamMembers',
            element: <TeamMembers/>,
            key: routeLink.teamMembers.hidden_key,
        },
        {
            path: '/addNewMember',
            element: <AddTeamMember/>,
            key: routeLink.addTeamMember.hidden_key,
        },
        {
            path: '/editMember/:teamMemberId',
            element: <EditTeamMember/>,
            key: routeLink.editTeamMember.hidden_key,
        },
        {
            path: '/banners',
            element: <Banners/>,
            key: routeLink.banners.hidden_key,
        },
        {
            path: '/addNewBanner',
            element: <AddBanner/>,
            key: routeLink.addBanner.hidden_key,
        },
        {
            path: '/editBanner/:bannerId',
            element: <EditBanner/>,
            key: routeLink.editBanner.hidden_key,
        },
        {
            path: '/promotions',
            element: <PromotionList/>,
            key: routeLink.promotions.hidden_key,
        },
        {
            path: '/add-promotion',
            element: <AddPromotion/>,
            key: routeLink.addPromotion.hidden_key,
        },
        {
            path: '/edit-promotion/:promotionId',
            element: <EditPromotion/>,
            key: routeLink.editPromotion.hidden_key,
        },
        {
            path: '/promotion-details/:promotionId',
            element: <PromotionDetails/>,
            key: routeLink.promotionDetails.hidden_key,
        },
        {
            path: '*',
            element: <Error501/>,
            key: routeLink.error.hidden_key,
        },
    ]
};

export default MainRoutes;
