import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import hasAuthenticatedUser from '../../helpers/utility/hasAuthenticateUser';
import {useDispatch, useSelector} from 'react-redux';
import {getMyProfileData} from "../../state/features/Profile/profileSlice";
import {selectOnboardedSteps} from "../../state/features/OnboardedSteps/OnboardedStepsSelector";
import {selectProfile} from "../../state/features/Profile/profileSelector";
import {STATUS__ACTIVE} from "../../helpers/constant/coreConstant";
import {Backdrop, Box, CircularProgress, Stack, Typography} from "@mui/material";
import logoDark from "../../assets/images/LogoDarkText.svg";

const PrivateLayout = ({children}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {journeyModeOn} = useSelector(selectOnboardedSteps);
    const {myProfile} = useSelector(selectProfile);

    // check if user authenticated or not
    useEffect(() => {
        if (!hasAuthenticatedUser()) {
            navigate('/login');
        }
        if (!myProfile?.data) {
            dispatch(getMyProfileData());
        }
        if (myProfile?.data?.journeyModeOn === true && myProfile?.data?.status === STATUS__ACTIVE) {
            navigate('/onboarding');
        }
    }, [myProfile?.data?.journeyModeOn, myProfile?.data?.status]);

    if (myProfile?.data) {
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        return (
            <Box
                sx={{bgcolor: 'other.bgPaperElevation', overflowY: 'auto', textAlign: 'center'}}
            >
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <Stack alignItems={"center"}>
                        <img src={logoDark} alt="" width={150} height={150}/>
                        <CircularProgress color="inherit"/>
                    </Stack>

                </Backdrop>
            </Box>
        )
    }
};

export default PrivateLayout;
