export const registerPattern = {
    email: {
        required: "Email is required!",
        pattern: {
            value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: "Email is invalid!",
        },
        maxLength: {
            value: 50,
            message: "Email must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    password: {
        required: "Password is required!",
        minLength: {
            value: 6,
            message: "Password must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    confirm_password: {
        required: "Confirm password is required!",
        minLength: {
            value: 6,
            message: "Confirm password must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    name: {
        required: "Brand name is required!",
        maxLength: {
            value: 50,
            message: "Brand name must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    contract_person_name: {
        required: "Contract person name is required!",
        maxLength: {
            value: 50,
            message: "Contract person name must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    contract_person_phone: {
        required: "Phone number is required!",
        pattern: {
            value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
            message: "Invalid phone number! Ex. \"123-456-7890\"",
        },
        minLength: {
            value: 10,
            message: "Phone number must have minimum 11 digits!",
        },
        maxLength: {
            value: 13,
            message: "Phone number must have less than 11 digits!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    token: {
        required: "Token is required",
        minLength: {
            value: 6,
            message: "Token must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
};
export const ChangePassword = {
    password: {
        required: "New password is required!",
        minLength: {
            value: 6,
            message: "New password must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
}
export const ProductValidationPattern = {
    productName: {
        required: "Product name is required!",
        maxLength: {
            value: 50,
            message: "Product name must have less than 50 characters!",
        },
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    email: {
        required: "Email is required!",
        pattern: {
            value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: "Email is invalid!",
        },
        maxLength: {
            value: 50,
            message: "Email must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    skuId: {
        required: "Product SKU ID is required!",
        minLength: {
            value: 4,
            message: "Product SKU ID must have  at least 4 characters!",
        },
        maxLength: {
            value: 20,
            message: "Product SKU ID must have less than 50 characters!",
        },
        pattern: {
            value: /^[a-z0-9\-]+$/,
            message: "Invalid ID! Ex. 'abc123'",
        },
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    productDescription: {
        required: "Product description is required!",
        minLength: {
            value: 6,
            message: "Product description must have  at least 6 characters!",
        },
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    productCategory: {
        required: "Product category is required!",
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    fashionCategory: {
        required: "Fashion category is required!",
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    productPrice: {
        required: "Product price is required",
        pattern: {
            value: /^(?:0|[1-9]\d*)$/,
            message: "Price should be a positive number",
        },
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    storeLink: {
        // required: "Store link is required!",
        pattern: {
            value: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            message: "Invalid link! Ex. 'https://www.google.com'",
        },
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    postDescription: {
        required: "Post description is required!",
        minLength: {
            value: 6,
            message: "Post description must have  at least 6 characters!",
        },
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
}

export const TeamMemberValidationPattern = {
    firstName: {
        required: "First name is required!",
        maxLength: {
            value: 30,
            message: "First name must have less than 30 characters!",
        },
        setValueAs: (v) => (v ? v?.trim() : ""),
    },
    lastName: {
        required: "Last name is required!",
        maxLength: {
            value: 30,
            message: "Last name must have less than 30 characters!",
        },
        setValueAs: (v) => (v ? v?.trim() : ""),
    },
    email: {
        required: "Email is required!",
        pattern: {
            value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: "Email is invalid!",
        },
        maxLength: {
            value: 50,
            message: "Email must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    password: {
        required: "Password is required!",
        minLength: {
            value: 6,
            message: "Password must have at least 6 characters",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    roleUid: {
        required: "User role is required!",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
}

export const BannerValidation = {
    bannerTitle: {
        required: "Banner title is required!",
        maxLength: {
            value: 50,
            message: "Banner title must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v?.trim() : ""),
    },
}

export const BrandValidation = {
    brandName: {
        required: "Brand name is required!",
        maxLength: {
            value: 50,
            message: "Brand name must have less than 50 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    brandDescription: {
        required: "Brand description is required!",
        minLength: {
            value: 6,
            message: "Post description must have  at least 6 characters!",
        },
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    fashionCategories: {
        required: "Fashion category is required!",
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    genderPreference: {
        required: "Gender preference is required",
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
    storeLink: {
        required: "Store link is required!",
        pattern: {
            value: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            message: "Invalid link! Ex. 'https://www.orangetoolz.com'",
        },
        // setValueAs: (v) => (v ? v?.trim() : ""),
    },
}

export const UserValidation = {
    name: {
        required: "Brand name is required!",
        maxLength: {
            value: 50,
            message: "Brand name must have less than 50 characters!",
        },
    },
    phone: {
        rrequired: "Phone number is required!",
        pattern: {
            value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
            message: "Invalid phone number! Ex. \"243-456-7890\"",
        },
        minLength: {
            value: 10,
            message: "Phone number must have minimum 11 digits!",
        },
        maxLength: {
            value: 13,
            message: "Phone number must have less than 11 digits!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
}

export const PromotionValidation = {
    promotionType: {
        required: "Promotion type is required!",
    },
    productUid: {
        required: "Product is required!",
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    promotionTitle: {
        required: "Promotion title is required!",
        maxLength: {
            value: 200,
            message: "Rewards and policies cannot be longer than 200 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    winners: {
        required: "Input Should not be empty!",
        pattern: {
            value: /^[0-9]\d*$/,
            message: "Invalid Input, Please enter a positive number",
        }
    },
    promotionDescription: {
        required: "Promotion description is required!",
        minLength: {
            value: 10,
            message: "Promotion description must have minimum 10 digits!",
        },
        maxLength: {
            value: 2000,
            message: "Description cannot be longer than 2000 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
    startDate: {
        required: "Start date is required!",
        // setValueAs: (v) => (v ? v.trim() : ""),
    },
    endDate: {
        required: "End date is required!",
        // setValueAs: (v) => (v ? v.trim() : ""),
    },
    rewardsAndPolicies: {
        required: "Rewards and policies is required!",
        minLength: {
            value: 10,
            message: "Rewards and policies must have minimum 10 digits!",
        },
        maxLength: {
            value: 2000,
            message: "Rewards and policies cannot be longer than 2000 characters!",
        },
        setValueAs: (v) => (v ? v.trim() : ""),
    },
}


