import {requests} from "./http.service";

class ProductService {
    addProduct(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/create", body);
    }

    productList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/list", body);
    }
    updateProduct(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/update", body);
    }
    productVisibility(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/update/visibility", body);
    }
    singleTrash(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/in-trash/update/status/single", body);
    }
    multipleTrash(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/in-trash/update/status/bulk", body);
    }
    editProduct(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/get/specific/data", body);
    }
    multipleDelete(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/bulk/delete", body);
    }
    multipleRestore(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/in-trash/update/status/bulk", body);
    }
    singleDelete(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/single/delete", body);
    }
}

export default new ProductService();