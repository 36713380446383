import { requests } from "./http.service";

class UserService {
    attemptLogin(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/login", body);
    }
    attemptSignUp(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/registration", body);
    }
    attemptLogout(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/login-out", body);
    }
    attemptForgetPassword(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/reset/password/token/send", body);
    }
    attemptResetPassword(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/forget/password/token/verify", body);
    }
}

export default new UserService();
