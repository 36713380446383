import {
    ADD_BANNER__HIDDEN__KEY,
    ADD_PRODUCT__HIDDEN__KEY, ADD_PROMOTION__HIDDEN__KEY,
    ADD_TEAM_MEMBER__HIDDEN__KEY,
    BANNER__HIDDEN__KEY,
    DASHBOARD__HIDDEN__KEY,
    EDIT_BANNER__HIDDEN__KEY,
    EDIT_PRODUCT__HIDDEN__KEY, EDIT_PROMOTION__HIDDEN__KEY,
    EDIT_TEAM_MEMBER__HIDDEN__KEY,
    FTPO__ADMIN,
    FTPO__USER,
    MY_GALLERY__HIDDEN__KEY,
    MY_PRODUCT__HIDDEN__KEY,
    MY_PROFILE__HIDDEN__KEY,
    ONBOARDING__HIDDEN__KEY, PROMOTION_DETAILS__HIDDEN__KEY, PROMOTIONS__HIDDEN__KEY,
    STATUS__ACTIVE, STATUS__BLOCKED,
    STATUS__PENDING,
    STATUS__REJECTED,
    TEAM_MEMBER__HIDDEN__KEY,
    UNAUTHORIZED__HIDDEN__KEY,
} from "../constant/coreConstant";

export const routesFilterByHiddenKey = (mainRoutes = {}, hiddenKeys = []) => {
    const mainRoutesChildrenArr = [...mainRoutes.children];

    if (Array.isArray(hiddenKeys) && hiddenKeys.length > 0) {
        const result = mainRoutesChildrenArr.filter((childrenItem) => hiddenKeys.includes(childrenItem.key));
        return {...mainRoutes, children: result};
    } else {
        return mainRoutes;
    }
};

export const getRoleWiseHiddenKeysForTeamMember = (role, status) => {

    if (role === FTPO__ADMIN) {
        if (status === STATUS__ACTIVE) {
            return [
                DASHBOARD__HIDDEN__KEY,
                MY_PROFILE__HIDDEN__KEY,
                MY_PRODUCT__HIDDEN__KEY,
                ADD_PRODUCT__HIDDEN__KEY,
                EDIT_PRODUCT__HIDDEN__KEY,
                MY_GALLERY__HIDDEN__KEY,
                BANNER__HIDDEN__KEY,
                ADD_BANNER__HIDDEN__KEY,
                EDIT_BANNER__HIDDEN__KEY,
                PROMOTIONS__HIDDEN__KEY,
                ADD_PROMOTION__HIDDEN__KEY,
                EDIT_PROMOTION__HIDDEN__KEY,
                PROMOTION_DETAILS__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__BLOCKED) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__PENDING) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__REJECTED) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        }

    } else if (role === FTPO__USER) {
        if (status === STATUS__ACTIVE) {
            return [
                DASHBOARD__HIDDEN__KEY,
                MY_PROFILE__HIDDEN__KEY,
                MY_PRODUCT__HIDDEN__KEY,
                ADD_PRODUCT__HIDDEN__KEY,
                EDIT_PRODUCT__HIDDEN__KEY,
                MY_GALLERY__HIDDEN__KEY,
                BANNER__HIDDEN__KEY,
                ADD_BANNER__HIDDEN__KEY,
                EDIT_BANNER__HIDDEN__KEY,
                PROMOTIONS__HIDDEN__KEY,
                ADD_PROMOTION__HIDDEN__KEY,
                EDIT_PROMOTION__HIDDEN__KEY,
                PROMOTION_DETAILS__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__BLOCKED) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__PENDING) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__REJECTED) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        }
    }
}

export const getRoleWiseHiddenKeysForAdmin = (role, status) => {

    if (role === FTPO__ADMIN) {
        if (status === STATUS__ACTIVE) {
            return [
                ONBOARDING__HIDDEN__KEY,
                DASHBOARD__HIDDEN__KEY,
                MY_PROFILE__HIDDEN__KEY,
                MY_PRODUCT__HIDDEN__KEY,
                ADD_PRODUCT__HIDDEN__KEY,
                EDIT_PRODUCT__HIDDEN__KEY,
                MY_GALLERY__HIDDEN__KEY,
                TEAM_MEMBER__HIDDEN__KEY,
                ADD_TEAM_MEMBER__HIDDEN__KEY,
                EDIT_TEAM_MEMBER__HIDDEN__KEY,
                BANNER__HIDDEN__KEY,
                ADD_BANNER__HIDDEN__KEY,
                EDIT_BANNER__HIDDEN__KEY,
                PROMOTIONS__HIDDEN__KEY,
                ADD_PROMOTION__HIDDEN__KEY,
                EDIT_PROMOTION__HIDDEN__KEY,
                PROMOTION_DETAILS__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__BLOCKED) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__PENDING) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        } else if (status === STATUS__REJECTED) {
            return [
                DASHBOARD__HIDDEN__KEY,
                UNAUTHORIZED__HIDDEN__KEY
            ];
        }
    }
}