import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './state/store';
import './assets/scss/main.scss';
import {BrowserRouter} from 'react-router-dom';
import GlobalCheckLayout from './components/Layout/GlobalCheckLayout';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        <GlobalCheckLayout />
        <App />
      </React.StrictMode>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
