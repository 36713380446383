import React, {useEffect, useState} from 'react';
import {Box, Button, Stack, TextField, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectOnboardedSteps} from "../../../state/features/OnboardedSteps/OnboardedStepsSelector";
import {onboarding, updateBrandDescription} from "../../../state/features/OnboardedSteps/OnboardedStepsSlice";
import LoadingButton from "../../../assets/global/Button/LoadingButton";

const BrandDescription = ({activeStep, setActiveStep, steps, currentOnboardStep, myProfile}) => {
    const dispatch = useDispatch();
    const {brandDescription} = useSelector(selectOnboardedSteps);
    const [description, setDescription] = useState(myProfile?.data?.description ? myProfile?.data?.description : null);

    useEffect(() => {
        if (myProfile?.data?.description) {
            setDescription(myProfile?.data?.description);
        }
    }, [myProfile?.data?.description]);
    const handleNext = () => {
        if (activeStep < currentOnboardStep) {
            setActiveStep(activeStep + 1);
            dispatch(updateBrandDescription({description: description}));
        } else {
            dispatch(updateBrandDescription({description: description}));
            dispatch(onboarding({stageNo: activeStep + 1, journeyModeOn: true}));
        }
    };

    return (
        <Box>
            <Typography variant="h3" mb={3}>
                Tell us about your brand
            </Typography>

            <Box mb={6}>
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={5}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Box>

            <Stack direction={'row'} spacing={2}>
                <LoadingButton
                    isLoading={brandDescription?.isLoading}
                    loadingText={'Continuing...'}
                    variant="contained"
                    size={'medium'}
                    onClick={handleNext}
                >
                    Continue
                </LoadingButton>

                <Button
                    variant="text"
                    onClick={handleNext}
                    // disabled={activeStep === steps.length - 1}
                >
                    Skip
                </Button>
            </Stack>
        </Box>
    )

}

export default BrandDescription;