import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    graphData: {
        data: [],
        isLoading: false,
        isError: false,
        error: '',
    },
    dashboardCounts: {
        data: [],
        isLoading: false,
        isError: false,
        error: '',
    },
    topSavedProduct: {
        data: [],
        isLoading: false,
        isError: false,
        error: '',
    },
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        getGraphData: (state, action) => {
            state.graphData.isLoading = true;
        },
        getGraphDataSuccess: (state, action) => {
            state.graphData.data = action?.payload;
            state.graphData.isLoading = false;
            state.graphData.isError = false;
            state.graphData.error = '';
        },
        getGraphDataFailed: (state, action) => {
            state.graphData.data = [];
            state.graphData.isLoading = false;
            state.graphData.isError = true;
            state.graphData.error = action.payload;
        },
        getGraphDataClearState: (state, action) => {
            state.graphData.data = [];
            state.graphData.isLoading = false;
            state.graphData.isError = false;
            state.graphData.error = '';
        },

        getDashboardCounts: (state, action) => {
            state.dashboardCounts.isLoading = true;
        },
        getDashboardCountsSuccess: (state, action) => {
            state.dashboardCounts.data = action?.payload;
            state.dashboardCounts.isLoading = false;
            state.dashboardCounts.isError = false;
            state.dashboardCounts.error = '';
        },
        getDashboardCountsFailed: (state, action) => {
            state.dashboardCounts.data = [];
            state.dashboardCounts.isLoading = false;
            state.dashboardCounts.isError = true;
            state.dashboardCounts.error = action.payload;
        },
        getDashboardCountsClearState: (state, action) => {
            state.dashboardCounts.data = [];
            state.dashboardCounts.isLoading = false;
            state.dashboardCounts.isError = false;
            state.dashboardCounts.error = '';
        },

        getTopSavedProduct: (state, action) => {
            state.topSavedProduct.isLoading = true;
        },
        getTopSavedProductSuccess: (state, action) => {
            state.topSavedProduct.data = action?.payload;
            state.topSavedProduct.isLoading = false;
            state.topSavedProduct.isError = false;
            state.topSavedProduct.error = '';
        },
        getTopSavedProductFailed: (state, action) => {
            state.topSavedProduct.data = [];
            state.topSavedProduct.isLoading = false;
            state.topSavedProduct.isError = true;
            state.topSavedProduct.error = action.payload;
        },
        getTopSavedProductClearState: (state, action) => {
            state.topSavedProduct.data = [];
            state.topSavedProduct.isLoading = false;
            state.topSavedProduct.isError = false;
            state.topSavedProduct.error = '';
        },
    }
});

export const {
    getGraphData,
    getGraphDataSuccess,
    getGraphDataFailed,
    getGraphDataClearState,

    getDashboardCounts,
    getDashboardCountsSuccess,
    getDashboardCountsFailed,
    getDashboardCountsClearState,

    getTopSavedProduct,
    getTopSavedProductSuccess,
    getTopSavedProductFailed,
    getTopSavedProductClearState

} = dashboardSlice.actions;

export default dashboardSlice.reducer;
