import React from 'react'
import Box from "@mui/material/Box";
import trashIcon from "../../assets/images/Icons/trashIcon.svg";
import {Button, IconButton, Stack, Typography} from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import {useSelector} from "react-redux";
import {selectTeamMember} from "../../state/features/TeamMember/teamMemberSelector";

const BlockUserModalContent = ({handleModalClose, handleBlockUser, }) => {
    const {changeStatus} = useSelector(selectTeamMember);
    return (
        <>
            <Box p={'64px 40px 48px 40px'} textAlign={'center'}>
                <IconButton size="large" >
                    <BlockIcon sx={{fontSize: 150}} color="error"/>
                </IconButton>

                <Typography variant="h5" mt={3} mb={1}>
                    Are you sure you want to <br/> block{' '}
                    this team-member?
                </Typography>
                <Typography variant="body2" color={'text.secondary'}>
                    Once blocked, you can unblock it again.
                </Typography>
            </Box>

            <Stack
                p={'16px'}
                sx={{borderTop: 1, borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={1}
            >
                <Button
                    sx={{color: 'text.primary'}}
                    variant="text"
                    onClick={handleModalClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    isLoading={changeStatus?.isLoading}
                    loadingText={'Blocking...'}
                    variant="contained"
                    color="error"
                    onClick={handleBlockUser}
                >
                    Block
                </LoadingButton>
            </Stack>
        </>
    );
}
export default BlockUserModalContent;