import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    galleryImage: [],
    isGalleryImage: false,
    isLoading: false,
    isError: false,
    error: '',
};

const galleryListSlice = createSlice({
    name: 'GalleryList',
    initialState: initialState,
    reducers: {
        galleryList: (state, action) => {
            state.isLoading = true;
        },
        galleryListSuccess: (state, action) => {
            state.galleryImage = action?.payload?.data;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        galleryListFailed: (state, action) => {
            state.galleryImage = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        galleryListClearState: (state, action) => {
            state.isGalleryImage = false;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        galleryListClear: (state, action) => {
            state.galleryImage = [];
            state.isGalleryImage = false;
        },
    }
});

export const {
    galleryList,
    galleryListSuccess,
    galleryListFailed,
    galleryListClearState,
    galleryListClear
} = galleryListSlice.actions;

export default galleryListSlice.reducer;
