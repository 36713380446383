import React, {useEffect} from 'react';
import {Box, FormControl, FormHelperText, Stack, TextField, Typography} from '@mui/material';
import {ProductValidationPattern, registerPattern} from "../../../helpers/constant/validationPattern";
import {Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {
    setProductDescription,
    setProductName,
    setProductSKUId
} from "../../../state/features/Products/AddProduct/AddProductSlice";
import {showErrorMessage} from "../../../helpers/utility/Toastify";

const AddProductInfo = ({control, setValue}) => {
    const dispatch = useDispatch();
    const {productName, productSKUId, productDescription} = useSelector(selectAddProduct);

    useEffect(() => {
        if (productName) {
            setValue("name", productName || "");
        }
        if (productSKUId) {
            setValue("productSkuId", productSKUId || "");
        }
        if (productDescription) {
            setValue("description", productDescription || "");
        }
    }, [productName, productSKUId, productDescription]);

    return (
        <Box mb={5}>
            <Typography variant="h5" mb={3}>
                Product Info
            </Typography>

            <Stack spacing={3}>
                <Controller
                    control={control}
                    name="productSkuId"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb:2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="Product SKU ID"
                                fullWidth
                                focused
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    dispatch(setProductSKUId(e?.target?.value))
                                }}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={ProductValidationPattern.skuId}
                />
                <Controller
                    control={control}
                    name="name"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb:2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="Product Name"
                                fullWidth
                                focused
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    dispatch(setProductName(e?.target?.value))
                                }}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={ProductValidationPattern.productName}
                />
                <Controller
                    control={control}
                    name="description"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb:2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="Product Description"
                                multiline
                                rows={4}
                                fullWidth
                                focused
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    dispatch(setProductDescription(e?.target?.value))
                                }}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    // rules={ProductValidationPattern.productDescription}
                />
            </Stack>
        </Box>
    );
};

export default AddProductInfo;
