import React from 'react';
import {Box, Button, List, ListItem, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";

const AddImageDescription = () => {
    const {selectedImages, isSetImage} = useSelector(selectAddProduct);
    return (
        <Box pb={6}>
            <Typography variant="h5">Add Product Images</Typography>

            <List sx={{listStyleType: 'disc', pl: 3}}>
                <ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>
                    <Typography variant="body2">
                        Use 2:3 ratio images for the best view experience.
                    </Typography>
                </ListItem>
                <ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>
                    <Typography variant="body2">You can sort image using drag the images.</Typography>
                </ListItem>
                <ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>
                    <Typography variant="body2">First image will be count as featured image.</Typography>
                </ListItem>
                {/*<ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>*/}
                {/*    <Typography variant="body2">Image upload limit: Minimum 1 & Maximum 5.</Typography>*/}
                {/*</ListItem>*/}
                {/*<ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>*/}
                {/*    <Typography variant="body2">Size Limit: Total 10 megabytes for 5 images.</Typography>*/}
                {/*</ListItem>*/}
            </List>
            {
                selectedImages?.length === 0 && (
                    <Link to={'/myGallery/?type=productImage'}>
                        <Button variant="outlined" startIcon={<AddIcon/>}>
                            Click to add image
                        </Button>
                    </Link>
                )
            }


            {/*<Stack direction={'row'} alignItems={'center'} spacing={5} mt={3}>*/}
            {/*    <Box width={310}>*/}
            {/*        <BorderLinearSuccessProgress variant="determinate" value={80}/>*/}

            {/*        <Typography component={'p'} variant="overline" color={'text.secondary'} mt={1}>*/}
            {/*            13 MB of 15 MB added*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}

            {/*    <Box width={310}>*/}
            {/*        <BorderLinearErrorProgress variant="determinate" value={100}/>*/}

            {/*        <Typography component={'p'} variant="overline" color={'error.main'} mt={1}>*/}
            {/*            17 MB of 15 MB added, limit reached!*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</Stack>*/}
        </Box>
    );
}
export default AddImageDescription;