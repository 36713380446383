import React, {useEffect} from 'react';
import DashboardMain from "./DashboardMain";
import {useSelector} from "react-redux";
import {selectProfile} from "../../state/features/Profile/profileSelector";
import {STATUS__ACTIVE, STATUS__BLOCKED, STATUS__PENDING, STATUS__REJECTED} from "../../helpers/constant/coreConstant";
import DashboardLocked from "./DashboardLocked";
import {useNavigate} from "react-router-dom";
import {Backdrop, Box, CircularProgress, Stack, Typography} from "@mui/material";
import logoDark from "../../assets/images/LogoDarkText.svg";

const Dashboard = () => {
    const {myProfile} = useSelector(selectProfile);
    const navigate = useNavigate();

    useEffect(() => {
        if (myProfile?.data?.journeyModeOn && myProfile?.data?.status === STATUS__ACTIVE) {
            navigate('/onboarding');
        }
    }, [myProfile?.data]);


    if (myProfile?.data?.status) {
        switch (myProfile?.data?.status) {
            case STATUS__ACTIVE:
                return <DashboardMain/>
            case STATUS__BLOCKED:
                return <DashboardLocked status={STATUS__BLOCKED}/>
            case STATUS__PENDING:
                return <DashboardLocked status={STATUS__PENDING}/>
            case STATUS__REJECTED:
                return <DashboardLocked status={STATUS__REJECTED}/>
            default:
                return <DashboardLocked/>
        }
    } else {
        return (
            <Box
                height={'calc(100vh - 109px)'}
                sx={{bgcolor: 'other.bgPaperElevation', overflowY: 'auto', textAlign: 'center'}}
            >
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}
                >
                    <Stack alignItems={"center"}>
                        <img src={logoDark} alt="" width={150} height={150}/>
                        <CircularProgress color="inherit"/>
                    </Stack>

                </Backdrop>
            </Box>
        )
    }
};

export default Dashboard;
