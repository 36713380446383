import React, { useEffect } from 'react';

import { Stack } from '@mui/material';
import { PublicProfileCard } from '../PublicProfileStyle';
import UserImageGallery from '../UserProfile/UserImageGallery';
import DownloadSection from '../common/DownloadSection';
import HeaderWithLogo from '../common/HeaderWithLogo';
import PublicProfileInfo from '../common/PublicProfileInfo';
import PublicProfileStat from '../common/PublicProfileStat';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPublicPage } from '../../../state/features/publicPage/publicPageSelector';
import { getBrandProfile } from '../../../state/features/publicPage/publicPageSlice';
import PublicPageSkeletonLoader from '../common/PublicPageSkeletonLoader';

const PublicBrandProfile = () => {
    const dispatch = useDispatch();
    const { brandId } = useParams();
    const { brandProfile } = useSelector(selectPublicPage);
    const STAT_DATA = [
        {
            id: 1,
            stat: brandProfile?.data?.totalFollowers,
            label: 'Followers'
        },
        {
            id: 2,
            stat: brandProfile?.data?.totalProducts,
            label: 'Products'
        }
    ];

    useEffect(() => {
        dispatch(getBrandProfile({ uid: brandId }))
    }, [brandId])
    console.log('product image: ', brandProfile?.data?.productImages)
    return (
        <Stack height={'100vh'} alignItems={'center'} spacing={{ xs: 1, md: 5 }}>
            <HeaderWithLogo />
            {
                brandProfile.isLoading ? (
                    <PublicPageSkeletonLoader />
                ) : (
                    <PublicProfileCard variant="outlined" elevation={0}>
                        <PublicProfileInfo imageUrl={brandProfile?.data?.profileLogo && brandProfile?.data?.profileLogo} title={brandProfile?.data?.name && brandProfile?.data?.name} label={'brand'} />

                        <PublicProfileStat description={brandProfile?.data?.description} profileState={STAT_DATA} />

                        <UserImageGallery images={brandProfile?.data?.productImages && brandProfile?.data?.productImages} wrapperStyle={{ height: { xs: 100, sm: 300 } }} cols={brandProfile?.data?.productImages?.length >= 2 ? 2 : 1} />

                        <DownloadSection description={`To view ${brandProfile?.data?.name ? brandProfile?.data?.name : 'user'}’s full profile, download the app`} />
                    </PublicProfileCard>
                )
            }
        </Stack>
    );
};

export default PublicBrandProfile;
