import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {IS__FEATURED__APPLIED, STATUS__DRAFT} from "../../../helpers/constant/coreConstant";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import {useDispatch, useSelector} from "react-redux";
import {addBannerData, updateBannerData} from "../../../state/features/Banner/bannerSlice";
import {selectBanner} from "../../../state/features/Banner/bannerSelector";

const useEditBannerHook = ({bannerId}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {addBanner} = useSelector(selectBanner);
    const [ctaHover, setCtaHover] = useState(false);
    const [bannerImage, setBannerImage] = useState(null);
    const [featured, setFeatured] = useState(IS__FEATURED__APPLIED);
    const onSubmit = (data) => {
        if (data && bannerImage) {
            const formData = new FormData();
            formData.append('title', data.title);
            if (bannerImage?.data) {
                formData.append('imageUrl', bannerImage?.data);
            }
            formData.append('isFeatured', featured);
            formData.append('uid', bannerId);
            // formData.append('status', STATUS__DRAFT);
            dispatch(updateBannerData(formData));
        } else {
            showErrorMessage("Add banner image!");
        }
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setBannerImage({
                data: file,
                src: URL.createObjectURL(file)
            });
        } else {
            showErrorMessage("Image not found!");
        }
    }
    const handleRemoveImage = () => {
        setBannerImage(null);
    }

    return {
        navigate,
        ctaHover,
        setCtaHover,
        featured,
        setFeatured,
        bannerImage,
        setBannerImage,
        onSubmit,
        handleImageUpload,
        handleRemoveImage,
    }
}
export default useEditBannerHook;