import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    myProfile: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    brandProfile: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    updateMyProfileProfile: {
        data: null,
        isMyProfileUpdate: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    updateBrandProfile: {
        data: null,
        isBrandProfileUpdate: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    fashionCategories: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    clothingCategories: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    changePassword: {
        isChangePassword: false,
        isLoading: false,
        isError: false,
        error: '',
    }
};

const ProfileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        getMyProfileData: (state, action) => {
            state.myProfile.isLoading = true;
        },
        getMyProfileDataSuccess: (state, action) => {
            state.myProfile.data = action?.payload;
            state.myProfile.isLoading = false;
            state.myProfile.isError = false;
            state.myProfile.error = '';
        },
        getMyProfileDataFailed: (state, action) => {
            state.myProfile.data = null;
            state.myProfile.isLoading = false;
            state.myProfile.isError = true;
            state.myProfile.error = action.payload;
        },
        getMyProfileDataClearState: (state, action) => {
            state.myProfile.data = null;
            state.myProfile.isLoading = false;
            state.myProfile.isError = false;
            state.myProfile.error = '';
        },

        updateMyProfileData: (state, action) => {
            state.updateMyProfileProfile.isLoading = true;
        },
        updateMyProfileSuccess: (state, action) => {
            state.updateMyProfileProfile.data = action?.payload;
            state.updateMyProfileProfile.isMyProfileUpdate = true;
            state.updateMyProfileProfile.isLoading = false;
            state.updateMyProfileProfile.isError = false;
            state.updateMyProfileProfile.error = '';
        },
        updateMyProfileFailed: (state, action) => {
            state.updateMyProfileProfile.data = null;
            state.updateMyProfileProfile.isMyProfileUpdate = false;
            state.updateMyProfileProfile.isLoading = false;
            state.updateMyProfileProfile.isError = true;
            state.updateMyProfileProfile.error = action.payload;
        },
        updateMyProfileClearState: (state, action) => {
            state.updateMyProfileProfile.data = null;
            state.updateMyProfileProfile.isMyProfileUpdate = false;
            state.updateMyProfileProfile.isLoading = false;
            state.updateMyProfileProfile.isError = false;
            state.updateMyProfileProfile.error = '';
        },

        getBrandProfileData: (state, action) => {
            state.brandProfile.isLoading = true;
        },
        getBrandProfileDataSuccess: (state, action) => {
            state.brandProfile.data = action?.payload;
            state.brandProfile.isLoading = false;
            state.brandProfile.isError = false;
            state.brandProfile.error = '';
        },
        getBrandProfileDataFailed: (state, action) => {
            state.brandProfile.data = null;
            state.brandProfile.isLoading = false;
            state.brandProfile.isError = true;
            state.brandProfile.error = action.payload;
        },
        getBrandProfileDataClearState: (state, action) => {
            state.brandProfile.data = null;
            state.brandProfile.isLoading = false;
            state.brandProfile.isError = false;
            state.brandProfile.error = '';
        },

        updateBrandProfileData: (state, action) => {
            state.updateBrandProfile.isLoading = true;
        },
        updateBrandProfileSuccess: (state, action) => {
            state.updateBrandProfile.data = action?.payload;
            state.updateBrandProfile.isBrandProfileUpdate = true;
            state.updateBrandProfile.isLoading = false;
            state.updateBrandProfile.isError = false;
            state.updateBrandProfile.error = '';
        },
        updateBrandProfileFailed: (state, action) => {
            state.updateBrandProfile.data = null;
            state.updateBrandProfile.isBrandProfileUpdate = false;
            state.updateBrandProfile.isLoading = false;
            state.updateBrandProfile.isError = true;
            state.updateBrandProfile.error = action.payload;
        },
        updateBrandProfileClearState: (state, action) => {
            state.updateBrandProfile.data = null;
            state.updateBrandProfile.isBrandProfileUpdate = false;
            state.updateBrandProfile.isLoading = false;
            state.updateBrandProfile.isError = false;
            state.updateBrandProfile.error = '';
        },


        getFashionCategories: (state, action) => {
            state.fashionCategories.isLoading = true;
        },
        fashionCategoriesSuccess: (state, action) => {
            state.fashionCategories.data = action?.payload;
            state.fashionCategories.isLoading = false;
            state.fashionCategories.isError = false;
            state.fashionCategories.error = '';
        },
        fashionCategoriesFailed: (state, action) => {
            state.fashionCategories.data = null;
            state.fashionCategories.isLoading = false;
            state.fashionCategories.isError = true;
            state.fashionCategories.error = action.payload;
        },
        fashionCategoriesClearState: (state, action) => {
            state.fashionCategories.data = null;
            state.fashionCategories.isLoading = false;
            state.fashionCategories.isError = false;
            state.fashionCategories.error = '';
        },

        getClothingCategories: (state, action) => {
            state.clothingCategories.isLoading = true;
        },
        clothingCategoriesSuccess: (state, action) => {
            state.clothingCategories.data = action?.payload;
            state.clothingCategories.isLoading = false;
            state.clothingCategories.isError = false;
            state.clothingCategories.error = '';
        },
        clothingCategoriesFailed: (state, action) => {
            state.clothingCategories.data = null;
            state.clothingCategories.isLoading = false;
            state.clothingCategories.isError = true;
            state.clothingCategories.error = action.payload;
        },
        clothingCategoriesClearState: (state, action) => {
            state.clothingCategories.data = null;
            state.clothingCategories.isLoading = false;
            state.clothingCategories.isError = false;
            state.clothingCategories.error = '';
        },

        changePasswordData: (state, action) => {
            state.changePassword.isLoading = true;
        },
        changePasswordSuccess: (state, action) => {
            state.changePassword.isLoading = false;
            state.changePassword.isChangePassword = true;
            state.changePassword.isError = false;
            state.changePassword.error = '';
        },
        changePasswordFailed: (state, action) => {
            state.changePassword.data = null;
            state.changePassword.isLoading = false;
            state.changePassword.isError = true;
            state.changePassword.error = action.payload;
        },
        changePasswordClearState: (state, action) => {
            state.changePassword.isChangePassword = false;
            state.changePassword.isLoading = false;
            state.changePassword.isError = false;
            state.changePassword.error = '';
        },
    }
});

export const {
    getMyProfileData,
    getMyProfileDataSuccess,
    getMyProfileDataFailed,
    getMyProfileDataClearState,

    updateMyProfileData,
    updateMyProfileSuccess,
    updateMyProfileFailed,
    updateMyProfileClearState,

    getBrandProfileData,
    getBrandProfileDataSuccess,
    getBrandProfileDataFailed,
    getBrandProfileDataClearState,

    updateBrandProfileData,
    updateBrandProfileSuccess,
    updateBrandProfileFailed,
    updateBrandProfileClearState,

    getFashionCategories,
    fashionCategoriesSuccess,
    fashionCategoriesFailed,
    fashionCategoriesClearState,

    getClothingCategories,
    clothingCategoriesSuccess,
    clothingCategoriesFailed,
    clothingCategoriesClearState,

    changePasswordData,
    changePasswordSuccess,
    changePasswordFailed,
    changePasswordClearState,


} = ProfileSlice.actions;

export default ProfileSlice.reducer;
