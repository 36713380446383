import React, {useRef, useState} from 'react';
import {Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import Icon from "../../assets/images/Icons/arrowDropDownFilled.svg";
import {useDispatch, useSelector} from "react-redux";
import {selectTeamMember} from "../../state/features/TeamMember/teamMemberSelector";
import {changeUserRole} from "../../state/features/TeamMember/teamMemberSlice";

const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];
const RoleChangeButton = ({category, role, teamMemberUid, searchText}) => {
    const dispatch = useDispatch();
    const {userRole} = useSelector(selectTeamMember);
    const {teamMemberList} = useSelector(selectTeamMember);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(role);

    const handleClick = () => {
        console.info(`You clicked ${selectedIndex}`);
    };

    const handleMenuItemClick = (event, id) => {
        if (id && teamMemberUid) {
            setSelectedIndex(id);
            dispatch(changeUserRole({
                roleUid: id,
                teamMemberUid: teamMemberUid,
                category: category,
                searchInputText: searchText ? searchText : '',
                page: teamMemberList?.page,
                per_page: teamMemberList?.perPage,
            }));
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const findRoleName = (roleId) => {
        const targetObject = userRole?.data?.find(item => item.uid === roleId);
        const targetName = targetObject ? targetObject?.name : "Not found!";
        return targetName;
    }

    return (
        <>
            <ButtonGroup
                ref={anchorRef}
                aria-label="split button"
            >
                <Button
                    onClick={handleClick}
                >
                    {findRoleName(selectedIndex)}

                </Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <img style={{marginRight: -5}} src={Icon} alt=""/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    id="split-button-menu"
                                    autoFocusItem
                                >
                                    {userRole?.data?.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            // disabled={index === 2}
                                            selected={option?.uid === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, option?.uid)}
                                        >
                                            {option?.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
export default RoleChangeButton;