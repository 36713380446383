import {all} from 'redux-saga/effects';
import userSaga from './features/user/userSaga';
import onboardedStepsSaga from "./features/OnboardedSteps/OnboardedStepsSaga";
import addProductsSaga from "./features/Products/AddProduct/AddProductSaga";
import editProductsSaga from "./features/Products/EditProduct/EditProductSaga";
import productListsSaga from "./features/Products/ProductList/ProductListSaga";
import productDeleteSaga from "./features/Products/DeleteProduct/ProductDeleteSaga";
import galleryListsSaga from "./features/Gallery/GalleryList/galleryListSaga";
import gallerySaga from "./features/Gallery/GalleryImageDelete/gallerySaga";
import teamMembersSaga from "./features/TeamMember/teamMemberSaga";
import bannersSaga from "./features/Banner/bannerSaga";
import profileSaga from "./features/Profile/profileSaga";
import notificationSaga from "./features/Notification/notificationSaga";
import dashboardSaga from "./features/Dashboard/dashboardSaga";
import promotionSaga from "./features/Promotions/promotionSaga";
import publicPageSaga from "./features/publicPage/publicPageSaga";

export default function* rootSaga() {
    yield all([
        userSaga(),
        onboardedStepsSaga(),
        addProductsSaga(),
        editProductsSaga(),
        productListsSaga(),
        productDeleteSaga(),
        galleryListsSaga(),
        gallerySaga(),
        teamMembersSaga(),
        bannersSaga(),
        profileSaga(),
        notificationSaga(),
        dashboardSaga(),
        promotionSaga(),
        publicPageSaga(),
    ]);
}
