import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {addProductDraftFailed, addProductDraftSuccess, addProductFailed, addProductSuccess} from "./AddProductSlice";
import ProductService from "../../../../services/Product.service";
import {showErrorMessage, showSuccessMessage} from "../../../../helpers/utility/Toastify";

function* addProductWatcher() {
    yield takeEvery('AddProduct/addProduct', addProductSaga);
    yield takeEvery('AddProduct/addProductDraft', addProductDraftSaga);
}

function* addProductSaga(action) {
    try {
        const response = yield call(ProductService.addProduct, action.payload);

        if (response?.success) {
            yield put(addProductSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            if (response?.message?.productSkuId) {
                showErrorMessage(response?.message?.productSkuId[0]);
            } else if (response?.message?.name) {
                showErrorMessage(response?.message?.name[0]);
            } else if (response?.message?.productStoreLink) {
                showErrorMessage(response?.message?.productStoreLink[0]);
            } else if (response?.message?.description) {
                showErrorMessage(response?.message?.description[0]);
            }
            yield put(addProductFailed(response?.data));
        }
    } catch (err) {
        yield put(addProductFailed(err.message));
    }
}
function* addProductDraftSaga(action) {
    try {
        const response = yield call(ProductService.addProduct, action.payload);

        if (response?.success) {
            yield put(addProductDraftSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(addProductDraftFailed(response?.data));
        }
    } catch (err) {
        yield put(addProductFailed(err.message));
    }
}

export default function* addProductsSaga() {
    yield all([addProductWatcher()]);
}
