import {all, put} from 'redux-saga/effects';
import {call, delay, takeEvery, takeLatest} from '@redux-saga/core/effects';
import {
    getFashionCategoriesDataFailed,
    getFashionCategoriesDataSuccess,
    getOnboardDataFailed,
    getOnboardDataSuccess,
    onboardedFailed,
    onboardedSuccess,
    onboarding,
    updateBrandDescriptionFailed,
    updateBrandDescriptionSuccess,
    updateBrandStoreLinkFailed,
    updateBrandStoreLinkSuccess,
    updateFashionCategoriesFailed,
    updateFashionCategoriesSuccess,
    updateGenderPreferenceFailed,
    updateGenderPreferenceSuccess,
    updateProfileLogoFailed,
    updateProfileLogoSuccess
} from './OnboardedStepsSlice';
import OnboardingService from '../../../services/Onboarding.service';
import {ONBOARDING_DELAY_TIME} from "../../../helpers/constant/coreConstant";
import {getMyProfileData} from "../Profile/profileSlice";

function* onboardedStepsWatcher() {
    yield takeLatest('Onboarded/getOnboardData', getOnboardDataSaga);
    yield takeEvery('Onboarded/onboarding', onboardingSaga);
    yield takeEvery('Onboarded/getFashionCategoriesData', getFashionCategoriesSaga);
    yield takeEvery('Onboarded/updateFashionCategories', updateFashionCategoriesSaga);
    yield takeEvery('Onboarded/updateGenderPreference', updateGenderPreferenceSaga);
    yield takeEvery('Onboarded/updateProfileLogo', updateProfileLogoSaga);
    yield takeEvery('Onboarded/updateBrandDescription', updateBrandDescriptionSaga);
    yield takeEvery('Onboarded/updateBrandStoreLink', updateBrandStoreLinkSaga);
}

function* getOnboardDataSaga(action) {
    try {
        const response = yield call(OnboardingService.getOnboardData, action.payload);

        if (response?.success) {
            yield put(getOnboardDataSuccess(response?.data));
        } else {
            yield put(getOnboardDataFailed(response?.data));
        }
    } catch (err) {
        yield put(onboardedFailed(err.message));
    }
}

function* onboardingSaga(action) {
    try {
        const response = yield call(OnboardingService.onboarding, action.payload);
        if (response?.success) {
            yield put(onboardedSuccess(action.payload));
        } else {
            yield put(onboardedFailed(response?.data));
        }
    } catch (err) {
        yield put(onboardedFailed(err.message));
    }
}

function* getFashionCategoriesSaga(action) {
    try {
        const response = yield call(OnboardingService.getFashionCategories, action.payload);
        if (response?.success) {
            yield put(getFashionCategoriesDataSuccess(response?.data));
        } else {
            yield put(getFashionCategoriesDataFailed(response?.message));
        }
    } catch (err) {
        yield put(getFashionCategoriesDataFailed(err.message));
    }
}

function* updateFashionCategoriesSaga(action) {
    try {
        yield delay(ONBOARDING_DELAY_TIME);
        const response = yield call(OnboardingService.updateFashionCategories, action.payload);

        if (response?.success) {
            yield put(updateFashionCategoriesSuccess(response?.data));
        } else {
            yield put(updateFashionCategoriesFailed(response?.message));
        }
    } catch (err) {
        yield put(updateFashionCategoriesFailed(err.message));
    }
}

function* updateGenderPreferenceSaga(action) {
    try {
        yield delay(ONBOARDING_DELAY_TIME);

        const response = yield call(OnboardingService.updateGenderPreference, action.payload);

        if (response?.success) {
            yield put(updateGenderPreferenceSuccess(response?.data));
        } else {
            yield put(updateGenderPreferenceFailed(response?.message));
        }
    } catch (err) {
        yield put(updateGenderPreferenceFailed(err.message));
    }
}

function* updateProfileLogoSaga(action) {
    try {
        yield delay(ONBOARDING_DELAY_TIME);

        const response = yield call(OnboardingService.updateProfileLogo, action.payload);

        if (response?.success) {
            yield put(updateProfileLogoSuccess(response?.data));
        } else {
            yield put(updateProfileLogoFailed(response?.message));
        }
    } catch (err) {
        yield put(updateProfileLogoFailed(err.message));
    }
}

function* updateBrandDescriptionSaga(action) {
    try {
        yield delay(ONBOARDING_DELAY_TIME);
        const response = yield call(OnboardingService.updateBrandDescription, action.payload);

        if (response?.success) {
            yield put(updateBrandDescriptionSuccess(response?.data));
        } else {
            yield put(updateBrandDescriptionFailed(response?.message));
        }
    } catch (err) {
        yield put(updateBrandDescriptionFailed(err.message));
    }
}

function* updateBrandStoreLinkSaga(action) {
    try {
        yield delay(ONBOARDING_DELAY_TIME);
        const response = yield call(OnboardingService.updateBrandStoreLink, action.payload);

        if (response?.success) {
            yield put(updateBrandStoreLinkSuccess(response?.data));
        } else {
            yield put(updateBrandStoreLinkFailed(response?.message));
        }
    } catch (err) {
        yield put(updateBrandStoreLinkFailed(err.message));
    }
}


export default function* onboardedStepsSaga() {
    yield all([onboardedStepsWatcher()]);
}
