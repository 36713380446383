import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import {BrandValidation} from '../../helpers/constant/validationPattern';
import {showErrorMessage} from '../../helpers/utility/Toastify';
import {GenderPreference} from '../../helpers/utility/GenderPreferenesLists';
import {useDispatch, useSelector} from 'react-redux';
import {selectProfile} from '../../state/features/Profile/profileSelector';
import {updateBrandProfileClearState, updateBrandProfileData} from '../../state/features/Profile/profileSlice';
import LoadingButton from "../../assets/global/Button/LoadingButton";

const EditBrandProfileForm = ({handleCancelEdit, setIsProfileEditable, setIsCancel}) => {
    const {brandProfile, fashionCategories, updateBrandProfile} = useSelector(selectProfile);
    const dispatch = useDispatch();
    const [category, setCategory] = useState([]);
    const [genderPreference, setGenderPreference] = useState(null);

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });
    const handleFashionCategoryStore = uids => {
        let exists = category?.includes(uids);
        if (exists) {
            showErrorMessage("You can't select same item twice!");
        } else {
            setCategory(prevItems => {
                let updatedFields = [];
                updatedFields = [...prevItems];
                updatedFields.push(uids);
                return updatedFields;
            });
            setValue('setCategory', setCategory);
        }
    };
    const filteredFashionCategories = fashionCategories?.data?.filter(obj =>
        category?.includes(obj.uid)
    );
    const handleDeleteCategory = uid => {
        setCategory(chips => chips.filter(id => id !== uid));
    };

    const onSubmit = data => {
        let payload = {};
        let categories = '';
        if (category?.length > 2) {
            if (!genderPreference) {
                showErrorMessage('Select gender preference!');
            } else {
                categories = JSON.stringify(category);
                payload = {
                    name: data?.name,
                    description: data?.description,
                    categoriesIds: categories,
                    genderPreference: genderPreference,
                    storeLink: data?.storeLink
                };
                dispatch(updateBrandProfileData(payload));
                setIsCancel(false);
            }
        } else {
            showErrorMessage('Select atleast three categories!');
        }
    };
    useEffect(() => {
        if (updateBrandProfile?.isBrandProfileUpdate) {
            setIsProfileEditable(false);
            setIsCancel(false);
            dispatch(updateBrandProfileClearState());
        }
    }, [updateBrandProfile?.isBrandProfileUpdate]);

    useEffect(() => {
        if (brandProfile?.data) {
            setValue('name', brandProfile?.data?.name);
            setValue('description', brandProfile?.data?.description);
            setValue('storeLink', brandProfile?.data?.storeLink);
            setCategory(brandProfile?.data?.categoriesIds ? brandProfile?.data?.categoriesIds : []);
            setValue('fashionCategories', category);
            setGenderPreference(brandProfile?.data?.genderPreference);
        }
    }, [brandProfile?.data]);

    const handleGenderPreference = value => {
        setGenderPreference(value);
    };
    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box px={5} py={4}>
                <Stack spacing={5}>
                    <Box>
                        <Typography variant="h6" mb={1}>
                            Brand Info
                        </Typography>

                        <Controller
                            control={control}
                            name="name"
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl
                                    size="small"
                                    sx={{mb: 2}}
                                    fullWidth
                                    error={!!(invalid && error.message)}
                                >
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Brand Name"
                                        fullWidth
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={BrandValidation.brandName}
                        />

                        <Controller
                            control={control}
                            name="description"
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl
                                    size="small"
                                    sx={{mb: 2}}
                                    fullWidth
                                    error={!!(invalid && error.message)}
                                >
                                    <TextField
                                        label="Brand Description"
                                        multiline
                                        rows={3}
                                        fullWidth
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={BrandValidation?.brandDescription}
                        />
                    </Box>

                    <Box>
                        <Typography variant="h6" mb={1}>
                            Brand Categories
                        </Typography>
                        <Controller
                            control={control}
                            name="fashionCategories"
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl
                                    size="small"
                                    sx={{mb: 2}}
                                    fullWidth
                                    error={!!(invalid && error.message)}
                                >
                                    <TextField
                                        id="outlined-select-category"
                                        select
                                        label="Fashion Category"
                                        value={value}
                                        onChange={e => {
                                            onChange(e);
                                            handleFashionCategoryStore(e?.target.value);
                                        }}
                                    >
                                        {fashionCategories?.data &&
                                            fashionCategories?.data?.map(items => (
                                                <MenuItem key={items.uid} value={items.uid}>
                                                    {items.categoryName}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            // rules={BrandValidation.fashionCategories}
                        />

                        <Typography variant="helperText" color="text.secondary">
                            You have to select at least 3
                        </Typography>
                        {filteredFashionCategories?.length > 0 && (
                            <Stack direction={'row'} flexWrap={'wrap'} gap={2} pt={2}>
                                {filteredFashionCategories?.map(data => {
                                    return (
                                        <Chip
                                            label={data?.categoryName}
                                            onDelete={() => handleDeleteCategory(data?.uid)}
                                        />
                                    );
                                })}
                            </Stack>
                        )}
                    </Box>

                    <Box>
                        <Typography variant="body1medium" mb={1}>
                            Gender Preferences
                        </Typography>

                        <FormGroup>
                            <Stack direction="row" justifyContent={'space-between'}>
                                {GenderPreference?.map(items => (
                                    <FormControlLabel
                                        key={items?.value}
                                        sx={{color: false ? 'text.primary' : 'text.secondary'}}
                                        control={
                                            <Checkbox
                                                checked={genderPreference === items?.value}
                                                onClick={() => handleGenderPreference(items?.value)}
                                            />
                                        }
                                        label={items.name}
                                    />
                                ))}
                            </Stack>
                        </FormGroup>
                    </Box>

                    <Box>
                        <Typography variant="h6" mb={2}>
                            Store Link
                        </Typography>

                        <Controller
                            control={control}
                            name="storeLink"
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl
                                    size="small"
                                    sx={{mb: 2}}
                                    fullWidth
                                    error={!!(invalid && error.message)}
                                >
                                    <TextField
                                        label="Store Link"
                                        id="outlined-start-adornment"
                                        fullWidth
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={BrandValidation.storeLink}
                        />
                    </Box>
                </Stack>
            </Box>

            <Stack
                p={'16px'}
                sx={{borderTop: 1, borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={2}
            >
                <Button sx={{color: 'text.primary'}} variant="text" onClick={handleCancelEdit}>
                    Cancel
                </Button>
                <LoadingButton
                    isLoading={updateBrandProfile?.isLoading}
                    loadingText={'Saving...'}
                    variant="contained"
                    disableElevation
                    type={'submit'}
                >
                    Save Changes
                </LoadingButton>
            </Stack>
        </Box>
    );
};
export default EditBrandProfileForm;
