import { Divider, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import Logo from '../../../assets/images/LogoDarkText.svg';
import { PublicProfileCard } from '../PublicProfileStyle'

const PublicPageSkeletonLoader = () => {
    return (
        <>
            <Stack height={'100vh'} alignItems={'center'} spacing={{ xs: 1, md: 5 }}>
                <Stack alignItems={'center'} spacing={1}>
                    <PublicProfileCard variant="outlined" elevation={0}>
                        <Stack alignItems={'center'} spacing={1}>
                            <Skeleton variant="circular" width={80} height={80} />
                            {/* <Avatar alt={title} src={imageUrl} /> */}

                            <Typography variant="h5"><Skeleton width={200} height={40} /></Typography>

                            <Skeleton
                                variant="rectangular"
                                width={96.5}
                                height={40}
                            />
                        </Stack>

                        <Stack paddingX={2} spacing={2}>
                            <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} mt={3}>
                                <>
                                    <Stack alignItems={'center'}>
                                        <Skeleton variant="rectangular" width={80} height={60} />
                                    </Stack>
                                    <Divider orientation="vertical" flexItem light />
                                    <Stack alignItems={'center'}>
                                        <Skeleton variant="rectangular" width={80} height={60} />
                                    </Stack>
                                    <Divider orientation="vertical" flexItem light />
                                    <Stack alignItems={'center'}>
                                        <Skeleton variant="rectangular" width={80} height={60} />
                                    </Stack>
                                </>
                            </Stack>

                            <Stack>
                                <Skeleton
                                    sx={{ borderRadius: '20px' }}
                                    variant="rounded"
                                    width={'100%'}
                                    height={500}
                                />
                            </Stack>
                        </Stack>
                    </PublicProfileCard>
                </Stack>
            </Stack>
        </>
    )
}

export default PublicPageSkeletonLoader
