import React, {useEffect} from 'react';
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import CookieService from "../../services/cookie/cookie.service";
import {getMyProfileData} from "../../state/features/Profile/profileSlice";
import {useDispatch} from "react-redux";
import hasAuthenticatedUser from "../../helpers/utility/hasAuthenticateUser";
import {showErrorMessage} from "../../helpers/utility/Toastify";

const ForceLoginRedirectPage = () => {
    const {token} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            CookieService.setCookie(process.env.REACT_APP_ACCESS_TOKEN, token, "");
            const hasAccessToken = hasAuthenticatedUser();
            if (hasAccessToken) {
                dispatch(getMyProfileData());
                setTimeout(() => {
                    navigate('/dashboard');
                }, [3000])
            } else {
                showErrorMessage('Authentication failed! Please login again.');
            }
        } else {
            navigate(-1);
        }
    }, [token]);

    return (
        <Stack justifyContent={'center'} alignItems={'center'} sx={{height: '100%'}}>
            <CircularProgress size={160} thickness={2}/>

            <Box textAlign={'center'} mt={5}>
                <Typography variant="h3">You're login to brand.</Typography>
                <Typography variant="body1" color={'text.secondary'} mb={3}>
                    Please wait while we do that!
                </Typography>
            </Box>
        </Stack>
    )
}
export default ForceLoginRedirectPage;