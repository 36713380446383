import React, {useEffect, useState} from 'react';
import {Box, MenuItem, Stack, TextField} from '@mui/material';
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import {useDispatch, useSelector} from "react-redux";
import {bannerCategories, bannerStatus, categories} from "../../../helpers/utility/TableContents";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MultipleTrashModal from "../../../assets/global/Modal/MultipleTrashModal";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import {multipleDeleteBannerStateClear, multipleDeletedBannerData} from "../../../state/features/Banner/bannerSlice";
import filterIcon from "../../../assets/images/Icons/FilterIcon.svg";
import {selectBanner} from "../../../state/features/Banner/bannerSelector";
import MultipleDeleteModal from "../../../assets/global/Modal/MultipleDeleteModal";

const BannersSearchBar = ({
                              selected,
                              setSelected,
                              trashedSelected,
                              setTrashedSelected,
                              handleCategorySelect,
                              handleSearchText,
                              category
                          }) => {
    const {multipleDeleteBanner} = useSelector(selectBanner);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleMultipleDelete = () => {
        if (selected?.length) {
            dispatch(multipleDeletedBannerData({
                uids: selected,
                category: category
            }));
            setSelected([]);
        } else {
            showErrorMessage("Select team member!");
        }
    }
    useEffect(() => {
        if (multipleDeleteBanner?.isMultipleDeleteBanner) {
            setOpen(false);
            dispatch(multipleDeleteBannerStateClear())
        }
    }, [multipleDeleteBanner?.isMultipleDeleteBanner]);
    return (
        <Box p={3}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={2}>
                    <TextField
                        id="outlined-required"
                        label="Search"
                        type={'search'}
                        placeholder="title..."
                        onChange={handleSearchText}
                    />

                    <TextField
                        id="outlined-select-category"
                        defaultValue={'ALL'}
                        select
                        label="Features"
                        sx={{minWidth: 156}}
                        onChange={handleCategorySelect}
                    >
                        {bannerStatus.map(option => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Stack justifyContent={'center'} sx={{cursor: 'pointer'}}>
                        <img src={filterIcon} alt="Filter Icon"/>
                    </Stack>
                </Stack>
                {
                    selected?.length > 0 && (
                        <Box sx={{marginRight: '10px'}}>
                            <LoadingButton
                                // isLoading={multipleTrashTeamMember?.isLoading}
                                loadingText={'Deleting...'}
                                size="small"
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon/>}
                                onClick={() => setOpen(true)}
                            >
                                Delete items ({selected?.length} Selected)
                            </LoadingButton>
                        </Box>
                    )
                }
            </Stack>

            <BasicWithHeaderModal width={460} open={open} header={false}>
                <MultipleDeleteModal
                    isLoading={multipleDeleteBanner?.isLoading}
                    handleModalClose={() => setOpen(false)}
                    onMultipleDelete={handleMultipleDelete}
                    itemLength={selected}/>
            </BasicWithHeaderModal>
        </Box>
    );
};

export default BannersSearchBar;
