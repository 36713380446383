import React, {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {Box, Button} from '@mui/material';
import ContentWrapper from '../../../assets/global/wrapper/ContentWrapper/ContentWrapper';
import WrapperHeader from '../../../assets/global/wrapper/ContentWrapper/WrapperHeader';
import MyProductsFilter from './MyProductsFilter';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {selectProductList} from "../../../state/features/Products/ProductList/ProductListSelector";
import MyProductsTable from "./MyProductsTable";
import TableLoaderSkeleton from "../../../assets/global/Loader/TableLoaderSkeleton";
import {productList} from "../../../state/features/Products/ProductList/ProductListSlice";
import {getFashionCategories} from "../../../state/features/Profile/profileSlice";
import {STATUS__ALL, STATUS__TRASHED} from "../../../helpers/constant/coreConstant";
import {productStateClear} from "../../../state/features/Products/AddProduct/AddProductSlice";
import DataNotFound from "../../ErrorPage/DataNotFound";

const MyProductLists = () => {
    const {products} = useSelector(selectProductList);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [category, setCategory] = useState(null);
    const [selected, setSelected] = useState([]);
    const [trashedSelected, setTrashedSelected] = useState([]);
    const [productId, setProductId] = useState(null);
    const [searchText, setSearchText] = useState(null);

    useEffect(() => {
        dispatch(productList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
        }));
    }, [category, searchText]);

    useEffect(() => {
        dispatch(getFashionCategories())
    }, []);

    const handleCategorySelect = (e) => {
        let category = e.target.value;
        if (selected?.length > 0) {
            setSelected([]);
        }
        if (trashedSelected?.length > 0) {
            setTrashedSelected([]);
        }

        if (category === STATUS__TRASHED) {
            setCategory(STATUS__TRASHED);
        } else if (category === STATUS__ALL) {
            setCategory('');
        } else {
            setCategory(category);
        }
    }
    const renderProductLists = () => {
        return (
            <>
                <WrapperHeader
                    title="My Products"
                    cta={
                        <Button
                            variant="contained"
                            startIcon={<AddIcon/>}
                            onClick={() => {
                                navigate('/addNewProduct');
                                dispatch(productStateClear())
                            }}
                        >
                            Add Product
                        </Button>
                    }
                    badge={products?.total > 1 ? `${products?.total} Items` : `${products?.total} Item`}
                />

                <MyProductsFilter
                    selected={selected}
                    setSelected={setSelected}
                    productId={productId}
                    setSearchText={setSearchText}
                    handleCategorySelect={handleCategorySelect}
                    trashedSelected={trashedSelected}
                    setTrashedSelected={setTrashedSelected}
                    category={category}
                />
                {
                    products?.isLoading ?
                        <Box py={2.4} px={1}><TableLoaderSkeleton/></Box> :
                        Array.isArray(products?.data) && products?.data?.length > 0 ? (
                            <MyProductsTable
                                selected={selected}
                                setSelected={setSelected}
                                productId={productId}
                                setProductId={setProductId}
                                trashedSelected={trashedSelected}
                                setTrashedSelected={setTrashedSelected}
                                category={category}
                                searchText={searchText}
                            />

                        ) : (
                            <Box sx={{textAlign: 'center', padding: '20px 0px'}}>
                                <DataNotFound title={'product'}/>
                            </Box>
                        )
                }
            </>
        );
    };
    return (
        <Box height={'calc(100vh - 109px)'} sx={{overflowY: 'auto'}}>
            <ContentWrapper>{renderProductLists()}</ContentWrapper>
        </Box>
    );
};

export default MyProductLists;
