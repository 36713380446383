import React, {useEffect, useState} from 'react';
import {Box, Button, ButtonGroup, MenuItem, Stack, TextField} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import BasicWithHeaderModal from "../../assets/global/Modal/BasicWithHeaderModal";
import {showErrorMessage} from "../../helpers/utility/Toastify";
import {useDispatch, useSelector} from "react-redux";
import MultipleTrashModal from "../../assets/global/Modal/MultipleTrashModal";
import {MOVE__TO__RESTORE, MOVE__TO__TRASH} from "../../helpers/constant/coreConstant";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import {selectTeamMember} from "../../state/features/TeamMember/teamMemberSelector";
import {
    multipleDeletedTeamMember, multipleDeleteTeamMemberStateClear,
    multipleRestoreTeamMemberData, multipleRestoreTeamMemberStateClear,
    multipleTrashedTeamMember, multipleTrashTeamMemberStateClear
} from "../../state/features/TeamMember/teamMemberSlice";
import {status, teamMemberStatus} from "../../helpers/utility/TableContents";
import filterIcon from "../../assets/images/Icons/FilterIcon.svg";
import restoreIcon from "../../assets/images/Icons/restoreIcon.svg";
import MultipleRestoreModal from "../../assets/global/Modal/MultipleRestoreModal";
import MultipleDeleteModal from "../../assets/global/Modal/MultipleDeleteModal";

const TeamMemberSearchBar = ({
                                 selected,
                                 setSelected,
                                 trashedSelected,
                                 setTrashedSelected,
                                 handleCategorySelect,
                                 handleSearchText,
                                 category,
                                 searchText
                             }) => {
    const {
        multipleTrashTeamMember,
        multipleDeleteTeamMember,
        multipleRestoreTeamMember
    } = useSelector(selectTeamMember);
    const [trashModal, setTrashModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);
    const dispatch = useDispatch();
    const handleMultipleDelete = () => {
        if (trashedSelected?.length > 0) {
            dispatch(multipleDeletedTeamMember({
                uids: trashedSelected,
                category: category,
                searchInputText: searchText ? searchText : '',
            }));
            setTrashedSelected([]);
        } else if (selected?.length > 0) {
            dispatch(multipleTrashedTeamMember({
                uids: selected,
                inTrash: MOVE__TO__TRASH,
                category: category,
                searchInputText: searchText ? searchText : '',
            }))
            setSelected([]);
        } else {
            showErrorMessage("Select team member!");
        }
    }
    useEffect(() => {
        if (multipleDeleteTeamMember?.isMultipleDeleteTeamMember) {
            setDeleteModal(false)
            dispatch(multipleDeleteTeamMemberStateClear())
        }
    }, [multipleDeleteTeamMember?.isMultipleDeleteTeamMember]);
    useEffect(() => {
        if (multipleTrashTeamMember?.isMultipleTrashTeamMember) {
            setTrashModal(false);
            dispatch(multipleTrashTeamMemberStateClear());
        }
    }, [multipleTrashTeamMember?.isMultipleTrashTeamMember]);
    const handleMultipleRestore = () => {
        if (trashedSelected?.length > 0) {
            dispatch(multipleRestoreTeamMemberData({
                uids: trashedSelected,
                inTrash: MOVE__TO__RESTORE,
                status: category,
                searchInputText: searchText ? searchText : '',
            }));
            setTrashedSelected([]);
        } else {
            showErrorMessage('Select products!');
        }
    }
    useEffect(() => {
        if (multipleRestoreTeamMember?.isMultipleRestoreTeamMember) {
            setRestoreModal(false);
            dispatch(multipleRestoreTeamMemberStateClear());
        }
    }, [multipleRestoreTeamMember?.isMultipleRestoreTeamMember]);
    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2} p={2}>
                <TextField
                    id="outlined-required"
                    label="Search"
                    type={'search'}
                    placeholder="Name.."
                    onChange={handleSearchText}
                />

                <TextField
                    id="outlined-select-category"
                    defaultValue={'ALL'}
                    select
                    label="Status"
                    sx={{minWidth: 156}}
                    onChange={handleCategorySelect}
                >
                    {teamMemberStatus.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            // selected={option.value === 'active'}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Stack justifyContent={'center'} sx={{cursor: 'pointer'}}>
                    <img src={filterIcon} alt="Filter Icon"/>
                </Stack>
            </Stack>
            {
                selected?.length > 0 ? (
                    <Box sx={{marginRight: '10px'}}>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon/>}
                            onClick={() => setTrashModal(true)}
                        >
                            Trash items ({selected?.length} Selected)
                        </Button>
                    </Box>
                ) : trashedSelected?.length > 0 && (
                    <Box sx={{marginRight: '10px'}}>
                        <ButtonGroup
                            disableElevation
                            variant="outlined"
                            aria-label="Disabled elevation buttons"
                        >
                            <Button
                                size="small"
                                variant="outlined"
                                color="success"
                                startIcon={<img height={20} width={20} src={restoreIcon} alt=""/>}
                                onClick={() => setRestoreModal(true)}
                            >
                                Restore Items ({trashedSelected?.length} Selected)
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon/>}
                                onClick={() => setDeleteModal(true)}
                            >
                                Delete Items ({trashedSelected?.length} Selected)
                            </Button>
                        </ButtonGroup>
                    </Box>
                )
            }

            <BasicWithHeaderModal width={460} open={trashModal} header={false}>
                <MultipleTrashModal
                    isLoading={multipleTrashTeamMember?.isLoading}
                    handleModalClose={() => setTrashModal(false)}
                    onMultipleTrashed={handleMultipleDelete}
                    itemLength={selected?.length > 0 && selected}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={deleteModal} header={false}>
                <MultipleDeleteModal
                    isLoading={multipleDeleteTeamMember?.isLoading}
                    handleModalClose={() => setDeleteModal(false)}
                    onMultipleDelete={handleMultipleDelete}
                    itemLength={trashedSelected?.length > 0 && trashedSelected}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={restoreModal} header={false}>
                <MultipleRestoreModal
                    isLoading={multipleRestoreTeamMember?.isLoading}
                    handleModalClose={() => setRestoreModal(false)}
                    onMultipleRestore={handleMultipleRestore}
                    itemLength={selected?.length > 0 ? selected : trashedSelected}/>
            </BasicWithHeaderModal>
        </Stack>
    );
};

export default TeamMemberSearchBar;
