import React, {useEffect} from 'react';
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentOnboardStep, selectJourneyModeOn} from "../state/features/OnboardedSteps/OnboardedStepsSelector";
import {useNavigate} from "react-router-dom";
import {getMyProfileData} from "../state/features/Profile/profileSlice";

const OnboardingLoadingPage = () => {
    const currentOnboardStep = useSelector(selectCurrentOnboardStep);
    const journeyModeOn = useSelector(selectJourneyModeOn);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            if (currentOnboardStep == 7 && journeyModeOn === false) {
                navigate('/dashboard');
            }
        }, 3000);
    }, []);

    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            <CircularProgress size={160} thickness={2}/>

            <Box textAlign={'center'} mt={5}>
                <Typography variant="h3">Setting up your brand</Typography>
                <Typography variant="body1" color={'text.secondary'} mb={3}>
                    Please wait while we do that
                </Typography>
            </Box>
        </Stack>
    )
}
export default OnboardingLoadingPage;