import {FormControlLabel, IconButton, Stack, Switch, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import deleteIcon from '../../../assets/images/Icons/deleteIcon.svg';
import editIcon from '../../../assets/images/Icons/editIcon.svg';
import viewIcon from '../../../assets/images/Icons/viewIcon.svg';
import BasicWithHeaderModal from '../../../assets/global/Modal/BasicWithHeaderModal';
import {BannerTableHeaderCell, createBannerTableData, renderGetFeatured} from "../../../helpers/utility/TableContents";
import EnhancedTableHead from "../../../assets/global/TableHeader/EnhancedTableHead";
import {useDispatch, useSelector} from "react-redux";
import {selectBanner} from "../../../state/features/Banner/bannerSelector";
import {IS__FEATURED__APPLIED} from "../../../helpers/constant/coreConstant";
import SingleDeleteModal from "../../../assets/global/Modal/SingleDeleteModal";
import {
    applyFeaturesData, editBannerStateClear,
    getBannerList,
    setVisibility,
    singleDeleteBannerData, singleDeleteBannerStateClear
} from "../../../state/features/Banner/bannerSlice";
import BannerProfile from "../ShowBannerProfile/BannerProfile";

const BannersTable = ({selected, setSelected, setTrashedSelected, trashedSelected, category, searchText}) => {
    const [order, setOrder] = useState('asc');
    const dispatch = useDispatch();
    const {bannerList, applyFeatures, singleDeleteBanner} = useSelector(selectBanner);
    const [orderBy, setOrderBy] = useState('uid');
    const [deleteModal, setDeleteModal] = useState(false);
    const [singleTeamMemberId, setSingleTeamMemberId] = useState(null);
    const [bannerProfileModal, setBannerProfileModal] = useState(false);
    const [bannerDetails, setBannerDetails] = useState(null);

    const rows =
        bannerList?.data?.map((items, index) => (
            createBannerTableData(items?.imageUrl, items?.title,
                items?.isVisible, items?.isFeatured, '', items?.uid)
        ));
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelected = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        dispatch(getBannerList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
            page: (newPage + 1),
            per_page: bannerList?.perPage,
        }));
    };

    const handleChangeRowsPerPage = event => {
        let row = parseInt(event.target.value, 10);
        dispatch(getBannerList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
            page: bannerList?.page,
            per_page: row,
        }));
    };

    const isSelected = id => selected.indexOf(id) !== -1;

    const emptyRows = (bannerList?.page - 1) > 0 ? Math.max(0, (1 + (bannerList?.page - 1)) * bannerList?.perPage - bannerList?.total) : 0;

    const handleBannerProfileView = (details) => {
        if (details) {
            setBannerProfileModal(true);
            setBannerDetails(details);
        }
    }
    const onSingleDeleteModalOpen = (id) => {
        if (id) {
            setDeleteModal(true);
            setSingleTeamMemberId(id);
        }
    }
    const onSingleTeamMemberDelete = () => {
        if (singleTeamMemberId) {
            dispatch(singleDeleteBannerData({
                bannerUid: singleTeamMemberId,
                searchInputText: searchText,
                category: category,
            }));
        }
    }
    useEffect(() => {
        if (singleDeleteBanner?.isSingleBannerDelete) {
            setDeleteModal(false);
            dispatch(singleDeleteBannerStateClear());
        }
    }, [singleDeleteBanner?.isSingleBannerDelete]);
    const handleApply = (id) => {
        if (id) {
            dispatch(applyFeaturesData({
                uid: id,
                isFeatured: IS__FEATURED__APPLIED,
                searchInputText: searchText,
                category: category,
                page: bannerList?.page,
                per_page: bannerList?.perPage,
            }))
        }
    }
    const handleVisibility = (e, id) => {
        const value = e.target?.checked;
        let visibility = null;
        if (value) {
            visibility = 1;
        } else {
            visibility = 0;
        }
        if (id) {
            dispatch(setVisibility({
                bannerUid: id,
                isVisible: visibility,
                searchInputText: searchText,
                category: category,
                page: bannerList?.page,
                per_page: bannerList?.perPage,
            }));
        }
    }
    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n.uid);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };


    return (
        <Box sx={{width: '100%'}}>
            <TableContainer>
                <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        tableHeader={BannerTableHeaderCell}
                        numSelected={selected?.length > 0 && selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={bannerList?.total}
                    />
                    <TableBody>
                        {rows.map((row, index) => {
                            const isItemSelected = isSelected(row?.uid);
                            const labelId = `enhanced-table-checkbox-${row?.uid}`;

                            return (
                                <TableRow
                                    key={row?.uid}
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                    sx={{cursor: 'pointer'}}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            onClick={event => handleSelected(event, row.uid)}
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" id={labelId} scope="row" padding="none">
                                        <Stack direction={'row'} spacing={1.4}>
                                            <img
                                                src={row.banner}
                                                width={197}
                                                style={{padding: 20}}
                                                alt="Product Banner"
                                            />
                                        </Stack>
                                    </TableCell>

                                    <TableCell align="left">
                                        <Typography variant="body2">{row.title.substring(0, 20) + '...'}</Typography>
                                    </TableCell>

                                    <TableCell align="center">
                                        <FormControlLabel
                                            control={<Switch
                                                defaultChecked={row.visibility}
                                                onChange={(e) => handleVisibility(e, row?.uid)}

                                            />}/>
                                    </TableCell>

                                    <TableCell
                                        align="center">{renderGetFeatured(row.featured, row?.uid, applyFeatures?.isLoading, handleApply)}</TableCell>

                                    <TableCell align="center">
                                        <Stack
                                            display={'inline-flex'}
                                            direction={'row'}
                                            spacing={1}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            <IconButton
                                                size="small"
                                                aria-label="view"
                                                onClick={() => handleBannerProfileView(row)}
                                            >
                                                <img src={viewIcon} alt=""/>
                                            </IconButton>

                                            <Link to={`/editBanner/${row?.uid}`} style={{textDecoration: 'none'}} onClick={() => dispatch(editBannerStateClear())}>
                                                <IconButton
                                                    size="small"
                                                    aria-label="edit"
                                                >
                                                    <img src={editIcon} alt=""/>
                                                </IconButton>
                                            </Link>

                                            <IconButton
                                                size="small"
                                                aria-label="delete"
                                                onClick={() => onSingleDeleteModalOpen(row?.uid)}
                                            >
                                                <img src={deleteIcon} alt=""/>
                                            </IconButton>

                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={bannerList?.total}
                rowsPerPage={bannerList?.perPage}
                page={(bannerList?.page - 1)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <BasicWithHeaderModal width={460} open={deleteModal} header={false}>
                <SingleDeleteModal
                    isLoading={singleDeleteBanner?.isLoading}
                    onSingleDelete={onSingleTeamMemberDelete}
                    handleModalClose={() => setDeleteModal(false)}/>
            </BasicWithHeaderModal>
            <BasicWithHeaderModal
                modalTitle="Summer Collection"
                open={bannerProfileModal}
                onClose={() => setBannerProfileModal(false)}
                width={1257}
            >
                <BannerProfile bannerDetails={bannerDetails}/>
            </BasicWithHeaderModal>
        </Box>
    );

    EnhancedTableHead.propTypes = {
        // numSelected: PropTypes.number.isRequired,
        // onRequestSort: PropTypes.func.isRequired,
        // onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired
    };
}

export default BannersTable;
