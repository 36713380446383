import React, {useEffect} from 'react';
import WrapperHeader from "../../../assets/global/wrapper/ContentWrapper/WrapperHeader";
import {Box, Button, Grid, Stack} from "@mui/material";
import {default as VisibilityIcon} from "@mui/material/SvgIcon/SvgIcon";
import WrapperFooter from "../../../assets/global/wrapper/ContentWrapper/WrapperFooter";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectBanner} from "../../../state/features/Banner/bannerSelector";
import BannerInfo from "../AddBanner/BannerInfo";
import BannerImage from "../AddBanner/BannerImage";
import useEditBannerHook from "../hooks/useEditBannerHook";
import {useParams} from "react-router-dom";
import {editBannerData, updateBannerStateClear} from "../../../state/features/Banner/bannerSlice";
import {IS__FEATURED__APPLIED, IS__FEATURED__APPLY} from "../../../helpers/constant/coreConstant";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import ContentWrapper from "../../../assets/global/wrapper/ContentWrapper/ContentWrapper";
import CoreBreadCrumbs from "../../../assets/global/BreadCrumbs/CoreBreadCrumbs";
import {bannersInfo, teamMemberInfo} from "../../../helpers/constant/pageInfo";

const EditBanner = () => {
    const {editBanner, updateBanner} = useSelector(selectBanner);
    const dispatch = useDispatch();
    const {bannerId} = useParams();
    const {
        navigate,
        featured,
        setFeatured,
        ctaHover,
        setCtaHover,
        bannerImage,
        setBannerImage,
        onSubmit,
        handleImageUpload,
        handleRemoveImage
    } = useEditBannerHook({bannerId});

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });

    useEffect(() => {
        if (bannerId) {
            dispatch(editBannerData({bannerUid: bannerId}));
        }
    }, [bannerId]);

    useEffect(() => {
        if (editBanner?.data) {
            setBannerImage({
                data: '',
                src: editBanner?.data?.imageUrl
            });
            setValue("title", editBanner?.data?.title || "");
            setFeatured(
                editBanner?.data?.isFeatured === IS__FEATURED__APPLY ?
                    false :
                    true
            );
            dispatch(updateBannerStateClear())
        }
    }, [editBanner?.data]);

    useEffect(() => {
        if (updateBanner?.isBannerUpdate) {
            setBannerImage({
                data: '',
                src: ''
            });
            reset();
            setFeatured(false);
            dispatch(updateBannerStateClear())
            navigate('/banners');
        }
    }, [updateBanner?.isBannerUpdate]);

    return (
        <ContentWrapper>
            <WrapperHeader
                title="Edit Banner"
                backButton={() => navigate('/banners')}
                breadCrumb={<CoreBreadCrumbs pageInfo={bannersInfo} keys={[1,3]}/>}
                // cta={
                //     <Button
                //         variant="text"
                //         startIcon={<VisibilityIcon/>}
                //         onClick={() => setAppPreview(true)}
                //     >
                //         In App Preview
                //     </Button>
                // }
            />
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    sx={{
                        overflowY: 'auto'
                    }}
                >
                    <Grid item sm={12} lg={4.55}>
                        <Box
                            py={4}
                            px={5}
                            borderRight={{lg: '1px solid'}}
                            borderColor={{lg: 'other.divider'}}
                            sx={{height: {sm: 'auto', lg: 'calc(100vh - 270px)'}, overflowY: 'auto'}}
                        >
                            <BannerInfo
                                control={control}
                                featured={featured}
                                setFeatured={setFeatured}
                            />

                        </Box>
                    </Grid>
                    <Grid item sm={12} lg={5.45}>
                        <Stack
                            py={4}
                            px={5}
                            spacing={3}
                            alignItems={'flex-start'}
                            // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                        >
                            <BannerImage
                                ctaHover={ctaHover}
                                setCtaHover={setCtaHover}
                                handleImageUpload={handleImageUpload}
                                bannerImage={bannerImage}
                                setBannerImage={setBannerImage}
                                handleRemoveImage={handleRemoveImage}
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <WrapperFooter>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <Button
                                sx={{color: 'text.primary'}}
                                variant="text"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            {/*<LoadingButton*/}
                            {/*    isLoading={draftBanner?.isLoading}*/}
                            {/*    loadingText={'Drafting...'}*/}
                            {/*    variant="contained"*/}
                            {/*    type={'submit'}*/}
                            {/*>*/}
                            {/*    Save as draft*/}
                            {/*</LoadingButton>*/}
                            <LoadingButton
                                isLoading={updateBanner?.isLoading}
                                loadingText={'Updating...'}
                                variant="contained"
                                disableElevation
                                type={'submit'}
                            >
                                Update Banner
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </WrapperFooter>
            </Box>
        </ContentWrapper>
    );
}

export default EditBanner;