import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectPromotion } from "../../../state/features/Promotions/promotionSelector";
import { useForm } from "react-hook-form";
import WrapperHeader from "../../../assets/global/wrapper/ContentWrapper/WrapperHeader";
import CoreBreadCrumbs from "../../../assets/global/BreadCrumbs/CoreBreadCrumbs";
import { promotionInfo } from "../../../helpers/constant/pageInfo";
import { Box, Button, Grid, Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PromotionForm from "../Form/PromotionForm";
import PromotionBannerUpload from "../Form/PromotionBannerUpload";
import WrapperFooter from "../../../assets/global/wrapper/ContentWrapper/WrapperFooter";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import ContentWrapper from "../../../assets/global/wrapper/ContentWrapper/ContentWrapper";
import {
    editPromotionData,
    updatePromotionData,
    updatePromotionStateClear
} from "../../../state/features/Promotions/promotionSlice";
import dayjs from "dayjs";
import { showErrorMessage } from "../../../helpers/utility/Toastify";
import { updateTeamMemberStateClear } from "../../../state/features/TeamMember/teamMemberSlice";
import routeLink from "../../../helpers/constant/routeLink";


const EditPromotion = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { promotionId } = useParams();
    const [banner, setBanner] = useState(null);
    const { editPromotion, updatePromotion } = useSelector(selectPromotion);
    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit'
    });

    const handleImage = (file) => {
        if (file) {
            setBanner(file)
        } else
            showErrorMessage('Image file not found!')
    }

    useEffect(() => {
        if (promotionId) {
            dispatch(editPromotionData({ promotionUid: promotionId }));
        }
    }, [promotionId]);

    useEffect(() => {
        if (editPromotion?.data) {
            setValue("promotionType", editPromotion?.data?.promotionType)
            setValue("productUid", editPromotion?.data?.productUid)
            setValue("promotionTitle", editPromotion?.data?.name)
            setValue("promotionDescription", editPromotion?.data?.description)
            setValue("startDate", dayjs(editPromotion?.data?.startDate))
            setValue("endDate", dayjs(editPromotion?.data?.endDate))
            setValue("winners", editPromotion?.data?.winners)
            setValue("rewardsAndPolicies", editPromotion?.data?.rewardsAndPolicies)
        }
    }, [editPromotion?.data]);

    const onSubmit = (data) => {
        if (data) {
            let formData = new FormData();
            formData.append("uid", promotionId);
            formData.append("promotionType", data?.promotionType);
            if (data?.promotionType === 2) {
                formData.append("productUid", data?.productUid);
            }
            formData.append("name", data?.promotionTitle);
            formData.append("description", data?.promotionDescription);
            formData.append("startDate", dayjs(data?.startDate).format('YYYY-MM-DD'));
            formData.append("endDate", dayjs(data?.endDate).format('YYYY-MM-DD'));
            formData.append("rewardsAndPolicies", data?.rewardsAndPolicies);
            formData.append("winners", data?.winners);
            formData.append("imageUrl", banner);
            dispatch(updatePromotionData(formData));
        } else {
            showErrorMessage("Data not found!");
        }
    }

    useEffect(() => {
        if (updatePromotion?.isPromotionUpdate) {
            reset({
                promotionType: "",
                promotionTitle: "",
                startDate: "",
                endDate: "",
                rewardsAndPolicies: "",
            });
            dispatch(updatePromotionStateClear());
            setBanner(null);
            navigate('/promotions');
        }
    }, [updatePromotion?.isPromotionUpdate]);

    return (
        <ContentWrapper>
            <WrapperHeader
                title="Edit Promotion"
                backButton={() => navigate('/promotions')}
                breadCrumb={<CoreBreadCrumbs pageInfo={promotionInfo} keys={[1, 3]} />}
            />
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    sx={{
                        overflowY: 'auto'
                    }}
                >
                    <Grid item sm={12} lg={4.55}>
                        <Box
                            py={4}
                            px={5}
                            borderRight={{ lg: '1px solid' }}
                            borderColor={{ lg: 'other.divider' }}
                            sx={{ height: { sm: 'auto', lg: 'calc(100vh - 270px)' }, overflowY: 'auto' }}
                        >
                            <PromotionForm control={control} type={routeLink.editPromotion.hidden_key} />
                        </Box>
                    </Grid>
                    <Grid item sm={12} lg={5.45}>
                        <PromotionBannerUpload handleImage={handleImage} />
                    </Grid>
                </Grid>

                <WrapperFooter>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <Button
                                sx={{ color: 'text.primary' }}
                                variant="text"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                isLoading={updatePromotion?.isLoading}
                                loadingText={'Updating...'}
                                variant="contained"
                                type={'submit'}
                            >
                                Update Promotion
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </WrapperFooter>
            </Box>
        </ContentWrapper>
    )
}
export default EditPromotion;