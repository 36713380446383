import {Box, Stack} from '@mui/material';
import React from 'react';
import {Outlet} from 'react-router-dom';
import PrivateLayout from "../PrivateLayout";

const OnboardingLoadingLayout = () => {
    return (
        <PrivateLayout>
            <Box sx={{display: 'grid', placeContent: 'center', height: '100vh'}}>
                <Stack justifyContent={'center'} alignItems={'center'} spacing={5} textAlign={'center'}>
                    <Outlet/>
                </Stack>
            </Box>
        </PrivateLayout>
    );
};

export default OnboardingLoadingLayout;
