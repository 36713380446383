import {Box, Button, Stack} from '@mui/material';
import React from 'react';

const WrapperFooter = props => {
  return (
    <Box p={2} borderTop={'1px solid'} borderColor={'other.divider'} {...props}>
      {props.children}
    </Box>
  );
};

export default WrapperFooter;
