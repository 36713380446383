import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import {Box, Stack, Typography} from '@mui/material';
import AddImageDescription from "./AddImageDescription";
import {addImageStyle} from "../../../helpers/utility/Product";
import ImageDnD from "./ImageDnD";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {Link} from "react-router-dom";

const AddProductImages = ({appPreview, setAppPreview}) => {
    const {selectedImages, productImages} = useSelector(selectAddProduct);

    return (
        <Box>
            <AddImageDescription/>
            {
                selectedImages?.length >= 1 && (
                    <>
                        <Stack direction={'row'} flexWrap={'wrap'} spacing={3.2} rowGap={3.2}>

                            <DndProvider backend={HTML5Backend}>
                                <ImageDnD appPreview={appPreview} setAppPreview={setAppPreview}/>
                            </DndProvider>
                            {
                                selectedImages?.length < 5 && (
                                    <Link to={'/myGallery/?type=productImage'}>
                                        <Box>
                                            <Stack justifyContent={'center'} alignItems={'center'} gap={1} sx={addImageStyle}>
                                                <AddIcon sx={{color: 'primary.main'}}/>
                                                <Typography variant="buttonSmall">Add Image</Typography>
                                            </Stack>
                                        </Box>
                                    </Link>
                                )
                            }
                        </Stack>
                        <Box sx={{textAlign: 'center'}} mt={2}>
                            <Typography sx={{color: "#808080", fontSize: '13px'}}>
                                For sorting, drag image from right to left for flexibility.
                            </Typography>
                        </Box>
                    </>
                )
            }
        </Box>
    );
};
export default AddProductImages;
