import {Box} from '@mui/material';
import React from 'react';

const BaseWrapper = props => {
  return (
    <Box bgcolor={'common.white'} p={3} borderRadius={1} {...props}>
      {props.children}
    </Box>
  );
};

export default BaseWrapper;
