import {useRef, useState} from "react";
import {showErrorMessage, showSuccessMessage} from "../helpers/utility/Toastify";
import {useDispatch, useSelector} from "react-redux";
import CookieService from "../services/cookie/cookie.service";
import GalleryService from "../services/Gallery.service";
import {galleryList, galleryListClear} from "../state/features/Gallery/GalleryList/galleryListSlice";
import {selectGallery} from "../state/features/Gallery/GalleryImageDelete/gallerySelector";
import {
    getGalleryImage,
    getMoreGalleryImage,
    getMoreGalleryImageStateClear
} from "../state/features/Gallery/GalleryImageDelete/gallerySlice";

const useSingleImageUpload = () => {
    const dispatch = useDispatch();
    const inputElementRef = useRef(null);
    const {galleryImage} = useSelector(selectGallery);
    const {hasMore, isFetching, page, perPage} = galleryImage || {}
    const scrollDivRef = useRef(null);
    const [progressValue, setProgressValue] = useState(10);
    const [uploading, setUploading] = useState(false);


    const handleImageUpload = async (e) => {
        let file = e?.target?.files[0];
        if (file) {
            setUploading(true);
            await uploadImageWithProgress(file)
            // Clear the selected file from the input
            e.target.value = '';
        }
    }

    const uploadImageWithProgress = async (file) => {
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            let xhr = new XMLHttpRequest();
            xhr.upload.addEventListener("progress", ProgressHandler, false);
            xhr.addEventListener("load", SuccessHandler, false);
            xhr.addEventListener("error", ErrorHandler, false);
            xhr.addEventListener("abort", AbortHandler, false);
            xhr.open("POST", process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/gallery/single/image/upload");
            xhr.setRequestHeader('Authorization', `bearer ${CookieService.getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`);
            xhr.send(formData);

        } else {
            showErrorMessage("Image not found!");
        }
    }
    const ProgressHandler = (e) => {
        const percent = (e.loaded / e.total) * 100;
        let value = Math.round(percent);
        setProgressValue(value);
    };

    const SuccessHandler = (e) => {
        let response = JSON.parse(e.target.response);
        if (response?.success) {
            const payload = {};
            payload.imageUrl = response?.data?.url;
            payload.imageName = response?.data?.fileName;

            GalleryService.imageSavedToDatabase(payload)
                .then((response) => {
                    if (response?.success) {
                        dispatch(getGalleryImage());
                        dispatch(getMoreGalleryImageStateClear());
                        showSuccessMessage(response?.message);
                        if (inputElementRef.current) {
                            inputElementRef.current.value = '';
                        }
                        setUploading(false);
                    } else {
                        setUploading(false);
                        showErrorMessage(response?.message)
                    }
                })
                .catch((err) => console.log(err));
        } else {
            if (response?.message?.image) {
                showErrorMessage(response?.message?.image[0]);
                setUploading(false);
            } else {
                setUploading(false);
            }
        }
    };
    const ErrorHandler = (event) => {
        let response = event.target.responseText;
    };
    const AbortHandler = () => {
        setUploading(false);
        showErrorMessage("Image Upload Canceled!");
    };

    return {
        scrollDivRef,
        uploading,
        progressValue,
        handleImageUpload,
        AbortHandler,
        inputElementRef
    };
};

export default useSingleImageUpload;