import { all, put } from 'redux-saga/effects';
import { call, debounce, takeEvery } from '@redux-saga/core/effects';
import ProductService from "../../../../services/Product.service";
import {
    multipleDeleteProductFailed,
    multipleDeleteProductSuccess, multipleRestoreProductFailed, multipleRestoreProductSuccess,
    multipleTrashProductFailed,
    multipleTrashProductSuccess,
    productList,
    productListFailed,
    productListSuccess,
    setProductVisibilityFailed,
    setProductVisibilitySuccess,
    singleDeleteProductFailed,
    singleDeleteProductSuccess, singleRestoreProductFailed, singleRestoreProductSuccess,
    singleTrashProductFailed,
    singleTrashProductSuccess, updateProductFailed, updateProductSuccess
} from "./ProductListSlice";
import { showErrorMessage, showSuccessMessage } from "../../../../helpers/utility/Toastify";
import { STATUS__TRASHED } from "../../../../helpers/constant/coreConstant";

function* productListWatcher() {
    yield debounce(500, 'ProductList/productList', productListSaga);
    yield takeEvery('ProductList/setProductVisibility', productVisibilitySaga);
    yield takeEvery('ProductList/singleTrashProductData', singleTrashProductSaga);
    yield takeEvery('ProductList/singleRestoreProductData', singleRestoreProductSaga);
    yield takeEvery('ProductList/singleDeleteProductData', singleDeleteProductSaga);
    yield takeEvery('ProductList/multipleTrashProductData', multipleTrashProductSaga);
    yield takeEvery('ProductList/multipleDeleteProductData', multipleDeleteProductSaga);
    yield takeEvery('ProductList/multipleRestoreProductData', multipleRestoreProductSaga);
    yield takeEvery('ProductList/updateProductData', updateProductSaga);
}

function* productListSaga(action) {
    try {
        const response = yield call(ProductService.productList, action.payload);
        if (response?.success) {
            yield put(productListSuccess(response?.data));
        } else {
            yield put(productListFailed(response?.message));
        }
    } catch (err) {
        yield put(productListFailed(err.message));
    }
}

function* productVisibilitySaga(action) {
    try {
        const response = yield call(ProductService.productVisibility, action.payload);
        if (response?.success) {
            yield put(setProductVisibilitySuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(setProductVisibilityFailed(response?.message));
            showErrorMessage(response?.message);
            yield put(productList({
                searchInputText: action?.payload?.searchInputText,
                status: action?.payload?.status,
                page: action.payload?.page,
                perPage: action.payload?.perPage,
            }));
        }
    } catch (err) {
        yield put(setProductVisibilityFailed(err.message));
    }
}

function* singleTrashProductSaga(action) {
    try {
        const response = yield call(ProductService.singleTrash, action.payload);
        console.log("SAGA ", action.payload)
        if (response?.success) {
            yield put(singleTrashProductSuccess(response?.data));
            yield put(productList({
                status: action?.payload?.category
            }));
            showSuccessMessage(response?.message);
        } else {
            showErrorMessage(response?.message);
            yield put(singleTrashProductFailed(response?.message));
        }
    } catch (err) {
        yield put(singleTrashProductFailed(err.message));
    }
}
function* singleRestoreProductSaga(action) {
    try {
        const response = yield call(ProductService.singleTrash, action.payload);
        if (response?.success) {
            yield put(singleRestoreProductSuccess(response?.data));
            yield put(productList({
                status: STATUS__TRASHED
            }));
            showSuccessMessage(response?.message);
        } else {
            showErrorMessage(response?.message);
            yield put(singleRestoreProductFailed(response?.message));
        }
    } catch (err) {
        yield put(singleRestoreProductFailed(err.message));
    }
}

function* singleDeleteProductSaga(action) {
    try {
        const response = yield call(ProductService.singleDelete, action.payload);
        if (response?.success) {
            yield put(singleDeleteProductSuccess(response?.data));
            showSuccessMessage(response?.message);
            yield put(productList({
                status: action.payload?.category,
            }));
        } else {
            showErrorMessage(response?.message);
            yield put(singleDeleteProductFailed(response?.message));
        }
    } catch (err) {
        yield put(singleDeleteProductFailed(err.message));
    }
}

function* multipleDeleteProductSaga(action) {
    try {
        const response = yield call(ProductService.multipleDelete, action.payload);
        if (response?.success) {
            yield put(multipleDeleteProductSuccess(response?.data));
            yield put(productList({
                status: action.payload?.category
            }));
            showSuccessMessage(response?.message);
        } else {
            showErrorMessage(response?.message);
            yield put(multipleDeleteProductFailed(response?.message));
        }
    } catch (err) {
        yield put(multipleDeleteProductFailed(err.message));
    }
}
function* multipleRestoreProductSaga(action) {
    try {
        const response = yield call(ProductService.multipleRestore, action.payload);
        if (response?.success) {
            yield put(multipleRestoreProductSuccess(response?.data));
            yield put(productList({
                status: action.payload?.status
            }));
            showSuccessMessage(response?.message);
        } else {
            showErrorMessage(response?.message);
            yield put(multipleRestoreProductFailed(response?.message));
        }
    } catch (err) {
        yield put(multipleRestoreProductFailed(err.message));
    }
}

function* multipleTrashProductSaga(action) {
    try {
        const response = yield call(ProductService.multipleTrash, action.payload);
        if (response?.success) {
            yield put(multipleTrashProductSuccess(response?.data));
            showSuccessMessage(response?.message);
            yield put(productList({
                status: action.payload?.category
            }));
        } else {
            showErrorMessage(response?.message);
            yield put(multipleTrashProductFailed(response?.message));
        }
    } catch (err) {
        yield put(multipleTrashProductFailed(err.message));
    }
}
function* updateProductSaga(action) {
    try {
        const response = yield call(ProductService.updateProduct, action.payload);
        if (response?.success) {
            yield put(updateProductSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            if (response?.message?.productSkuId) {
                showErrorMessage(response?.message?.productSkuId[0]);
            } else if (response?.message?.name) {
                showErrorMessage(response?.message?.name[0]);
            } else if (response?.message?.productStoreLink) {
                showErrorMessage(response?.message?.productStoreLink[0]);
            } else if (response?.message?.description) {
                showErrorMessage(response?.message?.description[0]);
            } else {
                showErrorMessage(response?.message);
            }
            yield put(updateProductFailed(response?.message));
        }
    } catch (err) {
        yield put(updateProductFailed(err.message));
    }
}


export default function* productListsSaga() {
    yield all([productListWatcher()]);
}
