import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    ids: null,
    isLoading: false,
    isError: false,
    error: '',
    singleProduct: {
        ids: null,
        isLoading: false,
        isError: false,
        error: '',
    },
};

const ProductDeleteSlice = createSlice({
    name: 'ProductDelete',
    initialState: initialState,
    reducers: {
        multipleProductDelete: (state, action) => {
            state.isLoading = true;
        },
        multipleProductDeleteSuccess: (state, action) => {
            state.ids = action?.payload?.data;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        multipleProductDeleteFailed: (state, action) => {
            state.ids = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        multipleProductDeleteClearState: (state, action) => {
            state.ids = null;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },

        singleProductDelete: (state, action) => {
            state.singleProduct.isLoading = true;
        },
        singleProductDeleteSuccess: (state, action) => {
            state.singleProduct.ids = action?.payload?.data;
            state.singleProduct.isLoading = false;
            state.singleProduct.isError = false;
            state.singleProduct.error = '';
        },
        singleProductDeleteFailed: (state, action) => {
            state.singleProduct.ids = null;
            state.singleProduct.isLoading = false;
            state.singleProduct.isError = true;
            state.singleProduct.error = action.payload;
        },
        singleProductDeleteClearState: (state, action) => {
            state.singleProduct.ids = null;
            state.singleProduct.isLoading = false;
            state.singleProduct.isError = false;
            state.singleProduct.error = '';
        },
    }
});

export const {
    multipleProductDelete,
    multipleProductDeleteSuccess,
    multipleProductDeleteFailed,
    multipleProductDeleteClearState,
    singleProductDelete,
    singleProductDeleteSuccess,
    singleProductDeleteFailed,
    singleProductDeleteClearState,
} = ProductDeleteSlice.actions;

export default ProductDeleteSlice.reducer;
