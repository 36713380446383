import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    productAdded: false,
    isLoading: false,
    isError: false,
    error: '',
    createdByUid: null,

    productName: "",
    isProductName: false,

    productSKUId : "",
    isProductSKUId : false,

    productDescription: "",
    isProductDescription: false,

    clothingCategory: "",
    isClothingCategory: false,

    fashionCategory: null,
    isFashionCategory: false,

    genderPreference: null,
    isGenderPreference: false,

    productPrice: "",
    isProductPrice: false,

    storeLink: "",
    isStoreLink: false,

    postDescription: "",
    isPostDescription: false,

    postChecked: false,

    selectedImages: [],
    isSetImage: false,

    productStatus: null,
    isProductStatus: false,

    productImages: [],
    isProductImages: false,

    imageInfo: {
        modalOpen: false,
        imageIndex: null,
        imageUrl: null,
        width: null,
        height: null,
    },
    draftProduct: {
        isLoading: false,
        isError: false,
        error: '',
    }
};

const AddProductSlice = createSlice({
    name: 'AddProduct',
    initialState: initialState,
    reducers: {
        addProduct: (state, action) => {
            state.isLoading = true;
        },
        addProductSuccess: (state, action) => {
            state.productAdded = true;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        addProductFailed: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload?.message;
        },
        productStateClear: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.error = '';
            state.productAdded = false;

            state.productName = "";
            state.isProductName = false;

            state.productSKUId = "";
            state.isProductSKUId = false;

            state.productDescription = "";
            state.isProductDescription = false;

            state.clothingCategory = "";
            state.isClothingCategory = false;

            state.fashionCategory = [];
            state.isFashionCategory = false;

            state.genderPreference = null;
            state.isGenderPreference = false;

            state.productPrice = "";
            state.isProductPrice = false;

            state.storeLink = "";
            state.isStoreLink = false;

            state.postDescription = "";
            state.isPostDescription = false;

            state.selectedImages = [];
            state.isSetImage = false;

            state.productStatus = null;
            state.isProductStatus = false;

            state.productImages = [];
            state.isProductImages = false;

            state.draftProduct.isLoading = false;
            state.draftProduct.isError = false;
            state.draftProduct.error = '';

            state.postChecked = false;

        },

        addProductDraft: (state, action) => {
            state.draftProduct.isLoading = true;
        },
        addProductDraftSuccess: (state, action) => {
            state.draftProduct.productAdded = true;
            state.draftProduct.isLoading = false;
            state.productAdded = true;
            state.draftProduct.isError = false;
            state.draftProduct.error = '';
        },
        addProductDraftFailed: (state, action) => {
            state.draftProduct.isLoading = false;
            state.draftProduct.isError = true;
            state.draftProduct.error = action.payload?.message;
        },

        setProductName: (state, action) => {
            state.productName = action?.payload;
            state.isProductName = true;
        },
        setProductNameClear: (state, action) => {
            state.productName = null;
            state.isProductName = false;
        },

        setProductSKUId: (state, action) => {
            state.productSKUId = action?.payload;
            state.isProductSKUId = true;
        },
        setProductSKUIdClear: (state, action) => {
            state.productSKUId = null;
            state.isProductSKUId = false;
        },

        setProductDescription: (state, action) => {
            state.productDescription = action?.payload;
            state.isProductDescription = true;
        },
        setProductDescriptionClear: (state, action) => {
            state.productDescription = null;
            state.isProductDescription = false;
        },
        setClothingCategory: (state, action) => {
            state.clothingCategory = action?.payload;
            state.isClothingCategory = true;
        },
        setClothingCategoryClear: (state, action) => {
            state.clothingCategory = null;
            state.isClothingCategory = false;
        },

        setFashionCategory: (state, action) => {
            state.fashionCategory = action?.payload;
            state.isFashionCategory = true;
        },
        setFashionCategoryClear: (state, action) => {
            state.fashionCategory = [];
            state.isFashionCategory = false;
        },

        setGenderPreference: (state, action) => {
            state.genderPreference = action?.payload;
            state.isGenderPreference = true;
        },
        setGenderPreferenceClear: (state, action) => {
            state.genderPreference = [];
            state.isGenderPreference = false;
        },
        setProductPrice: (state, action) => {
            state.productPrice = action?.payload;
            state.isProductPrice = true;
        },
        setProductPriceClear: (state, action) => {
            state.productPrice = null;
            state.isProductPrice = false;
        },

        setStoreLink: (state, action) => {
            state.storeLink = action?.payload;
            state.isStoreLink = true;
        },
        setStoreLinkClear: (state, action) => {
            state.storeLink = null;
            state.isStoreLink = false;
        },

        setPostDescription: (state, action) => {
            state.postDescription = action?.payload;
            state.isPostDescription = true;
        },
        setPostDescriptionClear: (state, action) => {
            state.postDescription = null;
            state.isPostDescription = false;
        },

        setPostChecked: (state, action) => {
            state.postChecked = action?.payload;
        },

        setSelectedImages: (state, action) => {
            state.selectedImages = action?.payload;
            state.isSetImage = true;
        },
        setSelectedImagesStateClear: (state, action) => {
            state.selectedImages = [];
            state.isSetImage = false;
        },

        setProductStatus: (state, action) => {
            state.productStatus = action?.payload;
            state.isProductStatus = true;
        },
        setProductStatusStateClear: (state, action) => {
            state.productStatus = null;
            state.isProductStatus = false;
        },

        setModalOpen: (state, action) => {
            state.imageInfo.modalOpen = true;
            state.imageInfo.imageIndex = action?.payload?.index;
            state.imageInfo.imageUrl = action?.payload?.imageLink;
            state.imageInfo.width = action?.payload?.width;
            state.imageInfo.height = action?.payload?.height;
        },
        setModalClose: (state, action) => {
            state.imageInfo.modalOpen = false;
            state.imageInfo.imageIndex = null;
            state.imageInfo.imageUrl = null;
            state.imageInfo.width = null;
            state.imageInfo.height = null;
        },

        //------------------set Edit Product Data---------------------------------
        setEditProductData: (state, action) => {
            state.createdByUid = action?.payload?.createdByUid;
            state.productName = action?.payload?.name;
            state.productSKUId = action?.payload?.productSkuId;
            state.productDescription = action?.payload?.description;
            state.clothingCategory = action?.payload?.clothingCategoryUid;
            state.fashionCategory = [];
            state.productPrice = action?.payload?.productPrice;
            state.storeLink = action?.payload?.productStoreLink;
            state.postDescription = action?.payload;
            state.selectedImages = action?.payload?.images;
            state.isSetImage = false;
            state.productImages = [];
            state.isProductImages = false;
        },
        setProductImages: (state, action) => {
            state.productImages = action?.payload;
            state.isProductImages = true;
        },
        setProductImagesStateClear: (state, action) => {
            state.productImages = null;
            state.isProductImages = false;
        },
    }
});

export const {
    addProduct,
    addProductSuccess,
    addProductFailed,
    productStateClear,
    setProductName,
    setProductNameClear,
    setProductSKUId,
    setProductSKUIdClear,
    setProductDescription,
    setProductDescriptionClear,
    setClothingCategory,
    setClothingCategoryClear,
    setFashionCategory,
    setFashionCategoryClear,
    setGenderPreference,
    setGenderPreferenceClear,
    setProductPrice,
    setProductPriceClear,
    setStoreLink,
    setStoreLinkClear,
    setPostDescription,
    setPostDescriptionClear,
    setSelectedImages,
    setSelectedImagesStateClear,
    setEditProductData,
    setProductStatus,
    setProductStatusStateClear,
    setProductImages,
    setProductImagesStateClear,
    setModalOpen,
    setModalClose,

    addProductDraft,
    addProductDraftSuccess,
    addProductDraftFailed,
    setPostChecked,

} = AddProductSlice.actions;

export default AddProductSlice.reducer;
