import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Chip, FormControlLabel, IconButton, Stack, Switch, Typography } from '@mui/material';
import editIcon from '../../../assets/images/Icons/editIcon.svg';
import deleteIcon from '../../../assets/images/Icons/deleteIcon.svg';
import restoreIcon from '../../../assets/images/Icons/restoreIcon.svg';
import { Link } from 'react-router-dom';
import {
    createProductTableData,
    getComparator,
    ProductTableHeadCell,
    stableSort
} from "../../../helpers/utility/TableContents";
import EnhancedTableHead from "../../../assets/global/TableHeader/EnhancedTableHead";
import { useDispatch, useSelector } from "react-redux";
import {
    productList,
    setProductVisibility,
    singleDeleteProductData, singleDeleteProductStateClear,
    singleRestoreProductData,
    singleTrashProductData, singleTrashProductStateClear
} from "../../../state/features/Products/ProductList/ProductListSlice";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import SingleTrashModal from "../../../assets/global/Modal/SingleTrashModal";
import {
    MOVE__TO__RESTORE,
    MOVE__TO__TRASH,
    STATUS__ACTIVE, STATUS__APPROVED,
    STATUS__BLOCKED,
    STATUS__DRAFT,
    STATUS__PENDING,
    STATUS__REJECTED,
    STATUS__TRASHED
} from "../../../helpers/constant/coreConstant";
import SingleDeleteModal from "../../../assets/global/Modal/SingleDeleteModal";
import SingleRestoreModal from "../../../assets/global/Modal/SingleRestoreModal";
import { selectProductList } from "../../../state/features/Products/ProductList/ProductListSelector";
import { selectProfile } from "../../../state/features/Profile/profileSelector";
import { singleRestoreTeamMemberStateClear } from "../../../state/features/TeamMember/teamMemberSlice";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function MyProductsTable({
    selected,
    setSelected,
    setTrashedSelected,
    trashedSelected,
    category,
    searchText
}) {
    const dispatch = useDispatch();
    const { products, singleTrashProduct, singleDeleteProduct, singleRestoreProduct } = useSelector(selectProductList);
    const { myProfile } = useSelector(selectProfile);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('uid');
    const [page, setPage] = useState(0);
    const [trashModal, setTrashModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);
    const [singleProductId, setSingleProductId] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const rows =
        products?.data?.map((items, index) => (
            createProductTableData(items?.uid, items?.name, items?.productSkuId, Number(items?.productPrice),
                items?.total_save, items?.is_visible, items?.status, '', items?.brand?.name)
        ));
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n?.uid);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleSelectOnlyTrash = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n?.uid);
            setTrashedSelected(newSelected);
            return;
        }
        setTrashedSelected([]);
    };
    const handleChangePage = (event, newPage) => {
        dispatch(productList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
            page: (newPage + 1),
            per_page: products?.perPage
        }));
    };
    const handleChangeRowsPerPage = event => {
        let row = parseInt(event.target.value, 10);
        dispatch(productList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
            page: products?.page,
            per_page: row
        }));
    };
    const isSelected = uid => selected.indexOf(uid) !== -1;
    const isTrashedSelected = id => trashedSelected.indexOf(id) !== -1;

    // Avoid a Layout jump when reaching the last page with empty rows.
    const emptyRows = (products?.page - 1) > 0 ? Math.max(0, (1 + (products?.page - 1)) * rowsPerPage - rows?.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, (products?.page - 1), products?.perPage]
    );
    const handleTrashSelected = (event, id) => {
        if (selected?.length > 0) {
            setSelected([]);
        }
        const selectedIndex = trashedSelected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(trashedSelected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(trashedSelected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(trashedSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                trashedSelected.slice(0, selectedIndex),
                trashedSelected.slice(selectedIndex + 1)
            );
        }
        setTrashedSelected(newSelected);
    };

    const handleCategorySelected = (event, id) => {
        if (trashedSelected?.length > 0) {
            setTrashedSelected([]);
        }
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const onSingleTrashModalOpen = (id) => {
        if (id) {
            setTrashModal(true);
            setSingleProductId(id);
        }
    }
    const onSingleProductTrashed = () => {
        if (singleProductId) {
            dispatch(singleTrashProductData({
                uid: singleProductId,
                inTrash: MOVE__TO__TRASH,
                category: category,
            }));
        }
    }

    useEffect(() => {
        if (singleTrashProduct?.isTrashedProduct) {
            setTrashModal(false);
            dispatch(singleTrashProductStateClear())
        }
    }, [singleTrashProduct?.isTrashedProduct]);

    const onSingleDeleteModalOpen = (id) => {
        if (id) {
            setDeleteModal(true);
            setSingleProductId(id);
        }
    }
    const onSingleProductDelete = () => {
        if (singleProductId) {
            dispatch(singleDeleteProductData({
                uid: singleProductId,
                category: category,
            }));

            setSingleProductId(null);
        }
    }

    useEffect(() => {
        if (singleDeleteProduct?.isSingleProductDelete) {
            setDeleteModal(false);
            dispatch(singleDeleteProductStateClear())
        }
    }, [singleDeleteProduct?.isSingleProductDelete]);

    const onSingleRestoreModalOpen = (id) => {
        if (id) {
            setRestoreModal(true);
            setSingleProductId(id);
        }
    }
    const onSingleProductRestore = () => {
        if (singleProductId) {
            dispatch(singleRestoreProductData({
                uid: singleProductId,
                inTrash: MOVE__TO__RESTORE,
                category: category,
            }));
            setSingleProductId(null);
        }
    }
    useEffect(() => {
        if (singleRestoreProduct?.isSingleRestoreProduct) {
            setRestoreModal(false);
            dispatch(singleRestoreTeamMemberStateClear())
        }
    }, [singleRestoreProduct?.isSingleRestoreProduct]);

    const handleVisibility = (e, id) => {
        const value = e.target?.checked;
        let visibility = null;
        if (value) {
            visibility = 1;
        } else {
            visibility = 0;
        }
        if (id) {
            dispatch(setProductVisibility({
                uid: id,
                isVisibility: visibility,
                searchInputText: searchText ? searchText : '',
                status: category,
                page: products?.page,
                page_page: products?.perPage,

            }));
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        tableHeader={ProductTableHeadCell}
                        numSelected={selected.length || trashedSelected?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={category === STATUS__TRASHED ? handleSelectOnlyTrash : handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={visibleRows?.length}
                    />
                    <TableBody>
                        {visibleRows?.map((row, index) => {
                            const isItemSelected = isSelected(row?.uid);
                            const isTrashSelected = isTrashedSelected(row?.uid);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected || isTrashSelected}
                                    tabIndex={-1}
                                    key={row?.name}
                                    selected={isItemSelected || isTrashSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        {
                                            category === STATUS__TRASHED ? (
                                                <Checkbox
                                                    color="primary"
                                                    onClick={event => handleTrashSelected(event, row.uid)}
                                                    checked={isTrashSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            ) : (
                                                <Checkbox
                                                    color="primary"
                                                    onClick={event => handleCategorySelected(event, row.uid)}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell component="th" id={labelId} scope="row" padding="none">
                                        <Stack>
                                            <Typography variant="body2medium">{row?.name}</Typography>
                                            {
                                                (!myProfile?.data?.isTeamMember || myProfile?.data?.role === 'Admin') && (
                                                    <Typography variant="alertDescription" color={'text.secondary'}>
                                                        {row?.createdBy && `Created By: ${row?.createdBy}`}
                                                    </Typography>
                                                )
                                            }
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="center">{row?.ID}</TableCell>
                                    <TableCell align="center">${row?.price}</TableCell>
                                    <TableCell align="center">{row?.totalSaves}</TableCell>
                                    <TableCell align="center">
                                        <FormControlLabel
                                            control={<Switch
                                                disabled={category === STATUS__TRASHED}
                                                defaultChecked={row.visibility}
                                                onChange={(e) => handleVisibility(e, row?.uid)}

                                            />} />
                                    </TableCell>
                                    <TableCell align="center">
                                        {row?.status === STATUS__PENDING &&
                                            <Chip label={'PENDING'} color="warning" />
                                        }
                                        {row?.status === STATUS__APPROVED &&
                                            <Chip label={'APPROVED'} color="success" />
                                        }
                                        {row?.status === STATUS__BLOCKED &&
                                            <Chip label={'BLOCKED'} color="error" />
                                        }
                                        {row?.status === STATUS__REJECTED &&
                                            <Chip label={'REJECTED'} color="error" />
                                        }
                                        {row?.status === STATUS__TRASHED &&
                                            <Chip label={'TRASHED'} color="error" />
                                        }
                                        {row?.status === STATUS__DRAFT &&
                                            <Chip label={'DRAFT'} color="warning" />
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            display={'inline-flex'}
                                            direction={'row'}
                                            spacing={1}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            {/*<IconButton size="small" aria-label="delete">*/}
                                            {/*    <img src={viewIcon} alt=""/>*/}
                                            {/*</IconButton>*/}
                                            {
                                                category === STATUS__TRASHED ? (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="restore"
                                                        onClick={() => onSingleRestoreModalOpen(row?.uid)}
                                                    >
                                                        <img src={restoreIcon} alt="" />
                                                    </IconButton>
                                                ) : (
                                                    <Link to={`/editProduct/${row?.uid}`}
                                                        style={{ textDecoration: 'none' }}>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="edit"
                                                        >
                                                            <img src={editIcon} alt="" />
                                                        </IconButton>
                                                    </Link>
                                                )
                                            }
                                            {
                                                category === STATUS__TRASHED ? (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={() => onSingleDeleteModalOpen(row?.uid)}
                                                    >
                                                        <img src={deleteIcon} alt="" />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={() => onSingleTrashModalOpen(row?.uid)}
                                                    >
                                                        <img src={deleteIcon} alt="" />
                                                    </IconButton>
                                                )
                                            }
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={products?.total}
                rowsPerPage={products?.perPage}
                page={(products?.page - 1)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <BasicWithHeaderModal width={460} open={trashModal} header={false}>
                <SingleTrashModal
                    isLoading={singleTrashProduct?.isLoading}
                    onSingleTrash={onSingleProductTrashed}
                    handleModalClose={() => setTrashModal(false)}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={deleteModal} header={false}>
                <SingleDeleteModal
                    isLoading={singleDeleteProduct?.isLoading}
                    onSingleDelete={onSingleProductDelete}
                    handleModalClose={() => setDeleteModal(false)}
                />
            </BasicWithHeaderModal>

            <BasicWithHeaderModal width={460} open={restoreModal} header={false}>
                <SingleRestoreModal
                    isLoading={singleRestoreProduct?.isLoading}
                    onSingleRestore={onSingleProductRestore}
                    handleModalClose={() => setRestoreModal(false)}
                />
            </BasicWithHeaderModal>
        </Box>
    );
}
