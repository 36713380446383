import {differenceInDays, format} from "date-fns";

export const getFormatFromAndToDate = (startDate, endDate) => {
    const fromDate = format(startDate, "yyyy-MM-dd");
    const toDate = format(endDate, "yyyy-MM-dd");
    const difference = differenceInDays(endDate, startDate);
    const durationType = getDurationType(difference);

    return { fromDate, toDate, difference, durationType };
};

export const getDurationType = (days = 1) => {
    let durationType = "month";
    if (days <= 7) {
        durationType = "week";
    } else if (days <= 30) {
        durationType = "month";
    } else if (days > 30) {
        durationType = "year";
    }

    return durationType;
};

export const getFormatDataPickerDate = (startDate, endDate) => {
    const fromDate = format(startDate, "MMM dd, yyyy");
    const toDate = format(endDate, "MMM dd, yyyy");

    return [fromDate, toDate];
};