import { all, put } from 'redux-saga/effects';
import { call, debounce, delay, takeEvery } from '@redux-saga/core/effects';
import {
    addPromotionFailed,
    addPromotionSuccess, changePromotionRewardFailed, changePromotionRewardSuccess,
    changePromotionVisibility,
    changePromotionVisibilityFailed,
    changePromotionVisibilitySuccess,
    changeStatusInfoFailed,
    changeStatusInfoSuccess,
    editPromotionFailed,
    editPromotionSuccess, getMoreProductListFailed, getMoreProductListSuccess,
    getProductListFailed,
    getProductListSuccess, getPromotionDetailsList,
    getPromotionDetailsListFailed,
    getPromotionDetailsListSuccess,
    getPromotionList,
    getPromotionListFailed,
    getPromotionListSuccess,
    multipleDeletePromotionFailed,
    multipleDeletePromotionSuccess,
    multipleRestorePromotionFailed,
    multipleRestorePromotionSuccess,
    multipleTrashPromotionFailed,
    multipleTrashPromotionSuccess,
    singleDeletePromotionFailed,
    singleDeletePromotionSuccess,
    singleRestorePromotionFailed,
    singleRestorePromotionSuccess,
    singleTrashPromotionFailed,
    singleTrashPromotionSuccess,
    updatePromotionFailed,
    updatePromotionSuccess,
} from "./promotionSlice";
import { showErrorMessage, showSuccessMessage } from "../../../helpers/utility/Toastify";
import PromotionService from "../../../services/Promotion.service";
import { STATUS__TRASHED } from "../../../helpers/constant/coreConstant";

function* promotionWatcher() {
    yield takeEvery('promotion/addPromotionDataForSave', addPromotionData);
    yield takeEvery('promotion/addPromotionDataForRequest', addPromotionData);
    yield debounce(500, 'promotion/getPromotionList', getPromotionListSaga);
    yield takeEvery('promotion/editPromotionData', editPromotionSaga);
    yield takeEvery('promotion/updatePromotionData', updatePromotionSaga);
    yield takeEvery('promotion/singleTrashPromotionData', singleTrashPromotionSaga);
    yield takeEvery('promotion/singleDeletePromotionData', singleDeletePromotionSaga);
    yield takeEvery('promotion/singleRestorePromotionData', singleRestorePromotionSaga);
    yield takeEvery('promotion/multipleRestorePromotionData', multipleRestorePromotionSaga);
    yield takeEvery('promotion/multipleDeletedPromotion', multipleDeletedPromotionSaga);
    yield takeEvery('promotion/multipleTrashedPromotion', multipleTrashedPromotionSaga);
    yield takeEvery('promotion/getPromotionDetailsList', getPromotionDetailsListSaga);
    yield takeEvery('promotion/changePromotionReward', changePromotionRewardSaga);
    yield takeEvery('promotion/getProductList', getProductListSaga);
    yield takeEvery('promotion/getMoreProductList', getMoreProductListSaga);
    yield takeEvery('promotion/changeStatusInfo', changeStatusInfoSaga);
}

function* addPromotionData(action) {
    try {
        const response = yield call(PromotionService.addPromotion, action.payload);

        if (response?.success) {
            yield put(addPromotionSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            if (response?.message?.name) {
                showErrorMessage(response?.message?.name[0]);
            } else if (response?.message?.description) {
                showErrorMessage(response?.message?.description[0]);
            } else {
                showErrorMessage(response?.message);
            }
            yield put(addPromotionFailed(response?.message));
        }
    } catch (err) {
        yield put(addPromotionFailed(err.message));
    }
}

function* getPromotionListSaga(action) {
    try {

        const response = yield call(PromotionService.promotionList, action.payload);
        if (response?.success) {
            yield put(getPromotionListSuccess(response?.data));
        } else {
            yield put(getPromotionListFailed(response?.message));
        }
    } catch (err) {
        yield put(getPromotionListFailed(err.message));
    }
}

function* editPromotionSaga(action) {
    try {
        yield delay(1000);
        const response = yield call(PromotionService.editPromotion, action.payload);

        if (response?.success) {
            yield put(editPromotionSuccess(response?.data));
            // showSuccessMessage(response?.message);
        } else {
            yield put(editPromotionFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(editPromotionFailed(err.message));
    }
}

function* updatePromotionSaga(action) {
    try {
        const response = yield call(PromotionService.updatePromotion, action.payload);

        if (response?.success) {
            yield put(updatePromotionSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(updatePromotionFailed(response?.data));
            if (response?.message?.name) {
                showErrorMessage(response?.message?.name[0]);
            } else if (response?.message?.description) {
                showErrorMessage(response?.message?.description[0]);
            } else {
                showErrorMessage(response?.message);
            }
            yield put(updatePromotionFailed(response?.message));
        }
    } catch (err) {
        yield put(updatePromotionFailed(err.message));
    }
}


function* singleTrashPromotionSaga(action) {
    try {
        const response = yield call(PromotionService.singleTrashPromotion, action.payload);

        if (response?.success) {
            yield put(singleTrashPromotionSuccess(response?.data));
            yield put(getPromotionList({
                status: action.payload?.category,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(singleTrashPromotionFailed(response?.data));
        }
    } catch (err) {
        yield put(singleTrashPromotionFailed(err.message));
    }
}

function* singleRestorePromotionSaga(action) {
    try {
        const response = yield call(PromotionService.singleRestorePromotion, action.payload);

        if (response?.success) {
            yield put(singleRestorePromotionSuccess(response?.data));
            yield put(getPromotionList({
                status: action.payload?.category,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(singleRestorePromotionFailed(response?.message));
        }
    } catch (err) {
        yield put(singleRestorePromotionFailed(err.message));
    }
}

function* multipleTrashedPromotionSaga(action) {
    try {
        const response = yield call(PromotionService.multipleTrashPromotion, action.payload);
        console.log('Hello SAGA: ', action.payload)
        if (response?.success) {
            yield put(multipleTrashPromotionSuccess(response?.data));
            yield put(getPromotionList({
                status: action.payload?.category,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleTrashPromotionFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleTrashPromotionFailed(err.message));
    }
}

function* singleDeletePromotionSaga(action) {
    try {
        const response = yield call(PromotionService.singleDeletePromotion, action.payload);

        if (response?.success) {
            yield put(singleDeletePromotionSuccess(response?.data));
            yield put(getPromotionList({
                status: STATUS__TRASHED,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            if (response?.message?.promotionUid) {
                showErrorMessage(response?.message?.promotionUid[0]);
            }
            yield put(singleDeletePromotionFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(singleDeletePromotionFailed(err.message));
    }
}

function* multipleDeletedPromotionSaga(action) {
    try {
        const response = yield call(PromotionService.multipleDeletePromotion, action.payload);

        if (response?.success) {
            yield put(multipleDeletePromotionSuccess(response?.data));
            yield put(getPromotionList({
                status: STATUS__TRASHED,
                searchInputText: action.payload?.searchInputText
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleDeletePromotionFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleDeletePromotionFailed(err.message));
    }
}

function* multipleRestorePromotionSaga(action) {
    try {
        const response = yield call(PromotionService.multipleTrashPromotion, action.payload);

        if (response?.success) {
            yield put(multipleRestorePromotionSuccess(response?.data));
            yield put(getPromotionList({
                status: action.payload?.status,
                searchInputText: action.payload?.searchInputText,
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleRestorePromotionFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleRestorePromotionFailed(err.message));
    }
}
function* getPromotionDetailsListSaga(action) {
    try {
        const response = yield call(PromotionService.promotionDetails, action.payload);

        if (response?.success) {
            yield put(getPromotionDetailsListSuccess(response));
        } else {
            yield put(getPromotionDetailsListFailed(response?.message));
        }
    } catch (err) {
        yield put(getPromotionDetailsListFailed(err.message));
    }
}
function* changePromotionRewardSaga(action) {
    try {
        const response = yield call(PromotionService.declareRewards, action.payload);

        if (response?.success) {
            yield put(changePromotionRewardSuccess(response?.data));
            yield put(getPromotionDetailsList());
            showSuccessMessage(response?.message);
        } else {
            yield put(changePromotionRewardFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(changePromotionRewardFailed(err.message));
    }
}
function* getProductListSaga(action) {
    try {
        const response = yield call(PromotionService.productList, action.payload);

        if (response?.success) {
            yield put(getProductListSuccess(response?.data));
            // showSuccessMessage(response?.message);
        } else {
            yield put(getProductListFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getProductListFailed(err.message));
    }
}
function* getMoreProductListSaga(action) {
    try {
        const response = yield call(PromotionService.productList, action.payload);

        if (response?.success) {
            yield put(getMoreProductListSuccess(response?.data));
            // showSuccessMessage(response?.message);
        } else {
            yield put(getMoreProductListFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(getProductListFailed(err.message));
    }
}

function* changeStatusInfoSaga(action) {
    try {
        const response = yield call(PromotionService.changeStatusInfo, action.payload);
        console.log('status SAGA = ', action)
        if (response?.success) {
            yield put(changeStatusInfoSuccess(response?.data));
            yield put(getPromotionList({
                status: action.payload?.category,
                searchInputText: action.payload?.searchInputText,
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(changeStatusInfoFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(changeStatusInfoFailed(err.message));
    }
}

export default function* promotionSaga() {
    yield all([promotionWatcher()]);
}
