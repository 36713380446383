import {createSlice} from '@reduxjs/toolkit';
import {ONBOARD_STEP} from "../../../helpers/constant/onboardConstant";



const initialState = {
    onboardedData: [],
    currentOnboardStep: ONBOARD_STEP.ONE,
    journeyModeOn: false,
    isLoading: false,
    isError: false,
    error: '',
    fashionCategories: {
        categories: [],
        isLoading: false,
        isError: false,
        error: '',
    },
    genders: {
        gender: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    profilePicture: {
        image: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    brandDescription: {
        description: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    storeLink: {
        link: null,
        isLoading: false,
        isError: false,
        error: '',
    },
};

const OnboardedStepsSlice = createSlice({
    name: 'Onboarded',
    initialState: initialState,
    reducers: {
        getOnboardData: (state) => {
            state.isLoading = true;
        },
        getOnboardDataSuccess: (state, action) => {
            state.isLoading = false;
            state.currentOnboardStep = action?.payload?.stageNo;
            state.journeyModeOn = action?.payload?.journey_mode_on;
        },
        getOnboardDataFailed: (state) => {
            state.isLoading = false;
        },

        onboarding: (state, action) => {
            state.isLoading = true;
        },
        onboardedSuccess: (state, action) => {
            state.onboardedData = action?.payload;
            state.currentOnboardStep = action.payload.stageNo;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        onboardedFailed: (state, action) => {
            state.onboardedData = null;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        onboardedClearState: (state, action) => {
            state.onboardedData = [];
            state.currentOnboardStep = ONBOARD_STEP.ONE;
            state.isLoading = false;
            state.isError = true;
            state.error = '';
        },

        setFashionCategories: (state, action) => {
            state.fashionCategories.isLoading = true;
        },
        setFashionCategoriesSuccess: (state, action) => {
            state.onboardingSteps = action?.payload?.onboardingSteps;
            // state.categories = action?.payload;
            state.fashionCategories.isLoading = false;
            state.fashionCategories.isError = false;
        },
        setFashionCategoriesFailed: (state, action) => {
            // state.categories = null;
            state.fashionCategories.isLoading = false;
            state.fashionCategories.isError = false;
            state.fashionCategories.error = action?.payload?.message;
        },
        setFashionCategoriesStateClear: (state, action) => {
            // state.categories = null;
            state.fashionCategories.isLoading = false;
            state.fashionCategories.isError = false;
            state.fashionCategories.error = '';
        },

        setGenderPreferences: (state, action) => {
            state.genders.isLoading = true;
        },
        setGenderPreferencesSuccess: (state, action) => {
            state.onboardingSteps = action?.payload?.onboardingSteps;
            state.genders.gender = action?.payload;
            state.genders.isLoading = false;
            state.genders.isError = false;
        },
        setGenderPreferencesFailed: (state, action) => {
            state.genders.gender = null;
            state.genders.isLoading = false;
            state.genders.isError = false;
            state.genders.error = action?.payload?.message;
        },
        setGenderPreferencesStateClear: (state, action) => {
            state.genders.gender = null;
            state.genders.isLoading = false;
            state.genders.isError = false;
        },

        setProfilePicture: (state, action) => {
            state.profilePicture.isLoading = true;
        },
        setProfilePictureSuccess: (state, action) => {
            state.onboardingSteps = action?.payload?.onboardingSteps;
            state.profilePicture.image = action?.payload;
            state.profilePicture.isLoading = false;
            state.profilePicture.isError = false;
        },
        setProfilePictureFailed: (state, action) => {
            state.profilePicture.image = null;
            state.profilePicture.isLoading = false;
            state.profilePicture.isError = false;
            state.profilePicture.error = action?.payload?.message;
        },
        setProfilePictureStateClear: (state, action) => {
            state.profilePicture.image = null;
            state.profilePicture.isLoading = false;
            state.profilePicture.isError = false;
            state.profilePicture.error = '';
        },

        setBrandDescription: (state, action) => {
            state.brandDescription.isLoading = true;
        },
        setBrandDescriptionSuccess: (state, action) => {
            state.onboardingSteps = action?.payload?.onboardingSteps;
            state.brandDescription.description = action?.payload;
            state.brandDescription.isLoading = false;
            state.brandDescription.isError = false;
        },
        setBrandDescriptionFailed: (state, action) => {
            state.brandDescription.description = null;
            state.brandDescription.isLoading = false;
            state.brandDescription.isError = false;
            state.brandDescription.error = action?.payload?.message;
        },
        setBrandDescriptionStateClear: (state, action) => {
            state.brandDescription.description = null;
            state.brandDescription.isLoading = false;
            state.brandDescription.isError = false;
            state.brandDescription.error = '';
        },

        setCurrentOnboard: (state, action) => {
            state.currentOnboardStep = action.payload
        },

        getFashionCategoriesData: (state) => {
            state.isLoading = true;
        },
        getFashionCategoriesDataSuccess: (state, action) => {
            state.isLoading = false;
            state.fashionCategories.categories = action.payload
        },
        getFashionCategoriesDataFailed: (state) => {
            state.isLoading = false;
        },

        updateFashionCategories: (state, action) => {
            state.fashionCategories.isLoading = true;
        },
        updateFashionCategoriesSuccess: (state, action) => {
            state.fashionCategories.isLoading = false;
            state.fashionCategories.isError = false;
        },
        updateFashionCategoriesFailed: (state, action) => {
            state.fashionCategories.isLoading = false;
            state.fashionCategories.isError = true;
            state.fashionCategories.error = action?.payload?.message;
        },

        updateGenderPreference: (state, action) => {
            state.genders.isLoading = true;
        },
        updateGenderPreferenceSuccess: (state, action) => {
            state.genders.isLoading = false;
            state.genders.isError = false;
        },
        updateGenderPreferenceFailed: (state, action) => {
            state.genders.isLoading = false;
            state.genders.isError = true;
            state.genders.error = action?.payload?.message;
        },

        updateProfileLogo: (state, action) => {
            state.profilePicture.isLoading = true;
        },
        updateProfileLogoSuccess: (state, action) => {
            state.profilePicture.isLoading = false;
            state.profilePicture.isError = false;
        },
        updateProfileLogoFailed: (state, action) => {
            state.profilePicture.isLoading = false;
            state.profilePicture.isError = true;
            state.profilePicture.error = action?.payload?.message;
        },

        updateBrandDescription: (state, action) => {
            state.brandDescription.isLoading = true;
        },
        updateBrandDescriptionSuccess: (state, action) => {
            state.brandDescription.isLoading = false;
            state.brandDescription.isError = false;
        },
        updateBrandDescriptionFailed: (state, action) => {
            state.brandDescription.isLoading = false;
            state.brandDescription.isError = true;
            state.brandDescription.error = action?.payload?.message;
        },

        updateBrandStoreLink: (state, action) => {
            state.storeLink.isLoading = true;
        },
        updateBrandStoreLinkSuccess: (state, action) => {
            state.storeLink.isLoading = false;
            state.storeLink.isError = false;
        },
        updateBrandStoreLinkFailed: (state, action) => {
            state.storeLink.isLoading = false;
            state.storeLink.isError = true;
            state.storeLink.error = action?.payload?.message;
        },

        getOnboardingData: (state, action) => {
            state.journeyModeOn = action.payload?.journey_mode_on;
            state.currentOnboardStep = action.payload?.stage_no;
            state.fashionCategories.categories = action.payload?.categories_id;
            state.genders.gender = action.payload?.gender_preferences;
            state.profilePicture.image = action.payload?.profile_logo;
            state.brandDescription.description = action.payload?.description;
            state.storeLink.link = action.payload?.store_link;
        },
        getOnboardingDataStateClear: (state, action) => {
            state.journeyModeOn = "";
            state.currentOnboardStep = "";
            state.fashionCategories.categories = "";
            state.genders.gender = "";
            state.profilePicture.image = "";
            state.brandDescription.description = "";
            state.storeLink.link = "";
        }

    }
});

export const {
    getOnboardData,
    getOnboardDataSuccess,
    getOnboardDataFailed,
    onboarding,
    onboardedSuccess,
    onboardedFailed,
    onboardedClearState,

    setFashionCategories,
    setFashionCategoriesSuccess,
    setFashionCategoriesFailed,
    setFashionCategoriesStateClear,

    setGenderPreferences,
    setGenderPreferencesSuccess,
    setGenderPreferencesFailed,
    setGenderPreferencesStateClear,

    setProfilePicture,
    setProfilePictureSuccess,
    setProfilePictureFailed,
    setProfilePictureStateClear,

    setBrandDescription,
    setBrandDescriptionSuccess,
    setBrandDescriptionFailed,
    setBrandDescriptionStateClear,


    getFashionCategoriesData,
    getFashionCategoriesDataSuccess,
    getFashionCategoriesDataFailed,

    updateFashionCategories,
    updateFashionCategoriesSuccess,
    updateFashionCategoriesFailed,

    updateGenderPreference,
    updateGenderPreferenceSuccess,
    updateGenderPreferenceFailed,

    updateProfileLogo,
    updateProfileLogoSuccess,
    updateProfileLogoFailed,

    updateBrandDescription,
    updateBrandDescriptionSuccess,
    updateBrandDescriptionFailed,

    updateBrandStoreLink,
    updateBrandStoreLinkSuccess,
    updateBrandStoreLinkFailed,

    getOnboardingData,
    getOnboardingDataStateClear,

} = OnboardedStepsSlice.actions;

export default OnboardedStepsSlice.reducer;
