import React, {useState} from 'react';
import Box from '@mui/material/Box';
import logoDark from '../../../assets/images/LogoDarkText.svg';
import SidebarItems from '../Common/SidebarItems';

const AppSidebar = ({open}) => {
    const [selectedSubMenu, setSelectedSubMenu] = useState('');

    const handleClickMenu = menuItem => {
        if (menuItem.subMenu) {
            if (selectedSubMenu === menuItem.name) {
                setSelectedSubMenu('');
            } else {
                setSelectedSubMenu(menuItem.name);
            }
        }
    };

    return (
        <>
            <Box py={4} px={2.5} minHeight={108} textAlign={'center'} sx={{cursor: 'pointer'}}>
                {open && <img src={logoDark} alt=""/>}
            </Box>

            <SidebarItems open={open}/>

        </>
    );
};

export default AppSidebar;
