import React, {useEffect, useState} from 'react';
import {Box, Checkbox, FormControl, FormHelperText, Stack, TextField, Typography} from '@mui/material';
import {Controller} from "react-hook-form";
import {setPostChecked, setPostDescription} from "../../../state/features/Products/AddProduct/AddProductSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {ProductValidationPattern} from "../../../helpers/constant/validationPattern";

const label = {inputProps: {'aria-label': 'Checkbox demo'}};

const AddProductPostDescription = ({control, setValue, postChecked}) => {
    const dispatch = useDispatch();
    const {postDescription} = useSelector(selectAddProduct);
    const handlePostChecked = (e) => {
        let value = e?.target.checked;
        if (value === true) {
            dispatch(setPostChecked(true));
        } else {
            dispatch(setPostChecked(false));
        }
    }

    useEffect(() => {
        if (postDescription) {
            setValue("postDescription", postDescription || "");
        }
    }, [postDescription]);

    return (
        <Box>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
                <Typography variant="h5">Post about this</Typography>
                <Box>
                    <Checkbox
                        color="primary"
                        onClick={(e) => handlePostChecked(e)}
                        checked={postChecked === true}
                    />
                </Box>
            </Stack>

            <Stack spacing={3} mb={5}>
                <Controller
                    control={control}
                    name="postDescription"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="Post Description"
                                id="outlined-start-adornment"
                                multiline
                                rows={4}
                                fullWidth
                                focused
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    dispatch(setPostDescription(e?.target?.value))
                                }}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    // rules={ProductValidationPattern.productDescription}
                />
            </Stack>
        </Box>
    );
};

export default AddProductPostDescription;
