import { requests } from "./http.service";

class PublicPageService {
    user(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/user/data", body);
    }
    brand(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/brand/data", body);
    }
    collection(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/collection/data", body);
    }
    product(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/product/data", body);
    }
    promotion(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/data", body);
    }
    rewardsPolicies(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/rewards-policies/data", body);
    }

}
export default new PublicPageService();