import React, {useState} from 'react';
import {Box, Stack, TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {onboarding, updateBrandStoreLink} from '../../../state/features/OnboardedSteps/OnboardedStepsSlice';
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import {selectOnboardedSteps} from "../../../state/features/OnboardedSteps/OnboardedStepsSelector";
import {getMyProfileData} from "../../../state/features/Profile/profileSlice";
import {useNavigate} from "react-router-dom";

const WebsiteLink = ({activeStep, setActiveStep, steps, currentOnboardStep, myProfile}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {storeLink} = useSelector(selectOnboardedSteps);
    const [webLink, setWebLink] = useState(myProfile?.data?.storeLink ? myProfile?.data?.storeLink : '');

    const handleNext = async () => {
        dispatch(updateBrandStoreLink({storeLink: webLink}));
        dispatch(onboarding({stageNo: activeStep + 1, journeyModeOn: false}));
        dispatch(getMyProfileData());
        navigate('/completing-onboarding');
    };

    return (
        <Box>
            <Typography variant="h3" sx={{fontSize: '30px'}}>Add your online store website</Typography>
            <Typography variant="body1" color={'text.secondary'} mb={3}>
                So that users can visit your website from the app.
            </Typography>

            <Box mb={6}>
                <TextField
                    label="Your website link"
                    variant="outlined"
                    fullWidth
                    value={webLink}
                    onChange={e => setWebLink(e.target.value)}
                />
            </Box>

            <Stack direction={'row'} spacing={2}>
                <LoadingButton
                    isLoading={storeLink?.isLoading}
                    loadingText={'Continuing...'}
                    variant="contained"
                    size={'medium'}
                    onClick={handleNext}
                >
                    Continue
                </LoadingButton>
            </Stack>
        </Box>
    );
};

export default WebsiteLink;
