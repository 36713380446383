import {
    ADD_BANNER__HIDDEN__KEY,
    ADD_PRODUCT__HIDDEN__KEY, ADD_PROMOTION__HIDDEN__KEY,
    ADD_TEAM_MEMBER__HIDDEN__KEY,
    BANNER__HIDDEN__KEY,
    DASHBOARD__HIDDEN__KEY,
    EDIT_BANNER__HIDDEN__KEY,
    EDIT_PRODUCT__HIDDEN__KEY, EDIT_PROMOTION__HIDDEN__KEY,
    EDIT_TEAM_MEMBER__HIDDEN__KEY,
    FORCE__LOGIN,
    MY_GALLERY__HIDDEN__KEY,
    MY_PRODUCT__HIDDEN__KEY,
    MY_PROFILE__HIDDEN__KEY,
    ONBOARDING__HIDDEN__KEY, PROMOTION_DETAILS__HIDDEN__KEY, PROMOTIONS__HIDDEN__KEY,
    TEAM_MEMBER__HIDDEN__KEY,
    UNAUTHORIZED__HIDDEN__KEY
} from "./coreConstant";

const routeLink = {
    forceLogin: {
        name: "forceLogin",
        to: "/force-login",
        path: "/force-login",
        hidden_key: FORCE__LOGIN,
    },
    onboarding: {
        name: "Onboarding",
        to: "/onboarding",
        path: "/onboarding",
        hidden_key: ONBOARDING__HIDDEN__KEY,
    },
    dashboard: {
        name: "Dashboard",
        to: "/dashboard",
        path: "/dashboard",
        hidden_key: DASHBOARD__HIDDEN__KEY,
    },
    myProfile: {
        name: "My Profile",
        to: "/profileSettings",
        path: "/profileSettings",
        hidden_key: MY_PROFILE__HIDDEN__KEY,
    },
    myProduct: {
        name: "My Product",
        to: "/myProducts",
        path: "/myProducts",
        hidden_key: MY_PRODUCT__HIDDEN__KEY,
    },
    addProduct: {
        name: "Add Product",
        to: "/addNewProduct",
        path: "/addNewProduct",
        hidden_key: ADD_PRODUCT__HIDDEN__KEY,
    },
    editProduct: {
        name: "Add Product",
        to: "/editProduct/:productId",
        path: "/editProduct/:productId",
        hidden_key: EDIT_PRODUCT__HIDDEN__KEY,
    },
    myGallery: {
        name: "My Gallery",
        to: "/myGallery",
        path: "/myGallery",
        hidden_key: MY_GALLERY__HIDDEN__KEY,
    },
    teamMembers: {
        name: "Team Members",
        to: "/teamMembers",
        path: "/teamMembers",
        hidden_key: TEAM_MEMBER__HIDDEN__KEY,
    },
    addTeamMember: {
        name: "Team Members",
        to: "/addNewMember",
        path: "/addNewMember",
        hidden_key: ADD_TEAM_MEMBER__HIDDEN__KEY,
    },
    editTeamMember: {
        name: "Team Members",
        to: "/editMember/:teamMemberId",
        path: "/editMember/:teamMemberId",
        hidden_key: EDIT_TEAM_MEMBER__HIDDEN__KEY,
    },
    banners: {
        name: "Banners",
        to: "/banners",
        path: "/banners",
        hidden_key: BANNER__HIDDEN__KEY,
    },
    addBanner: {
        name: "Banners",
        to: "/addNewBanner",
        path: "/addNewBanner",
        hidden_key: ADD_BANNER__HIDDEN__KEY,
    },
    editBanner: {
        name: "Banners",
        to: "/editBanner/:bannerId",
        path: "/editBanner/:bannerId",
        hidden_key: EDIT_BANNER__HIDDEN__KEY,
    },
    promotions: {
        name: "Promotions",
        to: "/promotions",
        path: "/promotions",
        hidden_key: PROMOTIONS__HIDDEN__KEY,
    },
    addPromotion: {
        name: "Add Promotion",
        to: "/add-promotion",
        path: "/add-promotion",
        hidden_key: ADD_PROMOTION__HIDDEN__KEY,
    },
    editPromotion: {
        name: "Edit Promotion",
        to: "/edit-promotion",
        path: "/edit-promotion",
        hidden_key: EDIT_PROMOTION__HIDDEN__KEY,
    },
    promotionDetails: {
        name: "Promotion Details",
        to: "/promotion-details",
        path: "/promotion-details",
        hidden_key: PROMOTION_DETAILS__HIDDEN__KEY,
    },
    error: {
        name: "Error",
        to: "/*",
        path: "/*",
        hidden_key: UNAUTHORIZED__HIDDEN__KEY,
    },

    // authenticate routes
    login: {
        name: "Login",
        to: "/login",
        path: "/login",
    },
    register: {
        name: "Register",
        to: "/sign-up",
        path: "/sign-up",
    },
    forgetPassword: {
        name: "Forget password",
        to: "/forgot-password",
        path: "/forgot-password",
    },
    resetPassword: {
        name: "Reset password",
        to: "/reset-password",
        path: "/reset-password",
    },
};

export default routeLink;
