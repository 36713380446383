import {Link, List, ListItemButton, ListItemIcon, ListItemText, styled} from '@mui/material';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {appSideBar} from "../../../helpers/utility/AppSideBarHelper";
import {
    BANNER__HIDDEN__KEY,
    DASHBOARD__HIDDEN__KEY,
    MY_GALLERY__HIDDEN__KEY,
    MY_PRODUCT__HIDDEN__KEY, PROMOTIONS__HIDDEN__KEY,
    TEAM_MEMBER__HIDDEN__KEY
} from "../../../helpers/constant/coreConstant";
import {useEffect, useState} from "react";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import {setSelectedImagesStateClear} from "../../../state/features/Products/AddProduct/AddProductSlice";
import routeLink from "../../../helpers/constant/routeLink";
import {selectProfile} from "../../../state/features/Profile/profileSelector";

const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
    color: 'rgb(75, 85, 99)',
    gap: '25px',
    paddingInline: 25,
    svg: {
        fill: 'rgb(156, 163, 175)'
    },

    '&.Mui-selected': {
        // color: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.other.primaryStatesSelected,
        borderRadius: 4
        //   borderLeft: `4px solid ${theme.palette.primary.main}`
    },
    '&.Mui-selected::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '4px',
        borderRadius: 8,
        backgroundColor: theme.palette.primary.main /* Set your desired border color here */
    },

    '&.Mui-selected svg ': {
        fill: theme.palette.primary.main
    },

    '&.Mui-focusVisible': {
        backgroundColor: 'rgb(238, 242, 255) !important'
    },
    '&:hover': {
        backgroundColor: 'rgb(238, 242, 255) !important'
    }
}));

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    minWidth: 'auto'
}));

export default function SidebarItems({open}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {myProfile} = useSelector(selectProfile);
    const {isTeamMember} = myProfile?.data || {};
    const location = useLocation();
    const currentRoute = location.pathname;
    const [selectedMenu, setSelectedMenu] = useState('');

    const handleActiveSideBar = (key) => {
        if (key === DASHBOARD__HIDDEN__KEY) {
            setSelectedMenu(DASHBOARD__HIDDEN__KEY);
        } else if (key === MY_PRODUCT__HIDDEN__KEY) {
            setSelectedMenu(MY_PRODUCT__HIDDEN__KEY);
        } else if (key === MY_GALLERY__HIDDEN__KEY) {
            dispatch(setSelectedImagesStateClear());
            setSelectedMenu(MY_GALLERY__HIDDEN__KEY);
        } else if (key === TEAM_MEMBER__HIDDEN__KEY) {
            setSelectedMenu(TEAM_MEMBER__HIDDEN__KEY);
        } else if (key === BANNER__HIDDEN__KEY) {
            setSelectedMenu(BANNER__HIDDEN__KEY);
        } else if (key === PROMOTIONS__HIDDEN__KEY) {
            setSelectedMenu(PROMOTIONS__HIDDEN__KEY);
        } else {
            showErrorMessage('Undefined');
        }
    }

    useEffect(() => {
        if (currentRoute === '/') {
            navigate('/dashboard');
        } else if (routeLink.myProfile.to === currentRoute) {
            setSelectedMenu(null);
        } else if (routeLink.addProduct.to === currentRoute) {
            setSelectedMenu(MY_PRODUCT__HIDDEN__KEY);
        } else if (routeLink.editProduct.to === currentRoute) {
            setSelectedMenu(MY_PRODUCT__HIDDEN__KEY);
        } else if (routeLink.addTeamMember.to === currentRoute) {
            setSelectedMenu(TEAM_MEMBER__HIDDEN__KEY);
        } else if (routeLink.addTeamMember.to === currentRoute) {
            setSelectedMenu(TEAM_MEMBER__HIDDEN__KEY);
        } else if (routeLink.editTeamMember.to === currentRoute) {
            setSelectedMenu(TEAM_MEMBER__HIDDEN__KEY);
        } else if (routeLink.addBanner.to === currentRoute) {
            setSelectedMenu(BANNER__HIDDEN__KEY);
        } else if (routeLink.editBanner.to === currentRoute) {
            setSelectedMenu(BANNER__HIDDEN__KEY);
        } else if (routeLink.promotions.to === currentRoute) {
            setSelectedMenu(PROMOTIONS__HIDDEN__KEY);
        }
    }, [currentRoute]);

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 288,
                bgcolor: 'background.paper',
                paddingBlock: open ? 0 : 0,
                paddingInline: open ? 2.5 : 0
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {
                appSideBar.map((items) => {
                    if (items?.key === routeLink.teamMembers.hidden_key && isTeamMember) {
                        return null;
                    } else {
                        return (
                            <Link
                                key={items.key}
                                component={RouterLink}
                                to={items.route}
                                underline="none"
                                onClick={() => handleActiveSideBar(items?.key)}
                            >
                                <StyledListItemButton
                                    selected={items?.key === selectedMenu || items.route === currentRoute}
                                >
                                    <StyledListItemIcon>
                                        {((items?.key === selectedMenu) || (items.route === currentRoute)) ? items?.activeIcon : items?.icon}
                                    </StyledListItemIcon>
                                    <ListItemText primary={items.name}/>
                                </StyledListItemButton>
                            </Link>
                        );
                    }
                })
            }
        </List>
    );
}
