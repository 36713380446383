import { requests } from "./http.service";

class PromotionService {
    addPromotion(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/create", body);
    }
    promotionList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/list", body);
    }
    editPromotion(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/get/specific/data", body);
    }
    updatePromotion(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/update", body);
    }
    singleDeletePromotion(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/single/delete", body);
    }
    singleTrashPromotion(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/in-trash/update/status/single", body);
    }
    singleRestorePromotion(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/in-trash/update/status/single", body);
    }
    multipleDeletePromotion(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/bulk/delete", body);
    }
    multipleTrashPromotion(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/in-trash/update/status/bulk", body);
    }
    declareRewards(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/declare/promotion/update", body)
    }
    promotionDetails(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/details/user/counts", body);
    }
    productList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/product/list", body);
    }
    changeStatusInfo(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/promotion/status/update", body);
    }
}
export default new PromotionService();