import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { PublicProfileCard } from '../PublicProfileStyle';
import UserImageGallery from '../UserProfile/UserImageGallery';
import DownloadSection from '../common/DownloadSection';
import HeaderWithLogo from '../common/HeaderWithLogo';
import PublicProfileInfoCard from '../common/PublicProfileInfoCard';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPublicPage } from '../../../state/features/publicPage/publicPageSelector';
import { getCollectionInfo } from '../../../state/features/publicPage/publicPageSlice';
import PublicPageSkeletonLoader from '../common/PublicPageSkeletonLoader';

const Collection = () => {
    const dispatch = useDispatch();
    const { collectionId } = useParams();
    const { collectionInfo } = useSelector(selectPublicPage);

    useEffect(() => {
        dispatch(getCollectionInfo({ uid: collectionId }))
    }, [collectionId])
    return (
        <Stack height={'100vh'} alignItems={'center'} spacing={{ xs: 1, md: 5 }}>
            <HeaderWithLogo />
            {
                collectionInfo.isLoading ? (
                    <PublicPageSkeletonLoader />
                ) : (
                    <PublicProfileCard sx={{ pt: 1.5 }} variant="outlined" elevation={0}>
                        <UserImageGallery images={collectionId?.data?.collectionItemImages} wrapperStyle={{ height: { xs: 300, sm: 450 }, mt: 0, borderRadius: 5 }} />

                        <Stack spacing={2} mt={-4} zIndex={99} position={'relative'}>
                            <PublicProfileInfoCard
                                imageData={collectionId?.data?.profileLogo && collectionId?.data?.profileLogo}
                                title={collectionId?.data?.collectionName && collectionId?.data?.collectionName}
                                subtitle={`Collected by ${collectionId?.data?.collectorName && collectionId?.data?.collectorName}`}
                                label={collectionId?.data?.collectionItemsCount ? collectionId?.data?.collectionItemsCount > 1 ? `${collectionId?.data?.collectionItemsCount} items` : `${collectionId?.data?.collectionItemsCount} item` : '0 item'}
                            />

                            <DownloadSection
                                wrapperStyle={{ mt: 0 }}
                                description={'To view the full collection, download the app'}
                            />
                        </Stack>
                    </PublicProfileCard>
                )
            }
        </Stack>
    );
};

export default Collection;
