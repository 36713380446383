import React from 'react';
import Box from "@mui/material/Box";
import trashIcon from "../../images/Icons/trashIcon.svg";
import {Button, Stack, Typography} from "@mui/material";
import LoadingButton from "../Button/LoadingButton";

const SingleTrashModal = ({handleModalClose, onSingleTrash, isLoading}) => {
    return (
        <>
            <Box p={'64px 40px 48px 40px'} textAlign={'center'}>
                <img src={trashIcon} alt="" width={120} height={150}/>

                <Typography variant="h5" mt={3} mb={1}>
                    Are you sure you want to <br/> trash{' '}
                    this item?
                </Typography>
                <Typography variant="body2" color={'text.secondary'}>
                    Once trashed, you can restore it again.
                </Typography>
            </Box>

            <Stack
                p={'16px'}
                sx={{borderTop: 1, borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={1}
            >
                <Button
                    sx={{color: 'text.primary'}}
                    variant="text"
                    onClick={handleModalClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    isLoading={isLoading}
                    loadingText={'Trashing...'}
                    variant="contained"
                    color="error"
                    onClick={onSingleTrash}
                >
                    Trashed
                </LoadingButton>
            </Stack>
        </>
    );
}
export default SingleTrashModal;
