import {Box, Checkbox, FormControl, FormHelperText, Stack, TextField, Typography} from "@mui/material";
import {Controller} from "react-hook-form";
import {setPostDescription} from "../../../state/features/Products/AddProduct/AddProductSlice";
import {ProductValidationPattern} from "../../../helpers/constant/validationPattern";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";

const EditProductPostDescription = ({control, setValue}) => {
    const dispatch = useDispatch();
    const {postDescription} = useSelector(selectAddProduct);

    useEffect(() => {
        if (postDescription) {
            setValue("postDescription", postDescription || "");
        }
    }, [postDescription]);
    return (
        <Box>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
                <Typography variant="h5">Edit Post</Typography>
            </Stack>

            <Stack spacing={3} mb={5}>
                <Controller
                    control={control}
                    name="postDescription"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="Post Description"
                                id="outlined-start-adornment"
                                multiline
                                rows={4}
                                fullWidth
                                focused
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    dispatch(setPostDescription(e?.target?.value))
                                }}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    // rules={ProductValidationPattern.productDescription}
                />
            </Stack>
        </Box>
    )
}
export default EditProductPostDescription;