import React, {useEffect, useState} from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box, Button, Grid, Stack, Tooltip} from '@mui/material';
import ContentWrapper from '../../../assets/global/wrapper/ContentWrapper/ContentWrapper';
import WrapperFooter from '../../../assets/global/wrapper/ContentWrapper/WrapperFooter';
import WrapperHeader from '../../../assets/global/wrapper/ContentWrapper/WrapperHeader';
import {useNavigate, useParams} from 'react-router-dom';
import AddProductInfo from '../AddProduct/AddProductInfo';
import AddProductCategories from '../AddProduct/AddProductCategories';
import AddProductOtherInfo from '../AddProduct/AddProductOtherInfo';
import AddProductImages from '../AddProduct/AddProductImages';
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {useForm} from "react-hook-form";
import {editProduct} from "../../../state/features/Products/EditProduct/EditProductSlice";
import {STATUS__PENDING} from "../../../helpers/constant/coreConstant";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import {
    updateProductData,
    updateProductStateClear
} from "../../../state/features/Products/ProductList/ProductListSlice";
import {selectProductList} from "../../../state/features/Products/ProductList/ProductListSelector";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import {productStateClear} from "../../../state/features/Products/AddProduct/AddProductSlice";
import {selectEditProduct} from "../../../state/features/Products/EditProduct/EditProductSelector";
import CoreBreadCrumbs from "../../../assets/global/BreadCrumbs/CoreBreadCrumbs";
import {myProductInfo} from "../../../helpers/constant/pageInfo";
import EditProductPostDescription from "./EditProductPostDescription";
import ProductGenderPreference from "../AddProduct/ProductGenderPreference";

const EditProduct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {updateProduct} = useSelector(selectProductList);
    const {editProductData} = useSelector(selectEditProduct);
    const {productId} = useParams();
    const [isUploadImage, setIsUploadImage] = useState(false);
    const [appPreview, setAppPreview] = useState(false);
    const [postChecked, setPostChecked] = useState(false);


    const {
        productName,
        productSKUId,
        productDescription,
        clothingCategory,
        fashionCategory,
        genderPreference,
        productPrice,
        storeLink,
        postDescription,
        selectedImages,
        productImages,
    } = useSelector(selectAddProduct)
    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });
    useEffect(() => {
        if (errors) {
            handleValidate(errors)
        }
    }, [errors]);

    const handleValidate = (err) => {
        if (errors?.productSkuId) {
            showErrorMessage(errors?.productSkuId?.message);
        } else if (errors?.name) {
            showErrorMessage(errors?.name?.message)
        } else if (errors?.clothingCategoryUid) {
            showErrorMessage(errors?.clothingCategoryUid?.message)
        } else if (errors?.fashionCategoryUid) {
            showErrorMessage(errors?.fashionCategoryUid?.message)
        } else if (errors?.productPrice) {
            showErrorMessage(errors?.productPrice?.message)
        }
    }

    useEffect(() => {
        if (editProductData == null || selectedImages?.length === 0) {
            dispatch(editProduct({uid: productId}));
        }
    }, []);

    const onSubmit = async (data) => {
        if (productImages?.length > 0) {
            const payload = {};
            payload.status = STATUS__PENDING;
            payload.name = productName;
            payload.productSkuId = productSKUId;
            payload.description = productDescription;
            payload.clothingCategoryUid = clothingCategory;
            payload.fashionCategoryUid = fashionCategory;
            payload.gender = genderPreference;
            payload.productPrice = productPrice;
            payload.productStoreLink = storeLink;
            payload.postChecked = postChecked;
            payload.postDescription = postDescription;
            payload.productImages = productImages;
            payload.uid = productId;
            dispatch(updateProductData(payload));
        } else {
            showErrorMessage("Add product images!");
        }
    };

    useEffect(() => {
        if (updateProduct?.isProductUpdate) {
            let defaultValues = {};
            defaultValues.productSkuId = "";
            defaultValues.name = "";
            defaultValues.description = "";
            defaultValues.clothingCategoryUid = "";
            defaultValues.fashionCategory = "";
            defaultValues.productPrice = "";
            defaultValues.productStoreLink = "";
            defaultValues.postChecked = "";
            defaultValues.postDescription = "";
            reset({...defaultValues});
            navigate('/myProducts');
            dispatch(productStateClear());
            dispatch(updateProductStateClear());
        }
    }, [updateProduct?.isProductUpdate]);

    const renderEditProduct = () => {
        return (
            <ContentWrapper>
                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <WrapperHeader
                        title="Edit Product"
                        cta={
                            <Tooltip title="Add product info to see 'App Preview'" arrow>
                                <Button
                                    variant="text"
                                    startIcon={<VisibilityIcon/>}
                                    onClick={() => setAppPreview(true)}
                                >
                                    In App Preview Product
                                </Button>
                            </Tooltip>
                        }
                        backButton={() => {
                            navigate('/myProducts');
                            dispatch(productStateClear())
                            dispatch(updateProductStateClear())
                        }}
                        breadCrumb={<CoreBreadCrumbs pageInfo={myProductInfo} keys={[1, 3]}/>}
                    />

                    <Grid
                        container
                        sx={{
                            height: {sm: 'calc(100vh - 267px)', lg: 'auto'},
                            overflowY: 'auto'
                        }}
                    >
                        <Grid item sm={12} lg={3.55}>
                            <Box
                                py={4}
                                px={5}
                                borderRight={{lg: '1px solid'}}
                                borderColor={{lg: 'other.divider'}}
                                sx={{height: {sm: 'auto', lg: 'calc(100vh - 267px)'}, overflowY: 'auto'}}
                            >
                                <AddProductInfo control={control} setValue={setValue}/>

                                <AddProductCategories control={control} setValue={setValue}/>

                                <ProductGenderPreference control={control} setValue={setValue}/>

                                <AddProductOtherInfo control={control} setValue={setValue}/>

                                <EditProductPostDescription control={control} setValue={setValue}/>
                            </Box>
                        </Grid>
                        <Grid item sm={12} lg={8.45}>
                            <Box
                                py={4}
                                px={5}
                                sx={{height: {sm: 'auto', lg: 'calc(100vh - 267px)'}, overflowY: 'auto'}}
                            >
                                <AddProductImages appPreview={appPreview} setAppPreview={setAppPreview}/>
                            </Box>
                        </Grid>
                    </Grid>

                    <WrapperFooter>
                        <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
                            <Button
                                sx={{color: 'text.primary'}}
                                variant="text"
                                onClick={() => {
                                    navigate('/myProducts');
                                    dispatch(productStateClear());
                                    dispatch(updateProductStateClear())
                                }}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                isLoading={updateProduct?.isLoading}
                                loadingText={'Updating...'}
                                variant="contained"
                                type={'submit'}
                                onClick={() => handleValidate(errors)}
                            >
                                Update Product
                            </LoadingButton>
                        </Stack>
                    </WrapperFooter>
                </Box>
            </ContentWrapper>
        );
    };


    const onCloseIsUploadImage = () => {
        setIsUploadImage(false);
    };

    return <Box>{renderEditProduct()}</Box>;
};

export default EditProduct;
