import {requests} from "./http.service";

class DashboardService {
    graphData(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/dashboard/get/graph/data", body);
    }
    dashboardCounts(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/dashboard/get/total/counts", body);
    }
    topProducts(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/dashboard/get/top/saved/product/data", body);
    }
}
export default new DashboardService();