import React, {useEffect, useState} from 'react';
import {Box, Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";
import {GenderPreference} from "../../../helpers/utility/GenderPreferenesLists";
import {useDispatch, useSelector} from "react-redux";
import {selectOnboardedSteps} from "../../../state/features/OnboardedSteps/OnboardedStepsSelector";
import {onboarding, updateGenderPreference} from "../../../state/features/OnboardedSteps/OnboardedStepsSlice";
import LoadingButton from "../../../assets/global/Button/LoadingButton";

const GenderPreferences = ({activeStep, setActiveStep, steps, handleBack, currentOnboardStep, myProfile}) => {
    const dispatch = useDispatch();
    const {genders} = useSelector(selectOnboardedSteps);
    const [genderPreference, setGenderPreference] = useState('');

    useEffect(() => {
        if (myProfile?.data?.genderPreference) {
            setGenderPreference(myProfile?.data?.genderPreference);
        }
    }, [myProfile?.data?.genderPreference]);

    const handleNext = () => {
        if (activeStep < currentOnboardStep) {
            setActiveStep(activeStep + 1);
            dispatch(updateGenderPreference({gender: genderPreference}));
        } else {
            dispatch(updateGenderPreference({gender: genderPreference}));
            dispatch(onboarding({stageNo: activeStep + 1, journeyModeOn: true}));
        }
    };

    const handleGenderPreference = (e, id) => {
        setGenderPreference(id);
    }

    return (
        <Box>
            <Typography variant="h3" mb={3}>
                What are your brand’s <br/>
                Gender preference?
            </Typography>

            <Box my={6}>
                <FormGroup>
                    {
                        GenderPreference?.map((items) => (
                            <FormControlLabel
                                key={items?.id}
                                control={<Checkbox
                                    checked={genderPreference === items?.value}
                                    onClick={(e) => handleGenderPreference(e, items.value)}
                                />}
                                label={items.name}/>
                        ))
                    }
                </FormGroup>
            </Box>

            <LoadingButton
                isLoading={genders?.isLoading}
                loadingText={'Continuing...'}
                variant="contained"
                size={'medium'}
                onClick={handleNext}
                disabled={genderPreference?.length < 1}
            >
                Continue
            </LoadingButton>
        </Box>
    );

}

export default GenderPreferences;