import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Stack} from "@mui/material";
import AddProductInfo from "./AddProductInfo";
import AddProductCategories from "./AddProductCategories";
import AddProductOtherInfo from "./AddProductOtherInfo";
import AddProductPostDescription from "./AddProductPostDescription";
import WrapperFooter from "../../../assets/global/wrapper/ContentWrapper/WrapperFooter";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import AddProductImages from "./AddProductImages";
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {
    addProduct,
    addProductDraft,
    productStateClear
} from "../../../state/features/Products/AddProduct/AddProductSlice";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import {STATUS__DRAFT, STATUS__PENDING} from "../../../helpers/constant/coreConstant";
import ProductGenderPreference from "./ProductGenderPreference";

const AddProductForm = ({ appPreview, setAppPreview}) => {
    const navigate = useNavigate();
    const [isDraft, setIsDraft] = useState('');
    const dispatch = useDispatch();
    const {
        isLoading,
        productAdded,
        productName,
        productSKUId,
        productDescription,
        clothingCategory,
        fashionCategory,
        genderPreference,
        productPrice,
        storeLink,
        postDescription,
        selectedImages,
        productImages,
        draftProduct,
        postChecked,
    } = useSelector(selectAddProduct)

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });

    const onSubmit = async (data) => {
        if (productImages?.length > 0) {
            if (isDraft) {
                if (!genderPreference) {
                    showErrorMessage('Gender preference is required!');
                } else {
                    const payload = {};
                    payload.status = STATUS__DRAFT;
                    payload.name = productName;
                    payload.productSkuId = productSKUId;
                    payload.description = productDescription;
                    payload.clothingCategoryUid = clothingCategory;
                    payload.fashionCategoryUid = fashionCategory;
                    payload.gender = genderPreference;
                    payload.productPrice = productPrice;
                    payload.productStoreLink = storeLink;
                    payload.postChecked = postChecked;
                    payload.postDescription = postDescription;
                    payload.productImages = productImages;
                    dispatch(addProductDraft(payload));
                }
            } else {
                if (!genderPreference) {
                    showErrorMessage('Gender preference is required!');
                } else {
                    const payload = {};
                    payload.status = STATUS__PENDING;
                    payload.name = productName;
                    payload.productSkuId = productSKUId;
                    payload.description = productDescription;
                    payload.clothingCategoryUid = clothingCategory;
                    payload.fashionCategoryUid = fashionCategory;
                    payload.gender = genderPreference;
                    payload.productPrice = productPrice;
                    payload.productStoreLink = storeLink;
                    payload.postChecked = postChecked;
                    payload.postDescription = postDescription;
                    payload.productImages = productImages;
                    dispatch(addProduct(payload));
                }
            }
        } else {
            showErrorMessage("Add product images!");
        }
    };

    const handleCancel = () => {
        dispatch(productStateClear());
        navigate('/myProducts');
    }

    useEffect(() => {
        if (productAdded) {
            let defaultValues = {};
            defaultValues.productSkuId = "";
            defaultValues.name = "";
            defaultValues.description = "";
            defaultValues.clothingCategoryUid = "";
            defaultValues.fashionCategory = "";
            defaultValues.productPrice = "";
            defaultValues.productStoreLink = "";
            defaultValues.postDescription = "";
            reset({...defaultValues});
            navigate('/myProducts');
            dispatch(productStateClear())
        }
    }, [productAdded]);

    useEffect(() => {
        if (errors) {
            handleValidate(errors)
        }
    }, [errors]);

    const handleValidate = (err) => {
        if (errors?.productSkuId) {
            showErrorMessage(errors?.productSkuId?.message);
        } else if (errors?.name) {
            showErrorMessage(errors?.name?.message)
        } else if (errors?.clothingCategoryUid) {
            showErrorMessage(errors?.clothingCategoryUid?.message)
        } else if (errors?.fashionCategoryUid) {
            showErrorMessage(errors?.fashionCategoryUid?.message)
        } else if (errors?.productPrice) {
            showErrorMessage(errors?.productPrice?.message)
        }
    }

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                sx={{
                    height: {sm: 'calc(100vh - 267px)', lg: 'auto'},
                    overflowY: 'auto'
                }}
            >
                <Grid item sm={12} lg={3.55}>
                    <Box
                        py={4}
                        px={5}
                        borderRight={{lg: '1px solid'}}
                        borderColor={{lg: 'other.divider'}}
                        sx={{height: {sm: 'auto', lg: 'calc(100vh - 267px)'}, overflowY: 'auto'}}
                    >
                        <AddProductInfo control={control} setValue={setValue}/>

                        <AddProductCategories control={control} setValue={setValue}/>

                        <ProductGenderPreference control={control} setValue={setValue}/>

                        <AddProductOtherInfo control={control} setValue={setValue}/>

                        <AddProductPostDescription control={control} setValue={setValue} postChecked={postChecked}/>
                    </Box>
                </Grid>
                <Grid item sm={12} lg={8.45}>
                    <Box
                        py={4}
                        px={5}
                        sx={{height: {sm: 'auto', lg: 'calc(100vh - 267px)'}, overflowY: 'auto'}}
                    >
                        <AddProductImages
                            appPreview={appPreview}
                            setAppPreview={setAppPreview}
                        />
                    </Box>
                </Grid>
            </Grid>

            <WrapperFooter>
                <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
                    <Button
                        sx={{color: 'text.primary'}}
                        variant="text"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        isLoading={draftProduct?.isLoading}
                        loadingText={'Drafting...'}
                        variant="outlined"
                        type={'submit'}
                        onClick={() => setIsDraft(true)}
                    >
                        Save as draft
                    </LoadingButton>
                    <LoadingButton
                        isLoading={isLoading}
                        loadingText={'Publishing...'}
                        variant="contained"
                        type={'submit'}
                        onClick={() => handleValidate(errors)}
                    >
                        Publish Product
                    </LoadingButton>
                </Stack>
            </WrapperFooter>
        </Box>
    )
}
export default AddProductForm;
