import {Box, Button, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Stack, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import {UserValidation} from "../../helpers/constant/validationPattern";
import React, {useEffect, useState} from "react";
import {showErrorMessage} from "../../helpers/utility/Toastify";
import {useDispatch, useSelector} from "react-redux";
import {selectProfile} from "../../state/features/Profile/profileSelector";
import {updateMyProfileClearState, updateMyProfileData} from "../../state/features/Profile/profileSlice";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import PhoneIcon from "@mui/icons-material/Phone";

const EditMyProfileForm = ({setMyProfileEditModal}) => {
    const {myProfile, updateMyProfileProfile} = useSelector(selectProfile);
    const dispatch = useDispatch();
    const [genderPreference, setGenderPreference] = useState(null);
    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });

    useEffect(() => {
        if (myProfile?.data) {
            setValue('name', myProfile?.data?.name);
            setValue('phone', myProfile?.data?.phone);
            setGenderPreference(myProfile?.data?.genderPreference);
        }
    }, [myProfile?.data]);
    const onSubmit = data => {
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        // formData.append('genderPreference', genderPreference);

        if (data.name && data.phone) {
            dispatch(updateMyProfileData(formData));
        } else {
            showErrorMessage('Data is missing!');
        }
    };

    useEffect(() => {
        if (updateMyProfileProfile?.isMyProfileUpdate) {
            setMyProfileEditModal(false);
            dispatch(updateMyProfileClearState());
        }
    }, [updateMyProfileProfile?.isMyProfileUpdate]);

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{padding: '20px'}}>
                <Controller
                    control={control}
                    name="name"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl
                            size="small"
                            fullWidth
                            error={!!(invalid && error.message)}
                        >
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Your Name"
                                fullWidth
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={UserValidation.name}
                />
                <Controller
                    control={control}
                    name="phone"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl variant="outlined" fullWidth
                                     error={!!(invalid && error.message)}>
                            <InputLabel htmlFor="outlined-adornment-phone">Your phone</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-phone"
                                type="tel"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PhoneIcon sx={{fontSize: 18}}/> +1
                                    </InputAdornment>
                                }
                                label="Your phone"
                                value={value}
                                onChange={onChange}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={UserValidation?.phone}
                />

                {/*<Box>*/}
                {/*    <Typography variant="body1medium" mb={1}>*/}
                {/*        Gender Preferences*/}
                {/*    </Typography>*/}

                {/*<FormGroup>*/}
                {/*    <Stack direction="row" justifyContent={'space-between'}>*/}
                {/*        {GenderPreference?.map(items => {*/}
                {/*            if (items?.id === 1) {*/}
                {/*                return null;*/}
                {/*            } else {*/}
                {/*                return (*/}
                {/*                    <FormControlLabel*/}
                {/*                        key={items?.value}*/}
                {/*                        sx={{color: false ? 'text.primary' : 'text.secondary'}}*/}
                {/*                        control={*/}
                {/*                            <Checkbox*/}
                {/*                                checked={genderPreference === items?.value}*/}
                {/*                                onClick={() => handleGenderPreference(items?.value)}*/}
                {/*                            />*/}
                {/*                        }*/}
                {/*                        label={items.name}*/}
                {/*                    />*/}
                {/*                );*/}
                {/*            }*/}
                {/*        })}*/}
                {/*    </Stack>*/}
                {/*</FormGroup>*/}
                {/*</Box>*/}
            </Stack>

            <Stack
                p={'16px'}
                sx={{borderTop: 1, borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={2}
            >
                <Button
                    sx={{color: 'text.primary'}}
                    variant="text"
                    onClick={() => setMyProfileEditModal(false)}
                >
                    Cancel
                </Button>
                <LoadingButton
                    isLoading={updateMyProfileProfile?.isLoading}
                    loadingText={'Saving...'}
                    variant="contained"
                    disableElevation
                    type={'submit'}
                >
                    Save Changes
                </LoadingButton>
            </Stack>
        </Box>
    );
}
export default EditMyProfileForm;