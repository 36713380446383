import routeLink from "./routeLink";

export const myProductInfo = {
    title: "My Products",
    breadcrumbs: [
        {
            name: routeLink.myProduct.name,
            path: routeLink.myProduct.to,
            key: 1,
        },
        {
            name: "Add Product",
            path: '/addNewProduct',
            key: 2,
        },
        {
            name: "Edit Product",
            path: "/editProduct",
            key: 3,
        },
    ],
};

export const myGalleryInfo = {
    title: "My Gallery",
    breadcrumbs: [
        {
            name: routeLink.myGallery.name,
            path: routeLink.myGallery.to,
            key: 1,
        },
    ],
};
export const teamMemberInfo = {
    title: "Team Members",
    breadcrumbs: [
        {
            name: routeLink.teamMembers.name,
            path: routeLink.teamMembers.to,
            key: 1,
        },
        {
            name: "Add New Team Member",
            path: '/addNewMember',
            key: 2,
        },
        {
            name: "Edit Team Member",
            path: "/editMember",
            key: 3,
        },
    ],
};
export const bannersInfo = {
    title: "Team Members",
    breadcrumbs: [
        {
            name: routeLink.banners.name,
            path: routeLink.banners.to,
            key: 1,
        },
        {
            name: "Add New Banner",
            path: '/addNewBanner',
            key: 2,
        },
        {
            name: "Edit Banner",
            path: "/editBanner",
            key: 3,
        },
    ],
};

export const promotionInfo = {
    title: "Promotions",
    breadcrumbs: [
        {
            name: routeLink.promotions.name,
            path: routeLink.promotions.to,
            key: 1,
        },
        {
            name: "Add Promotion",
            path: '/add-promotion',
            key: 2,
        },
        {
            name: "Edit Promotion",
            path: "/edit-promotion",
            key: 3,
        },
    ],
};