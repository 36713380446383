import React, {useEffect} from 'react';
import {Box, IconButton, Stack, Typography} from '@mui/material';
import CloseFilledIcon from '../../assets/images/Icons/CloseFilledIcon.svg';
import {useDispatch, useSelector} from 'react-redux';
import {getNotificationList} from '../../state/features/Notification/notificationSlice';
import {selectNotification} from '../../state/features/Notification/notificationSelector';

const Notification = ({handleCloseNotification}) => {
    const dispatch = useDispatch();
    const {notificationList} = useSelector(selectNotification);
    useEffect(() => {
        dispatch(getNotificationList());
    }, []);
    const jsonParseNotificationTitle = (data) => {
        if (!data) return null;
        let result = [];
        const messageObj = JSON.parse(data);
        const message = messageObj?.message;
        const ary = message.split('__');

        const length = ary.length;
        for (let i = 0; i < length; i++) {
            const firstWord = ary[i].split(' ')[0];
            let replaced = null;
            let part = null;
            let content = null;
            const replacements = {
                ':user': messageObj?.user?.name,
                ':product': messageObj?.product?.name,
                ':brand': messageObj?.brand?.name,
                ':collection': messageObj?.collection?.name,
            };
            if (replacements.hasOwnProperty(firstWord)) {
                replaced = replacements[firstWord];
                part = ary[i].replace(firstWord, '');
            } else {
                part = ary[i];
            }
            result.push(
                <Stack direction={'row'} spacing={1}>
                    <Typography component="h3" variant="body1medium" color="error.main">
                        {replaced} {part}
                    </Typography>
                </Stack>
            )
        }
        return (
            <Typography>
                {result}
            </Typography>
        )
    }

    const jsonParseNotificationDate = (data) => {
        if (!data) return null;
        const messageObj = JSON.parse(data);
        const dateTime = new Date(messageObj?.notificationDate);

        const year = dateTime.getFullYear();
        const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
        const day = dateTime.getDate().toString().padStart(2, "0");

        const hours = dateTime.getHours().toString().padStart(2, "0");
        const minutes = dateTime.getMinutes().toString().padStart(2, "0");
        const seconds = dateTime.getSeconds().toString().padStart(2, "0");

        const milliseconds = dateTime.getMilliseconds().toString().padStart(3, "0");

        const formattedDateTime = `Date : ${year}-${month}-${day} | Time: ${hours}:${minutes}`;
        return formattedDateTime;
    }

    const jsonParseImage = (data) => {
        const messageObj = JSON.parse(data);
        if (messageObj.type === 1) {
            return messageObj?.product?.image;
        } else if (messageObj?.type === 2) {
            return messageObj?.collection?.image;
        } else if (messageObj?.type === 3) {
            return messageObj?.brand?.image;
        } else {
            return messageObj?.image;
        }
    }

    return (
        <Box width={650}>
            {/* Header */}
            <Stack
                sx={{borderBottom: '1px solid', borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                padding={'20px !important'}
            >
                <Typography variant="h6">Notifications</Typography>

                <Box>
                    <IconButton aria-label="delete" onClick={() => handleCloseNotification()}>
                        <img src={CloseFilledIcon} alt=""/>
                    </IconButton>
                </Box>
            </Stack>

            {notificationList?.data &&
                Array.isArray(notificationList?.data) &&
                notificationList?.data.map((item, index) => (
                    <Stack
                        key={index}
                        sx={{
                            borderBottom: '1px solid #D3D3D3',
                            borderColor: 'other.divider',
                            padding: ' 24px 40px 24px 24px'
                        }}
                        direction={'row'}
                        flexWrap={'wrap'}
                        gap={2}
                        pt={2}
                    >
                        <Box position={'relative'}>
                            <img height={50} width={50} src={jsonParseImage(item?.message)} alt=""/>
                        </Box>

                        <Box>

                            {jsonParseNotificationTitle(item?.message)}

                            <Typography component="p" variant="body2" color="text.secondary">
                                {jsonParseNotificationDate(item?.message)}
                            </Typography>
                        </Box>
                    </Stack>
                ))}
        </Box>
    );
};

export default Notification;
