import React from 'react';
import {Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import itemPic from '../../assets/images/dashboardTableItem.png';
import DateRangeController from "./DateRangePicker/DateRangeController";
import {useSelector} from "react-redux";
import {selectDashboard} from "../../state/features/Dashboard/dashboardSelector";
import TableLoaderSkeleton from "../../assets/global/Loader/TableLoaderSkeleton";
import DataNotFound from "../ErrorPage/DataNotFound";

function createData(rank, itemName, totalSaves) {
    return {rank, itemName, totalSaves};
}

const TopSavedProducts = ({setProductDateRange}) => {
    const {topSavedProduct} = useSelector(selectDashboard);
    const rows = topSavedProduct?.data?.map((items, index) => (

        createData(index + 1, {image: items?.main_image_url, name: items?.name}, items?.total_save)
    ));
    const datePickerForTopProduct = (data) => {
        setProductDateRange(data);
    };
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} mb={5}>
                <Typography variant="h5" mb={2}>Top Saved Products</Typography>

                <Box sx={{minWidth: 150}}>
                    <DateRangeController datePickerCallback={datePickerForTopProduct}/>
                </Box>
            </Stack>
            {
                topSavedProduct?.data?.isLoading ? <TableLoaderSkeleton/> :
                    (topSavedProduct?.data?.length > 0 && rows?.length > 0) ? (
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Rank</TableCell>
                                        <TableCell align="left">Item Name</TableCell>
                                        <TableCell align="center">Total Saves</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <TableRow
                                            key={row.rank}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.rank}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Stack direction={'row'} alignItems={'center'} spacing={1.5}>
                                                    <img src={row.itemName.image} alt="" width={20} height={20}/>
                                                    <Typography
                                                        variant="body2"
                                                        color={'text.primary'}
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            maxWidth: '95px'
                                                        }}>
                                                        {row.itemName.name}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="center">{row.totalSaves}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <DataNotFound title={'product'}/>
                    )

            }
        </>
    );
};

export default TopSavedProducts;
