import {Stack} from '@mui/material';
import React from 'react';
import StatCard from './StatCard';
import {useSelector} from "react-redux";
import {selectDashboard} from "../../state/features/Dashboard/dashboardSelector";

const DashboardStatus = () => {
    const {dashboardCounts} = useSelector(selectDashboard);
  return (
    <Stack direction={'row'} flexWrap={"wrap"} gap={2}>
      <StatCard
          info="Total Followers"
          state="negative"
          numbers={dashboardCounts?.data?.totalFollowers ? dashboardCounts?.data?.totalFollowers : '0'}
          rate={'1.45'}
      />
      <StatCard
          type="store"
          info="Total Product Saves"
          state="negative"
          numbers={dashboardCounts?.data?.totalProductSaves ? dashboardCounts?.data?.totalProductSaves : '0'}
          rate={'1.45'}
      />
      <StatCard
          type="people"
          info="Total Products"
          numbers={dashboardCounts?.data?.totalProducts ? dashboardCounts?.data?.totalProducts : '0'}
          rate={'11.45'}
      />
    </Stack>
  );
};

export default DashboardStatus;
