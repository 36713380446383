import {Stack, Typography} from '@mui/material';
import React from 'react';

const EachUserProfileStat = ({state, label}) => {
  return (
    <Stack alignItems={'center'}>
      <Typography variant="body2" color={'other.gray8'} fontWeight={600}>
        {state}
      </Typography>
      <Typography variant="body2" color={'other.gray5'} sx={{fontSize: 11}}>
        {label}
      </Typography>
    </Stack>
  );
};

export default EachUserProfileStat;
