import { Box, Button, CircularProgress, FormHelperText, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import React, { useEffect, useState } from "react";
import { PromotionValidation } from "../../../helpers/constant/validationPattern";
import { useDispatch, useSelector } from "react-redux";
import {
    editPromotionStateClear,
    getMoreProductList,
    getProductList,
    getProductListStateClear
} from "../../../state/features/Promotions/promotionSlice";
import { selectPromotion } from "../../../state/features/Promotions/promotionSelector";
import routeLink from "../../../helpers/constant/routeLink";

const PromotionForm = ({ control, type }) => {
    const dispatch = useDispatch();
    const { productList, editPromotion } = useSelector(selectPromotion);
    const { hasMore, isFetching, page } = productList || {}
    const [promotionType, setPromotionType] = useState(null);
    const [product, setProduct] = useState(null);
    const [isProductList, setIsProductList] = useState(false);

    const handleChangePromotionType = (e) => {
        const value = e.target.value;
        if (value === 2) {
            setPromotionType(value);
            dispatch((getProductList()));
        } else {
            setPromotionType(value);
        }
    }

    const handleSelectProduct = (e) => {
        const value = e.target.value;
        if (value) {
            setProduct(value);
        }
    }
    const handleFetchMore = () => {
        console.log("see more")
        dispatch(getMoreProductList({
            page: page + 1,
            perPage: 10
        }))
    }

    const handleClickProduct = () => {
        setIsProductList(true)
    }

    useEffect(() => {
        if (editPromotion?.data?.productUid) {
            setProduct(editPromotion?.data?.productUid);
        }
    }, [editPromotion?.data?.productUid]);

    useEffect(() => {
        if (editPromotion?.data?.promotionType) {
            setPromotionType(editPromotion?.data?.promotionType);
            if (editPromotion?.data?.promotionType === 2) {
                dispatch((getProductList()));
            }
            else
                setIsProductList(true);
        }
    }, [editPromotion?.data?.promotionType]);

    useEffect(() => {
        return () => {
            dispatch(editPromotionStateClear());
            dispatch(getProductListStateClear())
            setPromotionType(null);
        }
    }, []);

    return (
        <>
            <Typography variant="h6" mb={2}>
                Promotion Type
            </Typography>

            <Stack>
                <Controller
                    control={control}
                    name="promotionType"
                    render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                        <FormControl size="small" sx={{ mb: 3 }} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                id="outlined-select-category"
                                select
                                label="What do you want to promote?"
                                value={promotionType}
                                focused
                                onChange={(e) => {
                                    onChange(e);
                                    handleChangePromotionType(e);
                                }}
                                error={!!(invalid && error.message)}
                            >
                                <MenuItem
                                    value={1}
                                    selected={promotionType === 1}
                                >
                                    Brand
                                </MenuItem>
                                <MenuItem
                                    value={2}
                                    selected={promotionType === 2}
                                >
                                    Product
                                </MenuItem>
                            </TextField>
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={PromotionValidation.promotionType}
                />
            </Stack>
            {
                (promotionType === 2) && (
                    <Stack>
                        <Controller
                            control={control}
                            name="productUid"
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                                <FormControl size="small" sx={{ mb: 2 }} fullWidth error={!!(invalid && error.message)}>
                                    <TextField
                                        id="outlined-select-category"
                                        select
                                        label={"Select Products"}
                                        value={product}
                                        focused
                                        onChange={(e) => {
                                            onChange(e);
                                            handleSelectProduct(e);
                                        }}
                                        onClick={handleClickProduct}
                                        error={!!(invalid && error.message)}
                                    >
                                        {
                                            (productList?.isLoading || productList?.data === null) ? (
                                                <MenuItem>
                                                    <Box sx={{ textAlign: 'center', width: '100%' }}>
                                                        <CircularProgress size={25} />
                                                    </Box>
                                                </MenuItem>
                                            ) : (
                                                isProductList || type == routeLink.addPromotion.hidden_key ? (
                                                    productList?.data?.map((item, i) => (
                                                        <MenuItem
                                                            key={item?.uid}
                                                            value={item?.uid}
                                                            selected={product === item?.uid}
                                                        >
                                                            {item?.name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem
                                                        value={editPromotion?.data?.productUid}
                                                        selected={product === editPromotion?.data?.productUid}
                                                    >
                                                        {editPromotion?.data?.productName}
                                                    </MenuItem>
                                                )
                                            )
                                        }
                                        {
                                            (productList?.total > productList?.data?.length) && (
                                                <Box sx={{ textAlign: 'center', width: '100%' }}>
                                                    <Button
                                                        fullWidth={true}
                                                        color={'primary'}
                                                        onClick={handleFetchMore}
                                                    >
                                                        See More
                                                    </Button>
                                                </Box>
                                            )
                                        }
                                    </TextField>
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={PromotionValidation.productUid}
                        />
                    </Stack>
                )
            }

            <Stack spacing={2}>

                <Typography variant="h6" mb={2} mt={3}>
                    Promotion Info
                </Typography>

                    <Controller
                        control={control}
                        name="promotionTitle"
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                            <FormControl size="small" sx={{ mb: 3 }} fullWidth error={!!(invalid && error.message)}>
                                <TextField
                                    label="Promotion Title"
                                    value={value}
                                    onChange={onChange}
                                    focused
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={PromotionValidation.promotionTitle}
                    />
                    <Controller
                        control={control}
                        name="promotionDescription"
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                            <FormControl size="small" sx={{ mb: 2 }} fullWidth error={!!(invalid && error.message)}>
                                <TextField
                                    label="Description"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={value}
                                    onChange={onChange}
                                    focused
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={PromotionValidation.promotionDescription}
                    />

                    <Controller
                        control={control}
                        name="winners"
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                            <FormControl size="small" sx={{ mb: 3 }} fullWidth error={!!(invalid && error.message)}>
                                <TextField
                                    type="number"
                                    label="No of Winners"
                                    value={value}
                                    onChange={onChange}
                                    focused
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={PromotionValidation.winners}
                    />
            </Stack>


            <Stack spacing={2}>
                <Typography variant="h6" mb={2} mt={3}>
                    Promotion Timeline
                </Typography>


                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        control={control}
                        name="startDate"
                        defaultValue={dayjs('2023-12-30')}
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                            <FormControl size="small" sx={{ mb: 3 }} fullWidth error={!!(invalid && error.message)}>
                                <DatePicker
                                    label="Start Date"
                                    value={value}
                                    onChange={onChange}
                                    focused
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={PromotionValidation.startDate}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        control={control}
                        name="endDate"
                        defaultValue={dayjs('2023-12-30')}
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                            <FormControl size="small" sx={{ mb: 2 }} fullWidth error={!!(invalid && error.message)}>
                                <DatePicker
                                    label="End Date"
                                    value={value}
                                    onChange={onChange}
                                    focused
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={PromotionValidation.endDate}
                    />
                </LocalizationProvider>
            </Stack>

            <Typography variant="h6" mb={2} mt={3}>
                Rewards and Policies
            </Typography>

            <Stack spacing={5}>
                <Controller
                    control={control}
                    name="rewardsAndPolicies"
                    render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                        <FormControl size="small" sx={{ mb: 2 }} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="Rewards & policies"
                                multiline
                                rows={4}
                                fullWidth
                                value={value}
                                onChange={onChange}
                                focused
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={PromotionValidation.rewardsAndPolicies}
                />
            </Stack>
        </>
    );
}
export default PromotionForm;