import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    user: null,
    journeyMode: false,
    isLogin: false,
    pending: false,
    roleName: null,
    hiddenKeys: null,
    signUpState: {
        email: null,
        userId: null,
        brandName: null,
        password: null,
        isMailSend: null,
        isSignUpSuccess: false,
        pending: false,
        error: null,
    },
    forgetPasswordState: {
        email: null,
        isMailSend: false,
        isLoading: false,
        error: null,
    },
    resetPasswordState: {
        isLoading: false,
        isPasswordSaved: false,
        error: null,
        message: null,
    },
    logoutState : {
        isLoading: false,
        isLogoutSuccess: false,
        error: '',
    },
    error: "",
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        attemptLogin: (state) => {
            state.pending = true;
        },
        attemptLoginSuccess: (state, action) => {
            state.isLogin = true;
            state.pending = false;
            state.journeyMode = action?.payload?.journey_mode_on;
            state.error = "";
        },
        attemptLoginFailed: (state, action) => {
            state.isLogin = false;
            state.pending = false;
            state.user = null;
            state.error = action.payload;
        },
        attemptClearLogin: (state) => {
            state.pending = false;
            state.isLogin = false;
            state.error = "";
        },

        //------------------------------Sign up-------------------------------------

        attemptSignUp: (state, action) => {
            // state.signUpState.email = action.payload.email;
            // state.signUpState.password = action.payload.password;
            state.signUpState.pending = true;
            state.signUpState.isMailSend = false;
            state.signUpState.isSignUpSuccess = false;
        },
        attemptSignUpSuccess: (state, action) => {
            state.signUpState.email = action?.payload?.data?.email;
            state.signUpState.brandName = action?.payload?.data?.name;
            state.signUpState.userId = action?.payload?.data?.uid;
            state.signUpState.isMailSend = true;
            state.signUpState.isSignUpSuccess = true;
            state.signUpState.pending = false;
            state.signUpState.error = "";
        },
        attemptSignUpFailed: (state, action) => {
            state.signUpState.email = "";
            state.signUpState.password = "";
            state.signUpState.pending = false;
            state.signUpState.isMailSend = false;
            state.signUpState.isSignUpSuccess = false;
            state.signUpState.error = action.payload?.message;
        },
        attemptSignUpStateClear: (state, action) => {
            state.signUpState.email = null;
            state.signUpState.brandName = null;
            state.signUpState.userId = null;
            state.signUpState.password = null;
            state.signUpState.pending = false;
            state.signUpState.isMailSend = false;
            state.signUpState.isSignUpSuccess = false;
            state.signUpState.error = null;
        },

        //------------------------------Forget password-------------------------------------

        attemptForgetPassword: (state, action) => {
            state.forgetPasswordState.email = action.payload.email;
            state.forgetPasswordState.isLoading = true;
            state.forgetPasswordState.isMailSend = false;
        },
        attemptForgetPasswordSuccess: (state, action) => {
            state.forgetPasswordState.message = action.payload?.message;
            state.forgetPasswordState.isMailSend = true;
            state.forgetPasswordState.isLoading = false;
            state.forgetPasswordState.error = "";
        },
        attemptForgetPasswordFailed: (state, action) => {
            state.forgetPasswordState.email = "";
            state.forgetPasswordState.isLoading = false;
            state.forgetPasswordState.isMailSend = false;
            state.forgetPasswordState.error = action.payload.message;
        },
        attemptForgetPasswordStateClear: (state, action) => {
            state.forgetPasswordState.email = null;
            state.forgetPasswordState.isLoading = false;
            state.forgetPasswordState.isMailSend = false;
            state.forgetPasswordState.error = null;
        },

        //------------------------------Reset password-------------------------------------

        attemptResetPassword: (state, action) => {
            state.resetPasswordState.isLoading = true;
            state.resetPasswordState.isPasswordSaved = false;
        },
        attemptResetPasswordSuccess: (state, action) => {
            state.resetPasswordState.isLoading = false;
            state.resetPasswordState.isPasswordSaved = true;
            state.resetPasswordState.message = action.payload?.message;
            state.resetPasswordState.error = null;
        },
        attemptResetPasswordFailed: (state, action) => {
            state.resetPasswordState.isLoading = false;
            state.resetPasswordState.isPasswordSaved = false;
            state.resetPasswordState.meaasge = action.payload?.message;
        },
        attemptResetPasswordStateClear: (state, action) => {
            state.resetPasswordState.isLoading = false;
            state.resetPasswordState.isPasswordSaved = false;
            state.resetPasswordState.error = null;
            state.resetPasswordState.meaasge = null;
            state.forgetPasswordState.email = null;
            state.forgetPasswordState.isLoading = false;
            state.forgetPasswordState.isMailSend = false;
            state.forgetPasswordState.error = null;
        },

        //------------------------------Reset password-------------------------------------

        attemptLogout: (state, action) => {
            state.logoutState.isLogoutSuccess = true;
            state.isLogin = false;
            state.user = null;
            state.error = "";
        },
    },
});

export const {
    attemptLogin,
    attemptLoginSuccess,
    attemptLoginFailed,
    attemptClearLogin,
    attemptSignUp,
    attemptSignUpSuccess,
    attemptSignUpFailed,
    attemptSignUpStateClear,
    attemptForgetPassword,
    attemptForgetPasswordSuccess,
    attemptForgetPasswordFailed,
    attemptForgetPasswordStateClear,
    attemptResetPassword,
    attemptResetPasswordSuccess,
    attemptResetPasswordFailed,
    attemptResetPasswordStateClear,
    attemptLogout,
    attemptLogoutSuccess,
    attemptLogoutFailed

} = userSlice.actions;

export default userSlice.reducer;
