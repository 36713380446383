import React, {useState} from 'react';
import {Box, Button, Stack, Tab, Tabs, Typography} from '@mui/material';
import BasicWithHeaderModal from '../../assets/global/Modal/BasicWithHeaderModal';
import ContentWrapper from '../../assets/global/wrapper/ContentWrapper/ContentWrapper';
import WrapperHeader from '../../assets/global/wrapper/ContentWrapper/WrapperHeader';
import BrandProfile from './BrandProfile';
import MyProfile from './MyProfile';
import EditBrandProfileForm from "./EditBrandProfileForm";
import ScrollableModal from "../../assets/global/Modal/ScrollableModal";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

const ProfileSettings = () => {
    const [value, setValue] = useState(0);
    const [isProfileEditable, setIsProfileEditable] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCancelEdit = () => {
        setIsCancel(true);
        setIsProfileEditable(false);
    };

    return (
        <Box height={'calc(100vh - 109px)'} sx={{overflowY: 'auto'}}>
            <ContentWrapper>
                <WrapperHeader title="Profile Settings" border={false}/>

                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab sx={{px: 5}} label="My Profile" {...a11yProps(0)} />
                        <Tab sx={{px: 5}} label="Brand Profile" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                {/* My Profile */}
                <CustomTabPanel value={value} index={0}>
                    <MyProfile/>
                </CustomTabPanel>

                {/* Brand Profile */}
                <CustomTabPanel value={value} index={1}>
                    <BrandProfile onProfileEdit={() => setIsProfileEditable(true)}/>
                </CustomTabPanel>
            </ContentWrapper>

            <BasicWithHeaderModal open={isCancel} header={false} width={510}>
                <Stack alignItems={'center'} py={6} textAlign={'center'}>
                    <Typography variant="h5" mb={1}>
                        Are you sure you want to <br/> cancel?
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        All the changes you made will be lost
                    </Typography>
                </Stack>

                <Stack
                    p={'16px'}
                    sx={{borderTop: 1, borderColor: 'other.divider'}}
                    direction={'row'}
                    justifyContent={'flex-end'}
                    spacing={2}
                >
                    <Button
                        sx={{color: 'text.primary'}}
                        variant="text"
                        onClick={() => setIsProfileEditable(true)}
                    >
                        Go Back
                    </Button>
                    <Button variant="contained" disableElevation onClick={() => setIsCancel(false)}>
                        Discard Changes
                    </Button>
                </Stack>
            </BasicWithHeaderModal>

            <ScrollableModal
                modalTitle="Edit Brand Profile"
                open={isProfileEditable}
                onClose={() => setIsProfileEditable(false)}
            >
                <EditBrandProfileForm
                    handleCancelEdit={handleCancelEdit}
                    setIsProfileEditable={setIsProfileEditable}
                    setIsCancel={setIsCancel}
                />
            </ScrollableModal>
        </Box>
    );
};
export default ProfileSettings;
