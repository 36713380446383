import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHeaderWithoutCheckbox from "../../../assets/global/TableHeader/TableHeaderWithoutCheckbox";
import {
    createPromotionDetailsTableData, getComparator,
    PromotionDetailsHeadCell,
    stableSort
} from "../../../helpers/utility/TableContents";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import {Badge, Box, Chip, Stack} from "@mui/material";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectPromotion} from "../../../state/features/Promotions/promotionSelector";
import Typography from "@mui/material/Typography";
import {getPromotionDetailsList} from "../../../state/features/Promotions/promotionSlice";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const PromotionDetailsTable = ({promotionId, searchText}) => {
    const dispatch = useDispatch();
    const {
        promotionDetails,
    } = useSelector(selectPromotion);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('uid');

    const rows = promotionDetails?.data?.map((items) => (
        createPromotionDetailsTableData(items?.user?.first_name, Number(items?.share_count), Number(items?.user_count),
            Number(items?.click_count), items?.user?.email, items?.user?.phone, items?.uid, items?.user?.last_name, items?.reward_id, items.winner_position)
    ));
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows = (promotionDetails?.page - 1) > 0 ? Math.max(0, (1 + (promotionDetails?.page - 1)) * promotionDetails?.perPage - rows?.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, (promotionDetails?.page - 1), promotionDetails?.perPage]
    );
    // const isSelected = (id, index) => {
    //     if (promotionDetails?.promotionData?.isRewarded && id !== null) {
    //         return true;
    //     } else if (!promotionDetails?.promotionData?.isRewarded) {
    //         if (index <= 1) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //
    //     }
    // }
    const handleChangePage = (event, newPage) => {
        dispatch(getPromotionDetailsList({
            promotionUid: promotionId ,
            searchInputText: searchText,
            page: newPage,
            per_page: promotionDetails?.perPage,
        }));
    };
    const handleChangeRowsPerPage = event => {
        let row = parseInt(event.target.value, 10);
        dispatch(getPromotionDetailsList({
            promotionUid: promotionId ,
            searchInputText: searchText,
            page: promotionDetails?.perPage,
            per_page: row,
        }));
    };

    const winnerIndex = (index) => {
        const position = index + 1;

        const suffixes = ['th', 'st', 'nd', 'rd'];
        const remainder10 = position % 10;
        const remainder100 = position % 100;

        return position + (suffixes[remainder10 === 1 && remainder100 !== 11 ? 1 : remainder10 === 2 && remainder100 !== 12 ? 2 : remainder10 === 3 && remainder100 !== 13 ? 3 : 0] || suffixes[0]);
    }
    const winnerPosition = (position) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const remainder10 = position % 10;
        const remainder100 = position % 100;

        return position + (suffixes[remainder10 === 1 && remainder100 !== 11 ? 1 : remainder10 === 2 && remainder100 !== 12 ? 2 : remainder10 === 3 && remainder100 !== 13 ? 3 : 0] || suffixes[0]);
        // if (position == 1) {
        //     return '1st';
        // } else if (position == 2) {
        //     return '2nd';
        // } else if (position == 3) {
        //     return '3rd';
        // }
    }

    const WinnerDeclear = ({index, rewardId, winner}) => {
        if (promotionDetails?.promotionData?.isRewarded) {
            if (rewardId != null && winner != null) {
                return (
                    <Badge color="warning" badgeContent={winnerPosition(winner)}>
                        <EmojiEventsIcon color={'warning'}/>
                    </Badge>
                )
            }
        } else {
            if (promotionDetails?.page == 1 && index <= 10) {
                return (
                    <Badge color="success" badgeContent={winnerIndex(index)}/>
                )
            }
        }
    }

    return(
        <Box sx={{ width: '100%' }}>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeaderWithoutCheckbox
                        tableHeader={PromotionDetailsHeadCell}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {visibleRows?.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row?.uid}
                                    sx={{ cursor: 'pointer' }}
                                    selected={row?.winnerPosition != null}
                                >
                                    <TableCell>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography>
                                                {row?.firstName ? row?.firstName : row?.email} &nbsp; &nbsp; &nbsp;
                                                <WinnerDeclear index={index} rewardId={row?.rewardId} winner={row?.winnerPosition}/>
                                            </Typography>
                                        </Stack>
                                        {/*<img src={medal} width={20} height={20} />*/}

                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip variant={'outlined'} label={row?.shares} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip variant={'outlined'} label={row?.clicks} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip variant={'outlined'} label={row?.activations} />
                                    </TableCell>
                                    <TableCell align="center">{row?.email}</TableCell>
                                    <TableCell align="center">{row?.phone}</TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={promotionDetails?.total}
                rowsPerPage={promotionDetails?.perPage}
                page={(promotionDetails?.page - 1)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}
export default PromotionDetailsTable;