import WrapperHeader from "../../../assets/global/wrapper/ContentWrapper/WrapperHeader";
import {Box, Button, Chip, Stack, TextField, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import ContentWrapper from "../../../assets/global/wrapper/ContentWrapper/ContentWrapper";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectPromotion} from "../../../state/features/Promotions/promotionSelector";
import Typography from "@mui/material/Typography";
import {
    changePromotionReward,
    changePromotionRewardStateClear,
    getPromotionDetailsList
} from "../../../state/features/Promotions/promotionSlice";
import TableLoaderSkeleton from "../../../assets/global/Loader/TableLoaderSkeleton";
import DataNotFound from "../../ErrorPage/DataNotFound";
import PromotionDetailsTable from "./PromotionDetailsTable";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DeclareWinnerModal from "../PromotionList/modal/DeclareWinnerModal";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import {PromotionTableStatus} from "../PromotionList/PromotionTableContent";

const PromotionDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { declareRewards, promotionDetails } = useSelector(selectPromotion);
    const [singlePromotionId, setSinglePromotionId] = useState(null);
    const [promotionDeclareModal, setPromotionDeclareModal] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { promotionId } = useParams();

    useEffect(() => {
        dispatch(getPromotionDetailsList({ promotionUid: promotionId }));
    }, [promotionId]);

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        dispatch(getPromotionDetailsList({
            promotionUid: promotionId ,
            searchInputText: value
        }));
    }

    const handleDeclareWinner = (id) => {
        if (id) {
            setSinglePromotionId(id);
            setPromotionDeclareModal(true);
        }
    }

    const handlePromotionDeclare = () => {
        if (singlePromotionId) {
            dispatch(changePromotionReward({
                promotionUid: singlePromotionId,
                rewards: true,
            }));
        }
    }

    useEffect(() => {
        if (declareRewards?.isDeclareRewards) {
            setPromotionDeclareModal(false);
            dispatch(changePromotionRewardStateClear());
        }
    }, [declareRewards?.isDeclareRewards]);

    return (
        <ContentWrapper>
            <WrapperHeader
                title="Shares Details"
                backButton={() => navigate('/promotions')}
                cta={
                    <Stack spacing={2} direction={'row'}>
                        {
                            promotionDetails?.promotionData && (
                                <>
                                    <Stack>
                                        <Typography variant="body2medium">
                                            {promotionDetails?.promotionData && promotionDetails?.promotionData?.promotionName}
                                        </Typography>
                                        <Typography variant="alertDescription" color={'text.secondary'}>
                                            {`End Date : ${promotionDetails?.promotionData?.endDate}`}
                                        </Typography>
                                    </Stack>
                                    <PromotionTableStatus promotionStatus={promotionDetails?.promotionData?.status}/>
                                    <Chip label={promotionDetails?.promotionData?.noOfWinners > 1 ? `${promotionDetails?.promotionData?.noOfWinners} Winners` : `${promotionDetails?.promotionData?.noOfWinners} Winner`} color={'success'}/>

                                </>
                            )
                        }
                    </Stack>
                }
            />
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'column'} spacing={2} pt={2} pl={2}>
                    <TextField
                        id="outlined-required"
                        label="Search"
                        type={'search'}
                        focused
                        placeholder={'Search...'}
                        onChange={handleSearch}
                    />
                    <Stack direction={'row'} pb={2} pl={2}>
                        <Typography variant="body2">
                            Total {promotionDetails?.total} shares
                        </Typography>
                    </Stack>
                </Stack>
                {
                    !promotionDetails?.promotionData?.isRewarded && (
                        <Stack sx={{paddingBottom: '50px', paddingRight: '10px'}}>
                            <Button
                                variant="contained"
                                startIcon={<EmojiEventsIcon/>}
                                onClick={() => handleDeclareWinner(promotionId)}
                                color={'warning'}
                            >
                                Declare winners
                            </Button>
                        </Stack>
                    )
                }


            </Stack>

            {
                promotionDetails?.isLoading ?
                    <Box py={2.4} px={1}><TableLoaderSkeleton /></Box> :
                    promotionDetails?.data?.length > 0 ? (
                        <PromotionDetailsTable
                            promotionId={promotionId}
                            searchText={searchValue}
                        />
                    ) : (
                        <Box sx={{ textAlign: 'center', padding: '20px 0px' }}>
                            <DataNotFound title={'promotion'} />
                        </Box>
                    )
            }
            <BasicWithHeaderModal width={460} open={promotionDeclareModal} header={false}>
                <DeclareWinnerModal
                    handleModalClose={() => setPromotionDeclareModal(false)}
                    isLoading={declareRewards?.isLoading}
                    handlePromotionDeclare={handlePromotionDeclare}
                />
            </BasicWithHeaderModal>
        </ContentWrapper>
    )
}
export default PromotionDetails;