import React from 'react';
import Box from "@mui/material/Box";
import restoreModalIcon from "../../images/Icons/restoreModalIcon.svg";
import { Button, Stack, Typography } from "@mui/material";
import LoadingButton from "../Button/LoadingButton";
import {
    PROMOTION_STATUS_ACTIVE, PROMOTION_STATUS_COMPLETED,
    PROMOTION_STATUS_INACTIVE, PROMOTION_STATUS_PAUSED,
    PROMOTION_STATUS_REQUESTED
} from '../../../helpers/constant/coreConstant';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CheckIcon from '@mui/icons-material/Check';
import {useSelector} from "react-redux";
import {selectPromotion} from "../../../state/features/Promotions/promotionSelector";

const StatusChangeModal = ({ handleModalClose, onChangeStatus, uid, isLoading, status }) => {
    const {statusInfo} = useSelector(selectPromotion);
    const findText = (status) => {
        switch (status) {
            case PROMOTION_STATUS_ACTIVE:
                return "again active"
            case PROMOTION_STATUS_INACTIVE:
                return "cancel"
            case PROMOTION_STATUS_REQUESTED:
                return "request to publish"
            case PROMOTION_STATUS_PAUSED:
                return "paused or complete"
            case PROMOTION_STATUS_COMPLETED:
                return "paused or complete"
            default:
                break;
        }
    }
    return (
        <>
            <Box p={'64px 40px 48px 40px'} textAlign={'center'}>
                <Typography variant="h5" mt={3} mb={1}>
                    Are you sure you want to <br /> {findText(status)} this promotion?
                </Typography>
                <Typography variant="body2" color={'text.secondary'}>
                    Currently published promotion will be stopped as you can publish only 1 promotion at a time
                </Typography>
            </Box>

            <Stack
                p={'16px'}
                sx={{ borderTop: 1, borderColor: 'other.divider' }}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={1}
            >
                <Button
                    sx={{ color: 'text.primary' }}
                    variant="text"
                    onClick={handleModalClose}
                >
                    Close
                </Button>
                {
                    status == PROMOTION_STATUS_REQUESTED ? (
                        <LoadingButton
                            isLoading={isLoading}
                            startIcon={<PublishedWithChangesIcon />}
                            loadingText={'Updating...'}
                            variant="contained"
                            onClick={() => onChangeStatus(uid, status)}
                        >
                            Publish Anyway
                        </LoadingButton>
                    ) : status == PROMOTION_STATUS_INACTIVE ? (
                        <LoadingButton
                            isLoading={isLoading}
                            loadingText={'Updating...'}
                            startIcon={<DoNotDisturbAltIcon />}
                            variant="contained"
                            color={"error"}
                            onClick={() => onChangeStatus(uid, PROMOTION_STATUS_INACTIVE)}
                        >
                            Cancel Request
                        </LoadingButton>
                    ) :  (status == PROMOTION_STATUS_COMPLETED)  ? (
                        <>
                            <LoadingButton
                                isLoading={statusInfo?.isPausing}
                                loadingText={'Updating...'}
                                variant="contained"
                                color={"error"}
                                startIcon={<PauseCircleFilledOutlinedIcon />}
                                onClick={() => onChangeStatus(uid, PROMOTION_STATUS_PAUSED)}
                            >
                                Paused
                            </LoadingButton>
                            <LoadingButton
                                isLoading={statusInfo?.isCompleting}
                                startIcon={<TaskAltIcon />}
                                loadingText={'Updating...'}
                                variant="contained"
                                color={"success"}
                                onClick={() => onChangeStatus(uid, PROMOTION_STATUS_COMPLETED)}
                            >
                                Complete
                            </LoadingButton>
                        </>
                    ): (
                        <LoadingButton
                            isLoading={isLoading}
                            loadingText={'Updating...'}
                            startIcon={<CheckIcon />}
                            variant="contained"
                            onClick={() => onChangeStatus(uid, PROMOTION_STATUS_ACTIVE)}
                            color={'secondary'}
                        >
                            Active again
                        </LoadingButton>
                    )
                }
            </Stack>
        </>
    );
}
export default StatusChangeModal;
