import { requests } from "./http.service";

class TeamMemberService {
    addTeamMember(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/create", body);
    }
    teamMemberList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/list", body);
    }
    editTeamMember(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/get/specific/data", body);
    }
    updateTeamMember(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/update", body);
    }
    getUserRoleList(body) {
        return requests.get(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/role/list", body);
    }
    singleTrash(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/in-trash/update/status/single", body);
    }
    multipleTrash(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/in-trash/update/status/bulk", body);
    }
    singleDelete(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/single/delete", body);
    }
    multipleDelete(body) {
        return requests.delete(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/bulk/delete", body);
    }
    multipleRestore(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/in-trash/update/status/bulk", body);
    }
    changeRole(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/role/update", body);
    }
    changeStatus(body) {
        return requests.put(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/status/update", body);
    }
    changePassword(body) {
        return requests.post(process.env.REACT_APP_API_URL_MAIN_USER_SERVER + "/team-member/password/update", body);
    }
}

export default new TeamMemberService();