import { createSlice } from '@reduxjs/toolkit';
import { promotionDetailsData } from "../../../helpers/utility/TableContents";
import {PROMOTION_STATUS_COMPLETED, PROMOTION_STATUS_PAUSED} from "../../../helpers/constant/coreConstant";

const initialState = {
    addPromotion: {
        data: null,
        isPromotionAdd: false,
        isPublishing: false,
        isSaving: false,
        isError: false,
        error: '',
    },
    promotionList: {
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
        isLoading: false,
        isError: false,
        error: '',
    },
    editPromotion: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    updatePromotion: {
        data: null,
        isPromotionUpdate: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleTrashPromotion: {
        isTrashPromotion: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleRestorePromotion: {
        isSinglePromotionRestore: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleTrashPromotion: {
        isMultipleTrashPromotion: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleDeletePromotion: {
        isSingleDeletePromotion: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleDeletePromotion: {
        isMultipleDeletePromotion: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleRestorePromotion: {
        isMultipleRestorePromotion: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    declareRewards: {
        isDeclareRewards: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    promotionDetails: {
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
        promotionData: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    viewPromotion: {
        data: null,
        isPromotionViewed: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    productList: {
        data: null,
        page: 1,
        perPage: 10,
        total: 0,
        hasMore: true,
        isProductListFound: false,
        isLoading: false,
        isFetching: false,
        isError: false,
        error: false,
    },
    statusInfo: {
        isLoading: false,
        isStatus: false,
        isPausing: false,
        isCompleting: false,
        isError: false,
        error: '',
    }
};

const promotionSlice = createSlice({
    name: 'promotion',
    initialState: initialState,
    reducers: {
        addPromotionDataForSave: (state, action) => {
            state.addPromotion.isSaving = true;
        },
        addPromotionDataForRequest: (state, action) => {
            state.addPromotion.isPublishing = true;
        },
        addPromotionSuccess: (state, action) => {
            state.addPromotion.data = action?.payload;
            state.addPromotion.isPromotionAdd = true;
            state.addPromotion.isPublishing = false;
            state.addPromotion.isSaving = false;
            state.addPromotion.isError = false;
            state.addPromotion.error = '';
        },
        addPromotionFailed: (state, action) => {
            state.addPromotion.data = null;
            state.addPromotion.isPromotionAdd = false;
            state.addPromotion.isPublishing = false;
            state.addPromotion.isSaving = false;
            state.addPromotion.isError = true;
            state.addPromotion.error = action.payload;
        },
        addPromotionStateClear: (state, action) => {
            state.addPromotion.data = null;
            state.addPromotion.isPromotionAdd = false;
            state.addPromotion.isSaving = false;
            state.addPromotion.isPublishing = false;
            state.addPromotion.isError = false;
            state.addPromotion.error = '';
        },


        getPromotionList: (state, action) => {
            state.promotionList.isLoading = true;
        },
        getPromotionListSuccess: (state, action) => {
            state.promotionList.data = action?.payload?.data;
            state.promotionList.page = action?.payload?.current_page;
            state.promotionList.perPage = action?.payload?.per_page;
            state.promotionList.total = action?.payload?.total;
            state.promotionList.isLoading = false;
            state.promotionList.isError = false;
            state.promotionList.error = '';
        },
        getPromotionListFailed: (state, action) => {
            state.promotionList.data = null;
            state.promotionList.isLoading = false;
            state.promotionList.total = 0;
            state.promotionList.isError = true;
            state.promotionList.error = action.payload;
        },
        getPromotionListStateClear: (state, action) => {
            state.promotionList.data = null;
            state.promotionList.isLoading = false;
            state.promotionList.isError = false;
            state.promotionList.error = '';
            state.promotionList.page = 1;
            state.promotionList.perPage = 10;
            state.promotionList.total = '';
        },


        editPromotionData: (state, action) => {
            state.editPromotion.isLoading = true;
        },
        editPromotionSuccess: (state, action) => {
            state.editPromotion.data = action?.payload;
            state.editPromotion.isLoading = false;
            state.editPromotion.isError = false;
            state.editPromotion.error = '';
        },
        editPromotionFailed: (state, action) => {
            state.editPromotion.data = null;
            state.editPromotion.isLoading = false;
            state.editPromotion.isError = true;
            state.editPromotion.error = action.payload;
        },
        editPromotionStateClear: (state, action) => {
            state.editPromotion.data = null;
            state.editPromotion.isLoading = false;
            state.editPromotion.isError = false;
            state.editPromotion.error = '';
        },

        updatePromotionData: (state, action) => {
            state.updatePromotion.isLoading = true;
        },
        updatePromotionSuccess: (state, action) => {
            state.updatePromotion.data = action?.payload;
            state.updatePromotion.isPromotionUpdate = true;
            state.updatePromotion.isLoading = false;
            state.updatePromotion.isError = false;
            state.updatePromotion.error = '';
        },
        updatePromotionFailed: (state, action) => {
            state.updatePromotion.data = null;
            state.updatePromotion.ispromotionUpdate = false;
            state.updatePromotion.isLoading = false;
            state.updatePromotion.isError = true;
            state.updatePromotion.error = action.payload;
        },
        updatePromotionStateClear: (state, action) => {
            state.updatePromotion.data = null;
            state.updatePromotion.isPromotionUpdate = false;
            state.updatePromotion.isLoading = false;
            state.updatePromotion.isError = false;
            state.updatePromotion.error = '';
        },


        singleTrashPromotionData: (state, action) => {
            state.singleTrashPromotion.isLoading = true;
        },
        singleTrashPromotionSuccess: (state, action) => {
            state.singleTrashPromotion.isTrashPromotion = true;
            state.singleTrashPromotion.isLoading = false;
            state.singleTrashPromotion.isError = false;
            state.singleTrashPromotion.error = '';
        },
        singleTrashPromotionFailed: (state, action) => {
            state.singleTrashPromotion.isTeamMemberTrash = false;
            state.singleTrashPromotion.isLoading = false;
            state.singleTrashPromotion.isError = true;
            state.singleTrashPromotion.error = action.payload;
        },
        singleTrashPromotionStateClear: (state, action) => {
            state.singleTrashPromotion.isTeamMemberTrash = false;
            state.singleTrashPromotion.isLoading = false;
            state.singleTrashPromotion.isError = false;
            state.singleTrashPromotion.error = '';
        },

        singleRestorePromotionData: (state, action) => {
            state.singleRestorePromotion.isLoading = true;
        },
        singleRestorePromotionSuccess: (state, action) => {
            state.singleRestorePromotion.isSinglePromotionRestore = true;
            state.singleRestorePromotion.isLoading = false;
            state.singleRestorePromotion.isError = false;
            state.singleRestorePromotion.error = '';
        },
        singleRestorePromotionFailed: (state, action) => {
            state.singleRestorePromotion.isSinglePromotionRestore = false;
            state.singleRestorePromotion.isLoading = false;
            state.singleRestorePromotion.isError = true;
            state.singleRestorePromotion.error = action.payload;
        },
        singleRestorePromotionStateClear: (state, action) => {
            state.singleRestorePromotion.isSinglePromotionRestore = false;
            state.singleRestorePromotion.isLoading = false;
            state.singleRestorePromotion.isError = false;
            state.singleRestorePromotion.error = '';
        },

        multipleTrashedPromotion: (state, action) => {
            state.multipleTrashPromotion.isLoading = true;
        },
        multipleTrashPromotionSuccess: (state, action) => {
            state.multipleTrashPromotion.isMultipleTrashPromotion = true;
            state.multipleTrashPromotion.isLoading = false;
            state.multipleTrashPromotion.isError = false;
            state.multipleTrashPromotion.error = '';
        },
        multipleTrashPromotionFailed: (state, action) => {
            state.multipleTrashPromotion.isMultipleTrashPromotion = false;
            state.multipleTrashPromotion.isLoading = false;
            state.multipleTrashPromotion.isError = true;
            state.multipleTrashPromotion.error = action.payload;
        },
        multipleTrashPromotionStateClear: (state, action) => {
            state.multipleTrashPromotion.isMultipleTrashPromotion = false;
            state.multipleTrashPromotion.isLoading = false;
            state.multipleTrashPromotion.isError = false;
            state.multipleTrashPromotion.error = '';
        },

        singleDeletePromotionData: (state, action) => {
            state.singleDeletePromotion.isLoading = true;
        },
        singleDeletePromotionSuccess: (state, action) => {
            state.singleDeletePromotion.isSingleDeletePromotion = true;
            state.singleDeletePromotion.isLoading = false;
            state.singleDeletePromotion.isError = false;
            state.singleDeletePromotion.error = '';
        },
        singleDeletePromotionFailed: (state, action) => {
            state.singleDeletePromotion.isSingleDeletePromotion = false;
            state.singleDeletePromotion.isLoading = false;
            state.singleDeletePromotion.isError = true;
            state.singleDeletePromotion.error = action.payload;
        },
        singleDeletePromotionStateClear: (state, action) => {
            state.singleDeletePromotion.isSingleDeletePromotion = false;
            state.singleDeletePromotion.isLoading = false;
            state.singleDeletePromotion.isError = false;
            state.singleDeletePromotion.error = '';
        },


        multipleRestorePromotionData: (state, action) => {
            state.multipleRestorePromotion.isLoading = true;
        },
        multipleRestorePromotionSuccess: (state, action) => {
            state.multipleRestorePromotion.isMultipleRestorePromotion = true;
            state.multipleRestorePromotion.isLoading = false;
            state.multipleRestorePromotion.isError = false;
            state.multipleRestorePromotion.error = '';
        },
        multipleRestorePromotionFailed: (state, action) => {
            state.multipleRestorePromotion.isMultipleRestorePromotion = false;
            state.multipleRestorePromotion.isLoading = false;
            state.multipleRestorePromotion.isError = true;
            state.multipleRestorePromotion.error = action.payload;
        },
        multipleRestorePromotionStateClear: (state, action) => {
            state.multipleRestorePromotion.isMultipleRestorePromotion = false;
            state.multipleRestorePromotion.isLoading = false;
            state.multipleRestorePromotion.isError = false;
            state.multipleRestorePromotion.error = '';
        },

        multipleDeletedPromotion: (state, action) => {
            state.multipleDeletePromotion.isLoading = true;
        },
        multipleDeletePromotionSuccess: (state, action) => {
            state.multipleDeletePromotion.isMultipleDeletePromotion = true;
            state.multipleDeletePromotion.isLoading = false;
            state.multipleDeletePromotion.isError = false;
            state.multipleDeletePromotion.error = '';
        },
        multipleDeletePromotionFailed: (state, action) => {
            state.multipleDeletePromotion.isMultipleDeletePromotion = false;
            state.multipleDeletePromotion.isLoading = false;
            state.multipleDeletePromotion.isError = true;
            state.multipleDeletePromotion.error = action.payload;
        },
        multipleDeletePromotionStateClear: (state, action) => {
            state.multipleDeletePromotion.isMultipleDeletePromotion = false;
            state.multipleDeletePromotion.isLoading = false;
            state.multipleDeletePromotion.isError = false;
            state.multipleDeletePromotion.error = '';
        },

        getPromotionDetailsList: (state, action) => {
            state.promotionDetails.isLoading = true;
        },
        getPromotionDetailsListSuccess: (state, action) => {
            state.promotionDetails.data = action?.payload?.data?.data;
            state.promotionDetails.page = action?.payload?.data?.current_page;
            state.promotionDetails.perPage = action?.payload?.data?.per_page;
            state.promotionDetails.total = action?.payload?.data?.total;
            state.promotionDetails.promotionData = action?.payload?.extra_data;
            state.promotionDetails.isLoading = false;
            state.promotionDetails.isError = false;
            state.promotionDetails.error = '';
        },
        getPromotionDetailsListFailed: (state, action) => {
            state.promotionDetails.data = [];
            state.promotionDetails.isLoading = false;
            state.promotionDetails.total = 0;
            state.promotionDetails.isError = true;
            state.promotionDetails.error = action.payload;
        },
        getPromotionDetailsListStateClear: (state, action) => {
            state.promotionDetails.data = [];
            state.promotionDetails.isLoading = false;
            state.promotionDetails.isError = false;
            state.promotionDetails.error = '';
            state.promotionDetails.page = 1;
            state.promotionDetails.perPage = 10;
            state.promotionDetails.total = '';
        },

        viewPromotionData: (state, action) => {
            state.viewPromotion.isLoading = true;
        },
        viewPromotionSuccess: (state, action) => {
            state.viewPromotion.data = action?.payload;
            state.viewPromotion.isPromotionViewed = true;
            state.viewPromotion.isLoading = false;
            state.viewPromotion.isError = false;
            state.viewPromotion.error = '';
        },
        viewPromotionFailed: (state, action) => {
            state.viewPromotion.data = null;
            state.viewPromotion.isPromotionViewed = false;
            state.viewPromotion.isLoading = false;
            state.viewPromotion.isError = false;
            state.viewPromotion.error = action.payload;
        },
        viewPromotionStateClear: (state, action) => {
            state.viewPromotion.data = null;
            state.viewPromotion.isPromotionViewed = false;
            state.viewPromotion.isLoading = false;
            state.viewPromotion.isError = false;
            state.viewPromotion.error = '';
        },

        changePromotionReward: (state, action) => {
            state.declareRewards.isLoading = true;
        },
        changePromotionRewardSuccess: (state, action) => {
            state.declareRewards.isDeclareRewards = true;
            state.declareRewards.isLoading = false;
            state.declareRewards.isError = false;
        },
        changePromotionRewardFailed: (state, action) => {
            state.declareRewards.isDeclareRewards = false;
            state.declareRewards.isLoading = false;
            state.declareRewards.isError = true;
        },
        changePromotionRewardStateClear: (state, action) => {
            state.declareRewards.isDeclareRewards = false;
            state.declareRewards.isLoading = false;
            state.declareRewards.isError = false;
        },

        getProductList: (state, action) => {
            state.productList.isLoading = true;
        },
        getProductListSuccess: (state, action) => {
            state.productList.data = action?.payload?.data;
            state.productList.perPage = action.payload?.per_page;
            state.productList.page = action.payload?.current_page;
            state.productList.total = action.payload?.total;
            state.productList.isProductListFound = true;
            state.productList.isLoading = false;
            state.productList.isError = false;
            state.productList.error = '';
        },
        getProductListFailed: (state, action) => {
            state.productList.data = null;
            state.productList.isProductListFound = false;
            state.productList.isLoading = false;
            state.productList.isError = true;
            state.productList.error = action.payload;
        },
        getProductListStateClear: (state, action) => {
            state.productList.data = null;
            state.productList.isProductListFound = false;
            state.productList.isLoading = false;
            state.productList.isError = false;
            state.productList.error = '';
            state.productList.isFetching = false;
            state.productList.hasMore = true;
        },

        getMoreProductList: (state, action) => {
            state.productList.isFetching = true;
        },
        getMoreProductListSuccess: (state, action) => {
            state.productList.data = [...state.productList.data, ...action.payload?.data];
            state.productList.perPage = action.payload?.per_page;
            state.productList.page = action.payload?.current_page;
            state.productList.total = action.payload?.total;
            state.productList.to = action.payload?.to;
            state.productList.isFetching = false;
            state.productList.hasMore = state.productList.total > state.productList.data.length;
        },
        getMoreProductListFailed: (state, action) => {
            state.productList.isFetching = false;
            state.productList.hasMore = false;
        },

        changeStatusInfo: (state, action) => {
            if(action?.payload?.status === PROMOTION_STATUS_PAUSED) {
                state.statusInfo.isPausing = true;
            } else if (action?.payload?.status === PROMOTION_STATUS_COMPLETED) {
                state.statusInfo.isCompleting = true;
            } else {
                state.statusInfo.isLoading = true;
            }
        },
        changeStatusInfoSuccess: (state, action) => {
            state.statusInfo.isLoading = false;
            state.statusInfo.isCompleting = false;
            state.statusInfo.isLoading = false;
            state.statusInfo.isError = false;
            state.statusInfo.isStatus = true;
        },
        changeStatusInfoFailed: (state, action) => {
            state.statusInfo.isLoading = false;
            state.statusInfo.isCompleting = false;
            state.statusInfo.isPausing = false;
            state.statusInfo.isError = true;
            state.statusInfo.isStatus = true;
        },
        changeStatusInfoClear: (state, action) => {
            state.statusInfo.isLoading = false;
            state.statusInfo.isCompleting = false;
            state.statusInfo.isPausing = false;
            state.statusInfo.isError = false;
            state.statusInfo.isStatus = true;
        }
    }
});

export const {
    addPromotionDataForSave,
    addPromotionDataForRequest,
    addPromotionSuccess,
    addPromotionFailed,
    addPromotionStateClear,

    getPromotionList,
    getPromotionListSuccess,
    getPromotionListFailed,
    getPromotionListStateClear,

    editPromotionData,
    editPromotionSuccess,
    editPromotionFailed,
    editPromotionStateClear,

    updatePromotionData,
    updatePromotionSuccess,
    updatePromotionFailed,
    updatePromotionStateClear,

    singleDeletePromotionData,
    singleDeletePromotionSuccess,
    singleDeletePromotionFailed,
    singleDeletePromotionStateClear,

    singleRestorePromotionData,
    singleRestorePromotionSuccess,
    singleRestorePromotionFailed,
    singleRestorePromotionStateClear,

    singleTrashPromotionData,
    singleTrashPromotionSuccess,
    singleTrashPromotionFailed,
    singleTrashPromotionStateClear,

    multipleRestorePromotionData,
    multipleRestorePromotionSuccess,
    multipleRestorePromotionFailed,
    multipleRestorePromotionStateClear,

    multipleDeletedPromotion,
    multipleDeletePromotionSuccess,
    multipleDeletePromotionFailed,
    multipleDeletePromotionStateClear,

    multipleTrashedPromotion,
    multipleTrashPromotionSuccess,
    multipleTrashPromotionFailed,
    multipleTrashPromotionStateClear,

    getPromotionDetailsList,
    getPromotionDetailsListSuccess,
    getPromotionDetailsListFailed,
    getPromotionDetailsListStateClear,

    viewPromotionData,
    viewPromotionSuccess,
    viewPromotionFailed,
    viewPromotionStateClear,

    changePromotionReward,
    changePromotionRewardSuccess,
    changePromotionRewardFailed,
    changePromotionRewardStateClear,

    getProductList,
    getProductListSuccess,
    getProductListFailed,
    getProductListStateClear,

    getMoreProductList,
    getMoreProductListSuccess,
    getMoreProductListFailed,
    getMoreProductListStateClear,

    changeStatusInfo,
    changeStatusInfoSuccess,
    changeStatusInfoFailed,
    changeStatusInfoClear,
} = promotionSlice.actions;

export default promotionSlice.reducer;
