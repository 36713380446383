import {LinearProgress, styled} from "@mui/material";
import {linearProgressClasses} from "@mui/material/LinearProgress";


export const productImageFormate = (data) => {
    const hasKey = data.some(obj => obj.hasOwnProperty('uid'));
    let newArray = [];

    if (hasKey) {
        newArray = data?.map((obj, index) => {
            const newObj = {url: obj.imageUrl, id: obj.uid};

            if (index === 0) {
                newObj.isMain = true;
            } else {
                newObj.isMain = false;
            }
            return newObj;
        });
        return newArray;
    } else {
        newArray = data.map((obj, index) => {
            if (index === 0) {
                return { ...obj, isMain: true };
            } else {
                return { ...obj, isMain: false };
            }
        });
        return newArray;
    }
}

export const galleryImageFormat = (data) => {
    return {
        url: data.imageUrl,
        id: data.uid,
        isMain: null,
    };
}


export const addImageStyle = {
    position: 'relative',
    height: 282,
    width: 191,
    border: '1px solid',
    borderColor: 'other.divider',
    borderRadius: 0.8,
    cursor: 'move',
    overflow: 'hidden',
};
export const itemData = [
    {
        id: 1,
        img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
        title: 'Bed'
    },
    {
        id: 2,
        img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
        title: 'Books'
    },
    {
        id: 3,
        img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
        title: 'Sink'
    },
    // {id: 4, img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3', title: 'Kitchen'},
    // {id: 5, img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3', title: 'Blinds'},
    // {id: 6, img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622', title: 'Chairs'},
    // {id: 7, img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77', title: 'Laptop'},
    // {id: 8, img: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61', title: 'Doors'},
    // {id: 9, img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7', title: 'Coffee'},
    // {id: 10, img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee', title: 'Storage'},
    // {id: 11, img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62', title: 'Candle'},
    // {
    //     id: 12,
    //     img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
    //     title: 'Coffee table'
    // }
];

export const BorderLinearSuccessProgress = styled(LinearProgress)(({theme}) => ({
    // height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.other.progressPrimary
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.action.active
    }
}));

export const BorderLinearErrorProgress = styled(LinearProgress)(({theme}) => ({
    // height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.error.main
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.error.main
    }
}));

export const StyledButton = {
    '&.MuiButton-outlined': {
        color: 'common.white',
        borderColor: 'common.white',
        mb: 1.2
    },

    '&.MuiButton-contained': {
        color: 'common.black',
        bgcolor: 'common.white'
    }
};