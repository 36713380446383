import {useRoutes} from 'react-router-dom';
import AuthenticationRoutes from './AuthenticationRoutes';
import OnboardingRoutes from './OnboardingRoutes';
import MainRoutes from './MainRoutes';
import {useSelector} from 'react-redux';
import {selectProfile} from '../state/features/Profile/profileSelector';
import {useEffect, useState} from 'react';
import {
  getRoleWiseHiddenKeysForAdmin,
  getRoleWiseHiddenKeysForTeamMember,
  routesFilterByHiddenKey
} from '../helpers/utility/Routefiltering';
import OnboardingLoadingRoutes from './OnboardingLoadingRoutes';
import PublicPageRoutes from './PublicPageRoutes';

export default function ThemeRoutes() {
  const {myProfile} = useSelector(selectProfile);
  const {role, isTeamMember, status} = myProfile?.data || {};
  const [mainRouteList, setMainRouteList] = useState(MainRoutes);
  const [onboardingRouteList, setOnboardingRouteList] = useState(OnboardingRoutes);
  const [onboardingLoadingRouteList, setOnboardingLoadingRouteList] =
    useState(OnboardingLoadingRoutes);

  useEffect(() => {
    if (isTeamMember) {
      const roleWiseHiddenKeys = getRoleWiseHiddenKeysForTeamMember(role, status);
      if (Array.isArray(roleWiseHiddenKeys) && roleWiseHiddenKeys.length > 0) {
        const mainRoutes = routesFilterByHiddenKey(MainRoutes, roleWiseHiddenKeys);
        const onboardingRoutes = routesFilterByHiddenKey(OnboardingRoutes, roleWiseHiddenKeys);
        const onboardingLoadingRoutes = routesFilterByHiddenKey(
          OnboardingLoadingRoutes,
          roleWiseHiddenKeys
        );
        setMainRouteList(mainRoutes);
        setOnboardingRouteList(onboardingRoutes);
        setOnboardingLoadingRouteList(onboardingLoadingRoutes);
      }
    } else if (!isTeamMember) {
      const roleWiseHiddenKeys = getRoleWiseHiddenKeysForAdmin(role, status);
      if (Array.isArray(roleWiseHiddenKeys) && roleWiseHiddenKeys.length > 0) {
        const mainRoutes = routesFilterByHiddenKey(MainRoutes, roleWiseHiddenKeys);
        const onboardingRoutes = routesFilterByHiddenKey(OnboardingRoutes, roleWiseHiddenKeys);
        const onboardingLoadingRoutes = routesFilterByHiddenKey(
          OnboardingLoadingRoutes,
          roleWiseHiddenKeys
        );
        setMainRouteList(mainRoutes);
        setOnboardingRouteList(onboardingRoutes);
        setOnboardingLoadingRouteList(onboardingLoadingRoutes);
      }
    }
  }, [role]);
  // console.log("==========mainRouteList==============", mainRouteList);
  // console.log("==========onboardingRoutes==============", onboardingRouteList);
  // console.log("==========onboardingLoadingRouteList==============", onboardingLoadingRouteList);

  return useRoutes([
    mainRouteList,
    onboardingRouteList,
    onboardingLoadingRouteList,
    AuthenticationRoutes,
    PublicPageRoutes
  ]);
}
