import React from 'react';
import {Box, Checkbox, FormControlLabel, FormGroup, Typography} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {setGenderPreference} from "../../../state/features/Products/AddProduct/AddProductSlice";
import {GenderPreference} from "../../../helpers/utility/GenderPreferenesLists";

const ProductGenderPreference = ({control, setValue}) => {
    const {genderPreference} = useSelector(selectAddProduct);
    const dispatch = useDispatch();

    const handleGenderPreference = (e, value) => {
        dispatch(setGenderPreference(value));
    }

    return (
        <Box mb={5}>
            <Typography variant="h5" mb={3}>
                Product Gender Preference
            </Typography>
            <FormGroup>
                {
                    GenderPreference?.map((items) => {
                        if (items?.value === 'ALL') {
                            return null;
                        } else {
                            return (
                                <FormControlLabel
                                    key={items?.id}
                                    control={<Checkbox
                                        checked={genderPreference === items?.value}
                                        onClick={(e) => handleGenderPreference(e, items.value)}
                                    />}
                                    label={items.name}
                                />
                            )
                        }
                    })
                }
            </FormGroup>

        </Box>
    );
};

export default ProductGenderPreference;
