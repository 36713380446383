import React, {useEffect, useState} from 'react';
import {Box, Button, ButtonGroup, MenuItem, Stack, TextField} from '@mui/material';
import filterIcon from '../../../assets/images/Icons/FilterIcon.svg';
import DeleteIcon from "@mui/icons-material/Delete";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import {useDispatch, useSelector} from "react-redux";
import {
    multipleDeleteProductData, multipleDeleteProductStateClear,
    multipleRestoreProductData, multipleRestoreProductStateClear,
    multipleTrashProductData, multipleTrashProductStateClear
} from "../../../state/features/Products/ProductList/ProductListSlice";
import {productStatus} from "../../../helpers/utility/TableContents";
import {MOVE__TO__RESTORE, MOVE__TO__TRASH} from "../../../helpers/constant/coreConstant";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import MultipleTrashModal from "../../../assets/global/Modal/MultipleTrashModal";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import {selectProductList} from "../../../state/features/Products/ProductList/ProductListSelector";
import restoreIcon from "../../../assets/images/Icons/restoreIcon.svg";
import MultipleRestoreModal from "../../../assets/global/Modal/MultipleRestoreModal";
import MultipleDeleteModal from "../../../assets/global/Modal/MultipleDeleteModal";

const categories = [
    {
        value: 'Select',
        label: 'Select'
    },
    {
        value: 'Shirt',
        label: 'Shirt'
    }
];

const MyProductsFilter = ({
                              selected,
                              setSelected,
                              productId,
                              setSearchText,
                              handleCategorySelect,
                              trashedSelected,
                              setTrashedSelected,
                              category,
                          }) => {
    const {multipleTrashProduct, multipleDeleteProduct, multipleRestoreProduct} = useSelector(selectProductList);
    const [trashModal, setTrashModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);
    const dispatch = useDispatch();
    const handleMultipleDelete = () => {
        if (trashedSelected?.length > 0) {
            dispatch(multipleDeleteProductData({
                uids: trashedSelected,
                category: category
            }));
            setTrashedSelected([]);

        } else if (selected?.length > 0) {
            dispatch(multipleTrashProductData({
                uids: selected,
                inTrash: MOVE__TO__TRASH,
                category: category
            }))
            setSelected([]);
        } else {
            showErrorMessage("Select products!");
        }
    }

    useEffect(() => {
        if (multipleDeleteProduct?.isMultipleProductDelete) {
            setDeleteModal(false);
            dispatch(multipleDeleteProductStateClear())
        }
    }, [multipleDeleteProduct?.isMultipleProductDelete]);

    useEffect(() => {
        if (multipleTrashProduct?.isMultipleProductTrashed) {
            setTrashModal(false);
            dispatch(multipleTrashProductStateClear())
        }
    }, [multipleTrashProduct?.isMultipleProductTrashed]);

    const handleMultipleRestore = () => {
        if (trashedSelected?.length > 0) {
            dispatch(multipleRestoreProductData({
                uids: trashedSelected,
                inTrash: MOVE__TO__RESTORE,
                status: category
            }));
            setTrashedSelected([]);
        } else {
            showErrorMessage('Select products!');
        }
    }

    useEffect(() => {
        if (multipleRestoreProduct?.isMultipleProductRestore) {
            setRestoreModal(false);
            dispatch(multipleRestoreProductStateClear())
        }
    }, [multipleRestoreProduct?.isMultipleProductRestore]);

    return (
        <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={2} p={2}>
                    <TextField
                        id="outlined-required"
                        label="Search"
                        type={'search'}
                        placeholder={'Product name...'}
                        onChange={(e) => setSearchText(e.target.value)}
                    />

                    <TextField
                        id="outlined-select-category"
                        defaultValue={'ALL'}
                        select
                        label="Category"
                        sx={{minWidth: 156}}
                        onChange={handleCategorySelect}
                    >
                        {productStatus?.map(option => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Stack justifyContent={'center'} sx={{cursor: 'pointer'}}>
                        <img src={filterIcon} alt="Filter Icon"/>
                    </Stack>
                </Stack>

                {
                    selected?.length > 0 ? (
                        <Box sx={{marginRight: '10px'}}>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon/>}
                                onClick={() => setTrashModal(true)}
                            >
                                Trash items ({selected?.length} Selected)
                            </Button>
                        </Box>
                    ) : trashedSelected?.length > 0 && (
                        <Box sx={{marginRight: '10px'}}>
                            <ButtonGroup
                                disableElevation
                                variant="outlined"
                                aria-label="Disabled elevation buttons"
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="success"
                                    startIcon={<img height={20} width={20} src={restoreIcon} alt=""/>}
                                    onClick={() => setRestoreModal(true)}
                                >
                                    Restore Items ({trashedSelected?.length} Selected)
                                </Button>

                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteIcon/>}
                                    onClick={() => setDeleteModal(true)}
                                >
                                    Delete Items ({trashedSelected?.length} Selected)
                                </Button>
                            </ButtonGroup>
                        </Box>
                    )
                }
            </Stack>

            <BasicWithHeaderModal width={460} open={trashModal} header={false}>
                <MultipleTrashModal
                    isLoading={multipleTrashProduct?.isLoading}
                    handleModalClose={() => setTrashModal(false)}
                    onMultipleTrashed={handleMultipleDelete}
                    itemLength={selected?.length > 0 && selected}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={deleteModal} header={false}>
                <MultipleDeleteModal
                    isLoading={multipleDeleteProduct?.isLoading}
                    handleModalClose={() => setDeleteModal(false)}
                    onMultipleDelete={handleMultipleDelete}
                    itemLength={trashedSelected?.length > 0 && trashedSelected}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={restoreModal} header={false}>
                <MultipleRestoreModal
                    isLoading={multipleRestoreProduct?.isLoading}
                    handleModalClose={() => setRestoreModal(false)}
                    onMultipleRestore={handleMultipleRestore}
                    itemLength={selected?.length > 0 ? selected : trashedSelected}/>
            </BasicWithHeaderModal>
        </>
    );
};

export default MyProductsFilter;
