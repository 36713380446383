import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
    getDashboardCountsFailed,
    getDashboardCountsSuccess,
    getGraphDataFailed,
    getGraphDataSuccess, getTopSavedProductFailed, getTopSavedProductSuccess
} from "./dashboardSlice";
import DashboardService from "../../../services/Dashboard.service";

function* dashboardWatcher() {
    yield takeEvery('dashboard/getGraphData', getGraphDataSaga);
    yield takeEvery('dashboard/getDashboardCounts', getDashboardCountSaga);
    yield takeEvery('dashboard/getTopSavedProduct', getTopSavedProductSaga);
}

function* getGraphDataSaga(action) {
    try {
        const response = yield call(DashboardService.graphData, action.payload);
        if (response?.success) {
            yield put(getGraphDataSuccess(response?.data));
        } else {
            yield put(getGraphDataFailed(response?.message));
        }
    } catch (err) {
        yield put(getGraphDataFailed(err.message));
    }
}

function* getDashboardCountSaga(action) {
    try {
        const response = yield call(DashboardService.dashboardCounts, action.payload);
        if (response?.success) {
            yield put(getDashboardCountsSuccess(response?.data));
        } else {
            yield put(getDashboardCountsFailed(response?.message));
        }
    } catch (err) {
        yield put(getDashboardCountsFailed(err.message));
    }
}

function* getTopSavedProductSaga(action) {
    try {
        const response = yield call(DashboardService.topProducts, action.payload);
        if (response?.success) {
            yield put(getTopSavedProductSuccess(response?.data));
        } else {
            yield put(getTopSavedProductFailed(response?.message));
        }
    } catch (err) {
        yield put(getTopSavedProductFailed(err.message));
    }
}
export default function* dashboardSaga() {
    yield all([dashboardWatcher()]);
}
