import React from 'react';
import {Stack, Typography} from '@mui/material';
import AppleApp from '../../../assets/images/AppleAppStore.svg';

const DownloadSection = ({wrapperStyle, description}) => {
  return (
    <Stack
      alignItems={'center'}
      spacing={3}
      sx={{
        position: 'relative',
        zIndex: 9,
        p: {xs: 2, sm: 5},
        bgcolor: 'primary.main',
        borderRadius: 4,
        mt: -4,
        textAlign: 'center',
        ...wrapperStyle
      }}
    >
      <Typography variant="h6" color={'common.white'} px={{xs: 1, sm: 5}}>
        {description}
      </Typography>

        <a href="https://apps.apple.com/us/app/find-the-perfect-outfit/id6463143954" target="_blank" rel="app store">
            <img height={40} width={120} src={AppleApp} alt="Download from App Store " />
        </a>

    </Stack>
  );
};

export default DownloadSection;
