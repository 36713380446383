import React from 'react';
import Logo from '../../../assets/images/LogoDarkText.svg';
import LogoLight from '../../../assets/images/LogoLight.svg';
import {Stack} from '@mui/material';

const HeaderWithLogo = ({light}) => {
  const logo = !light ? Logo : LogoLight;
  return (
    <Stack alignItems={'center'} sx={{paddingBlock: {xs: 2, md: 4}}}>
      <img width={96.5} src={logo} alt="FTPO Logo" />
    </Stack>
  );
};

export default HeaderWithLogo;
