import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {useNavigate} from "react-router-dom";
import {useDrag, useDrop} from "react-dnd";
import {setModalOpen} from "../../../state/features/Products/AddProduct/AddProductSlice";
const useImageDnD = ({moveCard, index, id}) => {
    const {selectedImages, isSetImage} = useSelector(selectAddProduct);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ctaHover, setCtaHover] = useState(null);
    const [imageCrop, setImageCrop] = useState(false);
    const [productImage, setProductImages] = useState(null);

    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));


    const handleAdjustImage = (imageUrl, index) => {
        if (imageUrl) {
            const image = new Image();
            image.src = imageUrl;

            image.onload = () => {
                dispatch(setModalOpen({
                    index: index,
                    imageLink: imageUrl,
                    width: image.width,
                    height: image.height
                }));
            };
        }
    }

    useEffect(() => {
        if (selectedImages) {
            setProductImages(selectedImages)
        }

    }, [isSetImage]);

    const handleHover = index => setCtaHover(index);

    return {
        ref,
        handlerId,
        moveCard,
        ctaHover,
        imageCrop,
        setImageCrop,
        handleHover,
        handleAdjustImage,
    };
}
export default useImageDnD;