import {
    PROMOTION_STATUS_ACTIVE, PROMOTION_STATUS_COMPLETED,
    PROMOTION_STATUS_INACTIVE, PROMOTION_STATUS_PAUSED,
    PROMOTION_STATUS_REQUESTED
} from "../../../helpers/constant/coreConstant";
import React from "react";
import {Button, Chip} from "@mui/material";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export const PromotionTableStatus = ({promotionStatus}) => {
    switch (promotionStatus) {
        case PROMOTION_STATUS_ACTIVE:
            return (
                <Chip variant="outlined" color="info" label={'Active'} />
            );

        case PROMOTION_STATUS_INACTIVE:
            return (
                <Chip variant="outlined" color="error" label={'Inactive'} />
            );

        case PROMOTION_STATUS_REQUESTED:
            return (
                <Chip variant="outlined" color="warning" label={'Requested'} />
            );

        case PROMOTION_STATUS_COMPLETED:
            return (
                <Chip variant="outlined" color="success" label={'Completed'} />
            );

        case PROMOTION_STATUS_PAUSED:
            return (
                <Chip
                    icon={<PauseCircleFilledOutlinedIcon color={'error'}/>}
                    label="Paused"
                    variant="outlined"
                    color={'error'}
                />
            );

        default:
            break;
    }
}

export const PublishedFeatured = ({status, id, loading, handleApply}) => {
    switch (status) {
        case PROMOTION_STATUS_ACTIVE:
            return (
                <LoadingButton
                    size={'small'}
                    color={'error'}
                    variant="contained"
                    isLoading={loading}
                    loadingText={'Applying...'}
                    onClick={() => handleApply(id, PROMOTION_STATUS_COMPLETED)}
                >
                    Paused <PauseCircleFilledOutlinedIcon /> /Complete <TaskAltIcon />
                </LoadingButton>
            );

        case PROMOTION_STATUS_INACTIVE:
            return (
                <LoadingButton
                    size={'small'}
                    isLoading={loading}
                    loadingText={'Applying...'}
                    variant="contained"
                    onClick={() => handleApply(id, PROMOTION_STATUS_REQUESTED)}
                >
                    Requested to publish
                </LoadingButton>
            );

        case PROMOTION_STATUS_REQUESTED:
            return (
                <LoadingButton
                    size={'small'}
                    isLoading={loading}
                    loadingText={'Applying...'}
                    variant="contained"
                    color={'error'}
                    onClick={() => handleApply(id, PROMOTION_STATUS_INACTIVE)}
                >
                    Cancel Request
                </LoadingButton>
            );

        case PROMOTION_STATUS_PAUSED:
            return (
                <LoadingButton
                    size={'small'}
                    isLoading={loading}
                    loadingText={'Applying...'}
                    variant="contained"
                    color={'secondary'}
                    onClick={() => handleApply(id, PROMOTION_STATUS_ACTIVE)}
                >
                    Active Again
                </LoadingButton>
            );

        case PROMOTION_STATUS_COMPLETED:
            return (
                <Chip color="success" label={'Published'} />
            );

        default:
            break;
    }
};

export const PromotionWinners = ({uid, status, rewarded, handleDeclareWinner}) => {
    switch (rewarded) {
        case false:
            if (status === PROMOTION_STATUS_COMPLETED) {
                return (
                    <Button
                        variant="contained"
                        sx={{borderRadius: '20px'}}
                        color={'warning'}
                        onClick={() => handleDeclareWinner(uid)}
                    >
                        Declare winners
                    </Button>
                );
            } else if (status === PROMOTION_STATUS_REQUESTED) {
                return (
                    <Chip color="warning" label={'Requested to publish'} />
                );
            } else if (status === PROMOTION_STATUS_INACTIVE) {
                return (
                    <Chip color="error" label={'Promotion Inactive'} />
                );
            }else if (status === PROMOTION_STATUS_PAUSED) {
                return (
                    <Chip color="error" label={'Promotion Paused'} />
                );
            } else {
                return (
                    <Chip color="warning" label={'Promotion is running'} />
                );
            }

        case true:
            return (
                <Chip variant="outlined" color="success" label={'Already declared'} />
            );

        default:
            break;
    }
}