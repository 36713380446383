import React from 'react';
import {Box, Divider, Modal, Paper, Stack, styled, Typography} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
const PaperStyle = styled(Paper)(({theme}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #222',
    boxShadow: 24,
    maxHeight: '90vh',
    overflowY: 'auto',
}));
const ScrollableModal = ({
                             modalTitle,
                             open,
                             onClose,
                             width = 700,
                             children,
                             disableOutSideClick = false,
                             header = true,
                             headerBorder = true,
}) => {
    return (
        <Modal
            open={open}
            onClose={!disableOutSideClick ? onClose : () => false}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                <PaperStyle sx={{width: width, border: 0, boxShadow: 'none'}}>
                    {header && (
                        <Stack
                            direction={'row'}
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{padding: '16px'}}
                        >
                            <Typography component={'span'} variant="h6" color="text.primary">
                                {modalTitle}
                            </Typography>
                            <IconButton onClick={onClose}>
                                {/* <img src={closeFilled} alt="" /> */}
                                <Close fontSize={'medium'} />
                            </IconButton>
                        </Stack>
                    )}

                    {headerBorder && <Divider />}

                    <Box>{children}</Box>
                </PaperStyle>
            </Box>
        </Modal>
    );
};

export default ScrollableModal;