import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PublicProfileCard } from '../PublicProfileStyle';
import DownloadSection from '../common/DownloadSection';
import HeaderWithLogo from '../common/HeaderWithLogo';
import PublicProfileInfo from '../common/PublicProfileInfo';
import PublicProfileStat from '../common/PublicProfileStat';
import UserImageGallery from './UserImageGallery';
import { useParams } from 'react-router-dom';
import { selectPromotion } from '../../../state/features/Promotions/promotionSelector';
import { selectPublicPage } from '../../../state/features/publicPage/publicPageSelector';
import { getUserProfile } from '../../../state/features/publicPage/publicPageSlice';
import PublicPageSkeletonLoader from '../common/PublicPageSkeletonLoader';

const itemData = [
    'https://images.unsplash.com/photo-1516762689617-e1cffcef479d?auto=format&fit=crop&q=80&w=2022&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1594750863984-8be99377ce41?auto=format&fit=crop&q=80&w=2069&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1525507119028-ed4c629a60a3?auto=format&fit=crop&q=80&w=1935&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1613053342567-924891457d16?auto=format&fit=crop&q=80&w=1856&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://plus.unsplash.com/premium_photo-1670444333487-0064c6399f88?auto=format&fit=crop&q=80&w=1974&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1525507119028-ed4c629a60a3?auto=format&fit=crop&q=80&w=1935&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
];

const UserProfile = () => {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const { userProfile } = useSelector(selectPublicPage);
    const STAT_DATA = [
        {
            id: 1,
            stat: userProfile?.data?.followers ? userProfile?.data?.total_followers : '0',
            label: 'Followers'
        },
        {
            id: 2,
            stat: userProfile?.data?.following ? userProfile?.data?.total_following : '0',
            label: 'Following'
        },
        {
            id: 3,
            stat: userProfile?.data?.saves ? userProfile?.data?.total_save_product : '0',
            label: 'Saves'
        }
    ];
    useEffect(() => {
        dispatch(getUserProfile({ uid: userId }))
    }, [userId])
    return (
        <Stack height={'100vh'} alignItems={'center'} spacing={{ xs: 1, md: 5 }}>
            <HeaderWithLogo />
            {
                userProfile.isLoading ? (
                    <PublicPageSkeletonLoader />
                ) : (
                    <PublicProfileCard variant="outlined" elevation={0}>
                        <PublicProfileInfo imageUrl={userProfile?.data?.profileImage && userProfile?.data?.profileImage} label={'USER'} title={userProfile?.data?.lastName ? userProfile?.data?.firstName + " " + userProfile?.data?.lastName : userProfile?.data?.firstName} />

                        <PublicProfileStat description={userProfile?.data?.description && userProfile?.data?.description} profileState={STAT_DATA} />

                        <UserImageGallery images={itemData} wrapperStyle={{ height: { xs: 100, sm: 300 } }} />

                        <DownloadSection description={`To view ${userProfile?.data?.firstName || userProfile?.data?.lastName ? userProfile?.data?.firstName + userProfile?.data?.lastName : 'user'}’s full profile, download the app`} />
                    </PublicProfileCard>
                )
            }


        </Stack>
    );
};

export default UserProfile;
