import { Box, Grid, List, ListItem, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'

const ViewPromotionSekeleton = () => {
    return (
        <Grid
            container
            sx={{
                overflowY: 'auto'
            }}
        >
            <Grid item sm={12} lg={5}>
                <Box
                    px={5}
                    py={4}
                >
                    <Skeleton
                        animation="wave"
                        variant='rounded'
                        width={400}
                        height={350}
                    />
                </Box>
            </Grid>
            <Grid
                borderLeft={{ lg: '1px solid' }}
                borderColor={{ lg: 'other.divider' }}
                sm={12}
                lg={7}
            >
                <Grid
                    px={5}
                    py={4}
                >
                    <Skeleton
                        animation="wave"
                        variant='rounded'
                        width={'60%'}
                        height={35}
                        sx={{ marginBottom: '10px' }}
                    />
                    <Skeleton
                        animation="wave"
                        variant='rounded'
                        width={'100%'}
                        height={150}
                        sx={{ marginBottom: '10px' }}
                    />

                    <Skeleton
                        animation="wave"
                        variant='rounded'
                        width={'40%'}
                        height={30}
                        sx={{ marginBottom: '10px' }}
                    />
                    <Skeleton
                        animation="wave"
                        variant='rounded'
                        width={'40%'}
                        height={20}
                        sx={{ marginBottom: '10px' }}
                    />
                </Grid>
                <Grid
                    borderTop={{ lg: '1px solid' }}
                    borderColor={{ lg: 'other.divider' }}
                    py={4}
                    px={5}
                >
                    <Skeleton
                        animation="wave"
                        variant='rounded'
                        width={'60%'}
                        height={35}
                        sx={{ marginBottom: '10px' }}
                    />
                    <Skeleton
                        animation="wave"
                        variant='rounded'
                        width={'100%'}
                        height={200}
                        sx={{ marginBottom: '10px' }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ViewPromotionSekeleton
