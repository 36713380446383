import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {Box, FormHelperText, TextField, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {registerPattern} from "../../helpers/constant/validationPattern";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import {useNavigate} from "react-router-dom";
import {showSuccessMessage} from "../../helpers/utility/Toastify";
import {attemptForgetPassword} from "../../state/features/user/userSlice";

const ForgetPasswordForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {forgetPasswordState} = useSelector(state => state.user);

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });

    const onSubmit = async (data) => {
        if (data) {
            await dispatch(attemptForgetPassword(data))
        } else {
        }
    };

    useEffect(() => {
        if (forgetPasswordState?.isMailSend) {
            setTimeout(() => {
                navigate('/reset-password');
            }, [2000])
        }
        reset();
    }, [forgetPasswordState?.isMailSend])

    return (
        <>
            <Box textAlign={'center'} mb={6}>
                <Typography variant="h3" mb={0} color={'text.primary'}>
                    Forgot Password?
                </Typography>
                <Typography variant="body1" color={'text.secondary'}>
                    Don’t worry. Enter your mail & we’ll send you a password recovery link.
                </Typography>
            </Box>

            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box component={'span'}>
                    <Controller
                        control={control}
                        name="email"
                        defaultValue=""
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Email Address"
                                    fullWidth
                                    type="email"
                                    value={value}
                                    onChange={onChange}
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={registerPattern.email}
                    />
                    <LoadingButton
                        isLoading={forgetPasswordState?.isLoading}
                        fullWidth
                        loadingText={'Recovering...'}
                        type={'submit'}
                        variant="contained"
                        sx={{mb: 6}}
                    >
                        Recover Password
                    </LoadingButton>
                </Box>
            </Box>
        </>
    );

}

export default ForgetPasswordForm;