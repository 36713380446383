import { Chip, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPromotion } from "../../../state/features/Promotions/promotionSelector";
import { editPromotionStateClear } from "../../../state/features/Promotions/promotionSlice";
import ViewPromotionSekeleton from "./ViewPromotionSekeleton";

const dateFormate = (date) => {
    let newDate = new Date(date);
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    let formattedDate = newDate.toLocaleDateString('en-US', options);
    return formattedDate;
}
const ViewPromotion = () => {
    const dispatch = useDispatch();
    const { editPromotion } = useSelector(selectPromotion);
    useEffect(() => {
        return () => {
            dispatch(editPromotionStateClear());
        }
    }, []);

    return (
        <>
            {
                editPromotion.isLoading ? (
                    <ViewPromotionSekeleton />
                ) : (
                    <Grid
                        container
                        sx={{
                            overflowY: 'auto'
                        }}
                    >
                        <Grid item sm={12} lg={5}>
                            <Box
                                py={4}
                                px={5}
                            >
                                {
                                    editPromotion?.data?.bannerUrl ? (
                                        <img src={editPromotion?.data?.bannerUrl} width={'390px'} height={'320px'} style={{ borderRadius: '6px' }} alt="" />
                                    ) : (
                                        <Box
                                            sx={{
                                                width: '390px',
                                                height: '320px',
                                                backgroundColor: '#D3D3D3',
                                                borderRadius: '6px'
                                            }}
                                        >
                                        </Box>
                                    )
                                }
                            </Box>
                        </Grid>
                        <Grid
                            borderLeft={{ lg: '1px solid' }}
                            borderColor={{ lg: 'other.divider' }}
                            item
                            sm={12}
                            lg={7}
                        >
                            <Stack
                                py={4}
                                px={5}
                                spacing={2}
                            >
                                <Box>
                                    <Typography variant="h6">{editPromotion?.data?.name && editPromotion?.data?.name}</Typography>
                                    <Chip sx={{ mt: '5px' }} size="small" label={editPromotion?.data?.promotionType === 1 ? `Brand Promotion` : `Product Promotion`} />
                                </Box>
                                <Typography variant="p" fontWeight={400} lineHeight={'22px'}>
                                    {editPromotion?.data?.description && editPromotion?.data?.description}
                                </Typography>
                                {
                                    editPromotion?.data?.productName && (
                                        <Stack>
                                            <Typography variant="alertDescription" color={'action.active'}>
                                                Product Name
                                            </Typography>
                                            <Typography variant="body1medium" color={'text.primary'}>
                                                {editPromotion?.data?.productName.substring(0, 45) + '...'}
                                            </Typography>
                                        </Stack>
                                    )
                                }

                                <Stack>
                                    <Typography variant="alertDescription" color={'action.active'}>
                                        Start Date
                                    </Typography>
                                    <Typography variant="body1medium" color={'text.primary'}>
                                        {dateFormate(editPromotion?.data?.startDate)}
                                    </Typography>
                                </Stack>

                                <Stack>
                                    <Typography variant="alertDescription" color={'action.active'}>
                                        End Date
                                    </Typography>
                                    <Typography variant="body1medium" color={'text.primary'}>
                                        {dateFormate(editPromotion?.data?.endDate)}
                                    </Typography>
                                </Stack>

                            </Stack>
                            <Box
                                py={4}
                                px={5}
                                borderTop={{ lg: '1px solid' }}
                                borderColor={{ lg: 'other.divider' }}
                            >
                                <Typography variant="body1medium" color={'text.primary'}>
                                    Rewards & Policies
                                </Typography>


                                <List sx={{ listStyleType: 'disc', mt: `0 !important`, pl: 3 }}>
                                    <ListItem sx={{ display: 'list-item', p: 0.5, }}>
                                        <Typography variant="body2">
                                            {editPromotion?.data?.rewardsAndPolicies}
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
        </>

    )
}
export default ViewPromotion;