import React from 'react';
import {MenuItem, Stack, TextField} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import filterIcon from "../../assets/images/Icons/FilterIcon.svg";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import {useSelector} from "react-redux";
import {selectGallery} from "../../state/features/Gallery/GalleryImageDelete/gallerySelector";

const searchOrder = [
    {
        value: 'asc',
        label: 'Ascending'
    },
    {
        value: 'desc',
        label: 'Descending'
    }
];
const MyGalleryFilter = ({imageIds, onDelete, setSearchText, setSortedBy}) => {
    const {isLoading} = useSelector(selectGallery);
    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2}>
                <TextField
                    id="outlined-required"
                    label="Search"
                    type={'search'}
                    placeholder={'Image name...'}
                    onChange={(e) => setSearchText(e.target.value)}
                />

                <TextField
                    id="outlined-select-category"
                    select
                    label="Sort By"
                    defaultValue={'desc'}
                    sx={{minWidth: 156}}
                    onChange={(e) => setSortedBy(e.target.value)}
                >
                    {searchOrder.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Stack justifyContent={'center'} sx={{cursor: 'pointer'}}>
                    <img src={filterIcon} alt="Filter Icon"/>
                </Stack>
            </Stack>

            {imageIds.length > 0 && (
                <span>
                    <LoadingButton
                        isLoading={isLoading}
                        loadingText={'Deleting...'}
                        size="small"
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon/>}
                        onClick={onDelete}
                    >
                       Delete Images ({imageIds.length} Selected)
                    </LoadingButton>
                </span>
            )}
        </Stack>
    );
};

export default MyGalleryFilter;
