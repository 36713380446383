import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel, Link,
    OutlinedInput,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {registerPattern} from "../../helpers/constant/validationPattern";
import {useDispatch, useSelector} from "react-redux";
import {attemptSignUp, attemptSignUpStateClear} from "../../state/features/user/userSlice";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import PhoneIcon from '@mui/icons-material/Phone';

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const {signUpState} = useSelector(state => state.user)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    useEffect(() => {
        if (signUpState?.isSignUpSuccess) {
            reset();
            dispatch(attemptSignUpStateClear());
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        }
    }, [signUpState?.pending])

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });
    let pwd = watch("password");

    const onSubmit = async (data) => {
        if (data) {
            await dispatch(attemptSignUp(data))

        } else {
        }
    };

    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}
            sx={{pt: {md: 5}}}
        >
            <Box
                sx={{
                    maxWidth: '540px',
                    padding: '48px',
                    bgcolor: 'common.white',
                    borderRadius: '12px'
                }}
            >
                <Box textAlign={'center'} mb={6}>
                    <Typography variant="h3" color={'text.primary'}>
                        Create Brand Account
                    </Typography>
                </Box>
                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Box component={'span'}>
                        <Controller
                            control={control}
                            name="name"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                                    <TextField
                                        label="Brand Name"
                                        fullWidth
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={registerPattern.name}
                        />
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                                    <TextField
                                        label="Email Address"
                                        fullWidth
                                        focused
                                        type="email"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={registerPattern.email}
                        />
                        <Controller
                            control={control}
                            name="storeContactPersonName"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                                    <TextField
                                        label="Contact Person Name"
                                        fullWidth
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={registerPattern.contract_person_name}
                        />
                        <Controller
                            control={control}
                            name="storeContactPersonPhone"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl variant="outlined" sx={{mb: 2}} fullWidth
                                             error={!!(invalid && error.message)}>
                                    <InputLabel htmlFor="outlined-adornment-phone">Contact Person Phone</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-phone"
                                        type="tel"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <PhoneIcon sx={{ fontSize: 18 }}/> +1
                                            </InputAdornment>
                                        }
                                        label="Contact Person Phone"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={registerPattern.contract_person_phone}
                        />
                        <Controller
                            control={control}
                            name="password"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl variant="outlined" sx={{mb: 2}} fullWidth focused
                                             error={!!(invalid && error.message)}>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={registerPattern.password}
                        />
                        <Controller
                            control={control}
                            name="confirmPassword"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl variant="outlined" sx={{mb: 2}} fullWidth
                                             error={!!(invalid && error.message)}>
                                    <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm
                                        Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-confirm-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            // rules={registerPattern.confirm_password}
                            rules={{
                                required: "You must specify a password!",
                                validate: value => value === pwd || "The passwords do not match!"
                            }}
                        />

                        <LoadingButton
                            isLoading={signUpState?.pending}
                            loadingText={'Creating account...'}
                            type={'submit'}
                            variant="contained"
                        >
                            Create Brand Account
                        </LoadingButton>
                    </Box>
                </Box>
                <Box mt={5} sx={{
                    textAlign: "center",
                }}>
                    <Typography variant="body1" color={'text.secondary'} textAlign={'center'}>
                        Already have an account? {' '}
                        <Link fontWeight={600} component={RouterLink} to="/login" underline="none">
                            Login
                        </Link>
                    </Typography>
                </Box>
            </Box>

            <Box textAlign={'center'} mt={4}>
                <Typography variant="body2">@ 2023 FTPO - All Rights Reserved</Typography>
                <Typography variant="body2" color={'other.primaryDark'}>
                    <Link href="https://findtheperfectoutfit.com/terms-and-conditions" target="_blank" rel="noopener" underline="none">
                        Terms of services & privacy policy
                    </Link>
                </Typography>
            </Box>
        </Stack>
    );
};

export default SignUp;
