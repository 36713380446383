import React, {useEffect, useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {Backdrop, Box, Button, Chip, CircularProgress, Grid, Stack, Typography} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {selectProfile} from "../../state/features/Profile/profileSelector";
import {
    changePasswordClearState,
    changePasswordData,
    getMyProfileData,
    updateMyProfileData
} from "../../state/features/Profile/profileSlice";
import ScrollableModal from "../../assets/global/Modal/ScrollableModal";
import EditMyProfileForm from "./EditMyProfileForm";
import ImageInput from "../../assets/global/ImageCropper/ImageInput";
import BasicWithHeaderModal from "../../assets/global/Modal/BasicWithHeaderModal";
import ChangePasswordForm from "./ChangePasswordForm";
import {showErrorMessage} from "../../helpers/utility/Toastify";

const MyProfile = () => {
    const {myProfile, updateMyProfileProfile} = useSelector(selectProfile);
    const {isLoading} = updateMyProfileProfile || {};
    const {changePassword} = useSelector(selectProfile);
    const {isChangePassword} = changePassword;
    const dispatch = useDispatch();
    const [myProfileEditModal, setMyProfileEditModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [image, setImage] = useState('');
    let firstName = null;
    let lastName = null;

    useEffect(() => {
        if (!myProfile?.data) {
            dispatch(getMyProfileData());
        }
        if (myProfile?.data?.profileLogo) {
            setImage(myProfile?.data?.profileLogo);
        }
    }, [myProfile?.data]);

    useEffect(() => {
        if (image?.photo?.data) {
            if (!isLoading) {
                let formData = new FormData();
                formData.append('profileLogo', image?.photo?.data);
                dispatch(updateMyProfileData(formData));
                setImage(myProfile?.data?.profileLogo);
            }
        }
    }, [image?.photo?.data]);

    if (myProfile?.data) {
        const wordList = myProfile?.data?.name?.split(' ');
        if (wordList?.length >= 2) {
            firstName = wordList[0];
            lastName = wordList[1];
        } else if (wordList?.length === 1) {
            firstName = wordList[0];
            lastName = null;
        } else {
            firstName = null;
            lastName = null;
        }
    }
    useEffect(() => {
        if (isChangePassword) {
            setChangePasswordModal(false);
            dispatch(changePasswordClearState());
        }
    }, [isChangePassword]);

    const handleChangePassword = (data) => {
        if (data?.password !== data?.confirmPassword) {
            showErrorMessage('Password do not match!');
        } else if (data?.password === data?.confirmPassword) {
            dispatch(changePasswordData(data));
        } else {
            showErrorMessage('Something went wrong!');
        }
    }

    return (
        <>
            <Grid
                container
                sx={{
                    overflowY: 'auto'
                }}
            >
                <Grid item sm={12} lg={3.55}>
                    <Box
                        py={4}
                        px={5}
                        borderRight={{lg: '1px solid'}}
                        borderColor={{lg: 'other.divider'}}
                        sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                    >
                        <Stack spacing={3} alignItems={'center'} textAlign={'center'}>
                            <Box>
                                <ImageInput
                                    image={image}
                                    setImage={setImage}
                                    type="1"
                                />
                                <Backdrop
                                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                    open={isLoading}
                                >
                                    <CircularProgress color="inherit"/>
                                </Backdrop>
                            </Box>

                            <Box>
                                <Typography variant="h5" color={'text.primary'} mb={1}>
                                    {myProfile?.data?.name ? myProfile?.data?.name : ''}
                                </Typography>
                                {
                                    myProfile?.data?.role && (
                                        <Chip
                                            size="small"
                                            label={(myProfile?.data?.role === 'Admin' && !myProfile?.data?.isTeamMember) ? 'Brand Admin' : myProfile?.data?.role}
                                            sx={{height: 18, fontSize: 10, fontWeight: 500, color: 'text.secondary'}}
                                        />)
                                }
                            </Box>

                            <Button
                                variant="outlined"
                                startIcon={<EditIcon/>}
                                onClick={() => setMyProfileEditModal(true)}
                            >
                                Edit Profile
                            </Button>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item sm={12} lg={8.45}>
                    <Stack
                        py={4}
                        px={5}
                        borderBottom={{lg: '1px solid'}}
                        borderColor={{lg: 'other.divider'}}
                        spacing={4}
                        // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                    >
                        <Stack>
                            <Typography variant="alertDescription" color={'action.active'}>
                                First Name
                            </Typography>
                            <Typography variant="body1medium" color={'text.primary'}>
                                {firstName}
                            </Typography>
                        </Stack>

                        <Stack>
                            <Typography variant="alertDescription" color={'action.active'}>
                                Last Name
                            </Typography>
                            <Typography variant="body1medium" color={'text.primary'}>
                                {lastName}
                            </Typography>
                        </Stack>

                        <Stack>
                            <Typography variant="alertDescription" color={'action.active'}>
                                Phone Number
                            </Typography>
                            <Typography variant="body1medium" color={'text.primary'}>
                                {myProfile?.data?.phone !== null ? `+1 (${myProfile?.data?.phone?.slice(0, 3)}) ${myProfile?.data?.phone?.slice(4)}` : 'Phone number is not given!'}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        py={4}
                        px={5}
                        direction={'row'}
                        spacing={20}
                        // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                    >
                        <Stack>
                            <Typography variant="body1medium" color={'text.primary'}>
                                Email Address
                            </Typography>
                            <Typography variant="alertDescription" color={'action.active'}>
                                {myProfile?.data?.email ? myProfile?.data?.email : ''}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        // py={4}
                        px={5}
                        direction={'row'}
                        spacing={20}
                        // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                    >
                        <Stack>
                            <Typography variant="body1medium" color={'text.primary'}>
                                Password
                            </Typography>
                            <Typography variant="alertDescription" color={'action.active'}>
                                **********
                            </Typography>
                        </Stack>
                        <Button
                            variant="text"
                            size={'small'}
                            onClick={() => setChangePasswordModal(true)}
                        >
                            Change Password
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <ScrollableModal
                modalTitle="Edit Your Profile"
                width={500}
                open={myProfileEditModal}
                onClose={() => setMyProfileEditModal(false)}
            >
                <EditMyProfileForm setMyProfileEditModal={setMyProfileEditModal}/>
            </ScrollableModal>

            <BasicWithHeaderModal
                modalTitle="Change Password"
                width={500}
                open={changePasswordModal}
                onClose={() => setChangePasswordModal(false)}
            >
                <ChangePasswordForm
                    setChangePasswordModal={setChangePasswordModal}
                    onSubmit={handleChangePassword}
                />
            </BasicWithHeaderModal>
        </>
    );
};

export default MyProfile;
