import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import NoAccessImg from '../../assets/images/noAccess.png';
import BaseWrapper from '../../assets/global/wrapper/BaseWrapper';
import {STATUS__BLOCKED, STATUS__PENDING, STATUS__REJECTED} from "../../helpers/constant/coreConstant";

const DashboardLocked = ({status}) => {
    let title;
    if (status === STATUS__PENDING) {
        title = (
            <Typography variant="h3">
                You're pending until <br/> admin approved you!
            </Typography>
        );
    } else if (status === STATUS__BLOCKED) {
        title = (
            <Typography variant="h3">
                You're blocked until <br/> admin unblocked you!
            </Typography>
        );
    } else if (status === STATUS__REJECTED) {
        title = (
            <Typography variant="h3">
                You're rejected until <br/> admin accept you!
            </Typography>
        );
    } else {
        title = (
            <Typography variant="h3">
                Restricted until <br/> you’re approved!
            </Typography>
        );
    }

    return (
        <Box
            bgcolor={'other.bgPaperElevation'}
            height={'calc(100vh - 48px)'}
            display={'grid'}
            sx={{placeContent: 'center'}}
        >
            <BaseWrapper width={540} borderRadius={1.4} p={6}>
                <Stack spacing={0} alignItems={'center'} justifyContent={'center'} textAlign={'center'}>

                    {title}

                    <Box>
                        <img src={NoAccessImg} alt=""/>
                    </Box>

                    <Box>
                        <Typography variant="body2" color={'text.secondary'}>
                            Please wait while we work on approving <br/> your account. For enquiry, contact us at{' '}
                        </Typography>

                        <Typography
                            variant="body1"
                            fontWeight={600}
                            color={'text.secondary'}
                            sx={{textDecoration: 'underline'}}
                        >
                            support@ftpo.com
                        </Typography>
                    </Box>
                </Stack>
            </BaseWrapper>
        </Box>
    );
};

export default DashboardLocked;
