import React from 'react';
import Box from "@mui/material/Box";
import restoreModalIcon from "../../images/Icons/restoreModalIcon.svg";
import {Button, Stack, Typography} from "@mui/material";
import LoadingButton from "../Button/LoadingButton";

const SingleRestoreModal = ({handleModalClose, onSingleRestore, isLoading}) => {
    return (
        <>
            <Box p={'64px 40px 48px 40px'} textAlign={'center'}>
                <img src={restoreModalIcon} alt="" width={120} height={150}/>

                <Typography variant="h5" mt={3} mb={1}>
                    Are you sure you want to <br/> restore{' '}
                    this item?
                </Typography>
                <Typography variant="body2" color={'text.secondary'}>
                    Once restored, you can trash it again.
                </Typography>
            </Box>

            <Stack
                p={'16px'}
                sx={{borderTop: 1, borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={1}
            >
                <Button
                    sx={{color: 'text.primary'}}
                    variant="text"
                    onClick={handleModalClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    isLoading={isLoading}
                    loadingText={'Restoring...'}
                    variant="contained"
                    color="success"
                    onClick={onSingleRestore}
                >
                    Restore
                </LoadingButton>
            </Stack>
        </>
    );
}
export default SingleRestoreModal;
