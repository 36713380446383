import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createTeamTableData, TeamMemberListHeadCell} from "../../helpers/utility/TableContents";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import EnhancedTableHead from "../../assets/global/TableHeader/EnhancedTableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {Avatar, Chip, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {
    MOVE__TO__RESTORE,
    MOVE__TO__TRASH,
    STATUS__ACTIVE,
    STATUS__BLOCKED,
    STATUS__PENDING,
    STATUS__REJECTED,
    STATUS__TRASHED
} from "../../helpers/constant/coreConstant";
import editIcon from "../../assets/images/Icons/editIcon.svg";
import deleteIcon from "../../assets/images/Icons/deleteIcon.svg";
import TablePagination from "@mui/material/TablePagination";
import BasicWithHeaderModal from "../../assets/global/Modal/BasicWithHeaderModal";
import SingleTrashModal from "../../assets/global/Modal/SingleTrashModal";
import {selectTeamMember} from "../../state/features/TeamMember/teamMemberSelector";
import {
    changeTeamMemberStatus,
    editTeamMemberStateClear,
    getTeamMemberList,
    singleDeleteTeamMemberData,
    singleRestoreTeamMemberData, singleRestoreTeamMemberStateClear,
    singleTrashTeamMemberData
} from "../../state/features/TeamMember/teamMemberSlice";
import SingleDeleteModal from "../../assets/global/Modal/SingleDeleteModal";
import RoleChangeButton from "./RoleChangeButton";
import SingleRestoreModal from "../../assets/global/Modal/SingleRestoreModal";
import restoreIcon from "../../assets/images/Icons/restoreIcon.svg";
import BlockIcon from '@mui/icons-material/Block';
import BlockUserModalContent from "./BlockUserModalContent";
import UnblockUserModalContent from "./UnblockUserModalContent";
import {showErrorMessage} from "../../helpers/utility/Toastify";
import {
    singleDeleteProductStateClear,
    singleTrashProductStateClear
} from "../../state/features/Products/ProductList/ProductListSlice";

const TeamMemberListTable = ({
                                 selected,
                                 setSelected,
                                 setTrashedSelected,
                                 trashedSelected,
                                 category,
                                 searchText,
                             }) => {
    const {teamMemberList, singleTrashTeamMember, singleDeleteTeamMember, singleRestoreTeamMember} = useSelector(selectTeamMember);
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('uid');
    const [trashModal, setTrashModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);
    const [blockUserModal, setBlockUserModal] = useState(false);
    const [unblockUserModal, setUnblockUserModal] = useState(false);
    const [singleTeamMemberId, setSingleTeamMemberId] = useState(null);

    const rows =
        teamMemberList?.data?.map((items, index) => (
            createTeamTableData([items?.name, items?.email], items?.status, items?.roleUid, '', items?.uid, items?.profileLogo)
        ));
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n.uid);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleTrashSelected = (event, id) => {
        if (selected?.length > 0) {
            setSelected([]);
        }
        const selectedIndex = trashedSelected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(trashedSelected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(trashedSelected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(trashedSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                trashedSelected.slice(0, selectedIndex),
                trashedSelected.slice(selectedIndex + 1)
            );
        }
        setTrashedSelected(newSelected);
    };

    const handleCategorySelected = (event, id) => {
        if (trashedSelected?.length > 0) {
            setTrashedSelected([]);
        }
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = id => selected.indexOf(id) !== -1;
    const isTrashedSelected = id => trashedSelected.indexOf(id) !== -1;

    const emptyRows = (teamMemberList?.page - 1) > 0 ? Math.max(0, (1 + (teamMemberList?.page - 1)) * teamMemberList?.perPage - teamMemberList?.total) : 0;

    const onSingleRestoreModalOpen = (id) => {
        if (id) {
            setRestoreModal(true);
            setSingleTeamMemberId(id);
        }
    }
    const onSingleTeamMemberRestore = () => {
        if (singleTeamMemberId) {
            dispatch(singleRestoreTeamMemberData({
                uid: singleTeamMemberId,
                inTrash: MOVE__TO__RESTORE,
                category: category,
                searchInputText: searchText ? searchText : '',
            }));
            setSingleTeamMemberId(null);
        }
    }
    useEffect(() => {
        if (singleRestoreTeamMember?.isSingleTeamMemberRestore) {
            setRestoreModal(false);
            dispatch(singleRestoreTeamMemberStateClear());
        }
    }, [singleRestoreTeamMember?.isSingleTeamMemberRestore]);
    const onSingleTrashModalOpen = (id) => {
        if (id) {
            setTrashModal(true);
            setSingleTeamMemberId(id);
        }
    }
    const onSingleTeamMemberTrashed = () => {
        if (singleTeamMemberId) {
            dispatch(singleTrashTeamMemberData({
                uid: singleTeamMemberId,
                inTrash: MOVE__TO__TRASH,
                category: category,
                searchInputText: searchText ? searchText : '',
            }));
        }
    }
    useEffect(() => {
        if (singleTrashTeamMember?.isTeamMemberTrash) {
            setTrashModal(false);
            dispatch(singleTrashProductStateClear());
        }
    }, [singleTrashTeamMember?.isTeamMemberTrash]);

    const onSingleDeleteModalOpen = (id) => {
        if (id) {
            setDeleteModal(true);
            setSingleTeamMemberId(id);
        }
    }
    const onSingleTeamMemberDelete = () => {
        if (singleTeamMemberId) {
            dispatch(singleDeleteTeamMemberData({
                teamMemberUid: singleTeamMemberId,
                category: category,
                searchInputText: searchText ? searchText : '',
            }));
        }
    }
    useEffect(() => {
        if (singleDeleteTeamMember?.isSingleDeleteTeamMember) {
            setDeleteModal(false);
            dispatch(singleDeleteProductStateClear())
        }
    }, [singleDeleteTeamMember?.isSingleDeleteTeamMember]);

    const handleChangePage = (event, newPage) => {
        dispatch(getTeamMemberList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
            page: (newPage + 1),
            per_page: teamMemberList?.perPage,
        }));
    };

    const handleChangeRowsPerPage = event => {
        let row = parseInt(event.target.value, 10);
        dispatch(getTeamMemberList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
            page: teamMemberList?.page,
            per_page: row,
        }));
    };
    const handleSelectOnlyTrash = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n?.uid);
            setTrashedSelected(newSelected);
            return;
        }
        setTrashedSelected([]);
    };

    const handleUnblockTeamMember = (id) => {
        if (id) {
            setUnblockUserModal(true);
            setSingleTeamMemberId(id);
        } else {
            showErrorMessage('Team-member data not found!')
        }
    }
    const handleBlockTeamMember = (id) => {
        if (id) {
            setBlockUserModal(true);
            setSingleTeamMemberId(id);
        } else {
            showErrorMessage('Team-member data not found!')
        }
    }

    const handleBlockUser = () => {
        if (singleTeamMemberId) {
            dispatch(changeTeamMemberStatus({
                teamMemberUid: singleTeamMemberId,
                status: STATUS__BLOCKED,
                category: category,
                searchInputText: searchText ? searchText : '',
            }));
            setSingleTeamMemberId(null);
        } else {
            showErrorMessage('Team-member data not found!')
        }
    }
    const handleUnblockUser = () => {
        if (singleTeamMemberId) {
            dispatch(changeTeamMemberStatus({
                teamMemberUid: singleTeamMemberId,
                status: STATUS__ACTIVE,
                category: category,
                searchInputText: searchText ? searchText : '',
            }));
            setSingleTeamMemberId(null);
        } else {
            showErrorMessage('Team-member data not found!')
        }
    }

    return (
        <Box sx={{width: '100%'}}>
            <TableContainer>
                <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        tableHeader={TeamMemberListHeadCell}
                        numSelected={selected?.length > 0 ? selected.length : trashedSelected?.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={category === STATUS__TRASHED ? handleSelectOnlyTrash : handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={teamMemberList?.total}
                    />
                    <TableBody>
                        {rows?.map((row, index) => {
                            const isItemSelected = isSelected(row?.uid);
                            const isTrashSelected = isTrashedSelected(row?.uid);
                            const labelId = `enhanced-table-checkbox-${row?.uid}`;

                            return (
                                <TableRow
                                    key={row?.uid}
                                    hover
                                    role="checkbox"
                                    aria-checked={isItemSelected || isTrashSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected || isTrashSelected}
                                    sx={{cursor: 'pointer'}}
                                >
                                    <TableCell padding="checkbox">
                                        {
                                            category === STATUS__TRASHED ? (
                                                <Checkbox
                                                    color="primary"
                                                    onClick={event => handleTrashSelected(event, row.uid)}
                                                    checked={isTrashSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            ) : (
                                                <Checkbox
                                                    color="primary"
                                                    onClick={event => handleCategorySelected(event, row.uid)}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell component="th" id={labelId} scope="row" padding="none">
                                        <Stack direction={'row'} spacing={1.4}>
                                            {
                                                row?.profileLogo ? (
                                                    <Avatar src={row?.profileLogo} alt="User Logo"/>
                                                ) : (
                                                    <Avatar alt="User Logo"/>
                                                )
                                            }

                                            <Stack>
                                                <Typography variant="body2medium">{row.name[0]}</Typography>
                                                <Typography variant="alertDescription" color={'text.secondary'}>
                                                    {row.name[1]}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </TableCell>

                                    <TableCell align="center">
                                        {row?.status === STATUS__PENDING &&
                                            <Chip label={'PENDING'} color="warning"/>
                                        }
                                        {row?.status === STATUS__ACTIVE &&
                                            <Chip label={'APPROVED'} color="success"/>
                                        }
                                        {row?.status === STATUS__BLOCKED &&
                                            <Chip label={'BLOCKED'} color="error"/>
                                        }
                                        {row?.status === STATUS__REJECTED &&
                                            <Chip label={'REJECTED'} color="error"/>
                                        }
                                        {row?.status === STATUS__TRASHED &&
                                            <Chip label={'TRASHED'} color="error"/>
                                        }
                                    </TableCell>

                                    <TableCell align="center">
                                        <Box>
                                            <RoleChangeButton
                                                category={category}
                                                role={row?.role}
                                                teamMemberUid={row?.uid}
                                                searchText={searchText}
                                            />
                                        </Box>
                                    </TableCell>

                                    <TableCell align="center">
                                        <Stack
                                            display={'inline-flex'}
                                            direction={'row'}
                                            spacing={1}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                        >
                                            {
                                                category === STATUS__TRASHED ? (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="restore"
                                                        onClick={() => onSingleRestoreModalOpen(row?.uid)}
                                                    >
                                                        <img src={restoreIcon} alt=""/>
                                                    </IconButton>
                                                ) : (
                                                    <Link to={`/editMember/${row?.uid}`}
                                                          style={{textDecoration: 'none'}}
                                                          onClick={() => dispatch(editTeamMemberStateClear())}>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="edit"
                                                        >
                                                            <img src={editIcon} alt=""/>
                                                        </IconButton>
                                                    </Link>
                                                )
                                            }
                                            {
                                                row?.status === STATUS__BLOCKED ? (
                                                    <Tooltip title={'Unblock user'}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleUnblockTeamMember(row?.uid)}
                                                        >
                                                            <BlockIcon color="success"/>
                                                        </IconButton>
                                                    </Tooltip>

                                                ) : (
                                                    <Tooltip title={'Block user'}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleBlockTeamMember(row?.uid)}
                                                        >
                                                            <BlockIcon sx={{color: 'red'}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                category === STATUS__TRASHED ? (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={() => onSingleDeleteModalOpen(row?.uid)}
                                                    >
                                                        <img src={deleteIcon} alt=""/>
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={() => onSingleTrashModalOpen(row?.uid)}
                                                    >
                                                        <img src={deleteIcon} alt=""/>
                                                    </IconButton>
                                                )
                                            }
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={teamMemberList?.total}
                rowsPerPage={teamMemberList?.perPage}
                page={(teamMemberList?.page - 1)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <BasicWithHeaderModal width={460} open={trashModal} header={false}>
                <SingleTrashModal
                    isLoading={singleTrashTeamMember?.isLoading}
                    onSingleTrash={onSingleTeamMemberTrashed}
                    handleModalClose={() => setTrashModal(false)}
                />
            </BasicWithHeaderModal>

            <BasicWithHeaderModal width={460} open={deleteModal} header={false}>
                <SingleDeleteModal
                    isLoading={singleDeleteTeamMember?.isLoading}
                    onSingleDelete={onSingleTeamMemberDelete}
                    handleModalClose={() => setDeleteModal(false)}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={restoreModal} header={false}>
                <SingleRestoreModal
                    isLoading={singleRestoreTeamMember?.isLoading}
                    onSingleRestore={onSingleTeamMemberRestore}
                    handleModalClose={() => setRestoreModal(false)}
                />
            </BasicWithHeaderModal>

            <BasicWithHeaderModal width={460} open={blockUserModal} header={false}>
                <BlockUserModalContent
                    handleBlockUser={handleBlockUser}
                    handleModalClose={() => setBlockUserModal(false)}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={unblockUserModal} header={false}>
                <UnblockUserModalContent
                    handleUnblockUser={handleUnblockUser}
                    handleModalClose={() => setUnblockUserModal(false)}
                />
            </BasicWithHeaderModal>
        </Box>
    );
}

export default TeamMemberListTable;
