import AuthLayout from '../components/Layout/AuthLayout/AuthLayout';
import {Navigate} from 'react-router-dom';
import Login from '../components/AuthUser/Login';
import SignUp from '../components/AuthUser/SignUp';
import ForgotPassword from '../components/AuthUser/ForgotPassword';
import ResetPassword from '../components/AuthUser/ResetPassword';
import PasswordChangeSuccess from "../components/AuthUser/PasswordChangeSuccess";
import routeLink from "../helpers/constant/routeLink";
import Error501 from "../components/ErrorPage/Error501";
import ForceLoginRedirectPage from "../components/AuthUser/ForceLoginRedirectPage";

const AuthenticationRoutes = {
    path: '/',
    element: <AuthLayout/>,
    children: [
        {
            path: '',
            element: <Login element={<Navigate to="login" replace={true}/>}/>
        },
        {
            path: routeLink.login.to,
            element: <Login/>
        },
        {
            path: routeLink.register.to,
            element: <SignUp/>
        },
        {
            path: routeLink.forgetPassword.to,
            element: <ForgotPassword/>
        },
        {
            path: routeLink.resetPassword.to,
            element: <ResetPassword/>
        },
        {
            path: '/reset-password-success',
            element: <PasswordChangeSuccess/>
        },
        {
            path: '/force-login/:token',
            element: <ForceLoginRedirectPage/>,
        },
        {
            path: '*',
            element: <Error501/>
        },
    ]
};

export default AuthenticationRoutes;
