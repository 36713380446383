import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    addBanner: {
        data: null,
        isAddBanner: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    bannerList: {
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
        isLoading: false,
        isError: false,
        error: '',
    },
    editBanner: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    updateBanner: {
        data: null,
        isBannerUpdate: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleTrashBanner: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleTrashBanner: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleDeleteBanner: {
        isSingleBannerDelete: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleDeleteBanner: {
        isMultipleDeleteBanner: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    applyFeatures: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    visibility: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    draftBanner: {
        data: null,
        isLoading: false,
        isError: false,
        error: '',
    }
};

const bannerSlice = createSlice({
    name: 'banner',
    initialState: initialState,
    reducers: {
        addBannerData: (state, action) => {
            state.addBanner.isLoading = true;
        },
        addBannerSuccess: (state, action) => {
            state.addBanner.data = action?.payload?.data;
            state.addBanner.isAddBanner = true;
            state.addBanner.isLoading = false;
            state.addBanner.isError = false;
            state.addBanner.error = '';
        },
        addBannerFailed: (state, action) => {
            state.addBanner.data = null;
            state.addBanner.isAddBanner = false;
            state.addBanner.isLoading = false;
            state.addBanner.isError = true;
            state.addBanner.error = action.payload;
        },
        addBannerStateClear: (state, action) => {
            state.addBanner.data = null;
            state.addBanner.isAddBanner = false;
            state.addBanner.isLoading = false;
            state.addBanner.isError = false;
            state.addBanner.error = '';
        },


        draftBannerData: (state, action) => {
            state.draftBanner.isLoading = true;
        },
        draftBannerSuccess: (state, action) => {
            state.draftBanner.data = action?.payload?.data;
            state.draftBanner.isAddBanner = true;
            state.draftBanner.isLoading = false;
            state.draftBanner.isError = false;
            state.draftBanner.error = '';
        },
        draftBannerFailed: (state, action) => {
            state.draftBanner.data = null;
            state.draftBanner.isAddBanner = false;
            state.draftBanner.isLoading = false;
            state.draftBanner.isError = true;
            state.draftBanner.error = action.payload;
        },
        draftBannerStateClear: (state, action) => {
            state.draftBanner.data = null;
            state.draftBanner.isAddBanner = false;
            state.draftBanner.isLoading = false;
            state.draftBanner.isError = false;
            state.draftBanner.error = '';
        },


        getBannerList: (state, action) => {
            state.bannerList.isLoading = true;
        },
        getBannerListSuccess: (state, action) => {
            state.bannerList.data = action.payload?.data;
            state.bannerList.page = action.payload?.current_page;
            state.bannerList.perPage = action.payload?.per_page;
            state.bannerList.total = action.payload?.total;
            state.bannerList.isLoading = false;
            state.bannerList.isError = false;
            state.bannerList.error = '';
        },
        getBannerListFailed: (state, action) => {
            state.bannerList.data = null;
            state.bannerList.isLoading = false;
            state.bannerList.total = 0;
            state.bannerList.isError = true;
            state.bannerList.error = action.payload;
        },
        getBannerListStateClear: (state, action) => {
            state.bannerList.data = null;
            state.bannerList.isLoading = false;
            state.bannerList.isError = false;
            state.bannerList.error = '';
        },


        editBannerData: (state, action) => {
            state.editBanner.isLoading = true;
        },
        editBannerSuccess: (state, action) => {
            state.editBanner.data = action?.payload;
            state.editBanner.isLoading = false;
            state.editBanner.isError = false;
            state.editBanner.error = '';
        },
        editBannerFailed: (state, action) => {
            state.editBanner.data = null;
            state.editBanner.isLoading = false;
            state.editBanner.isError = true;
            state.editBanner.error = action.payload;
        },
        editBannerStateClear: (state, action) => {
            state.editBanner.data = null;
            state.editBanner.isLoading = false;
            state.editBanner.isError = false;
            state.editBanner.error = '';
        },

        updateBannerData: (state, action) => {
            state.updateBanner.isLoading = true;
        },
        updateBannerSuccess: (state, action) => {
            state.updateBanner.data = action?.payload;
            state.updateBanner.isBannerUpdate = true;
            state.updateBanner.isLoading = false;
            state.updateBanner.isError = false;
            state.updateBanner.error = '';
        },
        updateBannerFailed: (state, action) => {
            state.updateBanner.data = null;
            state.updateBanner.isBannerUpdate = false;
            state.updateBanner.isLoading = false;
            state.updateBanner.isError = true;
            state.updateBanner.error = action.payload;
        },
        updateBannerStateClear: (state, action) => {
            state.updateBanner.data = null;
            state.updateBanner.isBannerUpdate = false;
            state.updateBanner.isLoading = false;
            state.updateBanner.isError = false;
            state.updateBanner.error = '';
        },


        singleTrashBannerData: (state, action) => {
            state.singleTrashBanner.isLoading = true;
        },
        singleTrashBannerSuccess: (state, action) => {
            state.singleTrashBanner.data = action?.payload?.data;
            state.singleTrashBanner.isLoading = false;
            state.singleTrashBanner.isError = false;
            state.singleTrashBanner.error = '';
        },
        singleTrashBannerFailed: (state, action) => {
            state.singleTrashBanner.data = null;
            state.singleTrashBanner.isLoading = false;
            state.singleTrashBanner.isError = true;
            state.singleTrashBanner.error = action.payload;
        },
        singleTrashBannerStateClear: (state, action) => {
            state.singleTrashBanner.data = null;
            state.singleTrashBanner.isLoading = false;
            state.singleTrashBanner.isError = false;
            state.singleTrashBanner.error = '';
        },


        multipleTrashedBannerData: (state, action) => {
            state.multipleTrashBanner.isLoading = true;
        },
        multipleTrashBannerSuccess: (state, action) => {
            state.multipleTrashBanner.data = action?.payload?.data;
            state.multipleTrashBanner.isLoading = false;
            state.multipleTrashBanner.isError = false;
            state.multipleTrashBanner.error = '';
        },
        multipleTrashBannerFailed: (state, action) => {
            state.multipleTrashBanner.data = null;
            state.multipleTrashBanner.isLoading = false;
            state.multipleTrashBanner.isError = true;
            state.multipleTrashBanner.error = action.payload;
        },
        multipleTrashBannerStateClear: (state, action) => {
            state.multipleTrashBanner.data = null;
            state.multipleTrashBanner.isLoading = false;
            state.multipleTrashBanner.isError = false;
            state.multipleTrashBanner.error = '';
        },


        singleDeleteBannerData: (state, action) => {
            state.singleDeleteBanner.isLoading = true;
        },
        singleDeleteBannerSuccess: (state, action) => {
            state.singleDeleteBanner.isSingleBannerDelete = true;
            state.singleDeleteBanner.isLoading = false;
            state.singleDeleteBanner.isError = false;
            state.singleDeleteBanner.error = '';
        },
        singleDeleteBannerFailed: (state, action) => {
            state.singleDeleteBanner.isSingleBannerDelete = false;
            state.singleDeleteBanner.isLoading = false;
            state.singleDeleteBanner.isError = true;
            state.singleDeleteBanner.error = action.payload;
        },
        singleDeleteBannerStateClear: (state, action) => {
            state.singleDeleteBanner.isSingleBannerDelete = false;
            state.singleDeleteBanner.isLoading = false;
            state.singleDeleteBanner.isError = false;
            state.singleDeleteBanner.error = '';
        },


        multipleDeletedBannerData: (state, action) => {
            state.multipleDeleteBanner.isLoading = true;
        },
        multipleDeleteBannerSuccess: (state, action) => {
            state.multipleDeleteBanner.isMultipleDeleteBanner = true;
            state.multipleDeleteBanner.isLoading = false;
            state.multipleDeleteBanner.isError = false;
            state.multipleDeleteBanner.error = '';
        },
        multipleDeleteBannerFailed: (state, action) => {
            state.multipleDeleteBanner.isMultipleDeleteBanner = false;
            state.multipleDeleteBanner.isLoading = false;
            state.multipleDeleteBanner.isError = true;
            state.multipleDeleteBanner.error = action.payload;
        },
        multipleDeleteBannerStateClear: (state, action) => {
            state.multipleDeleteBanner.isMultipleDeleteBanner = false;
            state.multipleDeleteBanner.isLoading = false;
            state.multipleDeleteBanner.isError = false;
            state.multipleDeleteBanner.error = '';
        },

        applyFeaturesData: (state, action) => {
            state.applyFeatures.isLoading = true;
        },
        applyFeaturesSuccess: (state, action) => {
            state.applyFeatures.data = action?.payload?.data;
            state.applyFeatures.isLoading = false;
            state.applyFeatures.isError = false;
            state.applyFeatures.error = '';
        },
        applyFeaturesFailed: (state, action) => {
            state.applyFeatures.data = null;
            state.applyFeatures.isLoading = false;
            state.applyFeatures.isError = true;
            state.applyFeatures.error = action.payload;
        },
        applyFeaturesStateClear: (state, action) => {
            state.visibility.data = null;
            state.visibility.isLoading = false;
            state.visibility.isError = false;
            state.visibility.error = '';
        },

        setVisibility: (state, action) => {
            state.visibility.isLoading = true;
        },
        setVisibilitySuccess: (state, action) => {
            state.visibility.data = action?.payload?.data;
            state.visibility.isLoading = false;
            state.visibility.isError = false;
            state.visibility.error = '';
        },
        setVisibilityFailed: (state, action) => {
            state.visibility.data = null;
            state.visibility.isLoading = false;
            state.visibility.isError = true;
            state.visibility.error = action.payload;
        },
        setVisibilityStateClear: (state, action) => {
            state.visibility.data = null;
            state.visibility.isLoading = false;
            state.visibility.isError = false;
            state.visibility.error = '';
        },
    }
});

export const {
    addBannerData,
    addBannerSuccess,
    addBannerFailed,
    addBannerStateClear,

    draftBannerData,
    draftBannerSuccess,
    draftBannerFailed,
    draftBannerStateClear,

    getBannerList,
    getBannerListSuccess,
    getBannerListFailed,
    getBannerListStateClear,

    editBannerData,
    editBannerSuccess,
    editBannerFailed,
    editBannerStateClear,

    updateBannerData,
    updateBannerSuccess,
    updateBannerFailed,
    updateBannerStateClear,

    singleTrashBannerData,
    singleTrashBannerSuccess,
    singleTrashBannerFailed,
    singleTrashBannerStateClear,

    multipleTrashedBannerData,
    multipleTrashBannerSuccess,
    multipleTrashBannerFailed,
    multipleTrashBannerStateClear,

    singleDeleteBannerData,
    singleDeleteBannerSuccess,
    singleDeleteBannerFailed,
    singleDeleteBannerStateClear,

    multipleDeletedBannerData,
    multipleDeleteBannerSuccess,
    multipleDeleteBannerFailed,
    multipleDeleteBannerStateClear,

    applyFeaturesData,
    applyFeaturesSuccess,
    applyFeaturesFailed,
    applyFeaturesStateClear,

    setVisibility,
    setVisibilitySuccess,
    setVisibilityFailed,
    setVisibilityStateClear,


} = bannerSlice.actions;

export default bannerSlice.reducer;
