import React, {useState} from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Button, Tooltip} from '@mui/material';
import ContentWrapper from '../../../assets/global/wrapper/ContentWrapper/ContentWrapper';
import WrapperHeader from '../../../assets/global/wrapper/ContentWrapper/WrapperHeader';
import {useNavigate} from 'react-router-dom';
import AddProductForm from "./AddProductForm";
import {productStateClear} from "../../../state/features/Products/AddProduct/AddProductSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectProductList} from "../../../state/features/Products/ProductList/ProductListSelector";
import CoreBreadCrumbs from "../../../assets/global/BreadCrumbs/CoreBreadCrumbs";
import {myProductInfo} from "../../../helpers/constant/pageInfo";

const AddProduct = () => {
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const [appPreview, setAppPreview] = useState(false);

        return (
            <ContentWrapper>
                <WrapperHeader
                    title="Add New Product"
                    cta={
                        <Tooltip title="Add product info to see 'App Preview'" arrow>
                            <Button
                                variant="text"
                                startIcon={<VisibilityIcon/>}
                                onClick={() => setAppPreview(true)}
                            >
                                In App Preview Product
                            </Button>
                        </Tooltip>
                    }
                    backButton={() => {
                        navigate('/myProducts');
                        dispatch(productStateClear())
                    }}
                    breadCrumb={<CoreBreadCrumbs pageInfo={myProductInfo} keys={[1, 2]}/>}
                />
                <AddProductForm appPreview={appPreview} setAppPreview={setAppPreview}/>
            </ContentWrapper>
        )
    }
;

export default AddProduct;
