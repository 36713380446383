import {
    Box,
    Button,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import {showErrorMessage} from "../../helpers/utility/Toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    changeTeamMemberPassword,
    changeTeamMemberPasswordStateClear
} from "../../state/features/TeamMember/teamMemberSlice";
import {selectTeamMember} from "../../state/features/TeamMember/teamMemberSelector";

const ChangeTeamMemberPassword = ({setChangePasswordModal, teamMemberId}) => {
    const dispatch = useDispatch();
    const {changePassword} = useSelector(selectTeamMember);
    const {isLoading, isPasswordChange} = changePassword;
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        if (confirmPassword) {
            if (newPassword === confirmPassword) {
                setIsValid(false);
            } else {
                setIsValid(true);
            }
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        if (password === newConfirmPassword) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    };
    const handleChangePassword = () => {
        if (password && confirmPassword && teamMemberId) {
            if (password === confirmPassword) {
                dispatch(changeTeamMemberPassword({
                    password: password,
                    confirmPassword: confirmPassword,
                    teamMemberUid: teamMemberId,
                }));
            } else {
                showErrorMessage('Password do not match!')
            }
        } else {
            showErrorMessage('Password fields are empty!');
        }
    }

    useEffect(() => {
        if (isPasswordChange) {
            setChangePasswordModal(false);
            setPassword(null);
            setConfirmPassword(null);
            dispatch(changeTeamMemberPasswordStateClear())
        }
    }, [isPasswordChange]);

    return (
        <Box>
            <Stack p={3}>
                <FormControl variant="outlined" sx={{mb: 2}} fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={handlePasswordChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="New Password"
                    />
                    {/*<FormHelperText></FormHelperText>*/}
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm
                        Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-confirm-password"
                        onChange={handleConfirmPasswordChange}
                        type={showConfirmPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Confirm Password"
                    />
                    <FormHelperText>{isValid && (
                        <Typography color={'error'} fontSize={13}>Password do not match!</Typography>)}</FormHelperText>
                </FormControl>
            </Stack>
            <Stack
                p={'16px'}
                sx={{borderTop: 1, borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={2}
            >
                <Button
                    sx={{color: 'text.primary'}}
                    variant="text"
                    onClick={() => setChangePasswordModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleChangePassword}
                >
                    Change
                </Button>
            </Stack>
        </Box>
    );
}

export default ChangeTeamMemberPassword;