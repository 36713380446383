import {Box, Grid, Typography} from '@mui/material';

import {Outlet} from 'react-router-dom';
import PublicLayout from '../PublicLayout';
import React from 'react';
import Styles from './AuthLayout.module.css';
import {useTheme} from '@mui/material/styles';
import Logo from '../../../assets/images/Logo.svg';

const AuthLayout = () => {
  return (
    <Box>
      <PublicLayout>
        <Grid container sx={{minHeight: '100vh', bgcolor: 'other.bgPaperElevation'}}>
          {/* Left Side - Logo */}
          <Grid
            item
            md={6}
            sm={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'text.primary'
            }}
          >
            <img src={Logo} alt="FTPO Logo" />
          </Grid>

          {/* Right side */}
          <Grid item sm={12} md={6} py={5}>
            <Outlet />
          </Grid>
        </Grid>
      </PublicLayout>
    </Box>
  );
};

export default AuthLayout;
