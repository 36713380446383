import {Box, Button, Link, Typography} from "@mui/material";
import saveSuccess from "../../assets/images/saveSuccess.svg";
import {Link as RouterLink} from "react-router-dom";
import React from "react";

const PasswordChangeSuccess = () => {
    return (
        <Box textAlign={'center'}>
            <Typography variant="h3" mb={2} color={'text.primary'}>
                Password saved successfully!
            </Typography>

            <Box>
                <img src={saveSuccess} alt="Password reset successful"/>
            </Box>

            <Box component={'span'} color={'text.secondary'}>
                <Typography variant="body1" component={'p'}>
                    You can now use this new password to log in to your account & get started
                </Typography>

                <Link component={RouterLink} to="/login" underline="none">
                    <Button variant="outlined" disableElevation sx={{mt: 3}}>
                        Go Back to Log In Page
                    </Button>
                </Link>
            </Box>
        </Box>
    );
};
export default PasswordChangeSuccess;