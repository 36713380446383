import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import EachUserProfileStat from '../common/EachProfileStat';

const PublicProfileStat = ({ description, profileState }) => {
    return (
        <Stack paddingX={2} spacing={2}>
            <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} mt={3}>
                {profileState?.map((statItem, index, arr) => (
                    <React.Fragment key={statItem.id}>
                        <EachUserProfileStat state={statItem.stat} label={statItem.label} />
                        {index !== arr.length - 1 && <Divider orientation="vertical" flexItem light />}
                    </React.Fragment>
                ))}
            </Stack>

            <Stack paddingX={1}>
                <Typography variant="body2" color={'other.gray5'}>
                    {description && description}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default PublicProfileStat;
