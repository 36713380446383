import {Stack, Typography} from '@mui/material';
import React from 'react';
import favoriteIcon from '../../assets/images/Icons/favoriteStatIcon.svg';
import storeIcon from '../../assets/images/Icons/storeStatIcon.svg';
import peopleIcon from '../../assets/images/Icons/peopleStatIcon.svg';
import arrowUp from '../../assets/images/Icons/rateArrowUpIcon.svg';
import arrowDown from '../../assets/images/Icons/rateArrowDownIcon.svg';

const StatCard = ({
  type = 'favorite',
  numbers = 246,
  rate = 5.45,
  state = 'positive',
  info = 'Total Saves'
}) => {
  const renderIcon = () => {
    switch (type) {
      case 'favorite':
        return <img src={favoriteIcon} alt="" />;

      case 'store':
        return <img src={storeIcon} alt="" />;

      case 'people':
        return <img src={peopleIcon} alt="" />;

      default:
        break;
    }
  };

  return (
    <Stack direction={'row'} spacing={3} bgcolor={'common.white'} p={3} flex={1} borderRadius={1}>
      {renderIcon()}

      <Stack justifyContent={'space-between'}>
        <Typography variant="body1" color="text.secondary">
          {info}
        </Typography>

        <Stack
          direction={'row'}
          spacing={1.4}
          /* justifyContent={'flex-end'} */ alignItems={'flex-end'}
        >
          <Typography variant="h3" sx={{lineHeight: 1}}>
            {numbers}
          </Typography>

          {/*<Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>*/}
          {/*  <img src={state === 'positive' ? arrowUp : arrowDown} alt="" />*/}
          {/*  <Typography variant="info" color={state === 'positive' ? 'success.main' : 'error.main'}>*/}
          {/*    {rate}%*/}
          {/*  </Typography>*/}
          {/*</Stack>*/}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StatCard;
