import React, {useState} from 'react';
import {Drawer, IconButton, Stack} from '@mui/material';
import AppNavbarProfile from './AppNavbarProfile';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Notification from '../../Notification/Notification';

const anchor = 'right';
const AppNavbarRight = () => {
    const [notification, setNotification] = useState(false);

    const handleCloseNotification = () => {
        setNotification(false);
    };
    return (
        <>
            <Stack direction={'row'} spacing={2}>
                <IconButton aria-label="delete" onClick={() => setNotification(true)}>
                    <NotificationsIcon/>
                </IconButton>

                {/* <Divider orientation={'vertical'} flexItem /> */}

                <AppNavbarProfile/>
            </Stack>

            {/* Drawer Notification */}
            <Drawer anchor={anchor} open={notification} onClose={() => setNotification(false)}>
                <Notification handleCloseNotification={handleCloseNotification}/>
            </Drawer>
        </>
    );
};

export default AppNavbarRight;
