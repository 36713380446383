import React from 'react';
import {Checkbox, FormHelperText, Stack, TextField, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {BannerValidation} from "../../../helpers/constant/validationPattern";
import {Controller} from "react-hook-form";
import {IS__FEATURED__APPLIED, IS__FEATURED__APPLY, IS_FEATURED} from "../../../helpers/constant/coreConstant";

const BannerInfo = ({control, featured, setFeatured}) => {
    const handleFeatureBanner = (e) => {
        let value = e?.target.checked;
        if (value === true) {
            setFeatured(true);
        } else {
            setFeatured(false);
        }
    }
    return (
        <>
            <Typography variant="h6" mb={3}>
                Banner Info
            </Typography>

            <Stack spacing={5}>
                <Controller
                    control={control}
                    name="title"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Banner Title"
                                fullWidth
                                focused
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={BannerValidation.bannerTitle}
                />

                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h6">Apply to get featured</Typography>
                    <Checkbox
                        color="primary"
                        onClick={event => handleFeatureBanner(event)}
                        checked={featured === true}
                    />
                </Stack>
            </Stack>
        </>
    );
}
export default BannerInfo;