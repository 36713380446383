import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {attemptClearLogin, attemptLogin} from '../../state/features/user/userSlice';
import {FormGroup, FormHelperText, InputAdornment, InputLabel, Link, OutlinedInput, Stack} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import {registerPattern} from "../../helpers/constant/validationPattern";
import hasAuthenticatedUser from "../../helpers/utility/hasAuthenticateUser";
import LoadingButton from "../../assets/global/Button/LoadingButton";
import {getMyProfileData} from "../../state/features/Profile/profileSlice";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isLogin, pending, journeyMode} = useSelector(state => state.user);

    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });
    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        const storedPassword = localStorage.getItem('password');
        const storedRememberMe = localStorage.getItem('rememberMe');
        if (storedEmail && storedPassword) {
            setValue('email', storedEmail);
            setValue('password', storedPassword);
            setRememberMe(storedRememberMe);
        }
    }, []);

    const handleRememberMe = (e) => {
        if (e.target.checked) {
            setRememberMe(true);
        } else {
            setRememberMe(false);
        }
    }

    const onSubmit = async (data) => {
        if (rememberMe) {
            localStorage.setItem('email', data?.email);
            localStorage.setItem('password', data?.password);
            localStorage.setItem('rememberMe', rememberMe);
            await dispatch(attemptLogin(data));
        } else {
            localStorage.clear();
            await dispatch(attemptLogin(data));
        }
    };


    useEffect(() => {
        if (isLogin) {
            const hasAccessToken = hasAuthenticatedUser();
            if (hasAccessToken) {
                dispatch(getMyProfileData());
                dispatch(attemptClearLogin());
                setTimeout(() => {
                    reset();
                    navigate('/dashboard');
                }, [1000])
            }
        }
    }, [isLogin]);


    const handleClickShowPassword = () => setShowPassword(show => !show);

    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}
            sx={{pt: {md: 10}}}
        >
            <Box
                sx={{
                    maxWidth: '540px',
                    padding: '48px',
                    gap: '48px',
                    bgcolor: 'common.white',
                    borderRadius: '12px'
                }}
            >
                <Box textAlign={'center'} mb={3}>
                    <Typography variant="h3" mb={0} color={'text.primary'}>
                        Welcome
                    </Typography>
                    <Typography variant="body1" color={'text.secondary'}>
                        Log in to your account & get started
                    </Typography>
                </Box>

                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Box component={'span'}>
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Email Address"
                                        type="email"
                                        focused
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={registerPattern.email}
                        />

                        <Controller
                            control={control}
                            name="password"
                            defaultValue=""
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl variant="outlined" sx={{mb: 2}} fullWidth focused
                                             error={!!(invalid && error.message)}>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={registerPattern.password}
                        />

                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={4}>
                            <FormGroup sx={{color: 'text.secondary'}}>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onClick={handleRememberMe}
                                    />}
                                                  label="Remember me"/>
                            </FormGroup>

                            <Link component={RouterLink} to="/forgot-password" underline="none">
                                Forgot Password?
                            </Link>
                        </Stack>
                        <LoadingButton
                            isLoading={pending}
                            loadingText={'Login...'}
                            type={'submit'}
                            variant="contained"
                        >
                            Log In
                        </LoadingButton>
                    </Box>
                </Box>
                <Box mt={5} sx={{
                    textAlign: "center",
                }}>
                    <Typography variant="body1" color={'text.secondary'} textAlign={'center'}>
                        Didn't have any account? {' '}
                        <Link fontWeight={600} component={RouterLink} to="/sign-up" underline="none">
                            SignUp
                        </Link>
                    </Typography>
                </Box>
            </Box>

            <Box textAlign={'center'} mt={4}>
                <Typography variant="body2">@ 2023 FTPO - All Rights Reserved</Typography>
                <Typography variant="body2" color={'other.primaryDark'}>
                    <Link href="https://findtheperfectoutfit.com/terms-and-conditions" target="_blank" rel="noopener"
                          underline="none">
                        Terms of services & privacy policy
                    </Link>
                </Typography>
            </Box>
        </Stack>
    );
};

export default Login;
