import React, {useEffect, useState} from 'react';
import {Box, Chip, Stack, styled, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useDispatch, useSelector} from 'react-redux';
import {selectOnboardedSteps} from '../../../state/features/OnboardedSteps/OnboardedStepsSelector';
import {
    getFashionCategoriesData,
    onboarding,
    updateFashionCategories
} from '../../../state/features/OnboardedSteps/OnboardedStepsSlice';
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import {getMyProfileData} from "../../../state/features/Profile/profileSlice";

const StyledChip = styled(Chip)(({theme}) => ({
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '& .MuiChip-icon': {
        color: theme.palette.primary.main
        // Change to your desired color
    }
}));
const FashionCategory = ({activeStep, setActiveStep, steps, currentOnboardStep, myProfile}) => {
    const dispatch = useDispatch();
    const {fashionCategories, isFashionCategories} = useSelector(selectOnboardedSteps);
    const [fashionCategory, setFashionCategory] = useState([]);

    useEffect(() => {
        if (myProfile?.data?.categoryIds == undefined) {
            setFashionCategory([]);
        } else if (myProfile?.data?.categoryIds == null) {
            setFashionCategory([]);
        } else {
            setFashionCategory(JSON.parse(myProfile?.data?.categoryIds));
        }
    }, [myProfile?.data?.categoryIds]);
    const handleNext = () => {
        if (activeStep < currentOnboardStep) {
            setActiveStep(activeStep + 1);
            dispatch(updateFashionCategories({categoryIds: fashionCategory}));
        } else {
            dispatch(updateFashionCategories({categoryIds: fashionCategory}));
            dispatch(onboarding({stageNo: activeStep + 1, journeyModeOn: true}));
        }
    };
    const categoryChecked = uid => {
        let exists = fashionCategory?.includes(uid);
        if (exists) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (fashionCategories && isFashionCategories) {
            setFashionCategory(fashionCategories);
        }
    }, [dispatch, fashionCategories]);

    useEffect(() => {
        dispatch(getFashionCategoriesData());
    }, []);
    const handleChipClick = uid => {
        let exists = fashionCategory?.includes(uid);
        if (exists) {
            setFashionCategory(chips => chips.filter(id => id !== uid));
        } else {
            setFashionCategory(chips => {
                let updatedFields = [];
                updatedFields = [...chips];
                updatedFields.push(uid);
                return updatedFields;
            });
        }
    };
    return (
        <Box>
            <Typography variant="h3">Select Fashion Category</Typography>
            <Typography variant="body1" color={'text.secondary'}>
                You have to select at least 3
            </Typography>

            <Box my={6}>
                <Stack direction="row" flexWrap={'wrap'} spacing={1} rowGap={1}>
                    {fashionCategories?.categories
                        && Array.isArray(fashionCategories?.categories)
                        && fashionCategories?.categories?.map((item, index) => (
                            <StyledChip
                                key={item.uid}
                                icon={categoryChecked(item.uid) && <CheckCircleIcon/>}
                                variant={categoryChecked(item.uid) ? 'outlined' : 'contained'}
                                label={item?.categoryName}
                                onClick={() => handleChipClick(item?.uid)}
                            />
                        ))}
                </Stack>
            </Box>

            <LoadingButton
                isLoading={fashionCategories?.isLoading}
                loadingText={'Continuing...'}
                variant="contained"
                size={'medium'}
                onClick={handleNext}
                disabled={fashionCategory?.length < 3}
            >
                Continue ({fashionCategory?.length} selected)
            </LoadingButton>
        </Box>
    );
};

export default FashionCategory;
