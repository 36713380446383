import {Box} from '@mui/material';
import React from 'react';
import {Outlet} from 'react-router-dom';

const PublicPageLayout = () => {
  return (
    <>
      <Box>
        <Outlet />
      </Box>
    </>
  );
};

export default PublicPageLayout;
