import {
    IS__FEATURED__ALL,
    IS__FEATURED__APPLIED,
    IS__FEATURED__APPLY,
    IS__FEATURED__DRAFT,
    IS__FEATURED__FEATURED, PROMOTION_STATUS_ACTIVE, PROMOTION_STATUS_INACTIVE, PROMOTION_STATUS_REQUESTED,
    STATUS__ACTIVE,
    STATUS__ALL,
    STATUS__APPROVED,
    STATUS__BLOCKED,
    STATUS__DRAFT,
    STATUS__PENDING,
    STATUS__REJECTED,
    STATUS__TRASHED
} from "../constant/coreConstant";
import { Chip } from "@mui/material";
import React from "react";
import LoadingButton from "../../assets/global/Button/LoadingButton";


export const BannerTableHeaderCell = [
    {
        id: 'banner',
        cellsAlign: 'left',
        sortable: false,
        label: 'Banner'
    },

    {
        id: 'title',
        cellsAlign: 'left',
        sortable: false,
        label: 'Title'
    },
    {
        id: 'visibility',
        cellsAlign: 'center',
        sortable: false,
        label: 'Visibility'
    },
    {
        id: 'featured',
        cellsAlign: 'center',
        sortable: false,
        label: 'Featured'
    },
    {
        id: 'action',
        cellsAlign: 'center',
        sortable: false,
        label: 'Action'
    }
];
export const ProductTableHeadCell = [
    {
        id: 'name',
        cellsAlign: 'left',
        sortable: false,
        label: 'Item Name'
    },
    {
        id: 'ID',
        cellsAlign: 'center',
        sortable: true,
        label: 'ID'
    },
    {
        id: 'price',
        cellsAlign: 'center',
        sortable: true,
        label: 'Price'
    },
    {
        id: 'totalSaves',
        cellsAlign: 'center',
        sortable: true,
        label: 'Total Saves'
    },
    {
        id: 'visibility',
        cellsAlign: 'center',
        sortable: false,
        label: 'Visibility'
    },
    {
        id: 'status',
        cellsAlign: 'center',
        sortable: false,
        label: 'Status'
    },
    {
        id: 'actions',
        cellsAlign: 'center',
        sortable: false,
        label: 'Actions'
    }
];
export const PromotionTableHeadCell = [
    {
        id: 'promotionTitle',
        cellsAlign: 'left',
        sortable: false,
        label: 'Promotion Title'
    },
    {
        id: 'type',
        cellsAlign: 'center',
        sortable: false,
        label: 'Type'
    },
    {
        id: 'winners',
        cellsAlign: 'center',
        sortable: false,
        label: 'Winners (person)'
    },
    {
        id: 'shares',
        cellsAlign: 'center',
        sortable: true,
        label: 'Shares'
    },
    {
        id: 'rewarded',
        cellsAlign: 'center',
        sortable: false,
        label: 'Declare winners'
    },
    {
        id: 'status',
        cellsAlign: 'center',
        sortable: false,
        label: 'Status'
    },
    {
        id: 'publish',
        cellsAlign: 'center',
        sortable: false,
        label: 'Publish'
    },
    {
        id: 'action',
        cellsAlign: 'center',
        sortable: false,
        label: 'Action'
    }
];

export const PromotionDetailsHeadCell = [
    {
        id: 'user',
        cellsAlign: 'left',
        sortable: false,
        label: 'User'
    },
    {
        id: 'shares',
        cellsAlign: 'center',
        sortable: true,
        label: 'Shares'
    },
    {
        id: 'clicks',
        cellsAlign: 'center',
        sortable: true,
        label: 'Clicks'
    },
    {
        id: 'activations',
        cellsAlign: 'center',
        sortable: true,
        label: 'Activations'
    },
    {
        id: 'email',
        cellsAlign: 'center',
        sortable: false,
        label: 'Email'
    },
    {
        id: 'phone',
        cellsAlign: 'center',
        sortable: false,
        label: 'Phone'
    },
];
export const promotionData = [
    {
        uid: '9a757f59-560b-425c-aa0e-79b5dd26d740',
        promotionTitle: 'Fastive campaign launch stock',
        type: 'Brand',
        dateRange: '15Sep - 19Dec',
        shares: 12,
        completed: 19,
        visibility: true,
        status: 'Active',
        publish: 'Stop Promotion',
    },
    {
        uid: '9a757f59-560b-425c-aa0e-79b5dd26d741',
        promotionTitle: 'Fastive campaign launch stock',
        type: 'Product',
        dateRange: '15Sep - 20Dec',
        shares: 12,
        completed: 87,
        visibility: false,
        status: 'Inactive',
        publish: 'Requested to publish',
    },
    {
        uid: '9a757f59-560b-425c-aa0e-79b5dd26d743',
        promotionTitle: 'Fastive campaign launch stock',
        type: 'Product',
        dateRange: '15Sep - 19Dec',
        shares: 12,
        completed: 39,
        visibility: true,
        status: 'Requested',
        publish: 'Cancel Request',
    },
];

export const promotionDetailsData = [
    {
        uid: '9a757f59-560b-425c-aa0e-79b5dd26d740',
        user: 'Jone Doe',
        shares: 43,
        clicks: 66,
        activations: 31,
        email: 'jon@mail.com',
        phone: '123-211-2233',
    },
    {
        uid: '9a757f59-560b-425c-aa0e-79b5dd26d741',
        user: 'Jone Doe',
        shares: 23,
        clicks: 11,
        activations: 44,
        email: 'jon@mail.com',
        phone: '123-211-2233',
    },
    {
        uid: '9a757f59-560b-425c-aa0e-79b5dd26d721',
        user: 'Jone Doe',
        shares: 12,
        clicks: 43,
        activations: 33,
        email: 'jon@mail.com',
        phone: '123-211-2233',
    },
];

export const productStatus = [
    {
        value: STATUS__ALL,
        label: 'All'
    },
    {
        value: STATUS__APPROVED,
        label: 'Approved'
    },
    {
        value: STATUS__REJECTED,
        label: 'Rejected'
    },
    {
        value: STATUS__TRASHED,
        label: 'Trashed'
    },
    {
        value: STATUS__BLOCKED,
        label: 'Blocked'
    },
    {
        value: STATUS__PENDING,
        label: 'Pending'
    },
    {
        value: STATUS__DRAFT,
        label: 'Draft'
    }
];
export const promotionStatus = [
    {
        value: STATUS__ALL,
        label: 'All'
    },
    {
        value: 0,
        label: 'Inactive'
    },
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 2,
        label: 'Requested'
    },
    {
        value: 'TRASH',
        label: 'Trash'
    },
];

export const bannerStatus = [
    {
        value: IS__FEATURED__ALL,
        label: 'All'
    },
    {
        value: IS__FEATURED__APPLY,
        label: 'Apply'
    },
    {
        value: IS__FEATURED__DRAFT,
        label: 'Draft'
    },
    {
        value: IS__FEATURED__APPLIED,
        label: 'Applied'
    },
    {
        value: IS__FEATURED__FEATURED,
        label: 'Featured'
    },
];

export const teamMemberStatus = [
    {
        value: STATUS__ALL,
        label: 'All'
    },
    {
        value: STATUS__ACTIVE,
        label: 'Active'
    },
    {
        value: STATUS__BLOCKED,
        label: 'Blocked'
    },
    {
        value: STATUS__TRASHED,
        label: 'Trashed'
    },
    // {
    //     value: STATUS__PENDING,
    //     label: 'Pending'
    // },
    // {
    //     value: STATUS__REJECTED,
    //     label: 'Rejected'
    // },
];

export const renderGetFeatured = (status, id, loading, handleApply) => {
    switch (status) {
        case IS__FEATURED__APPLY:
            return (
                <LoadingButton
                    size={'small'}
                    isLoading={loading}
                    loadingText={'Applying...'}
                    variant="contained"
                    onClick={() => handleApply(id)}
                >
                    Apply
                </LoadingButton>
            );

        case IS__FEATURED__APPLIED:
            return <Chip label="Applied" />;

        case IS__FEATURED__DRAFT:
            return <Chip label="Draft" />;

        case IS__FEATURED__FEATURED:
            return <Chip label="Featured" />;

        // case IS__FEATURED__APPLY:
        //     return <Chip variant="outlined" color="primary" label="Featured"/>;

        default:
            break;
    }
};

export const TeamMemberListHeadCell = [
    {
        id: 'name',
        cellsAlign: 'left',
        sortable: false,
        label: 'Users'
    },
    {
        id: 'ID',
        cellsAlign: 'center',
        sortable: false,
        label: 'Status'
    },
    {
        id: 'price',
        cellsAlign: 'center',
        sortable: false,
        label: 'Role'
    },

    {
        id: 'actions',
        cellsAlign: 'center',
        sortable: false,
        label: 'Actions'
    }
];


export function createProductTableData(uid, name, ID, price, totalSaves, visibility, status, actions, createdBy) {
    return {
        uid,
        name,
        ID,
        price,
        totalSaves,
        visibility,
        status,
        actions,
        createdBy
    };
}
export function createTeamTableData(name, status, role, actions, uid, profileLogo) {
    return {
        name,
        status,
        role,
        actions,
        uid,
        profileLogo
    };
}

export function createBannerTableData(banner, title, visibility, featured, actions, uid) {
    return {
        banner,
        title,
        visibility,
        featured,
        actions,
        uid
    };
}

export function createPromotionTableData(promotionTitle, promotionType, startDate, endDate, shares, completed, rewarded, winners, status, publish, uid) {
    return {
        promotionTitle,
        promotionType,
        startDate,
        endDate,
        shares,
        completed,
        rewarded,
        winners,
        status,
        publish,
        uid
    };
}
export function createPromotionDetailsTableData(firstName, shares, clicks, activations, email, phone, uid, lastName, rewardId, winnerPosition) {
    return {
        firstName, shares, clicks, activations, email, phone, uid, lastName, rewardId, winnerPosition
    };
}

export function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map(el => el[0]);
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
