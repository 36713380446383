import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {useTheme} from '@mui/material/styles';
import AppNavbarRight from './AppNavbarRight';

const AppNavbar = ({handleDrawerToggle}) => {
    const theme = useTheme();

    return (
        <AppBar
            // position="fixed"
            sx={{
                padding: '0 20px',
                bgcolor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderBottom: `1px solid`,
                borderColor: 'other.divider',
                boxShadow: 'none',
                '& .MuiToolbar-root': {
                    minHeight: '60px'
                },
                position: 'static'
            }}
        >
            <Toolbar disableGutters>
                <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    sx={{
                        mr: 2,
                        color: theme.palette.action.active,
                        background: theme.palette.background.default,
                        borderRadius: '100px',
                        '&:hover': {
                            background: 'rgba(32, 164, 243, 0.08)',
                            color: theme.palette.info.main
                        }
                    }}
                >
                    <MenuIcon/>
                </IconButton>

                <Box sx={{flexGrow: 1}}/>

                <AppNavbarRight/>
            </Toolbar>
        </AppBar>
    );
};
export default AppNavbar;
