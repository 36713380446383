import AddIcon from '@mui/icons-material/Add';
import {Box, Button} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ContentWrapper from '../../../assets/global/wrapper/ContentWrapper/ContentWrapper';
import WrapperHeader from '../../../assets/global/wrapper/ContentWrapper/WrapperHeader';
import BannersSearchBar from './BannersSearchBar';
import BannersTable from './BannersTable';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectBanner} from "../../../state/features/Banner/bannerSelector";
import TableLoaderSkeleton from "../../../assets/global/Loader/TableLoaderSkeleton";
import {IS__FEATURED__ALL} from "../../../helpers/constant/coreConstant";
import {getBannerList} from "../../../state/features/Banner/bannerSlice";
import DataNotFound from "../../ErrorPage/DataNotFound";

const Banners = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {bannerList} = useSelector(selectBanner);
    const [selected, setSelected] = useState([]);
    const [addConfirmation, setAddConfirmation] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [category, setCategory] = useState(null);
    const [trashedSelected, setTrashedSelected] = useState([]);

    useEffect(() => {
        dispatch(getBannerList({
            status: category ? category : '',
            searchInputText: searchText ? searchText : '',
        }));
    }, [category, searchText]);
    const handleCategorySelect = (e) => {
        let category = e.target.value;
        if (selected?.length > 0) {
            setSelected([]);
        }
        if (trashedSelected?.length > 0) {
            setTrashedSelected([]);
        }
        if (category === IS__FEATURED__ALL) {
            setCategory('');
        } else {
            setCategory(category);
        }
    }
    const handleSearchText = (e) => {
        let text = e.target.value;
        setSearchText(text);
    }

    const renderBanners = () => {
        return (
            <>
                <WrapperHeader
                    title="Banners"
                    badge={bannerList?.total > 1 ? `${bannerList?.total} Banners` : `${bannerList?.total} Banner`}
                    cta={
                        <Button
                            variant="contained"
                            startIcon={<AddIcon/>}
                            onClick={() => {
                                navigate('/addNewBanner')
                            }}
                        >
                            Add New
                        </Button>
                    }
                />
                <BannersSearchBar
                    selected={selected}
                    setSelected={setSelected}
                    trashedSelected={trashedSelected}
                    setTrashedSelected={setTrashedSelected}
                    handleCategorySelect={handleCategorySelect}
                    handleSearchText={handleSearchText}
                    category={category}
                />
                {
                    bannerList?.isLoading ?
                        <Box py={2.4} px={1}><TableLoaderSkeleton/></Box> :
                        bannerList?.data?.length > 0 ? (
                            <BannersTable
                                selected={selected}
                                setSelected={setSelected}
                                trashedSelected={trashedSelected}
                                setTrashedSelected={setTrashedSelected}
                                category={category}
                                searchText={searchText}
                            />
                        ) : (
                            <Box sx={{textAlign: 'center', padding: '20px 0px'}}>
                                <DataNotFound title={'banner'}/>
                            </Box>
                        )
                }
            </>
        );
    };

    return (
        <Box height={'calc(100vh - 109px)'} sx={{overflowY: 'auto'}}>
            <ContentWrapper>{renderBanners()}</ContentWrapper>
        </Box>
    );
};

export default Banners;
