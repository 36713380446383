import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import HeaderWithLogo from '../common/HeaderWithLogo';
import { PublicProfileCard } from '../PublicProfileStyle';
import UserImageGallery from '../UserProfile/UserImageGallery';
import PublicProfileInfoCard from '../common/PublicProfileInfoCard';
import DownloadSection from '../common/DownloadSection';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPublicPage } from '../../../state/features/publicPage/publicPageSelector';
import { getProductInfo } from '../../../state/features/publicPage/publicPageSlice';
import PublicPageSkeletonLoader from '../common/PublicPageSkeletonLoader';

const Product = () => {
    const dispatch = useDispatch();
    const { productId } = useParams();
    const { productInfo } = useSelector(selectPublicPage);

    useEffect(() => {
        dispatch(getProductInfo({ uid: productId }))
    }, [productId])

    return (
        <Stack height={'100vh'} alignItems={'center'} spacing={{ xs: 1, md: 5 }}>
            <HeaderWithLogo />
            {
                productInfo.isLoading ? (
                    <PublicPageSkeletonLoader />
                ) : (
                    <PublicProfileCard sx={{ pt: 1.5 }} variant="outlined" elevation={0}>
                        <UserImageGallery
                            images={productInfo?.data?.productImages && productInfo?.data?.productImages}
                            cols={productInfo?.data?.productImages?.length >= 2 ? 2 : 1}
                            wrapperStyle={{ height: { xs: 300, sm: 450 }, mt: 0, borderRadius: 5 }}
                        />

                        <Stack spacing={2} mt={-4} zIndex={99} position={'relative'}>
                            <PublicProfileInfoCard
                                imageData={productInfo?.data?.brandLogo && productInfo?.data?.brandLogo}
                                title={productInfo?.data?.productName && productInfo?.data?.productName}
                                subtitle={productInfo?.data?.brandName && `by ${productInfo?.data?.brandName}`}
                                label={productId?.data?.totalSaves ? productId?.data?.totalSaves > 1 ? `${productId?.data?.totalSaves} saves` : `${productId?.data?.totalSaves} save` : '0 save'}
                            />

                            <DownloadSection
                                wrapperStyle={{ mt: 0 }}
                                description={'To view the item details, download the app'}
                            />
                        </Stack>
                    </PublicProfileCard>
                )
            }
        </Stack>
    );
};

export default Product;
