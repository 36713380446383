import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {registerPattern} from '../../helpers/constant/validationPattern';
import TextField from '@mui/material/TextField';
import {
    attemptResetPassword,
    attemptResetPasswordStateClear
} from '../../state/features/user/userSlice';
import {useNavigate} from 'react-router-dom';

const ResetPasswordContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const {resetPasswordState, forgetPasswordState} = useSelector(state => state.user);

    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });
    let pwd = watch('password');

    const onSubmit = async data => {
        if (data) {
            data.email = forgetPasswordState?.email;
            console.log(forgetPasswordState, '======forgetPasswordState===')
            console.log(data, '======data===')
            await dispatch(attemptResetPassword(data));
        } else {
        }
    };

    useEffect(() => {
        if (resetPasswordState?.isPasswordSaved) {
            reset();
            setTimeout(() => {
                navigate('/reset-password-success');
            }, [2000]);
        }
    }, [resetPasswordState?.isPasswordSaved]);

    // ## Clear Reset Password State When Leave the page
    useEffect(() => {
        return () => dispatch(attemptResetPasswordStateClear());
    }, []);

    return (
        <>
            <Box textAlign={'center'} mb={6}>
                <Typography variant="h3" mb={0} color={'text.primary'}>
                    Reset Password
                </Typography>
            </Box>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box component={'span'}>
                    <Controller
                        control={control}
                        name="token_code"
                        defaultValue=""
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Token"
                                    multiline
                                    fullWidth
                                    maxRows={4}
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={registerPattern.token}
                    />

                    <Controller
                        control={control}
                        name="password"
                        defaultValue=""
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl
                                variant="outlined"
                                sx={{mb: 2}}
                                fullWidth
                                error={!!(invalid && error.message)}
                            >
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    value={value}
                                    onChange={onChange}
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        rules={registerPattern.password}
                    />
                    <Controller
                        control={control}
                        name="confirm_password"
                        defaultValue=""
                        render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                            <FormControl
                                variant="outlined"
                                sx={{mb: 2}}
                                fullWidth
                                error={!!(invalid && error.message)}
                            >
                                <InputLabel htmlFor="outlined-adornment-confirm-password">
                                    Confirm Password
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-confirm-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password"
                                    value={value}
                                    onChange={onChange}
                                    error={!!(invalid && error.message)}
                                />
                                <FormHelperText>{invalid && error?.message}</FormHelperText>
                            </FormControl>
                        )}
                        // rules={registerPattern.confirm_password}
                        rules={{
                            required: 'You must specify a password',
                            validate: value => value === pwd || 'The passwords do not match'
                        }}
                    />

                    <Button type={'submit'} variant="contained" disableElevation /* onClick={onRecover}*/>
                        Save New Password
                    </Button>
                </Box>
            </Box>
        </>
    );
};
export default ResetPasswordContent;
