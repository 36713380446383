import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    notificationList: {
        data: [],
        isLoading: false,
        isError: false,
        error: '',
    },
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        getNotificationList: (state, action) => {
            state.notificationList.isLoading = true;
        },
        getNotificationListSuccess: (state, action) => {
            state.notificationList.data = action.payload?.data;
            state.notificationList.isLoading = false;
            state.notificationList.isError = false;
            state.notificationList.error = '';
        },
        getNotificationListFailed: (state, action) => {
            state.notificationList.data = null;
            state.notificationList.isLoading = false;
            state.notificationList.isError = true;
            state.notificationList.error = action.payload;
        },
    }
});

export const {
    getNotificationList,
    getNotificationListSuccess,
    getNotificationListFailed,
} = notificationSlice.actions;

export default notificationSlice.reducer;
