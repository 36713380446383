import {Box, Grid} from '@mui/material';
import React from 'react';
import {Outlet} from 'react-router-dom';
import Logo from '../../../assets/images/Logo.svg';
import PrivateLayout from "../PrivateLayout";

const OnboardingLayout = () => {
    return (
        <Box>
            <PrivateLayout>
                <Grid container sx={{minHeight: '100vh', bgcolor: 'other.bgPaperElevation'}}>
                    {/* Left side */}
                    <Grid item sm={12} md={6} p={3}>
                        <Box sx={{bgcolor: 'common.white', borderRadius: 2, px: 15}} height={'100%'}>
                            <Outlet/>
                        </Box>
                    </Grid>

                    {/* Right Side - Logo */}
                    <Grid
                        item
                        md={6}
                        sm={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.primary'
                        }}
                    >
                        <img src={Logo} alt="FTPO Logo"/>
                    </Grid>
                </Grid>
            </PrivateLayout>
        </Box>
    );
};

export default OnboardingLayout;
