import React, {useCallback, useEffect, useState} from 'react';
import {Backdrop, CircularProgress, Stack} from "@mui/material";
import update from "immutability-helper";
import ImageCard from "./ImageCard";
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {showSuccessMessage} from "../../../helpers/utility/Toastify";
import {
    setModalClose,
    setProductImages,
    setSelectedImages
} from "../../../state/features/Products/AddProduct/AddProductSlice";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import {productImageFormate} from "../../../helpers/utility/Product";
import SetImageCropInfo from "../../../assets/global/ImageCropper/SetImageCropInfo";
import useAdjustProductImageHook from "../hooks/useAdjustProductImageHook";
import AppReviewTabContent from "./AppReviewTabContent";
import ScrollableModal from "../../../assets/global/Modal/ScrollableModal";

const ImageDnD = ({appPreview, setAppPreview}) => {
    const dispatch = useDispatch();
    const {selectedImages, imageInfo} = useSelector(selectAddProduct);
    const [isImage, setIsImage] = useState(false);
    const {
        image,
        setImage,
        imageAdjustBackdrop,
        productImage,
        setProductImage,
        onChangeHandler
    } = useAdjustProductImageHook();

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setImage((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            }),
        )
    }, []);

    useEffect(() => {
        if (image) {
            const format = productImageFormate(image);
            setProductImage(format);
            dispatch(setProductImages(format));
        }
    }, [image]);

    const handleRemoveImage = (imageId) => {
        if (imageId) {
            setProductImage(prevItems => prevItems.filter(item => item?.id !== imageId));
            showSuccessMessage("Image removed!");
            setIsImage(true);
        }
    }

    useEffect(() => {
        if (isImage) {
            dispatch(setSelectedImages(productImage));
            dispatch(setProductImages(productImage));
            setIsImage(false);
        }
    }, [isImage]);


    const renderImageCard = useCallback((item, index) => {
        return (
            <ImageCard
                key={item?.id}
                index={index}
                image={item?.url}
                id={item?.id}
                imageName={item?.id}
                moveCard={moveCard}
                handleRemoveImage={handleRemoveImage}
            />
        );
    }, []);

    return (
        <>
            <Stack direction={'row'} flexWrap={'wrap'} gap={3.2}>
                {
                    productImage && (
                        productImage?.map((item, index) =>
                            renderImageCard(item, index))
                    )
                }
            </Stack>

            <BasicWithHeaderModal
                modalTitle="Adjust Image"
                open={imageInfo?.modalOpen}
                width={500}
                onClose={() => dispatch(setModalClose())}
            >
                <SetImageCropInfo
                    open={imageInfo?.modalOpen}
                    onClose={() => {
                        dispatch(setModalClose());
                    }}
                    image={imageInfo?.imageUrl}
                    imageName={""}
                    onCropImage={croppedImg => onChangeHandler([croppedImg])}
                    ratio={imageInfo?.height / imageInfo?.width <= 0.5 ? true : false}
                />
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={imageAdjustBackdrop}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </BasicWithHeaderModal>

            <ScrollableModal
                modalTitle="In App Preview"
                open={appPreview}
                onClose={() => setAppPreview(false)}
                headerBorder={false}
            >
                <AppReviewTabContent productImage={productImage}/>
            </ScrollableModal>
        </>
    )
}
export default ImageDnD;