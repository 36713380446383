import React, { useEffect, useState } from 'react'
import ContentWrapper from "../../../assets/global/wrapper/ContentWrapper/ContentWrapper";
import { Box, Button } from "@mui/material";
import WrapperHeader from "../../../assets/global/wrapper/ContentWrapper/WrapperHeader";
import AddIcon from "@mui/icons-material/Add";
import TableLoaderSkeleton from "../../../assets/global/Loader/TableLoaderSkeleton";
import DataNotFound from "../../ErrorPage/DataNotFound";
import { STATUS__ALL, STATUS__TRASHED } from "../../../helpers/constant/coreConstant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectPromotion } from "../../../state/features/Promotions/promotionSelector";
import PromotionFilter from "./PromotionFilter";
import PromotionTable from "./PromotionTable";
import routeLink from "../../../helpers/constant/routeLink";
import { getPromotionList } from "../../../state/features/Promotions/promotionSlice";

const PromotionList = () => {
    const { promotionList } = useSelector(selectPromotion);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [category, setCategory] = useState('');
    const [selected, setSelected] = useState([]);
    const [trashedSelected, setTrashedSelected] = useState([]);
    const [productId, setProductId] = useState(null);
    const [searchText, setSearchText] = useState(null);

    useEffect(() => {
        dispatch(getPromotionList({
            status: category ? category : category === 0 ? category : '',
            searchInputText: searchText ? searchText : '',
        }));
    }, [category, searchText]);

    const handleCategorySelect = (e) => {
        let category = e.target.value;
        console.log('category===', category)
        if (selected?.length > 0) {
            setSelected([]);
        }
        if (trashedSelected?.length > 0) {
            setTrashedSelected([]);
        }

        if (category === STATUS__TRASHED) {
            setCategory(STATUS__TRASHED);
        } else if (category === STATUS__ALL) {
            setCategory('');
        } else {
            setCategory(+category);
        }
    }

    const renderProductLists = () => {
        return (
            <>
                <WrapperHeader
                    title="Promotions"
                    cta={
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => navigate(routeLink.addPromotion.to)}
                        >
                            Add Promotion
                        </Button>
                    }
                    badge={promotionList?.total > 1 ? `${promotionList?.total} Promotions` : `${promotionList?.total} Promotion`}
                />

                <PromotionFilter
                    selected={selected}
                    setSelected={setSelected}
                    productId={productId}
                    setSearchText={setSearchText}
                    handleCategorySelect={handleCategorySelect}
                    trashedSelected={trashedSelected}
                    setTrashedSelected={setTrashedSelected}
                    category={category}
                />
                {
                    promotionList?.isLoading ?
                        <Box py={2.4} px={1}><TableLoaderSkeleton /></Box> :
                        Array.isArray(promotionList?.data) && promotionList?.data?.length > 0 ? (
                            <PromotionTable
                                selected={selected}
                                setSelected={setSelected}
                                productId={productId}
                                setProductId={setProductId}
                                trashedSelected={trashedSelected}
                                setTrashedSelected={setTrashedSelected}
                                category={category}
                                searchText={searchText}
                            />

                        ) : (
                            <Box sx={{ textAlign: 'center', padding: '20px 0px' }}>
                                <DataNotFound title={'promotion'} />
                            </Box>
                        )
                }
            </>
        );
    };
    return (
        <Box height={'calc(100vh - 109px)'} sx={{ overflowY: 'auto' }}>
            <ContentWrapper>{renderProductLists()}</ContentWrapper>
        </Box>
    )
}
export default PromotionList;