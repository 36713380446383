import React, {useEffect, useState} from 'react';
import {Avatar, Box, Button, Stack, styled, Typography} from "@mui/material";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import CircularProgressBar from "../../../assets/global/ProgressBar/CircularProgressBar";
import './../Styles/CustomInput.css';
import ImageInput from "../../../assets/global/ImageCropper/ImageInput";
import {onboarding, updateProfileLogo} from "../../../state/features/OnboardedSteps/OnboardedStepsSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectOnboardedSteps} from "../../../state/features/OnboardedSteps/OnboardedStepsSelector";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import LoadingButton from "../../../assets/global/Button/LoadingButton";

const AddProfilePicture = ({activeStep, setActiveStep, steps, currentOnboardStep, myProfile}) => {
    const dispatch = useDispatch();
    const {profilePicture} = useSelector(selectOnboardedSteps);
    const [imageUpload, setImageUpload] = useState(false);
    const [image, setImage] = useState("");

    const handleNext = () => {
        if (image?.photo?.data) {
            let formData = new FormData();
            formData.append('profileLogo', image?.photo?.data);
            dispatch(updateProfileLogo(formData));
            dispatch(onboarding({stageNo: activeStep + 1, journeyModeOn: true}));
        } else if (myProfile?.data?.profileLogo) {
            setActiveStep(activeStep + 1);
        } else {
            showErrorMessage("Add an image!");
        }
    };

    useEffect(() => {
        if (myProfile?.data?.profileLogo) {
            setImage(myProfile?.data?.profileLogo);
        }
    }, [myProfile?.data?.profileLogo]);

    return (
        <Box>
            <Typography variant="h3" mb={0}>
                Add a profile picture
            </Typography>
            <Typography variant="body1" color={'text.secondary'}>
                It can be a logo or anything that represents your brand.
            </Typography>

            <Stack alignItems={'center'} my={6} sx={{bg: '#000'}}>
                <ImageInput
                    image={image}
                    setImage={setImage}
                    type="1"
                />
                <BasicWithHeaderModal
                    modalTitle="Uploading Image"
                    open={imageUpload}
                    onClose={() => setImageUpload(false)}
                    width={200}
                >
                    <Box textAlign={'center'} py={4}>
                        <Stack alignItems={'center'} mb={3}>
                            <CircularProgressBar/>

                            <Typography mt={2} variant="body1" color={'text.secondary'}>
                                Uploading profile picture, please wait...
                            </Typography>
                        </Stack>

                        <Button
                            variant="outlined"
                            sx={{color: 'text.primary', borderColor: 'text.primary'}}
                        >
                            Cancel
                        </Button>
                    </Box>
                </BasicWithHeaderModal>
            </Stack>

            <LoadingButton
                isLoading={profilePicture?.isLoading}
                loadingText={'Continuing...'}
                variant="contained"
                size={'medium'}
                onClick={handleNext}
                // disabled={activeStep === steps.length - 1}
            >
                Continue
            </LoadingButton>
        </Box>
    );
}
export default AddProfilePicture;