import React, { useEffect, useState } from 'react';
import WrapperHeader from "../../../assets/global/wrapper/ContentWrapper/WrapperHeader";
import { Box, Button, Grid, Stack } from "@mui/material";
import WrapperFooter from "../../../assets/global/wrapper/ContentWrapper/WrapperFooter";
import { useForm } from "react-hook-form";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ContentWrapper from "../../../assets/global/wrapper/ContentWrapper/ContentWrapper";
import CoreBreadCrumbs from "../../../assets/global/BreadCrumbs/CoreBreadCrumbs";
import { promotionInfo } from "../../../helpers/constant/pageInfo";
import { selectPromotion } from "../../../state/features/Promotions/promotionSelector";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { showErrorMessage } from "../../../helpers/utility/Toastify";
import PromotionForm from "../Form/PromotionForm";
import PromotionBannerUpload from "../Form/PromotionBannerUpload";
import {
    addPromotionData, addPromotionDataForRequest,
    addPromotionDataForSave,
    addPromotionStateClear
} from "../../../state/features/Promotions/promotionSlice";
import routeLink from '../../../helpers/constant/routeLink';


const AddPromotion = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { addPromotion } = useSelector(selectPromotion);
    const [banner, setBanner] = useState(null);
    const [promotion, setPromotion] = useState(null);
    const {
        handleSubmit,
        control,
        reset,
        watch,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit'
    });
    const handleImage = (file) => {
        if (file) {
            setBanner(file)
        } else
            showErrorMessage('Image file not found!')
    }
    const onSubmit = (data) => {
        let startDate = dayjs(data?.startDate).format('YYYY-MM-DD');
        let endDate = dayjs(data?.endDate).format('YYYY-MM-DD');

        if (banner) {
            if (startDate <= endDate) {
                let formData = new FormData();
                formData.append("promotionType", data?.promotionType);
                if (data?.promotionType === 2) {
                    formData.append("productUid", data?.productUid);
                }
                formData.append("name", data?.promotionTitle);
                formData.append("description", data?.promotionDescription);
                formData.append("startDate", dayjs(data?.startDate).format('YYYY-MM-DD'));
                formData.append("endDate", dayjs(data?.endDate).format('YYYY-MM-DD'));
                formData.append("rewardsAndPolicies", data?.rewardsAndPolicies);
                formData.append("imageUrl", banner);
                formData.append("winners", data?.winners);
                formData.append("status", promotion);
                if (promotion === 0) {
                    dispatch(addPromotionDataForSave(formData));
                } else if (promotion === 2) {
                    dispatch(addPromotionDataForRequest(formData));
                }
                setPromotion(null);
            } else {
                showErrorMessage('Start date is greater than end date!');
                setPromotion(null);
            }
        } else {
            showErrorMessage('Banner image not found!');
            setPromotion(null);
        }
    }

    useEffect(() => {
        if (addPromotion?.isPromotionAdd) {
            reset();
            dispatch(addPromotionStateClear());
            navigate('/promotions');
        }
    }, [addPromotion?.isPromotionAdd]);

    return (
        <ContentWrapper>
            <WrapperHeader
                title="Add Promotion"
                backButton={() => navigate('/promotions')}
                breadCrumb={<CoreBreadCrumbs pageInfo={promotionInfo} keys={[1, 2]} />}
            />
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    sx={{
                        overflowY: 'auto'
                    }}
                >
                    <Grid item sm={12} lg={4.55}>
                        <Box
                            py={4}
                            px={5}
                            borderRight={{ lg: '1px solid' }}
                            borderColor={{ lg: 'other.divider' }}
                            sx={{ height: { sm: 'auto', lg: 'calc(100vh - 270px)' }, overflowY: 'auto' }}
                        >
                            <PromotionForm control={control} type={routeLink.addPromotion.hidden_key} />
                        </Box>
                    </Grid>
                    <Grid item sm={12} lg={5.45}>
                        <PromotionBannerUpload handleImage={handleImage} />
                    </Grid>
                </Grid>

                <WrapperFooter>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <Button
                                sx={{ color: 'text.primary' }}
                                variant="text"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                isLoading={addPromotion?.isSaving}
                                loadingText={'Saving...'}
                                variant="outlined"
                                onClick={() => setPromotion(0)}
                                type={'submit'}
                            >
                                Save Promotion
                            </LoadingButton>
                            <LoadingButton
                                isLoading={addPromotion?.isPublishing}
                                loadingText={'Publishing...'}
                                variant="contained"
                                type={'submit'}
                                onClick={() => setPromotion(2)}
                            >
                                Publish Promotion
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </WrapperFooter>
            </Box>
        </ContentWrapper>
    );
}

export default AddPromotion;