import React from 'react';
import {Box, Chip, Stack, Typography} from '@mui/material';
import backIcon from '../../../images/Icons/arrowBackFilled.svg';

const WrapperHeader = ({
                           title = 'Team Members',
                           badge = false,
                           cta,
                           border = true,
                           backButton = false,
                           breadCrumb
                       }) => {
    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            p={3}
            // sx={{borderBottom: `${border ? '1px solid' : ''}`}}
            borderBottom={border ? '1px solid' : ''}
            borderColor={'other.divider'}
            flexWrap={'wrap'}
        >
            <Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={2.2}>
                    {backButton && (
                        <img className="cursor-pointer" src={backIcon} alt="" onClick={backButton}/>
                    )}
                    <Typography variant="h5">{title}</Typography>
                    {badge && <Chip sx={{fontWeight: 500, px: 0.5}} size="small" label={badge}/>}
                </Stack>
                <Box mt={2}>{breadCrumb}</Box>
            </Stack>
            {cta}
        </Stack>
    );
};

export default WrapperHeader;
