import {all, put} from 'redux-saga/effects';
import {call, debounce, takeEvery, takeLatest} from '@redux-saga/core/effects';
import {
    addBannerFailed,
    addBannerSuccess,
    applyFeaturesData,
    applyFeaturesFailed,
    applyFeaturesSuccess,
    draftBannerFailed,
    draftBannerSuccess,
    editBannerFailed,
    editBannerSuccess,
    getBannerList,
    getBannerListFailed,
    getBannerListSuccess,
    multipleDeleteBannerFailed,
    multipleDeleteBannerSuccess,
    multipleTrashBannerFailed,
    multipleTrashBannerSuccess,
    setVisibilityFailed,
    setVisibilitySuccess,
    singleDeleteBannerFailed,
    singleDeleteBannerSuccess,
    singleTrashBannerFailed,
    singleTrashBannerSuccess,
    updateBannerFailed,
    updateBannerSuccess
} from "./bannerSlice";
import {showErrorMessage, showSuccessMessage} from "../../../helpers/utility/Toastify";
import BannerService from "../../../services/Banner.service";

function* bannerWatcher() {
    yield takeEvery('banner/draftBannerData', draftBannerSaga);
    yield takeEvery('banner/addBannerData', addBannerSaga);
    yield debounce(500, 'banner/getBannerList', getBannerListSaga);
    yield takeEvery('banner/editBannerData', editBannerSaga);
    yield takeEvery('banner/updateBannerData', updateBannerSaga);
    yield takeEvery('banner/singleTrashBannerData', singleTrashBannerSaga);
    yield takeEvery('banner/multipleTrashedBannerData', multipleTrashBannerSaga);
    yield takeEvery('banner/singleDeleteBannerData', singleDeleteBannerSaga);
    yield takeEvery('banner/multipleDeletedBannerData', multipleDeleteBannerSaga);
    yield takeEvery('banner/applyFeaturesData', applyFeaturesSaga);
    yield takeEvery('banner/setVisibility', setVisibilitySaga);
}

function* addBannerSaga(action) {
    try {
        const response = yield call(BannerService.addBanner, action.payload);
        if (response?.success) {
            yield put(addBannerSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(addBannerFailed(response?.data));
            if (response?.message?.title) {
                showErrorMessage(response?.message.title[0]);
            } else {
                showErrorMessage(response?.message);
            }
        }
    } catch (err) {
        yield put(addBannerFailed(err.message));
    }
}

function* draftBannerSaga(action) {
    try {
        const response = yield call(BannerService.addBanner, action.payload);
        if (response?.success) {
            yield put(draftBannerSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(draftBannerFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(draftBannerFailed(err.message));
    }
}

function* getBannerListSaga(action) {
    try {

        const response = yield call(BannerService.bannerList, action.payload);
        if (response?.success) {
            yield put(getBannerListSuccess(response?.data));
        } else {
            yield put(getBannerListFailed(response?.data));
        }
    } catch (err) {
        yield put(getBannerListFailed(err.message));
    }
}

function* editBannerSaga(action) {
    try {
        const response = yield call(BannerService.editBanner, action.payload);
        if (response?.success) {
            yield put(editBannerSuccess(response?.data));
            // showSuccessMessage(response?.message);
        } else {
            yield put(editBannerFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(editBannerFailed(err.message));
    }
}

function* updateBannerSaga(action) {
    try {
        const response = yield call(BannerService.updateBanner, action.payload);

        if (response?.success) {
            yield put(updateBannerSuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(updateBannerFailed(response?.data));
            if (response?.message?.title) {
                showErrorMessage(response?.message.title[0]);
            } else {
                showErrorMessage(response?.message);
            }
        }
    } catch (err) {
        yield put(updateBannerFailed(err.message));
    }
}


function* singleTrashBannerSaga(action) {
    try {
        const response = yield call(BannerService.singleTrash, action.payload);

        if (response?.success) {
            yield put(singleTrashBannerSuccess(response?.data));
            yield put(getBannerList({status : action.payload?.category}));
            showSuccessMessage(response?.message);
        } else {
            yield put(singleTrashBannerFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(singleTrashBannerFailed(err.message));
    }
}

function* multipleTrashBannerSaga(action) {
    try {
        const response = yield call(BannerService.multipleTrash, action.payload);

        if (response?.success) {
            yield put(multipleTrashBannerSuccess(response?.data));
            yield put(getBannerList({
                searchInputText:  action.payload?.searchInputText,
                status : action.payload?.category,
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleTrashBannerFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleTrashBannerFailed(err.message));
    }
}

function* singleDeleteBannerSaga(action) {
    try {
        const response = yield call(BannerService.singleDelete, action.payload);
        if (response?.success) {
            yield put(singleDeleteBannerSuccess(response?.data));
            yield put(getBannerList({
                searchInputText:  action.payload?.searchInputText,
                status : action.payload?.category,
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(singleDeleteBannerFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(singleDeleteBannerFailed(err.message));
    }
}

function* multipleDeleteBannerSaga(action) {
    try {
        const response = yield call(BannerService.multipleDelete, action.payload);
        if (response?.success) {
            yield put(multipleDeleteBannerSuccess(response?.data));
            yield put(getBannerList({status : action.payload?.category}));
            showSuccessMessage(response?.message);
        } else {
            yield put(multipleDeleteBannerFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(multipleDeleteBannerFailed(err.message));
    }
}
function* applyFeaturesSaga(action) {
    try {
        const response = yield call(BannerService.applyFeatures, action.payload);
        if (response?.success) {
            yield put(applyFeaturesSuccess(response?.data));
            yield put(getBannerList({
                searchInputText:  action.payload?.searchInputText,
                status : action.payload?.category,
                page: action.payload?.page,
                per_page: action.payload?.perPage,
            }));
            showSuccessMessage(response?.message);
        } else {
            yield put(applyFeaturesFailed(response?.data));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(applyFeaturesFailed(err.message));
    }
}
function* setVisibilitySaga(action) {
    try {
        const response = yield call(BannerService.visibility, action.payload);
        if (response?.success) {
            yield put(setVisibilitySuccess(response?.data));
            showSuccessMessage(response?.message);
        } else {
            yield put(setVisibilityFailed(response?.data));
            yield put(getBannerList({
                searchInputText:  action.payload?.searchInputText,
                status : action.payload?.category,
                page: action.payload?.page,
                per_page: action.payload?.perPage,
            }));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        yield put(setVisibilityFailed(err.message));
    }
}

export default function* bannersSaga() {
    yield all([bannerWatcher()]);
}
