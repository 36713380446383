import {all, call, put, takeEvery} from "redux-saga/effects";
import userService from "../../../services/User.service";
import {delay} from "@redux-saga/core/effects";

import {
    attemptForgetPasswordFailed,
    attemptForgetPasswordSuccess,
    attemptLoginFailed,
    attemptLoginSuccess,
    attemptLogoutFailed,
    attemptLogoutSuccess, attemptResetPasswordFailed,
    attemptResetPasswordSuccess,
    attemptSignUpFailed,
    attemptSignUpSuccess,
} from "./userSlice";
import CookieService from "../../../services/cookie/cookie.service";
import {RESPONSE_LOADING_TIME, RESPONSE_LOADING_TIME_FOR_REGISTRATION} from "../../../helpers/constant/coreConstant";
import {showErrorMessage, showSuccessMessage} from "../../../helpers/utility/Toastify";
import {getOnboardingData} from "../OnboardedSteps/OnboardedStepsSlice";

function* userWatcher() {
    yield takeEvery("user/attemptLogin", attemptLoginSaga);
    yield takeEvery("user/attemptSignUp", attemptSignUpSaga);
    yield takeEvery("user/attemptForgetPassword", attemptForgetPasswordSaga);
    yield takeEvery("user/attemptResetPassword", attemptResetPasswordSaga);
    yield takeEvery("user/attemptLogout", attemptLogoutSaga);
}

function* attemptLoginSaga(action) {
    try {
        const response = yield call(userService.attemptLogin, action.payload);
        yield delay(RESPONSE_LOADING_TIME);
        if (response?.success) {
            CookieService.setCookie(process.env.REACT_APP_ACCESS_TOKEN, response?.data?.accessToken, "");
            CookieService.setCookie(process.env.REACT_APP_AUTH_ACCESS_TOKEN, response.data.user.auth_token, "");
            yield put(attemptLoginSuccess(response?.data?.user));
            yield put(getOnboardingData(response?.data?.user));
            yield put(getOnboardingData(response?.data?.user));
            showSuccessMessage(response?.message);
        } else {
            yield put(attemptLoginFailed(response.message));
            showErrorMessage(response.message);
        }
    } catch (err) {
        console.log("Error: ", err);
        yield put(attemptLoginFailed(""));
    }
}

function* attemptSignUpSaga(action) {
    try {

        yield delay(RESPONSE_LOADING_TIME_FOR_REGISTRATION);
        const response = yield call(userService.attemptSignUp, action.payload);

        yield delay(RESPONSE_LOADING_TIME);
        if (response?.success) {
            yield put(attemptSignUpSuccess(response));
            showSuccessMessage(response?.message);
        } else {
            yield put(attemptSignUpFailed(response));
            if (response?.message?.email) {
                showErrorMessage(response?.message?.email[0]);
            } else if (response?.message?.name) {
                showErrorMessage(response?.message?.name[0]);
            } else if (response?.message?.storeContactPersonName) {
                showErrorMessage(response?.message?.storeContactPersonName[0]);
            } else {
                showErrorMessage(response?.message);
            }
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* attemptLogoutSaga(action) {
    try {
        const response = yield call(userService.attemptLogout, action.payload);

        delay(RESPONSE_LOADING_TIME);
        if (response?.success) {
            yield put(attemptLogoutSuccess(response));
            showSuccessMessage(response?.message);
        } else {
            yield put(attemptLogoutFailed(response));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* attemptForgetPasswordSaga(action) {
    try {
        const response = yield call(userService.attemptForgetPassword, action.payload);

        delay(RESPONSE_LOADING_TIME);
        if (response?.success) {
            yield put(attemptForgetPasswordSuccess(response));
            showSuccessMessage(response?.message);
        } else {
            yield put(attemptForgetPasswordFailed(response));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

function* attemptResetPasswordSaga(action) {
    try {
        const response = yield call(userService.attemptResetPassword, action.payload);

        delay(RESPONSE_LOADING_TIME);
        if (response.success) {
            yield put(attemptResetPasswordSuccess(response));
            showSuccessMessage(response?.message);
        } else {
            yield put(attemptResetPasswordFailed(response));
            showErrorMessage(response?.message);
        }
    } catch (err) {
        console.log("Error: ", err);
    }
}

export default function* userSaga() {
    yield all([userWatcher()]);
}
