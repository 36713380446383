import React, {useState} from 'react';
import Default_User_Pic from './defaultImage.svg';
import ImageHandler from "./ImageHandler";
import './Style.css';
const ImageInput = ({image, setImage, type}) => {
    return (
        <div className="image-input">
            <ImageHandler
                imageData={image?.photo?.src ? image?.photo?.src : image}
                defaultPic={Default_User_Pic}
                type="admin"
                name="photo"
                label="Add Photo"
                showPreview
                onChange={(files) => setImage(files, "admin")}
                type={type}
            />
        </div>
    );
}

export default ImageInput;