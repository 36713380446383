import {Controller, useForm} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import {Box, Button, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack} from "@mui/material";
import {ChangePassword} from "../../helpers/constant/validationPattern";
import React, {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const ChangePasswordForm = ({setChangePasswordModal, onSubmit}) => {
    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    let pwd = watch("password");
    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{padding: '20px'}}>
                <Controller
                    control={control}
                    name="password"
                    defaultValue=""
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl variant="outlined" sx={{mb: 2}} fullWidth
                                     error={!!(invalid && error.message)}>
                            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="New Password"
                                value={value}
                                onChange={onChange}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={ChangePassword.password}
                />
                <Controller
                    control={control}
                    name="confirmPassword"
                    defaultValue=""
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl variant="outlined" sx={{mb: 2}} fullWidth
                                     error={!!(invalid && error.message)}>
                            <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm
                                Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm Password"
                                value={value}
                                onChange={onChange}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={{
                        required: "You must specify a password!",
                        validate: value => value === pwd || "The passwords do not match!"
                    }}
                />
            </Stack>
            <Stack
                p={'16px'}
                sx={{borderTop: 1, borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={2}
            >
                <Button
                    sx={{color: 'text.primary'}}
                    variant="text"
                    onClick={() => setChangePasswordModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    type={'submit'}
                >
                    Change
                </Button>
            </Stack>
        </Box>
    )
}
export default ChangePasswordForm;