import React, {useState} from 'react';
import BasicWithHeaderModal from "../Modal/BasicWithHeaderModal";
import './Style.css';
import SetImageCropInfo from "./SetImageCropInfo";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import {useSelector} from "react-redux";
import {selectTeamMember} from "../../../state/features/TeamMember/teamMemberSelector";
import {Avatar, Badge, Button, styled} from "@mui/material";
import addPicture from "../../images/Icons/addPicture.svg";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 50,
    height: 50,
    border: `2px solid ${theme.palette.background.paper}`,
}));
const ImageHandler = ({name, onChange, showPreview, imageData, defaultPic, type}) => {
    const [image, setImage] = useState("");
    const {editTeamMember} = useSelector(selectTeamMember);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const onChangeHandler = file => {
        onChange({
            [name]: {
                data: file[0],
                src: URL.createObjectURL(file[0]),
            },
        });
        setModalIsOpen(false);
    };

    const handleFile = e => {
        if (e.target.files.length > 0) {
            const file = e.target.files;
            let url = URL.createObjectURL(file[0]);
            setImageUrl(url);
            let img = new Image();
            img.src = url;
            img.onload = function () {
                setWidth(this.width);
                setHeight(this.height);
            };
            const maxAllowedSize = 5 * 1024 * 1024;
            if (file[0].size > maxAllowedSize) {
                showErrorMessage("Maximum image size exceed!");
            } else {
                setImage(file[0]);
                setModalIsOpen(true);
            }
            e.target.value = null;
        }
    };
    let inputElement;
    return (
        <>
            <BasicWithHeaderModal
                modalTitle="Adjust Image"
                open={modalIsOpen}
                onClose={() => {
                    setModalIsOpen(prevState => !prevState);
                }}
                width={'auto'}
            >
                <SetImageCropInfo
                    open={modalIsOpen}
                    onClose={() => {
                        setModalIsOpen(prevState => !prevState);
                    }}
                    image={imageUrl}
                    onCropImage={croppedImg => onChangeHandler([croppedImg])}
                    ratio={height / width <= 0.5 ? true : false}
                    type={type}
                />
            </BasicWithHeaderModal>

            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <SmallAvatar
                        alt="Remy Sharp"
                        src={addPicture}
                        onClick={() => inputElement.click()}
                    />
                }
            >
                {
                    imageData ? (
                        <>
                            <Avatar src={imageData} sx={{width: 144, height: 144}}
                                    alt="Brand Logo"/>
                        </>
                    )/*: myProfile?.data?.profileLogo ? (
                        <Avatar src={myProfile?.data?.profileLogo} sx={{width: 144, height: 144}}
                                alt="Brand Logo"/>
                    )*/  : (
                        <Avatar sx={{width: 144, height: 144}} alt="Brand Logo"/>
                    )
                }
            </Badge>

            <input
                ref={input => (inputElement = input)}
                accept="image/*"
                type="file"
                style={{display: "none"}}
                onChange={handleFile}
            />
        </>
    );

}
export default ImageHandler;