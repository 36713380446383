import React from 'react';
import { Box, ImageList, ImageListItem } from '@mui/material';

const UserImageGallery = ({ images, wrapperStyle, cols = 3, gap = 2 }) => {
    return (
        <Box
            sx={{
                borderRadius: 6,
                overflowY: 'hidden',
                position: 'relative',
                mt: 5,
                ...wrapperStyle
            }}
        >
            {
                images?.length > 0 ? (
                    <ImageList variant="masonry" cols={cols} gap={gap}>
                        {images?.map((item, i) => (
                            <ImageListItem key={i}>
                                <img
                                    srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item}?w=248&fit=crop&auto=format`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            height: '320px',
                            backgroundColor: '#D3D3D3',
                            borderRadius: '6px'
                        }}
                    >
                    </Box>
                )
            }


            <Box
                sx={{
                    top: 0,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(transparent, white)'
                }}
            />
        </Box>
    );
};

export default UserImageGallery;
