import React, {useEffect} from 'react';
import WrapperHeader from "../../../assets/global/wrapper/ContentWrapper/WrapperHeader";
import {Box, Button, Grid, Stack} from "@mui/material";
import WrapperFooter from "../../../assets/global/wrapper/ContentWrapper/WrapperFooter";
import BannerInfo from "./BannerInfo";
import BannerImage from "./BannerImage";
import useAddBannerHook from "../hooks/useAddBannerHook";
import {useForm} from "react-hook-form";
import LoadingButton from "../../../assets/global/Button/LoadingButton";
import {useDispatch, useSelector} from "react-redux";
import {selectBanner} from "../../../state/features/Banner/bannerSelector";
import {addBannerStateClear, draftBannerStateClear} from "../../../state/features/Banner/bannerSlice";
import ContentWrapper from "../../../assets/global/wrapper/ContentWrapper/ContentWrapper";
import CoreBreadCrumbs from "../../../assets/global/BreadCrumbs/CoreBreadCrumbs";
import {bannersInfo} from "../../../helpers/constant/pageInfo";

const AddBanner = () => {
    const dispatch = useDispatch();
    const {addBanner, draftBanner} = useSelector(selectBanner);
    const {
        navigate,
        featured,
        setFeatured,
        ctaHover,
        setCtaHover,
        bannerImage,
        setBannerImage,
        onSubmit,
        setIsDraft,
        handleImageUpload,
        handleRemoveImage
    } = useAddBannerHook();

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: {errors}
    } = useForm({
        mode: 'onSubmit'
    });

    useEffect(() => {
        if (addBanner?.isAddBanner || draftBanner?.isAddBanner) {
            reset({
                title: "",
            })
            setFeatured(false);
            setBannerImage({
                data: '',
                src: ''
            });
            setIsDraft(false);
            dispatch(addBannerStateClear());
            dispatch(draftBannerStateClear());
            navigate('/banners')
        }

    }, [addBanner?.isAddBanner, draftBanner?.isAddBanner]);

    return (
        <ContentWrapper>
            <WrapperHeader
                title="Add New Banner"
                backButton={() => navigate('/banners')}
                breadCrumb={<CoreBreadCrumbs pageInfo={bannersInfo} keys={[1,2]}/>}
                // cta={
                //     <Button
                //         variant="text"
                //         startIcon={<VisibilityIcon/>}
                //         // onClick={() => setAppPreview(true)}
                //     >
                //         In App Preview Product
                //     </Button>
                // }
            />
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    sx={{
                        overflowY: 'auto'
                    }}
                >
                    <Grid item sm={12} lg={4.55}>
                        <Box
                            py={4}
                            px={5}
                            borderRight={{lg: '1px solid'}}
                            borderColor={{lg: 'other.divider'}}
                            sx={{height: {sm: 'auto', lg: 'calc(100vh - 270px)'}, overflowY: 'auto'}}
                        >
                            <BannerInfo
                                control={control}
                                featured={featured}
                                setFeatured={setFeatured}/>

                        </Box>
                    </Grid>
                    <Grid item sm={12} lg={5.45}>
                        <Stack
                            py={4}
                            px={5}
                            spacing={3}
                            alignItems={'flex-start'}
                            // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                        >

                            <BannerImage
                                ctaHover={ctaHover}
                                setCtaHover={setCtaHover}
                                handleImageUpload={handleImageUpload}
                                bannerImage={bannerImage}
                                setBannerImage={setBannerImage}
                                handleRemoveImage={handleRemoveImage}
                            />

                        </Stack>
                    </Grid>
                </Grid>

                <WrapperFooter>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <Button
                                sx={{color: 'text.primary'}}
                                variant="text"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                isLoading={draftBanner?.isLoading}
                                loadingText={'Drafting...'}
                                variant="outlined"
                                type={'submit'}
                                onClick={() => setIsDraft(true)}
                            >
                                Save as draft
                            </LoadingButton>
                            <LoadingButton
                                isLoading={addBanner?.isLoading}
                                loadingText={'Publishing...'}
                                variant="contained"
                                type={'submit'}
                            >
                                Publish Banner
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </WrapperFooter>
            </Box>
        </ContentWrapper>
    );
}

export default AddBanner;