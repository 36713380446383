import {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {STATUS__DRAFT, STATUS__PUBLISHED} from "../../../helpers/constant/coreConstant";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import {useDispatch, useSelector} from "react-redux";
import {addBannerData, draftBannerData} from "../../../state/features/Banner/bannerSlice";
import {selectBanner} from "../../../state/features/Banner/bannerSelector";

const useAddBannerHook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ctaHover, setCtaHover] = useState(false);
    const [bannerImage, setBannerImage] = useState(null);
    const [isDraft, setIsDraft] = useState(false);
    const [featured, setFeatured] = useState(false);
    const onSubmit = (data) => {
        if (isDraft) {
            if (data && bannerImage) {
                const formData = new FormData();
                formData.append('status', STATUS__DRAFT);
                formData.append('title', data.title);
                formData.append('imageUrl', bannerImage?.data);
                formData.append('isFeatured', featured);
                dispatch(draftBannerData(formData));
                setIsDraft(false);
            } else {
                showErrorMessage("Add banner image!");
            }
        } else {
            if (data && bannerImage) {
                const formData = new FormData();
                formData.append('status', STATUS__PUBLISHED);
                formData.append('title', data.title);
                formData.append('imageUrl', bannerImage?.data);
                formData.append('isFeatured', featured);
                dispatch(addBannerData(formData));
            } else {
                showErrorMessage("Add banner image!");
            }
        }
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                const expectedRatio = 3 / 2;
                if (Math.abs(width / height - expectedRatio) < 0.13) {
                    setBannerImage({
                        data: file,
                        src: URL.createObjectURL(file)
                    });
                } else {
                    showErrorMessage('Image ratio must be 3:2!');
                }
                e.target.value = '';
            }
        } else {
            showErrorMessage("Image not found!");
        }
    }
    const handleRemoveImage = () => {
        setBannerImage(null);
    }

    return {
        navigate,
        ctaHover,
        setCtaHover,
        featured,
        setFeatured,
        bannerImage,
        setBannerImage,
        isDraft,
        setIsDraft,
        onSubmit,
        handleImageUpload,
        handleRemoveImage,
    }
}
export default useAddBannerHook;