import React, { useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import HeaderWithLogo from '../common/HeaderWithLogo';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPublicPage } from '../../../state/features/publicPage/publicPageSelector';
import { getRewardsPoliciesInfo } from '../../../state/features/publicPage/publicPageSlice';

const IMG_URL =
    'https://images.unsplash.com/photo-1618932260643-eee4a2f652a6?auto=format&fit=crop&q=80&w=1980&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
const RewardsPolicies = () => {
    const dispatch = useDispatch();
    const { promotionId } = useParams();
    const { rewardsPoliciesInfo } = useSelector(selectPublicPage);

    useEffect(() => {
        dispatch(getRewardsPoliciesInfo({ uid: promotionId }))
    }, [promotionId])
    return (
        <Stack
            sx={{ bgcolor: 'primary.main' }}
            height={'100vh'}
            alignItems={'center'}
            spacing={{ xs: 1, md: 5 }}
        >
            <HeaderWithLogo light />

            <Card elevation={0} sx={{ borderRadius: 6, width: { xs: 320, sm: 520 } }}>
                {
                    rewardsPoliciesInfo?.data?.banner_url ? (
                        <CardMedia sx={{ height: { xs: 280, sm: 320 } }} image={rewardsPoliciesInfo?.data?.banner_url && rewardsPoliciesInfo?.data?.banner_url} title="green iguana" />
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                height: '320px',
                                backgroundColor: '#D3D3D3',
                                borderRadius: '6px'
                            }}
                        >
                        </Box>
                    )
                }

                <CardContent sx={{ p: 2.5 }}>
                    <Typography variant="body2" color="primary.main" fontSize={{ xs: 12, sm: 14 }}>
                        {rewardsPoliciesInfo?.data?.name}
                    </Typography>

                    <Typography gutterBottom variant="h4" component="div" fontSize={{ xs: 24, sm: 32 }}>
                        Rewards and Policies
                    </Typography>

                    <Box component={'ul'} pl={2.5}>
                        <li>
                            <Typography variant="body2">{rewardsPoliciesInfo?.data?.privacy_policy ? rewardsPoliciesInfo?.data?.privacy_policy : 'No policies added for this promotion'}</Typography>
                        </li>
                    </Box>
                </CardContent>
            </Card>
        </Stack>
    );
};

export default RewardsPolicies;
