import { Chip, Paper, Stack, styled } from '@mui/material';

export const PublicProfileCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(4),
    borderRadius: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
        width: 320,
        padding: theme.spacing(1.5),
        paddingTop: theme.spacing(1.5),
        borderRadius: theme.spacing(2)
    },
    [theme.breakpoints.up('sm')]: {
        width: 520
    }
}));

export const PublicProfileChip = styled(Chip)(({ theme }) => ({
    textTransform: 'uppercase',
    fontWeight: 600,
    background: theme.palette.other.bgPaperElevation,
    color: theme.palette.other.gray5,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        height: 24
    }
}));

export const PublicProfileWrapper = styled(Stack)(({ theme }) => ({
    height: '100vh'
}));
