import Box from "@mui/material/Box";
import {Button, Stack, Typography} from "@mui/material";
import LoadingButton from "../../../../assets/global/Button/LoadingButton";
import React from "react";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const DeclareWinnerModal = ({isLoading, handlePromotionDeclare, handleModalClose}) => {
    return(
        <>
            <Box p={'64px 40px 48px 40px'} textAlign={'center'}>
                {/*<img src={trashIcon} alt="" />*/}
                <EmojiEventsIcon width={120} height={150} sx={{ fontSize: 150 }} color={'warning'}/>

                <Typography variant="h5" mt={3} mb={1}>
                    Are you sure you want to <br/> declare winners of this promotion?
                </Typography>
                <Typography variant="body2" color={'text.secondary'}>
                    You can update this status when the promotion date is end!
                </Typography>
            </Box>

            <Stack
                p={'16px'}
                sx={{borderTop: 1, borderColor: 'other.divider'}}
                direction={'row'}
                justifyContent={'flex-end'}
                spacing={1}
            >
                <Button sx={{color: 'text.primary'}} variant="text" onClick={handleModalClose}>
                    Cancel
                </Button>
                <LoadingButton
                    isLoading={isLoading}
                    loadingText={'Updating...'}
                    variant="contained"
                    color={'warning'}
                    onClick={handlePromotionDeclare}
                >
                    Declare
                </LoadingButton>
            </Stack>
        </>
    )
}
export default DeclareWinnerModal;