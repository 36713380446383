import {useState} from "react";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import CookieService from "../../../services/cookie/cookie.service";
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import GalleryService from "../../../services/Gallery.service";
import {setModalClose, setSelectedImages} from "../../../state/features/Products/AddProduct/AddProductSlice";

const useAdjustProductImageHook = () => {
    const {productImages, imageInfo, selectedImages} = useSelector(selectAddProduct);
    const [image, setImage] = useState(selectedImages);
    const [productImage, setProductImage] = useState(null);
    const [imageAdjustBackdrop, setImageAdjustBackdrop] = useState(false);

    const dispatch = useDispatch();
    const [adjustImage, setAdjustImage] = useState({
        data: '',
        src: '',
    });

    const onChangeHandler = async file => {
        if (file) {
            setImageAdjustBackdrop(true);
            setAdjustImage({
                data: file[0],
                src: URL.createObjectURL(file[0])
            });
            let formData = new FormData();
            formData.append('image', file[0]);

            await GalleryService.imageUploadToS3(formData)
                .then((response) => {
                    if (response?.success) {
                        let imageUrl = response?.data?.url;
                        const updatedData = [...productImages];
                        if (imageInfo?.imageIndex == 0) {
                            updatedData[imageInfo?.imageIndex] = {
                                ...updatedData[imageInfo?.imageIndex],
                                url: imageUrl,
                                isMain: true
                            };
                        } else {
                            updatedData[imageInfo?.imageIndex] = {
                                ...updatedData[imageInfo?.imageIndex],
                                url: imageUrl,
                                isMain: false
                            };
                        }
                        dispatch(setSelectedImages(updatedData));
                        setImage(updatedData);
                        dispatch(setModalClose());
                        setImageAdjustBackdrop(false);
                    } else {
                        setImageAdjustBackdrop(false);
                        showErrorMessage(response?.message)
                    }
                })
                .catch((err) => console.log(err));

        } else {
            showErrorMessage("Image not found!");
        }
    }

    return {
        image,
        setImage,
        imageAdjustBackdrop,
        productImage,
        setProductImage,
        onChangeHandler,
    }
};
export default useAdjustProductImageHook;