import { Box, Button, ButtonGroup, Chip, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { productStatus, promotionStatus } from "../../../helpers/utility/TableContents";
import filterIcon from "../../../assets/images/Icons/FilterIcon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectProductList } from "../../../state/features/Products/ProductList/ProductListSelector";
import {
    multipleDeleteProductData,
    multipleDeleteProductStateClear,
    multipleRestoreProductData,
    multipleRestoreProductStateClear,
    multipleTrashProductData,
    multipleTrashProductStateClear
} from "../../../state/features/Products/ProductList/ProductListSlice";
import { MOVE__TO__RESTORE, MOVE__TO__TRASH } from "../../../helpers/constant/coreConstant";
import { showErrorMessage } from "../../../helpers/utility/Toastify";
import { selectPromotion } from "../../../state/features/Promotions/promotionSelector";
import {
    multipleDeletePromotionStateClear, multipleRestorePromotionData, multipleRestorePromotionStateClear,
    multipleTrashPromotionStateClear,
    multipleTrashedPromotion, multipleDeletedPromotion
} from "../../../state/features/Promotions/promotionSlice";
import restoreIcon from "../../../assets/images/Icons/restoreIcon.svg";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import MultipleTrashModal from "../../../assets/global/Modal/MultipleTrashModal";
import MultipleDeleteModal from "../../../assets/global/Modal/MultipleDeleteModal";
import MultipleRestoreModal from "../../../assets/global/Modal/MultipleRestoreModal";

const PromotionFilter = ({
    selected,
    setSelected,
    productId,
    setSearchText,
    handleCategorySelect,
    trashedSelected,
    setTrashedSelected,
    category,
}) => {
    const { multipleTrashPromotion, multipleDeletePromotion, multipleRestorePromotion } = useSelector(selectPromotion);
    const [trashModal, setTrashModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);
    const dispatch = useDispatch();
    const handleMultipleDelete = () => {
        if (trashedSelected?.length > 0) {
            dispatch(multipleDeletedPromotion({
                uids: trashedSelected,
                category: category
            }));
            setTrashedSelected([]);

        } else if (selected?.length > 0) {
            dispatch(multipleTrashedPromotion({
                uids: selected,
                inTrash: MOVE__TO__TRASH,
            }))
            setSelected([]);
        } else {
            showErrorMessage("Select products!");
        }
    }

    useEffect(() => {
        if (multipleDeletePromotion?.isMultipleDeletePromotion) {
            setDeleteModal(false);
            dispatch(multipleDeletePromotionStateClear())
        }
    }, [multipleDeletePromotion?.isMultipleDeletePromotion]);

    useEffect(() => {
        if (multipleTrashPromotion?.isMultipleTrashPromotion) {
            setTrashModal(false);
            dispatch(multipleTrashPromotionStateClear())
        }
    }, [multipleTrashPromotion?.isMultipleTrashPromotion]);

    const handleMultipleRestore = () => {
        if (trashedSelected?.length > 0) {
            dispatch(multipleRestorePromotionData({
                uids: trashedSelected,
                inTrash: MOVE__TO__RESTORE,
                status: category
            }));
            setTrashedSelected([]);
        } else {
            showErrorMessage('Select products!');
        }
    }

    useEffect(() => {
        if (multipleRestorePromotion?.isMultipleRestorePromotion) {
            setRestoreModal(false);
            dispatch(multipleRestorePromotionStateClear())
        }
    }, [multipleRestorePromotion?.isMultipleRestorePromotion]);

    return (
        <>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={2} p={2}>
                    <TextField
                        id="outlined-required"
                        label="Search"
                        type={'search'}
                        placeholder={'Promotion name...'}
                        onChange={(e) => setSearchText(e.target.value)}
                    />

                    <TextField
                        id="outlined-select-category"
                        defaultValue={'ALL'}
                        select
                        label="Search by status"
                        sx={{ minWidth: 156 }}
                        onChange={handleCategorySelect}
                    >
                        {promotionStatus?.map(option => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Stack justifyContent={'center'} sx={{ cursor: 'pointer' }}>
                        <img src={filterIcon} alt="Filter Icon" />
                    </Stack>
                </Stack>
                {
                    selected?.length > 0 ? (
                        <Box sx={{ marginRight: '10px' }}>
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={() => setTrashModal(true)}
                            >
                                Trash items ({selected?.length} Selected)
                            </Button>
                        </Box>
                    ) : trashedSelected?.length > 0 && (
                        <Box sx={{ marginRight: '10px' }}>
                            <ButtonGroup
                                disableElevation
                                variant="outlined"
                                aria-label="Disabled elevation buttons"
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="success"
                                    startIcon={<img height={20} width={20} src={restoreIcon} alt="" />}
                                    onClick={() => setRestoreModal(true)}
                                >
                                    Restore Items ({trashedSelected?.length} Selected)
                                </Button>

                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => setDeleteModal(true)}
                                >
                                    Delete Items ({trashedSelected?.length} Selected)
                                </Button>
                            </ButtonGroup>
                        </Box>
                    )
                }
            </Stack>
            <Stack direction={'row'} pl={2}>
                <Box
                    sx={{
                        fontSize: 13, p: 1,
                        backgroundColor: '#e6eeff',
                        borderRadius: '20px',
                        padding: '4px 16px'
                    }}
                >
                    You have active <Typography color='#0288D1' fontWeight='bold' variant="p">1 promotion </Typography>at a time
                </Box>
            </Stack>


            <BasicWithHeaderModal width={460} open={trashModal} header={false}>
                <MultipleTrashModal
                    isLoading={multipleTrashPromotion?.isLoading}
                    handleModalClose={() => setTrashModal(false)}
                    onMultipleTrashed={handleMultipleDelete}
                    itemLength={selected?.length > 0 && selected}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={deleteModal} header={false}>
                <MultipleDeleteModal
                    isLoading={multipleDeletePromotion?.isLoading}
                    handleModalClose={() => setDeleteModal(false)}
                    onMultipleDelete={handleMultipleDelete}
                    itemLength={trashedSelected?.length > 0 && trashedSelected}
                />
            </BasicWithHeaderModal>
            <BasicWithHeaderModal width={460} open={restoreModal} header={false}>
                <MultipleRestoreModal
                    isLoading={multipleRestorePromotion?.isLoading}
                    handleModalClose={() => setRestoreModal(false)}
                    onMultipleRestore={handleMultipleRestore}
                    itemLength={selected?.length > 0 ? selected : trashedSelected} />
            </BasicWithHeaderModal>
        </>
    )
}
export default PromotionFilter;