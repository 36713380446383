import React from 'react';
import { Avatar, Paper, Stack, Typography } from '@mui/material';
import { PublicProfileChip } from '../PublicProfileStyle';

const PublicProfileInfoCard = ({
    imageData,
    title,
    subtitle,
    label
}) => {
    return (
        <Paper
            sx={{ paddingBlock: 2, paddingInline: { xs: 2, sm: 2.5 }, borderRadius: 2 }}
            elevation={0}
            variant="outlined"
        >
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} spacing={{ xs: 1, sm: 1.5 }}>
                    <Avatar src={imageData} sx={{ width: 44, height: 44 }} alt="Brand Logo" />

                    <Stack>
                        <Typography
                            sx={{ fontSize: { xs: 11, sm: 15 } }}
                            variant="body2"
                            fontWeight={600}
                            color={'other.gray8'}
                        >
                            {title}
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 10, sm: 13 } }} variant="body2" color={'other.gray5'}>
                            {subtitle}
                        </Typography>
                    </Stack>
                </Stack>

                <PublicProfileChip label={label} />
            </Stack>
        </Paper>
    );
};

export default PublicProfileInfoCard;
