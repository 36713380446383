import React from 'react';
import {Grid} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const ImageLoaderSkeleton = () => {
    return (
        <>
            <Grid sx={{flexGrow: 1}} container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="left" spacing={2} mb={2}>
                        {[0, 1, 2].map((value, index) => (
                            <Grid key={value} item>
                                <Skeleton variant="square" height={300} width={290}/>
                            </Grid>
                        ))}
                    </Grid>
                    {/*<Grid container justifyContent="left" spacing={2}>*/}
                    {/*    {[0, 1, 2].map((value, index) => (*/}
                    {/*        <Grid key={value} item>*/}
                    {/*            <Skeleton variant="square" height={300} width={310}/>*/}
                    {/*        </Grid>*/}
                    {/*    ))}*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
        </>
    )
        ;
}
export default ImageLoaderSkeleton;