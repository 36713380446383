import {combineReducers} from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice';
import OnboardedStepsSlice from "./features/OnboardedSteps/OnboardedStepsSlice";
import AddProductSlice from "./features/Products/AddProduct/AddProductSlice";
import EditProductSlice from "./features/Products/EditProduct/EditProductSlice";
import ProductListSlice from "./features/Products/ProductList/ProductListSlice";
import ProductDeleteSlice from "./features/Products/DeleteProduct/ProductDeleteSlice";
import galleryListSlice from "./features/Gallery/GalleryList/galleryListSlice";
import gallerySlice from "./features/Gallery/GalleryImageDelete/gallerySlice";
import teamMemberSlice from "./features/TeamMember/teamMemberSlice";
import bannerSlice from "./features/Banner/bannerSlice";
import profileSlice from "./features/Profile/profileSlice";
import notificationSlice from "./features/Notification/notificationSlice";
import dashboardSlice from "./features/Dashboard/dashboardSlice";
import promotionSlice from "./features/Promotions/promotionSlice";
import publicPageSlice from "./features/publicPage/publicPageSlice";

const combinedReducer = combineReducers({
    user: userSlice,
    onboarded: OnboardedStepsSlice,
    addProduct: AddProductSlice,
    editProduct: EditProductSlice,
    productList: ProductListSlice,
    productDelete: ProductDeleteSlice,
    galleryList: galleryListSlice,
    gallery: gallerySlice,
    teamMember: teamMemberSlice,
    banner: bannerSlice,
    profile: profileSlice,
    notification: notificationSlice,
    dashboard: dashboardSlice,
    promotion: promotionSlice,
    publicPage: publicPageSlice,
});

const rootReducer = (state, action) => {
    return combinedReducer(state, action);
};

export default rootReducer;
