import OnboardingLoadingLayout from '../components/Layout/OnboardingLoadingLayout/OnboardingLoadingLayout';
import OnboardingLoadingPage from "./OnboardingLoadingPage";
import routeLink from "../helpers/constant/routeLink";
import ForceLoginRedirectPage from "../components/AuthUser/ForceLoginRedirectPage";

const OnboardingLoadingRoutes = {
    path: '/',
    element: <OnboardingLoadingLayout/>,
    children: [
        {
            path: '/completing-onboarding',
            element: <OnboardingLoadingPage/>,
            key: routeLink.onboarding.hidden_key
        }
    ]
};

export default OnboardingLoadingRoutes;
