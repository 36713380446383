import React, {useEffect, useState} from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Box, Button, IconButton, InputAdornment, Stack, TextField, Typography} from '@mui/material';
import ContentWrapper from '../../assets/global/wrapper/ContentWrapper/ContentWrapper';
import WrapperHeader from '../../assets/global/wrapper/ContentWrapper/WrapperHeader';
import MyGalleryFilter from './MyGalleryFilter';
import ImageGallery from './ImageGallery';
import BasicWithHeaderModal from '../../assets/global/Modal/BasicWithHeaderModal';
import {Search} from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedImages} from "../../state/features/Products/AddProduct/AddProductSlice";
import {useNavigate, useSearchParams} from "react-router-dom";
import WrapperFooter from "../../assets/global/wrapper/ContentWrapper/WrapperFooter";
import useSingleImageUpload from "../../hooks/useSingleImageUpload";
import {showErrorMessage} from "../../helpers/utility/Toastify";
import {
    getGalleryImage,
    getMoreGalleryImage,
    getMoreGalleryImageStateClear,
    multipleImageDelete, multipleImageDeleteClearState
} from "../../state/features/Gallery/GalleryImageDelete/gallerySlice";
import {selectAddProduct} from "../../state/features/Products/AddProduct/AddProductSelector";
import MultipleDeleteModal from "../../assets/global/Modal/MultipleDeleteModal";
import {selectGallery} from "../../state/features/Gallery/GalleryImageDelete/gallerySelector";
import DataNotFound from "../ErrorPage/DataNotFound";
import ImageLoaderSkeleton from "../../assets/global/Loader/ImageLoaderSkeleton";
import LoadingButton from "../../assets/global/Button/LoadingButton";

const MyGallery = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {galleryImage, isMultipleImageDelete, isLoading} = useSelector(selectGallery);
    const {data = [], isFetching, hasMore, page, perPage} = galleryImage || {};
    const {selectedImages, isSetImage} = useSelector(selectAddProduct);
    let [searchParams, setSearchParams] = useSearchParams();
    const galleryType = searchParams.get('type');
    const [selectedItems, setSelectedItems] = useState(selectedImages);
    const [imageIds, setImageIds] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [imageUploadStat, setImageUploadStat] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [sortedBy, setSortedBy] = useState('desc');
    const [fetchMore, setFetchMore] = useState(0);

    const {
        scrollDivRef,
        uploading,
        progressValue,
        handleImageUpload,
        AbortHandler,
        inputElementRef
    } = useSingleImageUpload();

    const handleSelectedImages = () => {
        dispatch(setSelectedImages(selectedItems));
        navigate(-1);
    }

    useEffect(() => {
        dispatch(getGalleryImage({
            sortedBy: sortedBy ? sortedBy : '',
            searchText: searchText ? searchText : '',
            page: 1,
            per_page: 10
        }));
        dispatch(getMoreGalleryImageStateClear());
    }, [searchText, sortedBy]);

    useEffect(() => {
        if (fetchMore) {
            if (hasMore && !isFetching) {
                dispatch(getMoreGalleryImage({
                    sortedBy: 'desc',
                    page: page + 1,
                    per_page: 10
                }))
            } else {
                return;
            }
        }
    }, [fetchMore])
    const handleScroll = (entries, observer) => {
        const scrollDiv = scrollDivRef.current;

        if (scrollDiv) {
            const scrollTop = scrollDiv.scrollTop;
            const scrollHeight = scrollDiv.scrollHeight;
            const clientHeight = scrollDiv.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                setFetchMore(more => more + 1)
            }
        }
    };

    useEffect(() => {
        const scrollDiv = scrollDivRef.current;
        scrollDiv.addEventListener('scroll', handleScroll);
        return () => scrollDiv.removeEventListener('scroll', handleScroll);
    }, [scrollDivRef.current]);

    const handleMultipleDelete = () => {
        if (imageIds) {
            dispatch(multipleImageDelete({uids: imageIds}));
            setImageIds([]);
            setSelectedItems([]);
        } else {
            showErrorMessage("No Image found!");
        }
    }
    useEffect(() => {
        if (isMultipleImageDelete) {
            setModalOpen(false);
            dispatch(multipleImageDeleteClearState())
        }
    }, [isMultipleImageDelete]);

    let header = null;
    let filter = null;
    let inputElement = null;

    if (galleryType) {
        header = (
            <WrapperHeader
                title="My Gallery"
                cta={
                    <Stack direction={'row'} spacing={1}>
                        <TextField
                            variant="outlined"
                            placeholder="Search"
                            size="small"
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" aria-label="search">
                                            <Search/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <LoadingButton
                            isLoading={uploading}
                            loadingText={'Uploading...'}
                            variant="outlined"
                            startIcon={<UploadFileIcon/>}
                            sx={{cursor: 'pointer'}}
                            onClick={() => inputElementRef.current.click()}
                        >
                            Upload Image
                        </LoadingButton>
                        <input
                            ref={inputElementRef}
                            accept="image/*"
                            type="file"
                            style={{display: "none"}}
                            multiple
                            onChange={handleImageUpload}
                        />
                    </Stack>
                }
                backButton={() => navigate(-1)}
            />
        );

    } else {
        header = (
            <>
                <WrapperHeader
                    title="My Gallery"
                    cta={
                        <LoadingButton
                            isLoading={uploading}
                            loadingText={'Uploading...'}
                            variant="contained"
                            sx={{cursor: 'pointer'}}
                            startIcon={<CloudUploadIcon/>}
                            onClick={() => inputElement.click()}
                        >
                            Upload Image
                        </LoadingButton>
                    }
                    badge={galleryImage?.total > 1 ? `${galleryImage?.total} Images` : `${galleryImage?.total} Image`}
                />
                <input
                    ref={input => (inputElement = input)}
                    accept="image/*"
                    type="file"
                    style={{display: "none"}}
                    onChange={handleImageUpload}
                />
            </>
        );
        filter = (
            <MyGalleryFilter
                imageIds={imageIds}
                onDelete={() => setModalOpen(true)}
                setSearchText={setSearchText}
                setSortedBy={setSortedBy}
            />
        );
    }

    return (
        <Box height={'calc(100vh - 109px)'}>
            <ContentWrapper sx={{height: '100%'}}>
                {header}

                <Box pt={3} pr={3} pb={0} pl={3} height={'calc(100% - 86px)'}
                     sx={{overflowY: 'auto', position: 'relative', overflow: 'scroll'}} ref={scrollDivRef}>

                    {filter}
                    {
                        galleryImage?.isLoading ? <Box sx={{padding: '10px'}}><ImageLoaderSkeleton/></Box> : data?.length > 0 ? (
                            <>
                                <ImageGallery
                                    AbortHandler={AbortHandler}
                                    uploading={uploading}
                                    progressValue={progressValue}
                                    imageIds={imageIds}
                                    setImageIds={setImageIds}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    imageUploadStat={imageUploadStat}
                                    setImageUploadStat={setImageUploadStat}
                                    galleryType={galleryType}
                                />
                                {
                                    galleryType && (
                                        <ContentWrapper sx={{mt: 4.2, position: 'sticky', bottom: 0, left: 0}}>
                                            <WrapperFooter>
                                                <Stack
                                                    direction={'row'}
                                                    justifyContent={'space-between'}
                                                    alignItems={'center'}
                                                    spacing={2}
                                                >
                                                    <Typography variant="body2" color={'text.secondary'}>
                                                        You can select maximum 5 images
                                                    </Typography>

                                                    <Stack direction={'row'} spacing={2}>
                                                        <Button
                                                            sx={{color: 'text.primary'}}
                                                            variant="text"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleSelectedImages}
                                                            disabled={selectedItems?.length > 5}
                                                        >
                                                            Select {selectedItems?.length === 0 ? 'Image' : `${selectedItems?.length} Images`}
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </WrapperFooter>
                                        </ContentWrapper>
                                    )
                                }
                            </>
                        ) : (
                            <Box sx={{textAlign: 'center', padding: '20px 0px'}}>
                                <DataNotFound title={'gallery'}/>
                            </Box>

                        )
                    }
                </Box>
            </ContentWrapper>

            <BasicWithHeaderModal width={460} open={modalOpen} header={false}>
                <MultipleDeleteModal
                    isLoading={isLoading}
                    onMultipleDelete={handleMultipleDelete}
                    itemLength={imageIds}
                    handleModalClose={() => setModalOpen(false)}
                />
            </BasicWithHeaderModal>

        </Box>
    );
};

export default MyGallery;
