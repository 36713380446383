import React, {useEffect, useState} from 'react';
import {Box, LinearProgress, Stack, Typography} from '@mui/material';
import arrowBackFilled from '../../assets/images/Icons/arrowBackFilled.svg';
import "./Styles/EasyCrop.css"
import WelcomePage from "./OnboardedSteps/WelcomePage";
import FashionCategory from "./OnboardedSteps/FashionCategory";
import GenderPreferences from "./OnboardedSteps/GenderPreferences";
import AddProfilePicture from "./OnboardedSteps/AddProfilePicture";
import BrandDescription from "./OnboardedSteps/BrandDescription";
import WebsiteLink from "./OnboardedSteps/WebsiteLink";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCurrentOnboardStep,
    selectJourneyModeOn
} from "../../state/features/OnboardedSteps/OnboardedStepsSelector";
import {ONBOARD_STEP} from "../../helpers/constant/onboardConstant";
import {getOnboardData} from "../../state/features/OnboardedSteps/OnboardedStepsSlice";
import {useNavigate} from "react-router-dom";
import {selectProfile} from "../../state/features/Profile/profileSelector";
import {getMyProfileData} from "../../state/features/Profile/profileSlice";

const steps = ['Step 0', 'Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const Onboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {myProfile} = useSelector(selectProfile);
    const journeyModeOn = useSelector(selectJourneyModeOn);
    const [activeStep, setActiveStep] = useState(0);
    const currentOnboardStep = useSelector(selectCurrentOnboardStep);

    useEffect(() => {
        dispatch(getOnboardData())
    }, [currentOnboardStep]);

    useEffect(() => {
        setActiveStep(currentOnboardStep);
    }, [currentOnboardStep]);

    useEffect(() => {
        if (currentOnboardStep == 7 && journeyModeOn === false) {
            navigate('/completing-onboarding');
            dispatch(getMyProfileData());
        }
    }, [journeyModeOn]);

    const handleBack = () => {
        dispatch(getMyProfileData());
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    let content = null;
    if (ONBOARD_STEP.ONE === activeStep) {
        content = <WelcomePage
            activeStep={activeStep}
            steps={steps}
            setActiveStep={setActiveStep}
            currentOnboardStep={currentOnboardStep}
            brandName={myProfile?.data?.name}
        />
    } else if (ONBOARD_STEP.TWO === activeStep) {
        content =
            <FashionCategory
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                steps={steps}
                handleBack={handleBack}
                currentOnboardStep={currentOnboardStep}
                myProfile={myProfile}
            />
    } else if (ONBOARD_STEP.THREE === activeStep) {
        content =
            <GenderPreferences
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                steps={steps}
                handleBack={handleBack}
                currentOnboardStep={currentOnboardStep}
                myProfile={myProfile}
            />
    } else if (ONBOARD_STEP.FOUR === activeStep) {
        content =
            <AddProfilePicture
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                steps={steps}
                handleBack={handleBack}
                currentOnboardStep={currentOnboardStep}
                myProfile={myProfile}
            />
    } else if (ONBOARD_STEP.FIVE === activeStep) {
        content =
            <BrandDescription
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                steps={steps}
                handleBack={handleBack}
                currentOnboardStep={currentOnboardStep}
                myProfile={myProfile}
            />
    } else if (ONBOARD_STEP.SIX === activeStep) {
        content =
            <WebsiteLink
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                steps={steps}
                handleBack={handleBack}
                currentOnboardStep={currentOnboardStep}
                myProfile={myProfile}
            />
    }

    return (
        <Stack justifyContent={'center'} height={'100%'}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                minHeight={31}
                mb={8}
            >
                <Stack direction={'row'} alignItems={'center'} spacing={3}>
                    {currentOnboardStep > 1 && (
                        <Stack sx={{visibility: activeStep > 1 ? 'visible' : 'hidden', cursor: 'pointer'}}>
                            <img src={arrowBackFilled} onClick={handleBack} alt="Back Button"/>
                        </Stack>
                    )}

                    <Typography variant="subtitle2" color={'text.secondary'}>
                        Step {activeStep} of {steps.length}
                    </Typography>
                </Stack>

                <Box width={240}>
                    <LinearProgress
                        sx={{borderRadius: 4}}
                        variant="determinate"
                        value={(activeStep / (steps.length - 1)) * 100}
                    />
                </Box>
            </Stack>
            {content}
        </Stack>
    );
};
export default Onboard;
