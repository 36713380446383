import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import {Outlet} from 'react-router-dom';
import AppNavbar from '../AppNavbar/AppNavbar';
import AppSidebar from './AppSidebar';
import PrivateLayout from '../PrivateLayout';

const drawerWidth = 288;

const openedMixin = theme => ({
  width: drawerWidth,
  // top: 60,
  // paddingTop: 16,
  paddingBottom: '60px',
  zIndex: 1,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = theme => ({
  // top: 60,
  // paddingTop: 16,
  paddingBottom: '60px',
  zIndex: 1,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: '70px'
  // [theme.breakpoints.up("sm")]: {
  //     width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  // background: '#ddd',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppLayout = () => {
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
    // LocalStorageService.setMenuOpen(!open);
  };

  return (
    <>
      <PrivateLayout>
        <Box sx={{display: 'flex'}} bgcolor={'other.bgPaperElevation'}>
          {/* <DrawerHeader /> */}
          <Drawer variant="permanent" open={open}>
            <AppSidebar open={open} />
          </Drawer>

          <Box sx={{flexGrow: 1}}>
            <AppNavbar handleDrawerToggle={handleDrawerToggle} />

            <Box p={3}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </PrivateLayout>
    </>
  );
};

export default AppLayout;
