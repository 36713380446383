import React, {useEffect, useState} from 'react';
import {Box, Chip, FormControl, FormHelperText, ListItem, MenuItem, Stack, TextField, Typography} from '@mui/material';
import {ProductValidationPattern} from "../../../helpers/constant/validationPattern";
import {Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {selectAddProduct} from "../../../state/features/Products/AddProduct/AddProductSelector";
import {
    setClothingCategory,
    setFashionCategory,
    setFashionCategoryClear
} from "../../../state/features/Products/AddProduct/AddProductSlice";
import {showErrorMessage} from "../../../helpers/utility/Toastify";
import {FashionCategories} from "../../../helpers/utility/GenderPreferenesLists";
import {setFashionCategories} from "../../../state/features/OnboardedSteps/OnboardedStepsSlice";
import {selectProfile} from "../../../state/features/Profile/profileSelector";
import {getClothingCategories, getFashionCategories} from "../../../state/features/Profile/profileSlice";

const categories = [
    {
        value: 1,
        label: 'Clothing',
    },
    {
        value: 2,
        label: 'Fashion'
    },
];

const AddProductCategories = ({control, setValue}) => {
    const {clothingCategory, fashionCategory, productAdded} = useSelector(selectAddProduct);
    const {fashionCategories, clothingCategories} = useSelector(selectProfile);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFashionCategories());
        dispatch(getClothingCategories());
    }, []);
    useEffect(() => {
        if (clothingCategory) {
            setValue("clothingCategoryUid", clothingCategory || "");
        }
        if (fashionCategory) {
            setValue("fashionCategoryUid", fashionCategory || " ");
        }
    }, [clothingCategory, fashionCategory]);

    return (
        <Box mb={5}>
            <Typography variant="h5" mb={3}>
                Product Categories
            </Typography>
            <Controller
                control={control}
                name="clothingCategoryUid"
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                        <TextField
                            id="outlined-select-category"
                            select
                            label="Clothing Category"
                            value={clothingCategory}
                            defaultValue = {clothingCategory}
                            focused
                            onChange={e => {
                                onChange(e);
                                dispatch(setClothingCategory(e?.target?.value))
                            }}
                        >
                            {clothingCategories?.data?.map(items => (
                                <MenuItem
                                    key={items.uid}
                                    value={items.uid}
                                >
                                    {items.categoryName}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormHelperText>{invalid && error?.message}</FormHelperText>
                    </FormControl>
                )}
                rules={ProductValidationPattern.productCategory}
            />
            <Controller
                control={control}
                name="fashionCategoryUid"
                render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                    <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                        <TextField
                            id="outlined-select-category"
                            select
                            label="Fashion Category"
                            value={fashionCategory}
                            focused
                            onChange={e => {
                                onChange(e);
                                dispatch(setFashionCategory(e?.target?.value))
                            }}
                        >
                            {fashionCategories?.data?.map(items => (
                                <MenuItem
                                    key={items.uid}
                                    value={items.uid}
                                >
                                    {items.categoryName}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormHelperText>{invalid && error?.message}</FormHelperText>
                    </FormControl>
                )}
                rules={ProductValidationPattern.fashionCategory}
            />
        </Box>
    );
};

export default AddProductCategories;
