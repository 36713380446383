import React, {useEffect} from 'react';
import {Box, Button, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {onboarding} from "../../../state/features/OnboardedSteps/OnboardedStepsSlice";
import {useNavigate} from "react-router-dom";
import {selectJourneyModeOn, selectOnboardedSteps} from "../../../state/features/OnboardedSteps/OnboardedStepsSelector";
import {getMyProfileData} from "../../../state/features/Profile/profileSlice";
import LoadingButton from "../../../assets/global/Button/LoadingButton";

const WelcomePage = ({activeStep, setActiveStep, steps, currentOnboardStep, brandName}) => {
    const dispatch = useDispatch();
    const {isLoading} = useSelector(selectOnboardedSteps);

    const handleContinue = () => {
        if (activeStep < currentOnboardStep) {
            setActiveStep(activeStep + 1);
        }
        dispatch(onboarding({stageNo: activeStep + 1 , journeyModeOn: true}));
        dispatch(getMyProfileData());
    };

    return(
        <Box>
            <Typography variant="h3" mb={3}>
                Welcome {brandName && brandName}, <br/> <Typography variant="h3" sx={{fontSize: '30px'}}>Let’s get your brand onboarded!</Typography>
            </Typography>
            <LoadingButton
                isLoading={isLoading}
                loadingText={'Continuing...'}
                variant="contained"
                size={'medium'}
                onClick={handleContinue}
                // disabled={activeStep === steps?.length - 1}
            >
                Continue
            </LoadingButton>
        </Box>
    )

}
export default WelcomePage;