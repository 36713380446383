import {Box, Link, Stack, Typography} from '@mui/material';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import ForgetPasswordForm from './ForgetPasswordForm';

const ForgotPassword = () => {
    return (
        <Stack
            justifyContent={'space-between'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}
            sx={{pt: {md: 10}}}
        >
            <Box
                sx={{
                    width: {lg: '540px'},
                    maxWidth: '540px',
                    padding: '48px',
                    gap: '48px',
                    bgcolor: 'common.white',
                    borderRadius: '12px'
                }}
            >
                <ForgetPasswordForm/>

                <Box>
                    <Typography variant="body1" color={'text.secondary'} textAlign={'center'}>
                        Know your password?{' '}
                        <Link fontWeight={600} component={RouterLink} to="/login" underline="none">
                            Log in now
                        </Link>
                    </Typography>
                </Box>
            </Box>

            <Box textAlign={'center'} mt={4}>
                <Typography variant="body2">@ 2023 FTPO - All Rights Reserved</Typography>
                <Typography variant="body2" color={'other.primaryDark'}>
                    Terms of services & privacy policy
                </Typography>
            </Box>
        </Stack>
    );
};

export default ForgotPassword;
