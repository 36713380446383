import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    products: {
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
        isLoading: false,
        isError: false,
        error: '',
    },
    productVisibility: {
        data: [],
        isLoading: false,
        isError: false,
        error: '',
    },
    singleTrashProduct: {
        isTrashedProduct: null,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleRestoreProduct: {
        isSingleRestoreProduct: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    singleDeleteProduct: {
        isSingleProductDelete: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleDeleteProduct: {
        isMultipleProductDelete: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleRestoreProduct: {
        isMultipleProductRestore: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    multipleTrashProduct: {
        isMultipleProductTrashed: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    updateProduct: {
        data: null,
        isProductUpdate: false,
        isLoading: false,
        isError: false,
        error: '',
    }
};

const ProductListSlice = createSlice({
    name: 'ProductList',
    initialState: initialState,
    reducers: {
        productList: (state, action) => {
            state.products.isLoading = true;
        },
        productListSuccess: (state, action) => {
            state.products.data = action?.payload?.data;
            state.products.page = action?.payload?.current_page;
            state.products.perPage = action?.payload?.per_page;
            state.products.total = action?.payload.total;
            state.products.isLoading = false;
            state.products.isError = false;
            state.products.error = '';
        },
        productListFailed: (state, action) => {
            state.products.data = [];
            state.products.isLoading = false;
            state.products.total = 0;
            state.products.isError = true;
            state.products.error = action.payload;
        },
        productListClearState: (state, action) => {
            state.products.data = [];
            state.products.isLoading = false;
            state.products.isError = false;
            state.products.error = '';
        },

        setProductVisibility: (state, action) => {
            state.productVisibility.isLoading = true;
        },
        setProductVisibilitySuccess: (state, action) => {
            state.productVisibility.data = action?.payload;
            state.productVisibility.isLoading = false;
            state.productVisibility.isError = false;
            state.productVisibility.error = '';
        },
        setProductVisibilityFailed: (state, action) => {
            state.productVisibility.data = null;
            state.productVisibility.isLoading = false;
            state.productVisibility.isError = true;
            state.productVisibility.error = action.payload;
        },
        setProductVisibilityStateClear: (state, action) => {
            state.productVisibility.data = null;
            state.productVisibility.isLoading = false;
            state.productVisibility.isError = false;
            state.productVisibility.error = '';
        },

        singleTrashProductData: (state, action) => {
            state.singleTrashProduct.isLoading = true;
        },
        singleTrashProductSuccess: (state, action) => {
            state.singleTrashProduct.isTrashedProduct = true;
            state.singleTrashProduct.isLoading = false;
            state.singleTrashProduct.isError = false;
            state.singleTrashProduct.error = '';
        },
        singleTrashProductFailed: (state, action) => {
            state.singleTrashProduct.isTrashedProduct = false;
            state.singleTrashProduct.isLoading = false;
            state.singleTrashProduct.isError = true;
            state.singleTrashProduct.error = action.payload;
        },
        singleTrashProductStateClear: (state, action) => {
            state.singleTrashProduct.isTrashedProduct = false
            state.singleTrashProduct.isLoading = false;
            state.singleTrashProduct.isError = false;
            state.singleTrashProduct.error = '';
        },

        singleRestoreProductData: (state, action) => {
            state.singleRestoreProduct.isLoading = true;
        },
        singleRestoreProductSuccess: (state, action) => {
            state.singleRestoreProduct.isSingleRestoreProduct = true;
            state.singleRestoreProduct.isLoading = false;
            state.singleRestoreProduct.isError = false;
            state.singleRestoreProduct.error = '';
        },
        singleRestoreProductFailed: (state, action) => {
            state.singleRestoreProduct.isSingleRestoreProduct = false;
            state.singleRestoreProduct.isLoading = false;
            state.singleRestoreProduct.isError = true;
            state.singleRestoreProduct.error = action.payload;
        },
        singleRestoreProductStateClear: (state, action) => {
            state.singleRestoreProduct.isSingleRestoreProduct = false;
            state.singleRestoreProduct.isLoading = false;
            state.singleRestoreProduct.isError = false;
            state.singleRestoreProduct.error = '';
        },

        singleDeleteProductData: (state, action) => {
            state.singleDeleteProduct.isLoading = true;
        },
        singleDeleteProductSuccess: (state, action) => {
            state.singleDeleteProduct.isSingleProductDelete = true;
            state.singleDeleteProduct.isLoading = false;
            state.singleDeleteProduct.isError = false;
            state.singleDeleteProduct.error = '';
        },
        singleDeleteProductFailed: (state, action) => {
            state.singleDeleteProduct.isSingleProductDelete = false;
            state.singleDeleteProduct.isLoading = false;
            state.singleDeleteProduct.isError = true;
            state.singleDeleteProduct.error = action.payload;
        },
        singleDeleteProductStateClear: (state, action) => {
            state.singleDeleteProduct.isSingleProductDelete = false;
            state.singleDeleteProduct.isLoading = false;
            state.singleDeleteProduct.isError = false;
            state.singleDeleteProduct.error = '';
        },

        multipleTrashProductData: (state, action) => {
            state.multipleTrashProduct.isLoading = true;
        },
        multipleTrashProductSuccess: (state, action) => {
            state.multipleTrashProduct.isMultipleProductTrashed = true;
            state.multipleTrashProduct.isLoading = false;
            state.multipleTrashProduct.isError = false;
            state.multipleTrashProduct.error = '';
        },
        multipleTrashProductFailed: (state, action) => {
            state.multipleTrashProduct.isMultipleProductTrashed = false;
            state.multipleTrashProduct.isLoading = false;
            state.multipleTrashProduct.isError = true;
            state.multipleTrashProduct.error = action.payload;
        },
        multipleTrashProductStateClear: (state, action) => {
            state.multipleTrashProduct.isMultipleProductTrashed = false;
            state.multipleTrashProduct.isLoading = false;
            state.multipleTrashProduct.isError = false;
            state.multipleTrashProduct.error = '';
        },

        multipleDeleteProductData: (state, action) => {
            state.multipleDeleteProduct.isLoading = true;
        },
        multipleDeleteProductSuccess: (state, action) => {
            state.multipleDeleteProduct.isMultipleProductDelete = true;
            state.multipleDeleteProduct.isLoading = false;
            state.multipleDeleteProduct.isError = false;
            state.multipleDeleteProduct.error = '';
        },
        multipleDeleteProductFailed: (state, action) => {
            state.multipleDeleteProduct.isMultipleProductDelete = false;
            state.multipleDeleteProduct.isLoading = false;
            state.multipleDeleteProduct.isError = true;
            state.multipleDeleteProduct.error = action.payload;
        },
        multipleDeleteProductStateClear: (state, action) => {
            state.multipleDeleteProduct.isMultipleProductDelete = false;
            state.multipleDeleteProduct.isLoading = false;
            state.multipleDeleteProduct.isError = false;
            state.multipleDeleteProduct.error = '';
        },

        multipleRestoreProductData: (state, action) => {
            state.multipleRestoreProduct.isLoading = true;
        },
        multipleRestoreProductSuccess: (state, action) => {
            state.multipleRestoreProduct.isMultipleProductRestore = true;
            state.multipleRestoreProduct.isLoading = false;
            state.multipleRestoreProduct.isError = false;
            state.multipleRestoreProduct.error = '';
        },
        multipleRestoreProductFailed: (state, action) => {
            state.multipleRestoreProduct.isMultipleProductRestore = false;
            state.multipleRestoreProduct.isLoading = false;
            state.multipleRestoreProduct.isError = true;
            state.multipleRestoreProduct.error = action.payload;
        },
        multipleRestoreProductStateClear: (state, action) => {
            state.multipleRestoreProduct.isMultipleProductRestore = false;
            state.multipleRestoreProduct.isLoading = false;
            state.multipleRestoreProduct.isError = false;
            state.multipleRestoreProduct.error = '';
        },

        updateProductData: (state, action) => {
            state.updateProduct.isLoading = true;
        },
        updateProductSuccess: (state, action) => {
            state.updateProduct.data = action?.payload;
            state.updateProduct.isProductUpdate = true;
            state.updateProduct.isLoading = false;
            state.updateProduct.isError = false;
            state.updateProduct.error = '';
        },
        updateProductFailed: (state, action) => {
            state.updateProduct.data = null;
            state.updateProduct.isProductUpdate = false;
            state.updateProduct.isLoading = false;
            state.updateProduct.isError = true;
            state.updateProduct.error = action.payload;
        },
        updateProductStateClear: (state, action) => {
            state.updateProduct.data = null;
            state.updateProduct.isProductUpdate = false;
            state.updateProduct.isLoading = false;
            state.updateProduct.isError = false;
            state.updateProduct.error = '';
        },
    }
});

export const {
    productList,
    productListSuccess,
    productListFailed,
    productListClearState,

    setProductVisibility,
    setProductVisibilitySuccess,
    setProductVisibilityFailed,
    setProductVisibilityStateClear,

    singleTrashProductData,
    singleTrashProductSuccess,
    singleTrashProductFailed,
    singleTrashProductStateClear,

    singleRestoreProductData,
    singleRestoreProductSuccess,
    singleRestoreProductFailed,
    singleRestoreProductStateClear,

    singleDeleteProductData,
    singleDeleteProductSuccess,
    singleDeleteProductFailed,
    singleDeleteProductStateClear,

    multipleTrashProductData,
    multipleTrashProductSuccess,
    multipleTrashProductFailed,
    multipleTrashProductStateClear,

    multipleDeleteProductData,
    multipleDeleteProductSuccess,
    multipleDeleteProductFailed,
    multipleDeleteProductStateClear,

    multipleRestoreProductData,
    multipleRestoreProductSuccess,
    multipleRestoreProductFailed,
    multipleRestoreProductStateClear,

    updateProductData,
    updateProductSuccess,
    updateProductFailed,
    updateProductStateClear,

} = ProductListSlice.actions;

export default ProductListSlice.reducer;
