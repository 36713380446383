import React from 'react';
import ImageCropper from "./ImageCropper";
import './Style.css';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};
const SetImageCropInfo = ({open, onClose, image, imageName, onCropImage, ratio, type}) => {

    return (
        <>
            {image && (
                <ImageCropper
                    imgName={imageName}
                    onCropImage={onCropImage}
                    inputImg={image}
                    imgName={"image.png"}
                    closeModal={onClose}
                    ratio={ratio}
                    type={type}
                />
            )}
        </>
    )

}

export default SetImageCropInfo;