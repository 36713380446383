import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isMultipleImageDelete: false,
    isLoading: false,
    isError: false,
    error: '',
    singleImage: {
        isSingleImageDelete: false,
        isLoading: false,
        isError: false,
        error: '',
    },
    galleryImage: {
        data: [],
        page: 1,
        perPage: 10,
        total: 0,
        hasMore: true,
        isLoading: false,
        isFetching: false,
        isError: false,
        error: false,
    }
};

const gallerySlice = createSlice({
    name: 'GalleryImageDelete',
    initialState: initialState,
    reducers: {
        multipleImageDelete: (state, action) => {
            state.isLoading = true;
        },
        multipleImageDeleteSuccess: (state, action) => {
            state.isMultipleImageDelete = true;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        multipleImageDeleteFailed: (state, action) => {
            state.isMultipleImageDelete = false;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        },
        multipleImageDeleteClearState: (state, action) => {
            state.isMultipleImageDelete = false;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },

        singleImageDelete: (state, action) => {
            state.singleImage.isLoading = true;
        },
        singleImageDeleteSuccess: (state, action) => {
            state.singleImage.isSingleImageDelete = true;
            state.singleImage.isLoading = false;
            state.singleImage.isError = false;
            state.singleImage.error = '';
        },
        singleImageDeleteFailed: (state, action) => {
            state.singleImage.isSingleImageDelete = false;
            state.singleImage.isLoading = false;
            state.singleImage.isError = true;
            state.singleImage.error = action.payload;
        },
        singleImageDeleteClearState: (state, action) => {
            state.singleImage.isSingleImageDelete = false;
            state.singleImage.isLoading = false;
            state.singleImage.isError = false;
            state.singleImage.error = '';
        },

        getGalleryImage: (state, action) => {
            state.galleryImage.isLoading = true;
        },
        getGalleryImageSuccess: (state, action) => {
            state.galleryImage.data = action.payload?.data;
            state.galleryImage.dataFound = true;
            state.galleryImage.total = action.payload?.total;
            state.galleryImage.perPage = action.payload?.per_page;
            state.galleryImage.page = action.payload?.current_page;
            state.galleryImage.isLoading = false;
            state.galleryImage.isError = false;
            state.galleryImage.error = '';
        },
        getGalleryImageFailed: (state, action) => {
            state.galleryImage.data = [];
            state.galleryImage.dataFound = false;
            state.galleryImage.total = 0;
            state.galleryImage.perPage = 10;
            state.galleryImage.page = 1;
            state.galleryImage.isLoading = false;
            state.galleryImage.isError = true;
            state.galleryImage.error = action.payload;
        },
        getGalleryImageClearState: (state, action) => {
            state.galleryImage.isLoading = false;
            state.galleryImage.dataFound = false;
            state.galleryImage.isError = false;
            state.galleryImage.error = '';
        },


        getMoreGalleryImage: (state, action) => {
            state.galleryImage.isFetching = true;
        },
        getMoreGalleryImageSuccess: (state, action) => {
            state.galleryImage.data = [...state.galleryImage.data, ...action.payload?.data];
            state.galleryImage.perPage = action.payload?.per_page;
            state.galleryImage.page = action.payload?.current_page;
            state.galleryImage.isFetching = false;
            if (state.galleryImage.total <= state.galleryImage.data.length) {
                state.galleryImage.hasMore = false;
            } else {
                state.galleryImage.hasMore = true;
            }
        },
        getMoreGalleryImageFailed: (state, action) => {
            state.galleryImage.isFetching = false;
            state.galleryImage.hasMore = false;
        },
        getMoreGalleryImageStateClear: (state, action) => {
            state.galleryImage.isFetching = false;
            state.galleryImage.hasMore = true;
        }
}
});

export const {
    multipleImageDelete,
    multipleImageDeleteSuccess,
    multipleImageDeleteFailed,
    multipleImageDeleteClearState,

    singleImageDelete,
    singleImageDeleteSuccess,
    singleImageDeleteFailed,
    singleImageDeleteClearState,

    getGalleryImage,
    getGalleryImageSuccess,
    getGalleryImageFailed,
    getGalleryImageClearState,

    getMoreGalleryImage,
    getMoreGalleryImageSuccess,
    getMoreGalleryImageFailed,
    getMoreGalleryImageStateClear,
} = gallerySlice.actions;

export default gallerySlice.reducer;
