import React, {useState} from 'react';
import {Box, Button, List, ListItem, Stack, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SetImageCropInfo from "../../../assets/global/ImageCropper/SetImageCropInfo";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import useAdjustImageHook from "../hooks/useAdjustImage";
import EditBrandProfileForm from "../../ProfileSettings/EditBrandProfileForm";
import ScrollableModal from "../../../assets/global/Modal/ScrollableModal";

const StyledButton = {
    '&.MuiButton-outlined': {
        color: 'common.white',
        borderColor: 'common.white',
        mb: 1.2
    },

    '&.MuiButton-contained': {
        color: 'common.black',
        bgcolor: 'common.white'
    }
};
const BannerImage = ({ctaHover, setCtaHover, handleImageUpload, bannerImage, setBannerImage, handleRemoveImage}) => {
    const {
        height,
        width,
        modalIsOpen,
        setModalIsOpen,
        handleImageFile,
    } = useAdjustImageHook({bannerImage});
    const handleHover = index => setCtaHover(index);
    let inputElement = null;
    const onChangeHandler = file => {
        setBannerImage({
            data: file[0],
            src: URL.createObjectURL(file[0])
        });
        setModalIsOpen(false);
    }

    return (
        <>
            <Typography variant="h6">Add Banner Image</Typography>

            <List sx={{listStyleType: 'disc', mt: `0 !important`, pl: 3}}>
                <ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>
                    <Typography variant="body2">
                        Use 3:2 ratio images for the best view experience.
                    </Typography>
                </ListItem>
                {/*<ListItem sx={{display: 'list-item', p: 0.5, color: 'text.secondary'}}>*/}
                {/*    <Typography variant="body2">*/}
                {/*        Image upload limit: Minimum 1 & Maximum 5.*/}
                {/*    </Typography>*/}
                {/*</ListItem>*/}
            </List>

            {
                bannerImage?.src ? (
                    <Box position={'relative'}>
                        <img
                            src={bannerImage?.src}
                            style={{
                                // width: '686px',
                                // height: '431px',
                                width: '100%',
                                height: '100%',
                                borderRadius: '6px',
                                gap: '10px',
                                border: '1px',
                            }}
                            alt=""
                        />

                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: '99%',
                                width: '100%',
                                opacity: ctaHover ? 1 : 0,
                                transition: '.5s ease',
                                backgroundColor: 'text.secondary'
                            }}
                            onMouseEnter={() => handleHover(true)}
                            onMouseLeave={() => handleHover(false)}
                        >
                            <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
                                <Box textAlign={'center'} pb={2}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        sx={StyledButton}
                                        onClick={() => handleImageFile(bannerImage?.src)}
                                    >
                                        Adjust Image
                                    </Button>

                                    <Stack
                                        direction={'row'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        spacing={1.2}
                                    >
                                        <Button
                                            sx={StyledButton}
                                            size="small"
                                            variant="contained"
                                            onClick={() => inputElement?.click()}
                                        >
                                            Change
                                        </Button>
                                        <input
                                            ref={input => (inputElement = input)}
                                            accept="image/*"
                                            type="file"
                                            style={{display: "none"}}
                                            onChange={handleImageUpload}
                                        />
                                        <Button
                                            color="error"
                                            size="small"
                                            variant="contained"
                                            onClick={handleRemoveImage}
                                        >
                                            Remove
                                        </Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon/>}
                            /* onClick={onImageUpload} */
                            sx={{cursor: 'pointer'}}
                            onClick={() => inputElement?.click()}
                        >
                            Click to add image
                        </Button>
                        <input
                            ref={input => (inputElement = input)}
                            accept="image/*"
                            type="file"
                            style={{display: "none"}}
                            onChange={handleImageUpload}
                        />
                    </>
                )
            }
            <ScrollableModal
                modalTitle="Adjust Image"
                open={modalIsOpen}
                onClose={() => {
                    setModalIsOpen(prevState => !prevState);
                }}
                width={'auto'}
            >
                <SetImageCropInfo
                    open={modalIsOpen}
                    onClose={() => {
                        setModalIsOpen(prevState => !prevState);
                    }}
                    image={bannerImage?.src}
                    imageName={bannerImage?.data?.name}
                    onCropImage={croppedImg => onChangeHandler([croppedImg])}
                    ratio={height / width <= 0.5 ? true : false}
                    type="1"
                />
            </ScrollableModal>
            {/*<BasicWithHeaderModal*/}
            {/*    modalTitle="Adjust Image"*/}
            {/*    open={modalIsOpen}*/}
            {/*    onClose={() => {*/}
            {/*        setModalIsOpen(prevState => !prevState);*/}
            {/*    }}*/}
            {/*    width={'auto'}*/}
            {/*>*/}
            {/*    <SetImageCropInfo*/}
            {/*        open={modalIsOpen}*/}
            {/*        onClose={() => {*/}
            {/*            setModalIsOpen(prevState => !prevState);*/}
            {/*        }}*/}
            {/*        image={bannerImage?.src}*/}
            {/*        imageName={bannerImage?.data?.name}*/}
            {/*        onCropImage={croppedImg => onChangeHandler([croppedImg])}*/}
            {/*        ratio={height / width <= 0.5 ? true : false}*/}
            {/*        type="1"*/}
            {/*    />*/}
            {/*</BasicWithHeaderModal>*/}
        </>
    )
}
export default BannerImage;