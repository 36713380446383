import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {TeamMemberValidationPattern} from "../../../helpers/constant/validationPattern";
import {Controller} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectTeamMember} from "../../../state/features/TeamMember/teamMemberSelector";
import ImageInput from "../../../assets/global/ImageCropper/ImageInput";
import BasicWithHeaderModal from "../../../assets/global/Modal/BasicWithHeaderModal";
import ChangeTeamMemberPassword from "../ChangeTeamMemberPassword";

const TeamMemberForm = ({control, image, setImage, type = '', teamMemberId}) => {
    const {editTeamMember} = useSelector(selectTeamMember);
    const [showPassword, setShowPassword] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    useEffect(() => {
        if (editTeamMember?.data?.profileLogo) {
            setImage(editTeamMember?.data?.profileLogo);
        }
    }, [editTeamMember?.data?.profileLogo]);

    return (
        <>
            <Typography variant="h6">User Info</Typography>

            <Stack spacing={3}>
                <Box py={5}>
                    <ImageInput
                        image={image}
                        setImage={setImage}
                        type="1"
                    />

                </Box>
                <Controller
                    control={control}
                    name="firstName"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="First Name"
                                fullWidth
                                type="text"
                                focused
                                value={value}
                                onChange={onChange}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={TeamMemberValidationPattern.firstName}
                />
                <Controller
                    control={control}
                    name="lastName"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="Last Name"
                                fullWidth
                                focused
                                type="text"
                                value={value}
                                onChange={onChange}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={TeamMemberValidationPattern.lastName}
                />
                <Controller
                    control={control}
                    name="email"
                    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                        <FormControl size="small" sx={{mb: 2}} fullWidth error={!!(invalid && error.message)}>
                            <TextField
                                label="Email Address"
                                fullWidth
                                focused
                                type="email"
                                value={value}
                                onChange={onChange}
                                error={!!(invalid && error.message)}
                            />
                            <FormHelperText>{invalid && error?.message}</FormHelperText>
                        </FormControl>
                    )}
                    rules={TeamMemberValidationPattern.email}
                />
                {
                    type === 'add' && (
                        <Controller
                            control={control}
                            name="password"
                            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                                <FormControl sx={{my: 2}} fullWidth error={!!(invalid && error.message)}>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        value={value}
                                        onChange={onChange}
                                        error={!!(invalid && error.message)}
                                    />
                                    <FormHelperText>{invalid && error?.message}</FormHelperText>
                                </FormControl>
                            )}
                            rules={TeamMemberValidationPattern.password}
                        />
                    )
                }
                {
                    type === 'edit' && (
                        <Stack
                            direction={'row'}
                            justifyContent="space-between"
                            // sx={{height: {sm: 'auto', lg: 'calc(100vh - 240px)'}, overflowY: 'auto'}}
                        >
                            <Stack>
                                <Typography variant="body1medium" color={'text.primary'}>
                                    Password
                                </Typography>
                                <Typography variant="alertDescription" color={'action.active'}>
                                    **********
                                </Typography>
                            </Stack>
                            <Button
                                variant="text"
                                size={'small'}
                                onClick={() => setChangePasswordModal(true)}
                            >
                                Change Password
                            </Button>
                        </Stack>
                    )
                }

            </Stack>
            <BasicWithHeaderModal
                modalTitle="Change Password"
                width={500}
                open={changePasswordModal}
                onClose={() => setChangePasswordModal(false)}
            >
                <ChangeTeamMemberPassword
                    setChangePasswordModal={setChangePasswordModal}
                    teamMemberId={teamMemberId}
                />
            </BasicWithHeaderModal>
        </>
    );
}

export default TeamMemberForm;