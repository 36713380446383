import React, {useState} from 'react';
import {showErrorMessage} from "../../../helpers/utility/Toastify";

const useAdjustImageHook = ({bannerImageUrl}) => {
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleImageFile = (imageUrl) => {
        if (imageUrl) {
            let img = new Image();
            img.src = imageUrl;

            img.onload = function () {
                setWidth(this.width);
                setHeight(this.height);
            };
            setModalIsOpen(true)
        }
    };

    return {
        height,
        width,
        modalIsOpen,
        setModalIsOpen,
        handleImageFile,
    }
}
export default useAdjustImageHook;