import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    editProductData: null,
    isEditProductData: false,
    isLoading: false,
    isError: false,
    error: '',
};

const EditProductSlice = createSlice({
    name: 'EditProduct',
    initialState: initialState,
    reducers: {
        editProduct: (state, action) => {
            state.isLoading = true;
        },
        editProductSuccess: (state, action) => {
            state.editProductData = action?.payload;
            state.isEditProductData = true;
            state.isLoading = false;
            state.isError = false;
            state.error = '';
        },
        editProductFailed: (state, action) => {
            state.editProductData = null;
            state.isEditProductData = false;
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload?.message;
        },
        editProductClearState: (state, action) => {
            state.editProductData = [];
            state.isEditProductData = false;
            state.isLoading = false;
            state.isError = true;
            state.error = '';
        },
    }
});

export const {
    editProduct,
    editProductSuccess,
    editProductFailed,
    editProductClearState,
} = EditProductSlice.actions;

export default EditProductSlice.reducer;
